import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import { PaginationRequest } from '@shared/pagination';
import { DateTimeValidators } from '@shared/validators';
import { useEffect, useState } from 'react';

import { useFilters } from '@/components/filters';

import { generateDefaultBoardMeetingFilters } from './generate-default-board-meeting-filters';
import { BoardMeetingFilterData, useBoardMeetingService } from './use-board-meeting-service';

/**
 * Hook to manage retrieving Board Members.
 */
export const useBoardMeetings = () => {
  const [filterData, setFilterData] = useFilters<BoardMeetingFilterData>(
    'board-meetings',
    generateDefaultBoardMeetingFilters()
  );
  const [pagination, setPagination] = useState<PaginationRequest>({
    page: 1,
    rpp: 25
  });
  const { getBoardMeetings } = useBoardMeetingService();
  const { value, status, error, execute } = useAsync(getBoardMeetings, true);
  const { handleError } = useErrorHandler();
  const { loading, dismiss, error: notifyAsError } = useNotifications();

  // load initial
  useEffect(() => {
    if (DateTimeValidators.isValidDate(filterData.dateRangeBegin) || DateTimeValidators.isValidDate(filterData.dateRangeEnd)) {
      const notification = notifyAsError({
        title: 'Start and End Date must be in the format MM/DD/YYYY',
        autoClose: false
      });

      return () => dismiss(notification.id);
    }

    execute({ ...filterData, ...pagination });
  }, [filterData, pagination]);

  useEffect(() => {
    if (status === UseAsyncStatus.Pending && value !== null) {
      const notification = loading({ title: 'Refreshing Events...' });
      return () => dismiss(notification.id);
    }

    if (status !== UseAsyncStatus.Error) return;

    handleError(
      error,
      {
        title: 'Failed to load Board Meetings',
        autoClose: false
      }
    );
  }, [status]);

  return {
    boardMeetings: value?.items ?? [],
    loading: status === UseAsyncStatus.Pending,
    refresh: execute,
    filterData,
    setFilterData,
    setPagination,
    page: value?.page ?? 1,
    resultsPerPage: value?.resultsPerPage ?? 25,
    total: value?.total ?? 0
  };
};
