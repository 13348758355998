import { MarkdownPreview } from '@shared/markdown/markdown-preview';
import { Anchor, Box, NameValueList, NameValuePair, Text } from 'grommet';
import React from 'react';
import styled from 'styled-components';

import { ArtiaButton } from '@/components/artia-button';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { formatDate } from '@/utils/date-time-functions';

import { ChangelogProperty, ClinicalsChangeLog } from '../../types';

type ClinicalsChangeLogSnapshotDialogProps = {
  open: boolean;
  row?: ClinicalsChangeLog;
  onClose: () => void;
};

export const ClinicalsChangeLogSnapshotDialog = (props: ClinicalsChangeLogSnapshotDialogProps) => {
  const {
    open,
    row,
    onClose
  } = props;

  if (!open) return <></>;

  const formatNullableBoolean = (value?: boolean): string => {
    if (value === undefined || value === null) return '';

    return value ? 'Yes' : 'No';
  };

  return (
    <Dialog width="xlarge" open={open}>
      <DialogHeader
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        title={`${row.state} - ${row.drug}`}
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        subtitle={`Updated by ${row.audits[0].name} on ${row.audits[0].updatedAt}`}
        onClose={onClose}
      />
      <DialogBody>
        <NameValueList nameProps={{ width: '16rem' }} valueProps={{ width: '100%' }} margin="small">
          <NameValuePair>
            <Box width="100%" direction="row" gap="small">
              <Box basis="1/2">
                <Text weight="bold">Previous Version</Text>
              </Box>
              <Box basis="1/2">
                <Text weight="bold">Updated Version</Text>
              </Box>
            </Box>
          </NameValuePair>
          <NameValuePair name="PDL Status">
            {/* @ts-expect-error TS(2322): Type 'ChangelogProperty<string> | undefined' is no... Remove this comment to see the full error message */}
            <DiffTextRow property={row.updatedProperties?.pdlStatus} />
          </NameValuePair>
          <NameValuePair name="PDL Status Date">
            {/* @ts-expect-error TS(2322): Type 'ChangelogProperty<string> | undefined' is no... Remove this comment to see the full error message */}
            <DiffTextRow property={row.updatedProperties?.pdlStatusDate} format={(value) => formatDate(value)} />
          </NameValuePair>
          <NameValuePair name="Status Change Effective Date">
            {/* @ts-expect-error TS(2322): Type 'ChangelogProperty<string> | undefined' is no... Remove this comment to see the full error message */}
            <DiffTextRow property={row.updatedProperties?.pdlStatusEffectiveDate} format={(value) => formatDate(value)} />
          </NameValuePair>
          <NameValuePair name="PA Types">
            {/* @ts-expect-error TS(2322): Type 'ChangelogProperty<string> | undefined' is no... Remove this comment to see the full error message */}
            <DiffTextRow property={row.updatedProperties?.paTypes} format={(value) => value.join(', ')} />
          </NameValuePair>
          <NameValuePair name="Step Therapy">
            <Box width="100%" direction="row" gap="small">
              <Box basis="1/2">
                {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
                <Text>{row.updatedProperties?.stepTherapyCount?.old}{row.updatedProperties?.hasAdditionalSteps?.old && '+'}</Text>
              </Box>
              <Box basis="1/2">
                {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
                <Text>{row.updatedProperties?.stepTherapyCount?.new}{row.updatedProperties?.hasAdditionalSteps?.new && '+'}</Text>
              </Box>
            </Box>
          </NameValuePair>
          <NameValuePair name="Has Auto PA?">
            {/* @ts-expect-error TS(2322): Type 'ChangelogProperty<boolean> | undefined' is n... Remove this comment to see the full error message */}
            <DiffTextRow property={row.updatedProperties?.hasAutoPa} format={(value) => formatNullableBoolean(value)} />
          </NameValuePair>
          <NameValuePair name="PA Criteria Description">
            <Box width="100%" direction="row" gap="small">
              <Box basis="1/2">
                {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
                <MarkdownPreview markdown={row.updatedProperties?.paCriteriaDescription?.old} />
              </Box>
              <Box basis="1/2">
                {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
                <MarkdownPreview markdown={row.updatedProperties?.paCriteriaDescription?.new} />
              </Box>
            </Box>
          </NameValuePair>
          <NameValuePair name="PA Criteria Source URL">
            <Box width="100%" direction="row" gap="small">
              <Box basis="1/2">
                <StyledAnchor
                  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                  href={row.updatedProperties?.paCriteriaSourceUrl?.old}
                  referrerPolicy="no-referrer"
                  target="_blank"
                >
                  {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
                  {row.updatedProperties?.paCriteriaSourceUrl?.old}
                </StyledAnchor>
              </Box>
              <Box basis="1/2">
                <StyledAnchor
                  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                  href={row.updatedProperties?.paCriteriaSourceUrl?.new}
                  referrerPolicy="no-referrer"
                  target="_blank"
                >
                  {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
                  {row.updatedProperties?.paCriteriaSourceUrl?.new}
                </StyledAnchor>
              </Box>
            </Box>
          </NameValuePair>
          <NameValuePair name="Notes">
            <Box width="100%" direction="row" gap="small">
              <Box basis="1/2">
                {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
                <MarkdownPreview markdown={row.updatedProperties?.notes?.old} />
              </Box>
              <Box basis="1/2">
                {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
                <MarkdownPreview markdown={row.updatedProperties?.notes?.new} />
              </Box>
            </Box>
          </NameValuePair>
        </NameValueList>
      </DialogBody>
      <DialogActions>
        <ArtiaButton
          label="Close"
          type="button"
          onClick={onClose}
          size="large"
          variant="outlined"
        />
      </DialogActions>
    </Dialog>
  );
};

const StyledAnchor = styled(Anchor)`
    word-wrap: break-word;
`;

const RemovedText = styled(Text)`
  background: rgba(207, 34, 46, 0.2);
  color: rgb(207, 34, 46);
`;

const AddedText = styled(Text)`
  background: rgba(31, 136, 61, 0.2);
  color: rgb(31, 136, 61);
`;

const UpdatedText = styled(Text)`
  background: rgba(5, 80, 174, 0.2);
  color: rgb(5, 80, 174);
`;

const DiffTextRow = ({ property, format }: DiffRowProps) => {
  const formatFn = format || ((value: any) => value);

  const left = formatFn(property?.old);
  const right = formatFn(property?.new);

  let leftComponent;
  let rightComponent;

  if (property.isUpdated) {
    if (!left && right) {
      leftComponent = <Text>{left}</Text>;
      rightComponent = <AddedText>{right}</AddedText>;
    } else if (left && !right) {
      leftComponent = <RemovedText>{left}</RemovedText>;
      rightComponent = <Text>{right}</Text>;
    } else {
      leftComponent = <UpdatedText>{left}</UpdatedText>;
      rightComponent = <UpdatedText>{right}</UpdatedText>;
    }
  } else {
    leftComponent = <Text>{left}</Text>;
    rightComponent = <Text>{right}</Text>;
  }

  return (
    <Box width="100%" direction="row" gap="small">
      <Box basis="1/2">
        {leftComponent}
      </Box>
      <Box basis="1/2">
        {rightComponent}
      </Box>
    </Box>
  );
};

type DiffRowProps = {
  property: ChangelogProperty<any>;
  format?: (value: any) => string;
};
