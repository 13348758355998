import { FileDownload, useHttpClient } from '@shared/http';

import { NetCostComparisonRoot, PackagingNetCostComparison, TagNetCostComparison } from '@/features/bid-analysis/types/net-cost-comparison';

export const useNetCostComparisonService = (bidAnalysisId: number) => {
  const httpClient = useHttpClient();

  const updateNetCostComparisonPackaging = async (
    packagingId: number,
    { pharmacyReimbursementUnit }: { pharmacyReimbursementUnit: number | null }
  ): Promise<PackagingNetCostComparison> => {
    return await httpClient.put<PackagingNetCostComparison>(
      `bid-analyses/${bidAnalysisId}/net-cost-comparison/packagings/${packagingId}`,
      { data: { pharmacyReimbursementUnit } }
    );
  };

  const getInitialView = (): Promise<NetCostComparisonRoot> => {
    return httpClient.get(`bid-analyses/${bidAnalysisId}/net-cost-comparison`);
  };

  const expandProduct = (stateCode: string, productId: number, tagId?: number): Promise<ExpandProductResponse> => {
    if (tagId) {
      return httpClient.get(`bid-analyses/${bidAnalysisId}/net-cost-comparison/states/${stateCode}/tags/${tagId}`);
    }

    return httpClient.get(`bid-analyses/${bidAnalysisId}/net-cost-comparison/states/${stateCode}/drugs/${productId}`);
  };

  const exportToCsv = (): Promise<FileDownload> => {
    return httpClient.download('POST', `bid-analyses/${bidAnalysisId}/export`);
  };

  return {
    getInitialView,
    exportToCsv,
    updateNetCostComparisonPackaging,
    expandProduct
  };
};

type ExpandProductResponse = {
  tags?: TagNetCostComparison[];
  packagings?: PackagingNetCostComparison[];
};
