import React from 'react';

import { Seo } from '@/components-new/seo';
import { isCallableFuntion } from '@/utils/guards';

type PageHeaderProps = {
  title?: string;
}

type HeaderRender = (headerProps: PageHeaderProps) => React.ReactNode;

type PageProps = {
  children?: React.ReactNode;
  description?: string;
  header?: React.ReactNode | HeaderRender;
  title?: string;
}
export const Page = ({
  children,
  header,
  title,
  description,
}: PageProps) => {
  return (
    <div>
      <Seo title={title} description={description}/>
      <div>
        {isCallableFuntion(header) ? header({ title }) : header}
      </div>
      <div className="mt-6 flex flex-col gap-6">
        {children}
      </div>
    </div>
  );
};
