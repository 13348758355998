import { useErrorHandler } from '@shared/errors';
import { PaginationRequest, SortingInfo } from '@shared/pagination';
import { Box, RadioButtonGroup, Text } from 'grommet';
import React, { useEffect, useId, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ArtiaButton } from '@/components/artia-button';
import { Busy } from '@/components/busy';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { BorderlessFormField } from '@/components/form-controls';
import {
  CoverageCsvExportFormat,
  CoverageExportCsvFormatRequest,
  useCoverageService
} from '@/features/coverage/api/use-coverage-service';

import { FilterInputs } from './coverage-filters';

type ExportToAtlasCsvProps = {
  /**
   * Whether the dialog is open, required. Usually driven by state in the caller.
   */
  open: boolean;

  /**
   * The current search parameters to filter results by, required. Usually driven by a table's filters.
   */
  filters: FilterInputs & PaginationRequest & SortingInfo;

  /**
   * Callback that runs when the user closes the dialog without completing the primary action, required.
   * Usually used to just make `open` false.
   */
  onCancel: () => void;

  /**
   * Callback that runs when the user completes the dialog's primary action, required. Like `onCancel`, also
   * used to make `open` false, and do any other necessary post-completion work.
   */
  onSubmitComplete: () => void;
};

/**
 * Clinicals export dialog. Allows the user to select between multiple formats.
 */
export const ExportToCsvDialog = ({ open, filters, onCancel, onSubmitComplete }: ExportToAtlasCsvProps) => {
  const formId = useId();
  const { exportToCsv } = useCoverageService();
  const { handleError } = useErrorHandler();
  const [saving, setSaving] = useState(false);

  async function onSubmit(formatData: CoverageExportCsvFormatRequest) {
    try {
      const request = formatData.format === CoverageCsvExportFormat.ATLAS
        ? formatData
        : { format: formatData.format };
      setSaving(true);
      const csv = await exportToCsv(request, filters);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(csv.contents);
      link.download = csv.filename;
      link.click();
      onSubmitComplete();
    } catch (e) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      handleError(e, {
        title: 'Download Failed',
        message: 'We encountered an unexpected error while downloading your file. Please try again or contact an administrator.',
        autoClose: false
      });
    } finally {
      setSaving(false);
    }
  }

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
    watch
  } = useForm<CoverageExportCsvFormatRequest>({
    mode: 'all',
    defaultValues: {
      format: CoverageCsvExportFormat.IMPORTER
    }
  });
  const format = watch('format');
  const submitDisabled = saving || !isValid;
  useEffect(() => {
    reset();
  }, [open, reset]);

  return (
    <Dialog open={open} width="large">
      {/* @ts-expect-error TS(2322): Type 'false | (() => void)' is not assignable to t... Remove this comment to see the full error message */}
      <DialogHeader title="Export to CSV" onClose={!saving && onCancel} />
      <DialogBody>
        <Box
          id={formId}
          as="form"
          fill="horizontal"
          gap="medium"
          onSubmit={handleSubmit((data: CoverageExportCsvFormatRequest) => onSubmit(data))}
        >
          <Controller
            control={control}
            name="format"
            rules={{ required: true }}
            render={({ field: { value, onChange, onBlur, name } }) => (
              <>
                <Text as="label" margin="none" weight="bold">Format</Text>
                <BorderlessFormField
                  required
                  name={name}
                  htmlFor={`${formId}-${name}`}
                  error={errors.format?.message}
                >
                  <RadioButtonGroup
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    direction="row"
                    name={name}
                    id={`${formId}-${name}`}
                    options={[CoverageCsvExportFormat.IMPORTER, CoverageCsvExportFormat.ATLAS]}
                  />
                </BorderlessFormField>
              </>
            )}
          />
          {format === CoverageCsvExportFormat.ATLAS ? (
            <Text>Rows without a PDL Status or with a PDL Status of "Invalid" will not be exported.</Text>
          ) : null}
        </Box>
      </DialogBody>
      <DialogActions>
        <ArtiaButton label="Cancel" type="button" onClick={onCancel} disabled={saving} size="large" variant="outlined" />
        <ArtiaButton
          type="submit"
          form={formId}
          label={<Busy busy={saving} content="Download CSV" />}
          disabled={submitDisabled}
          size="large"
        />
      </DialogActions>
    </Dialog>
  );
};
