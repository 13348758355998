export const isAcceptedType = (accept: string) => (value: File): string | undefined => {
  if (value?.name == null || accept == null) return;

  const acceptedTypes = accept.split(',').map(type => type.trim().toLowerCase());

  const validationMessage = `The file's type must be one of the following: ${acceptedTypes.join(', ')}.`;

  const splitName = value.name.split('.').reverse();

  if (splitName.length === 0) return validationMessage;

  const fileType = `.${splitName[0]}`;

  if (!acceptedTypes.includes(fileType.toLowerCase())) return validationMessage;
};
