import { useCallback, useState } from 'react';

type UseToggle = (initialValue?: boolean) => [boolean, () => void];

export const useToggle: UseToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  const toggle = useCallback(() => setValue((value) => !value), []);

  return [value, toggle];
};
