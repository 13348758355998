import { TextInput } from 'grommet';
import React, { ChangeEventHandler, forwardRef } from 'react';

type DateTextInputProps = {
  id?: string;
  name?: string;
  onChange?: ChangeEventHandler;
  onBlur?: (event: React.FocusEvent) => any;
  value?: string;
};

export const TimeTextInput = forwardRef<HTMLInputElement, DateTextInputProps>(({ value, ...props }, ref) => {
  return (
    <TextInput
      {...props}
      type="time"
      ref={ref}
      value={value}
    />
  );
});
