import { FileDownload, RequestOptions, useHttpClient } from '@shared/http';

import { MarketBasket } from '@/features/market-baskets';
import { CreateMarketBasketFormInputs } from '@/features/market-baskets/types/market-basket-form-inputs';
import { useFileDownload } from '@/hooks/use-file-download';

type CreateMarketBasketRequest = {
  name: string;
  clientId: number;
  currentCpiu: number;
  currentCpiuDate: string;
};

export type UpdateMarketBasketRequest = {
  name: string;
  currentCpiu: number;
  currentCpiuDate: string;
};

export type UpdateOverridesRequest = {
  wacPrice?: string,
  wacUnitPrice?: string,
  amp?: string,
  totalUra?: string,
  uroa?: string
};

export const useMarketBasketService = () => {
  const httpClient = useHttpClient();

  const associateUnknownPackagings = (drugId: number, ndcs: string[]): Promise<void> => {
    return httpClient.post('market-baskets/associate-packagings', {
      data: {
        drugId: drugId,
        ndcs: ndcs
      }
    });
  };

  const getMarketBasket = (id: number): Promise<MarketBasket> => {
    return httpClient.get(`market-baskets/${id}`);
  };

  const getMarketBaskets = (): Promise<MarketBasket[]> => {
    return httpClient.get('market-baskets');
  };

  const deleteMarketBasketRows = (id: number, ndcs: string[]): Promise<void> => {
    return httpClient.delete(`market-baskets/${id}/market-details`, {
      data: ndcs
    });
  };

  const downloadAttachment = useFileDownload();

  const createMarketBasket = (form: CreateMarketBasketFormInputs): Promise<any> => {
    const data: CreateMarketBasketRequest = {
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      name: form.name?.length > 0 ? form.name : null,
      clientId: form.client?.id,
      currentCpiu: parseFloat(form.currentCpiu),
      currentCpiuDate: form.currentCpiuDate.slice(0, 10)
    };

    const options: RequestOptions<any> = {
      data
    };

    return httpClient.post('market-baskets', options);
  };

  const updateMarketBasket = (marketBasketId: number | string, request: UpdateMarketBasketRequest): Promise<any> => {
    const options: RequestOptions<any> = {
      data: request
    };

    return httpClient.put(`market-baskets/${marketBasketId}`, options);
  };

  const exportMarketBasket = (id: number): Promise<FileDownload> => {
    return httpClient.download('POST', `market-baskets/${id}/export`);
  };

  const uploadMarketDetailsFile = (file: File, marketBasketId: number): Promise<any> => {
    const data = new FormData();

    data.append('Upload', file);

    const options: RequestOptions<any> = {
      data
    };

    return httpClient.post(`market-baskets/${marketBasketId}/import-market-details`, options);
  };

  const uploadAttachment = (file: File, marketBasketId: number): Promise<any> => {
    const data = new FormData();

    data.append('Upload', file);

    const options: RequestOptions<any> = {
      data
    };

    return httpClient.post(`market-baskets/${marketBasketId}/attachments`, options);
  };

  const recalculate = (marketBasketId: number): Promise<any> => {
    return httpClient.post(`market-baskets/${marketBasketId}/recalculate`);
  };

  const updateOverrides = (marketBasketId: number | string, ndc: string, request: UpdateOverridesRequest) => {
    const options: RequestOptions<any> = {
      data: request
    };

    return httpClient.put(`market-baskets/${marketBasketId}/packagings/${ndc}/overrides`, options);
  };

  return {
    associateUnknownPackagings,
    getMarketBasket,
    getMarketBaskets,
    deleteMarketBasketRows,
    downloadAttachment,
    createMarketBasket,
    exportMarketBasket,
    updateMarketBasket,
    uploadAttachment,
    uploadMarketDetailsFile,
    updateOverrides,
    recalculate
  };
};
