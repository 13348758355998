import { ProtectedRoute } from '@shared/auth';

import { BidAnalysisDependencies } from '@/features/bid-analysis';

const BidAnalysesLayout = () => {
  return (
    <ProtectedRoute component={BidAnalysisDependencies} policies={['canManageBidAnalyses', 'isAssignedToAnyClient']} />
  );
};

export default BidAnalysesLayout;
