import { Box, FormField } from 'grommet';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { CurrencyTextInput } from '@/components/form-controls';
import { OverridableCompendiumValue } from '@/types/overridable-compendium-value';
import { formatCurrency } from '@/utils/formatting';

type WacPriceCellProps = {
  ndc: string,
  wac?: OverridableCompendiumValue
  isOverriding: boolean,
  control: Control<any>,
};

export const WacPriceCell = (props: WacPriceCellProps) => {
  const {
    ndc,
    wac,
    isOverriding,
    control
  } = props;

  const formKey = `${ndc}.wac`;

  return (
    <Box width={{ min: '8rem' }}>
      {isOverriding &&
        <Controller
          control={control}
          name={formKey}
          render={({ field: { value, onChange, onBlur } }) => (
            <FormField
              required
              name={formKey}
              htmlFor={formKey}
              margin="none"
            >
              <CurrencyTextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                id={formKey}
                name={formKey}
              />
            </FormField>
          )}
        />
      }

      {!isOverriding && wac?.overrideValue != null &&
        <span>{formatCurrency(wac.overrideValue)}</span>
      }

      {!isOverriding && wac?.overrideValue == null && wac?.compendiumValue != null &&
        <span>{formatCurrency(wac.compendiumValue)}</span>
      }

      {!isOverriding && wac?.overrideValue == null && wac?.compendiumValue == null &&
        <span><>&mdash;</></span>
      }
    </Box>
  );
};
