import Bugsnag, {
  BrowserConfig,
  Client,
  Config,
  User
} from '@bugsnag/js';
import { NodeConfig } from '@tiptap/react';
import { noop } from 'lodash';

export const noOpBugsnag = (): typeof Bugsnag => {
  return {
    // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'typeof... Remove this comment to see the full error message
    Breadcrumb: undefined,
    // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'typeof... Remove this comment to see the full error message
    Event: undefined,
    // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'typeof... Remove this comment to see the full error message
    Session: undefined,
    _notify: noop,
    addFeatureFlag: noop,
    addFeatureFlags: noop,
    addMetadata: noop,
    addOnBreadcrumb: noop,
    addOnError: noop,
    addOnSession: noop,
    clearFeatureFlag: noop,
    clearFeatureFlags: noop,
    clearMetadata: noop,
    getContext(): string | undefined { return undefined; },
    getMetadata: noop,
    getPlugin(_: string): any {},
    // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'User'.
    getUser(): User { return undefined; },
    leaveBreadcrumb: noop,
    notify: noop,
    pauseSession: noop,
    removeOnBreadcrumb: noop,
    removeOnError: noop,
    removeOnSession: noop,
    // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'Client... Remove this comment to see the full error message
    resumeSession(): Client { return undefined; },
    setContext: noop,
    setUser: noop,
    // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'Client... Remove this comment to see the full error message
    startSession(): Client { return undefined; },
    createClient(_: string | BrowserConfig | NodeConfig | Config): Client { return noopBugsnagClient; },
    isStarted(): boolean { return true; },
    start(_: string | BrowserConfig | NodeConfig | Config): Client {
      return noopBugsnagClient;
    }
  };
};

const noopBugsnagClient: Client = {
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'typeof... Remove this comment to see the full error message
  Breadcrumb: undefined,
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'typeof... Remove this comment to see the full error message
  Event: undefined,
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'typeof... Remove this comment to see the full error message
  Session: undefined,
  _notify: noop,
  addFeatureFlag: noop,
  addFeatureFlags: noop,
  addMetadata: noop,
  addOnBreadcrumb: noop,
  addOnError: noop,
  addOnSession: noop,
  clearFeatureFlag: noop,
  clearFeatureFlags: noop,
  clearMetadata: noop,
  getContext(): string | undefined { return undefined; },
  getMetadata: noop,
  getPlugin(_: 'react' | string): any {},
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'User'.
  getUser(): User { return undefined; },
  leaveBreadcrumb: noop,
  notify: noop,
  pauseSession: noop,
  removeOnBreadcrumb: noop,
  removeOnError: noop,
  removeOnSession: noop,
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'Client... Remove this comment to see the full error message
  resumeSession(): Client { return undefined; },
  setContext: noop,
  setUser:noop,
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'Client... Remove this comment to see the full error message
  startSession(): Client { return undefined; }
};
