import { defaultTo, split } from 'lodash';

import { safeJsonParse } from '@/utils/parsing';

type AppConfig = {
  routerBaseUrl?: string;
  bugsnagApiKey?: string;
  bugsnagEnabled: boolean;
  bugsnagReleaseStage?: string;
  bugsnagEnabledReleaseStages?: string[]
  logLevel?: string;
  oidcProviderUrl?: string;
  oidcClientId?: string;
  port?: number;
  env: string;
  isDev: boolean;
}

export const getAppConfig = (): AppConfig => ({
  routerBaseUrl: import.meta.env.VITE_ROUTER_BASE_URL,
  bugsnagApiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  bugsnagEnabled: Boolean(safeJsonParse<boolean>(import.meta.env.VITE_BUGSNAG_ENABLED)),
  bugsnagReleaseStage: import.meta.env.VITE_BUGSNAG_RELEASE_STAGE,
  bugsnagEnabledReleaseStages: import.meta.env.VITE_BUGSNAG_ENABLED_RELEASE_STAGES
    ? split(import.meta.env.VITE_BUGSNAG_ENABLED_RELEASE_STAGES, ' ')
    : undefined,
  logLevel: import.meta.env.VITE_LOG_LEVEL,
  oidcClientId: import.meta.env.VITE_OIDC_CLIENT_ID,
  oidcProviderUrl: import.meta.env.VITE_OIDC_PROVIDER_URL,
  port: defaultTo(safeJsonParse<number>(import.meta.env.VITE_PORT), undefined),
  // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
  env: process.env.NODE_ENV,
  isDev: process.env.NODE_ENV !== 'production',
});
