import { useSuspenseQuery } from '@tanstack/react-query';

import { CoverageTag } from '@/features/drugs/types/coverage-tag';
import { artiaProtectedApi } from '@/lib/api-client';

const getCoverageTag = (drugId: number, coverageTagId: number): Promise<CoverageTag> => {
  return artiaProtectedApi.get(`drugs/${drugId}/coverage-tags/${coverageTagId}`);
};

export const makeCoverageTagQueryKey = (drugId: number, coverageTagId: number) => ['drug', drugId, 'coverage-tags', coverageTagId];

export const useCoverageTag = (drugId: number, coverageTagId: number) => {
  const { data, error, isFetching, dataUpdatedAt } = useSuspenseQuery({
    queryKey: makeCoverageTagQueryKey(drugId, coverageTagId),
    queryFn: async () => getCoverageTag(drugId, coverageTagId)
  });

  if (error && !isFetching) throw error;

  return { data, dataUpdatedAt };
};
