import { Box, FormField, TextInput } from 'grommet';
import { Search } from 'grommet-icons';
import React from 'react';

import { GrommetFilterForm } from '@/components/filters';

type MarketBasketFiltersProps = {
  defaultValue: MarketBasketFilterData;
  onApplyFilters: (data: MarketBasketFilterData) => void;
}

export const MarketBasketFilters = ({ defaultValue, onApplyFilters }: MarketBasketFiltersProps) => {
  return (
    <GrommetFilterForm
      defaultValue={defaultValue}
      emptyValues={{ name: '', client: '' }}
      onChange={(value) => onApplyFilters(value)}
    >
      <Box width="medium">
        <FormField
          name="name"
          htmlFor="name"
          margin="none"
        >
          <TextInput icon={<Search color="accent-1" />} id="name" name="name" placeholder="Search By Market Basket Name" />
        </FormField>
      </Box>
      <Box width="medium">
        <FormField
          name="client"
          htmlFor="client"
          margin="none"
        >
          <TextInput icon={<Search color="accent-1" />} id="client" name="client" placeholder="Search By Client" />
        </FormField>
      </Box>
    </GrommetFilterForm>
  );
};

export type MarketBasketFilterData = { name?: string, client?: string };
