import { useCallback, useState } from 'react';

export enum UseAsyncStatus {
  Idle = 'IDLE',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Error = 'ERROR',
}

export type UseAsyncResult<TParameters extends unknown[], TResult> = {
  value: TResult,
  error: Error,
  status: UseAsyncStatus,
  previousParameters: TParameters,
  execute: (...args: TParameters) => Promise<TResult>,
  isSuccess: boolean;
  isIdle: boolean;
  isError: boolean;
  isPending: boolean;
}

export const useAsync = <TParameters extends unknown[], TResult>(
  asyncFunction: (...args: TParameters) => Promise<TResult>,
  keepValueAfterExecute = false
): UseAsyncResult<TParameters, TResult> => {
  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [value, setValue] = useState<TResult>(null);
  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [error, setError] = useState<Error>(null);
  const [status, setStatus] = useState<UseAsyncStatus>(UseAsyncStatus.Idle);
  // @ts-expect-error TS(2352): Conversion of type 'never[]' to type 'TParameters'... Remove this comment to see the full error message
  const [previousParameters, setPreviousParameters] = useState([] as TParameters);

  const execute = useCallback(async (...args: TParameters) => {
    setStatus(UseAsyncStatus.Pending);
    if (!keepValueAfterExecute) {
      // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      setValue(null);
    }
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setError(null);
    setPreviousParameters(args);

    try {
      const result = await asyncFunction(...args);
      setValue(result);
      setStatus(UseAsyncStatus.Success);
      return result;
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      setError(error);
      setStatus(UseAsyncStatus.Error);
      throw error;
    }
  }, [asyncFunction, keepValueAfterExecute]);

  return {
    value,
    error,
    status,
    previousParameters,
    execute,
    isError: status === UseAsyncStatus.Error,
    isIdle: status === UseAsyncStatus.Idle,
    isPending: status === UseAsyncStatus.Pending,
    isSuccess: status === UseAsyncStatus.Success
  };
};
