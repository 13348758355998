import { useSuspenseQuery } from '@tanstack/react-query';

import { CoverageTag } from '@/features/drugs/types/coverage-tag';
import { artiaProtectedApi } from '@/lib/api-client';

const getCoverageTags = (id: number): Promise<CoverageTag[]> => {
  return artiaProtectedApi.get(`drugs/${id}/coverage-tags`);
};

export const makeCoverageTagsQueryKey = (id: string | number) => ['drug', id, 'coverage-tags'];

export const useCoverageTags = (drugId: number) => {
  const { data, error, isFetching, dataUpdatedAt } = useSuspenseQuery({
    queryKey: makeCoverageTagsQueryKey(drugId),
    queryFn: async () => getCoverageTags(drugId)
  });

  if (error && !isFetching) throw error;

  return { data, dataUpdatedAt };
};
