import { User } from 'oidc-client-ts';

import { env } from '@/config/env';

const USER_STORAGE_KEY = `oidc.user:${env.OIDC_PROVIDER_URL}:${env.OIDC_CLIENT_ID}`;

/**
 * Retrieves the user object from the local storage.
 */
export const getUserFromLocalStorage = () => {
  const oidcStorage = localStorage.getItem(USER_STORAGE_KEY);
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
};

export const setUserInLocalStorage = (user: User) => {
  console.log(user.toStorageString());
  console.log(USER_STORAGE_KEY);
  localStorage.setItem(USER_STORAGE_KEY, user.toStorageString());
};

export const deleteUserFromLocalStorage = () => {
  localStorage.removeItem(USER_STORAGE_KEY);
};
