import { z } from 'zod';

const createEnv = () => {
  const EnvSchema = z.object({
    BASE_API_URL: z.string(),
    BUGSNAG_API_KEY: z.string().optional(),
    BUGSNAG_ENABLED: z
      .string()
      .refine((it) => it === 'true' || it === 'false')
      .transform((it) => it === 'true')
      .optional(),
    LOG_LEVEL: z.string().optional(),
    OIDC_PROVIDER_URL: z.string(),
    OIDC_CLIENT_ID: z.string(),
    REACT_QUERY_DEV_TOOLS_ENABLED: z
      .string()
      .refine((it) => it === 'true' || it === 'false')
      .transform((it) => it === 'true')
      .optional(),
  });

  const envVars = Object.entries(import.meta.env)
    .reduce<Record<string, string>>((acc, curr) => {
      const [key, value] = curr;
      if (key.startsWith('VITE_')) {
        acc[key.replace('VITE_', '')] = value;
      }

      return acc;
    }, {});

  const parsedEnv = EnvSchema.safeParse(envVars);

  if (!parsedEnv.success) {
    throw new Error(
      `Invalid env provided.
      The following variables are missing or invalid:
      ${Object.entries(parsedEnv.error.flatten().fieldErrors)
        .map(([key, value]) => `${key}: ${value}`)
        .join('\n')
      }`
    );
  }

  return parsedEnv.data;
};

export const env = createEnv();
