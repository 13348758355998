import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import { useEffect, useMemo, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import { DrugPackagingFormFields } from '@/components/drug-packagings-form';
import { useDrugPackagingsService } from '@/features/drugs/api/use-drug-packagings-service';
import { DrugPackaging } from '@/features/drugs/types/drug-packaging';

const defaultInitialFormValue: DrugPackagingFormFields = {
  brandGenericStatus: '',
  licenseType: '',
  onMarketWacPrice: '',
  onMarketWacUnitPrice: '',
  cmsOnMarketDate: '',
  wacBeginDate: '',
  baselineCpiu: '',
  baselineCpiuDate: ''
};

type UseDrugPackagingFormResult = {
  form: UseFormReturn<DrugPackagingFormFields>
  saveDrugPackaging: (productId: number, ndc: string, payload: DrugPackagingFormFields) => void
  savingDrugPackaging: boolean,
  savedDrugPackagingSuccessfully: boolean,
  submitForm: (productId: number, ndc: string) => void,
  fetchDrugPackaging: (productId: number, ndc: string) => void,
  fetchingDrugPackaging: boolean
  drugPackaging?: DrugPackaging
};

export const useDrugPackagingForm = (initialFormValue = defaultInitialFormValue): UseDrugPackagingFormResult => {
  const form = useForm<DrugPackagingFormFields>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialFormValue
  });

  const notifications = useNotifications();
  const { handleError } = useErrorHandler();
  const { updateDrugPackaging, getDrugPackaging } = useDrugPackagingsService();
  const saveDrugPackagingAsync = useAsync(updateDrugPackaging);
  const fetchDrugPackagingAsync = useAsync(getDrugPackaging);
  const [ drugPackaging, setDrugPackaging ] = useState<DrugPackaging>();

  const saveDrugPackaging = (productId: number, ndc: string, payload: DrugPackagingFormFields) => {
    saveDrugPackagingAsync.execute(productId, ndc, payload);
  };

  const savingDrugPackaging = useMemo(() => {
    return saveDrugPackagingAsync.status === UseAsyncStatus.Pending;
  }, [saveDrugPackagingAsync.status]);

  const savedDrugPackagingSuccessfully = useMemo(() => {
    return saveDrugPackagingAsync.status === UseAsyncStatus.Success;
  }, [saveDrugPackagingAsync.status]);

  const fetchingDrugPackaging = useMemo(() => {
    return fetchDrugPackagingAsync.status === UseAsyncStatus.Pending || fetchDrugPackagingAsync.status === UseAsyncStatus.Idle;
  }, [fetchDrugPackagingAsync.status]);

  useEffect(() => {
    if (saveDrugPackagingAsync.status === UseAsyncStatus.Success) {
      notifications.success({ title: 'Success', message: 'Drug Packaging updated successfully.', autoClose: true });
    }

    if (saveDrugPackagingAsync.status === UseAsyncStatus.Error) {

      handleError(
        saveDrugPackagingAsync.error,
        {
          title: 'Update Failed',
          message: 'Unable to update Drug Packaging.',
          autoClose: false
        }
      );
    }
  }, [saveDrugPackagingAsync.status]);

  useEffect(() => {
    if (fetchDrugPackagingAsync.status === UseAsyncStatus.Success) {
      setDrugPackaging(fetchDrugPackagingAsync.value);
      const {
        brandGenericStatus,
        licenseType,
        onMarketWacPrice,
        onMarketWacUnitPrice,
        cmsOnMarketDate,
        wacBeginDate,
        baselineCpiu,
        baselineCpiuDate
      } = fetchDrugPackagingAsync.value;

      form.setValue('brandGenericStatus', brandGenericStatus ?? '', { shouldValidate: true });
      form.setValue('licenseType', licenseType ?? '');
      form.setValue('onMarketWacPrice', onMarketWacPrice?.toString() ?? '');
      form.setValue('onMarketWacUnitPrice', onMarketWacUnitPrice?.toString() ?? '');
      form.setValue('cmsOnMarketDate', cmsOnMarketDate ?? '');
      form.setValue('wacBeginDate', wacBeginDate ?? '');
      form.setValue('baselineCpiu', baselineCpiu?.toString() ?? '');
      form.setValue('baselineCpiuDate', baselineCpiuDate ?? '');
    }
  }, [fetchDrugPackagingAsync.status]);

  const submitForm = (productId: number, ndc: string) => {
    form.handleSubmit((data) => {
      saveDrugPackagingAsync.execute(productId, ndc, data);
    })();
  };

  const fetchDrugPackaging = (productId: number, ndc: string) => {
    fetchDrugPackagingAsync.execute(productId, ndc);
  };

  return {
    form,
    saveDrugPackaging,
    savingDrugPackaging,
    savedDrugPackagingSuccessfully,
    submitForm,
    fetchDrugPackaging,
    fetchingDrugPackaging,
    drugPackaging
  };
};
