import { NotificationDefinition } from '@shared/notifications/notification-definition';
import { createContext } from 'react';

import { type NotificationOptions, type NotificationOptionsWithSeverity } from './notification-options';

const DEFAULT_NOTIFICATION_CONTEXT: NotificationContextProps = {
  dismiss: () => null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Notificatio... Remove this comment to see the full error message
  notify: () => null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Notificatio... Remove this comment to see the full error message
  error: () => null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Notificatio... Remove this comment to see the full error message
  warn: () => null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Notificatio... Remove this comment to see the full error message
  info: () => null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Notificatio... Remove this comment to see the full error message
  success: () => null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Notificatio... Remove this comment to see the full error message
  loading: () => null,
};

export const NotificationsContext = createContext<NotificationContextProps>(DEFAULT_NOTIFICATION_CONTEXT);

export type NotificationContextProps = {
  /**
   * Dismiss notification.
   * @param id
   */
  dismiss: (id: string) => void
  /**
   * Show generic notification with full control over options.
   */
  notify: (options: NotificationOptionsWithSeverity) => NotificationDefinition
  /**
   * Show error notification.
   */
  error: (options: NotificationOptions) => NotificationDefinition
  /**
   * Show warn notification.
   */
  warn: (options: NotificationOptions) => NotificationDefinition
  /**
   * Show info notification.
   */
  info: (options: NotificationOptions) => NotificationDefinition
  /**
   * Show success notification.
   */
  success: (options: NotificationOptions) => NotificationDefinition
  /**
   * Show loading notification.
   */
  loading: (options: NotificationOptions) => NotificationDefinition
};
