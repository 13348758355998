import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import { Box, Spinner } from 'grommet';
import React, { useId, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ArtiaButton } from '@/components/artia-button';
import { DeleteConfirmationDialog } from '@/components/delete-confirmation-dialog';
import { useStatesService } from '@/features/state-reports/api';
import { CustomStateReportForm } from '@/features/state-reports/components';
import { useStatesProvider } from '@/features/state-reports/components';
import NotFoundPage from '@/pages/404';

const EditReportPage = () => {
  const formId = useId();
  const { id } = useParams<'id'>();
  const { updateCustomReport, deleteCustomReport } = useStatesService();
  const updateRequest = useAsync(updateCustomReport);
  const deleteRequest = useAsync(deleteCustomReport);
  const isSubmitting = updateRequest.status === UseAsyncStatus.Pending
    || deleteRequest.status === UseAsyncStatus.Pending;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const {
    columnGroups: { customReports },
    loading,
    reloadColumnGroups
  } = useStatesProvider();

  const navigate = useNavigate();
  const { handleError } = useErrorHandler();
  const { success } = useNotifications();

  if (loading) {
    return (
      <Box align="center" justify="center" fill>
        <Spinner size="large" />
      </Box>
    );
  }

  const report = customReports.find(
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    (report) => report.id === +id
  );

  if (!report) {
    return <NotFoundPage />;
  }

  return (
    <>
      <DeleteConfirmationDialog
        open={deleteModalOpen}
        itemName="State Report"
        onCancel={() => setDeleteModalOpen(false)}
        onDeleteComplete={() => {
          setDeleteModalOpen(false);
          reloadColumnGroups();
          navigate('/state-reports/manage-reports', { state: 'skipBlock' });
        }}
        request={deleteRequest}
        parameters={[report.id]}
      />
      <CustomStateReportForm
        id={formId}
        key={report.id}
        heading={`Edit Report: ${report.name}`}
        defaultValues={{
          name: report.name,
          columns: report.columns
        }}
        onSubmit={async ({ name, columns }) => {
          try {
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            await updateRequest.execute(+id, name, columns);
            success({
              title: 'Custom state report updated',
              autoClose: true
            });
            reloadColumnGroups();
          } catch (error) {
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            handleError(error, {
              title: 'Failed to update custom state report',
              autoClose: false
            });

            throw error;
          }
        }}
      >
        <ArtiaButton
          label="Delete"
          disabled={isSubmitting}
          size="large"
          variant="danger"
          type="button"
          style={{ width: 'max-content', height: 'max-content' }}
          onClick={() => setDeleteModalOpen(true)}
        />
      </CustomStateReportForm>
    </>
  );
};

export default EditReportPage;
