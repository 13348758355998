import { Box } from 'grommet';
import { Add } from 'grommet-icons';
import React from 'react';

import { ArtiaButton } from '@/components/artia-button';
import { InMemoryMultiSelectList } from '@/components/form-controls';
import { Lookup } from '@/types/lookup';

type AddColumnsFormProps = {
  value: Lookup<string>[];
  options: Lookup<string>[];
  onChange: (newValue: Lookup<string>[]) => void;
  onAdd: () => void;
};

export const AddColumnsForm = ({ value, options, onChange, onAdd }: AddColumnsFormProps) => {
  return (
    <Box direction="row" gap="small" align="end">
      <InMemoryMultiSelectList
        name="columnsToAdd"
        label="Add Columns"
        options={options}
        value={value}
        onChange={onChange}
        width="100%"
      />
      <ArtiaButton
        label="Add"
        icon={<Add/>}
        size="large"
        type="button"
        style={{ width: 'max-content', height: 'max-content' }}
        onClick={onAdd}
      />
    </Box>
  );
};
