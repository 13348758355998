import { Box } from 'grommet';
import React from 'react';

const ManageReportsPage = () => {
  return (
    <Box justify="center" align="center" direction="column" pad="xlarge">
      Select a report to edit an existing one or select New Report to create a new one.
    </Box>
  );
};

export default ManageReportsPage;
