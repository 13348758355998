import { Box, FormField, TextInput } from 'grommet';
import { Search } from 'grommet-icons';
import React from 'react';

import { GrommetFilterForm } from '@/components/filters';

export type BidAnalysesFiltersProps = {
  /**
   * The initial value for the form, optional.
   */
  defaultValue?: BidAnalysesFilterData;

  /**
   * Triggered when the filters are applied.
   * @param filters
   */
  onApplyFilters: (filters: BidAnalysesFilterData) => void;
};

/**
 * Displays data filters for Bid Analyses table data.
 */
export const BidAnalysesFilters = ({ defaultValue, onApplyFilters }: BidAnalysesFiltersProps) => {
  return (
    <GrommetFilterForm
      // @ts-expect-error TS(2322): Type 'BidAnalysesFilterData | undefined' is not as... Remove this comment to see the full error message
      defaultValue={defaultValue}
      emptyValues={{ name: '', client: '', product: '' }}
      onChange={(value) => onApplyFilters(value)}
    >
      <Box width="22rem">
        <FormField
          name="name"
          htmlFor="name"
          margin="none"
        >
          <TextInput
            type="search"
            icon={<Search color="accent-1"/>}
            id="name"
            name="name"
            placeholder="Search By Bid Analysis Name"
          />
        </FormField>
      </Box>
      <Box width="22rem">
        <FormField
          name="client"
          htmlFor="client"
          margin="none"
        >
          <TextInput
            type="search"
            icon={<Search color="accent-1"/>}
            id="client"
            name="client"
            placeholder="Search By Client"
          />
        </FormField>
      </Box>
      <Box width="22rem">
        <FormField
          name="product"
          htmlFor="product"
          margin="none"
        >
          <TextInput
            type="search"
            icon={<Search color="accent-1"/>}
            id="product"
            name="product"
            placeholder="Search By Drug"
          />
        </FormField>
      </Box>
    </GrommetFilterForm>
  );
};

export type BidAnalysesFilterData = {
  name?: string;
  client?: string;
  product?: string;
};
