export type Meeting = {
  id: number;
  name: string;
  date: string;
  time: string;
  datetime: string;
  format: MeetingFormat;
  type: string;
  drugs?: string[];
  classifications?: string[];
  boardMembers?: string;
  location?: {
    venue: string;
    room?: string;
    address?: {
      line1: string;
      line2?: string;
      city: string;
      state: string;
      zipcode: string;
    }
  }
  webinar?: {
    url: string;
    phone?: string;
    meetingId?: string;
    password?: string;
  }
  resources?: MeetingResource[];
};

export type MeetingFormat = 'in_person' | 'virtual' | 'hybrid';

export type MeetingResource = {
  name: string;
  url: string;
};

export const formatMeetingFormat = (format: MeetingFormat) => {
  switch (format) {
    case 'in_person':
      return 'In-Person';
    case 'virtual':
      return 'Virtual';
    case 'hybrid':
      return 'Hybrid';
  }
};
