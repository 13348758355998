import React from 'react';

import { Spinner } from '@/components-new/spinner';
import { isCallableFuntion } from '@/utils/guards';

type IndicatorRenderProps = {
  message?: string | null;
}

type LoaderProps = {
  /**
   * Provides a custom rendering for the indicator element.
   */
  indicator?: React.ReactNode | ((renderProps: IndicatorRenderProps) => React.ReactNode);
  /**
   * Message displayed with the loading indicator.
   */
  message?: string | null;
}
const Loader = ({
  indicator = ({ message }) => <Spinner className="size-10" label={message} />,
  message = 'Loading...'
}: LoaderProps & React.ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className="flex flex-col items-center">
      {isCallableFuntion(indicator) ? indicator({ message  }) : indicator}
      <p className="mt-1 text-sm text-gray-500">
        {message}
      </p>
    </div>
  );
};

export { Loader };
