import { useState } from 'react';

export type UseStorage<T> = [T, (value: T | ((current: T) => T)) => void];

/**
 * Utility hook for using browser storage (works for both Session and Local Storage).
 * @param key the key for the item in storage
 * @param initialValue either the initial value OR a function that lets you override the value in session storage
 * @param storage the browser storage object (either window.localStorage or window.sessionStorage)
 */
export const useStorage = <T>(
  key: string,
  initialValue?: T | ((sessionStorageValue: T | null) => T),
  storage?: Storage
): UseStorage<T> => {
  // @ts-expect-error TS(2345): Argument of type '() => T | undefined' is not assi... Remove this comment to see the full error message
  const [storedValue, setStoredValue] = useState<T>(() => {
    const item = storage?.getItem(key);
    const parsedItem: T | null = item ? JSON.parse(item) as T : null;

    if (initialValue instanceof Function) {
      return initialValue(parsedItem);
    }

    return parsedItem ?? initialValue;
  });

  const setValue = <T>(value: T) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      storage?.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
};
