import { isEqual } from 'lodash';
import React from 'react';
import { useAuth } from 'react-oidc-context';

import { bugsnagClient } from '@/lib/bugsnag/bugsnag-client';

export const BugsnagManager = () => {
  const { user } = useAuth();

  React.useEffect(() => {
    const currentBugsnagUser = bugsnagClient.getUser();
    const newUser = {
      name: user?.profile.name,
      email: user?.profile.email,
      id: user?.profile.sub,
    };

    if (isEqual(currentBugsnagUser, newUser)) return;
    bugsnagClient.setUser(newUser.id, newUser.email, newUser.name);
  }, [user]);

  return null;
};
