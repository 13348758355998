import { Button } from '@/components-new/button';
import { useAuthFlow } from '@/hooks/use-auth-flow';
import { Navigate } from '@/router';

const LoginPage = () => {
  const { isAuthenticated, signInAsync, error } = useAuthFlow();

  const handleSignInClick = async () => {
    await signInAsync();
  };

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return(
    <div className="flex min-h-full flex-1 flex-col justify-center bg-gray-50 px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          alt="Artia Capsule Logo"
          src="/artia-capsule-logo.png"
          className="mx-auto h-20 w-auto"
        />
        {error && <p className="text-red-500">Error: {error.message}</p> }
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="px-6 sm:px-12">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <Button color="secondary" className="w-full" onClick={handleSignInClick}>
              Sign in
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
