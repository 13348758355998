export const BRAND = '#00314C';
export const ACCENT_1 = '#00A3A3';
export const ACCENT_1_25 = '#00A3A340';
export const ACCENT_2 = '#99E0DF';
export const ACCENT_WEAK = '#b3e3e3';
export const CONTRAST_1 = '#008080';
export const LIGHT_1 = '#F8F8F8';
export const LIGHT_2 = '#F2F2F2';
export const LIGHT_3 = '#EDEDED';
export const DARK_1 = '#333333';
export const DARK_2 = '#555555';
export const DARK_3 = '#8e8c8c';
export const STATUS_WARNING_WEAK = '#FFD48A';
