import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import { Box, Text } from 'grommet';
import React, { useEffect, useId, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Alert } from '@/components/alert';
import { ArtiaButton } from '@/components/artia-button';
import { Busy } from '@/components/busy';
import { DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { ChangeLogReason } from '@/features/changelog/types';
import {
  CoverageIdentifier,
  EditCoverageRequest,
  useCoverageService
} from '@/features/coverage/api/use-coverage-service';
import { ClinicalsProductEditForm } from '@/features/coverage/components/clinicals-product-edit-form';
import CoverageTagChip from '@/features/coverage/components/coverage-tag-chip';
import { PdlStatus } from '@/features/coverage/types/pdl-status';
import { StateCoverage } from '@/features/coverage/types/state-coverage';
import { CoverageTag } from '@/features/drugs/types/coverage-tag';
import { Drug } from '@/features/drugs/types/drug';
import { ACCENT_1 } from '@/styles';
import { Lookup } from '@/types/lookup';
import { stepTherapyDisplay } from '@/utils/state-coverage';

type DefaultFormValues = {
  pdlStatus?: PdlStatus;
  pdlStatusDate?: string;
  pdlStatusEffectiveDate?: string;
  paTypes?: Lookup[];
  stepTherapyCount?: number;
  hasAdditionalSteps?: boolean;
  paCriteriaDescription?: string;
  paCriteriaSourceUrl?: string;
  notes?: string;
  hasAutoPa?: boolean;
};

export type CoverageEditDialogMode = 'add' | 'single-edit' | 'full-pdl-review' | 'closed' | 'open-date-select';

export type CoverageEditDialogAction = 'next' | 'close' | 'previous';

const DEFAULT_VISIBLE_FIELDS = ['pdlStatusDate'] as (keyof EditCoverageRequest)[];

type CoverageEditDialogProps = {
  /**
   * Indicates which directions the user can go in during the Full PDL Review workflow, optional.
   * Defaults to an empty array.
   */
  allowedDirections?: ('next' | 'previous')[];

  /**
   * Dictates whether the "visible fields" functionality is shown or not. Defaults to 'single-edit', which does not
   * show the "visible fields" functions.
   *
   * Values of 'closed' and 'open-date-select' are ignored since the parent
   * component should never allow the modal to be open in such a state.
   */
  mode?: CoverageEditDialogMode;

  /**
   * Event handler for closing the dialog, required.
   */
  onClose: () => void;

  /**
   * Event handler for submitting the form, required.
   * @param updatedData the updated version of the record being edited (or null if no changes were made)
   * @param action whether to move forward, back, or close the dialog entirely
   */
  onSubmitComplete: (
    updatedData: StateCoverage | null,
    action: CoverageEditDialogAction
  ) => void;

  /**
   * Identifies the current record, required.
   */
  coverageIdentifier: CoverageIdentifier | null;

  /**
   * Name of the product, optional. Used for display purposes.
   *
   * **Must be provided when coverageIdentifier is non-null!**
   */
  productName: string | null;
  genericName: string | null;
  drug: Drug | null;
  coverageTags: CoverageTag[];

  /**
   * Class of the product, optional. Used for display purposes.
   *
   * **Must be provided when coverageIdentifier is non-null!**
   */
  drugClass?: string | null;

  /**
   * Name of the state being edited, optional. Used for display purposes.
   *
   * **Must be provided when coverageIdentifier is non-null!**
   */
  stateName: string | null;

  /**
   * Overrides for the default values, optional.
   *
   * These values will be used for the initial form load, but won't be the default value when the field is reset.
   */
  initialOverrides?: Pick<DefaultFormValues, 'pdlStatusDate'>;

  /**
   * Default values for the form, optional.
   *
   * These values will be used for the initial form load _and_ will be the value when the field is reset.
   */
  defaultValues?: DefaultFormValues;
};

/**
 * Represents the form body for an edit dialog for a coverage. It handles
 * the network request to update it.
 *
 * This component MUST be wrapped in its own <Dialog> component!
 */
export const CoverageEditDialogBody = (props: CoverageEditDialogProps) => {
  const {
    allowedDirections = [],
    mode = 'single-edit',
    onClose,
    onSubmitComplete,
    coverageIdentifier,
    productName,
    coverageTags,
    genericName,
    drugClass,
    stateName,
    initialOverrides: {
      pdlStatusDate: overridePdlStatusDate
    } = {},
    defaultValues: {
      pdlStatus,
      pdlStatusDate,
      pdlStatusEffectiveDate,
      paTypes,
      stepTherapyCount,
      hasAdditionalSteps,
      paCriteriaDescription,
      paCriteriaSourceUrl,
      notes,
      hasAutoPa
    } = {}
  } = props;

  const { handleError } = useErrorHandler();
  const { success } = useNotifications();
  const { createNewCoverage, editCoverage } = useCoverageService();
  const editRequest = useAsync(async (coverageIdentifier: CoverageIdentifier, request: EditCoverageRequest): Promise<StateCoverage | null> => {
    if (mode === 'add') {
      await createNewCoverage(request);
      return null;
    }

    return await editCoverage(coverageIdentifier, request);
  });

  const [visibleFields, setVisibleFields] = useState(() => {
    if (mode === 'full-pdl-review' && overridePdlStatusDate) {
      return DEFAULT_VISIBLE_FIELDS;
    }
    return [];
  });

  const onCloseWrapper = () => {
    onClose();
    shouldShowNextAlertRef.current = false;
  };

  const shouldMakeRequest = visibleFields.length > 0 || mode === 'single-edit' || mode === 'add';
  const shouldShowNextAlertRef = useRef(false);

  const makeRequest = async (data: EditCoverageRequest, action: CoverageEditDialogAction) => {
    try {
      // only make the request if we actually need to
      const result = shouldMakeRequest
        // @ts-expect-error TS(2345): Argument of type 'CoverageIdentifier | null' is no... Remove this comment to see the full error message
        ? await editRequest.execute(coverageIdentifier, data)
        : null;

      // only show the success toast if we're closing the modal for good
      if (mode === 'single-edit' || mode === 'add' || action === 'close') {
        success({
          title: 'Update Succeeded',
          autoClose: true
        });
      }

      // only show the next alert if we actually made a request
      shouldShowNextAlertRef.current = shouldMakeRequest && mode === 'full-pdl-review' && action !== 'close';

      onSubmitComplete(result, action);
    } catch (error) {
      handleError(
        // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        error,
        {
          title: 'Update Failed',
          message: 'Unable to update this State Coverage.',
          autoClose: false
        }
      );
    }
  };

  const [showNextAlert, setShowNextAlert] = useState(false);

  useEffect(() => {
    if (mode !== 'full-pdl-review' || !coverageIdentifier || !shouldShowNextAlertRef.current) return;

    setShowNextAlert(true);

    const timer = setTimeout(() => {
      setShowNextAlert(false);
    }, 5000);

    return () => {
      clearInterval(timer);
      setShowNextAlert(false);
    };
  }, [coverageIdentifier, mode]);

  const formId = useId();
  const form = useForm<EditCoverageRequest>({
    mode: 'all',
    // we use all empty values since this will run when the dialog first mounts, even if it's not opened yet.
    // the `useEffect` below will update the values to the selected product once it's opened
    defaultValues: {
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type '{ id?: numb... Remove this comment to see the full error message
      drug: null,
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type '{ id?: numb... Remove this comment to see the full error message
      state: null,
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'PdlStatus |... Remove this comment to see the full error message
      pdlStatus: null,
      pdlStatusDate: '',
      pdlStatusEffectiveDate: '',
      paTypes: [],
      stepTherapyCount: '',
      paCriteriaDescription: '',
      paCriteriaSourceUrl: '',
      changeReason: ChangeLogReason.PDL_UPDATE,
      notes: '',
      hasAutoPa: false
    }
  });
  const {
    formState: { isValid },
    handleSubmit,
    reset,
    resetField,
    setValue
  } = form;

  const saving = editRequest.status === UseAsyncStatus.Pending;
  const submitDisabled = !isValid || saving;

  const submitRef = useRef<HTMLButtonElement | null>(null);
  useEffect(() => {
    const onEnter = (event: KeyboardEvent) => {
      event.stopPropagation();
      if (event.key !== 'Enter') return;

      submitRef.current?.click();
    };
    window.addEventListener('keydown', onEnter);

    return () => window.removeEventListener('keydown', onEnter);
  }, []);

  useEffect(
    () => {
      if (!coverageIdentifier) {
        return;
      }

      reset({
        // @ts-expect-error TS(2322): Type 'PdlStatus | null' is not assignable to type ... Remove this comment to see the full error message
        pdlStatus: pdlStatus ? pdlStatus : null,
        pdlStatusDate: pdlStatusDate ? pdlStatusDate : '',
        pdlStatusEffectiveDate: pdlStatusEffectiveDate ? pdlStatusEffectiveDate : '',
        paTypes: paTypes ? paTypes : [],
        stepTherapyCount: stepTherapyDisplay(stepTherapyCount, hasAdditionalSteps),
        paCriteriaDescription: paCriteriaDescription ? paCriteriaDescription : '',
        paCriteriaSourceUrl: paCriteriaSourceUrl ? paCriteriaSourceUrl : '',
        changeReason: ChangeLogReason.PDL_UPDATE,
        notes: notes ?? '',
        hasAutoPa: hasAutoPa ?? false
      });

      if (overridePdlStatusDate) {
        setValue('pdlStatusDate', overridePdlStatusDate);
      }

      setVisibleFields(mode === 'full-pdl-review' && overridePdlStatusDate ? DEFAULT_VISIBLE_FIELDS : []);
    },
    // TODO: rework this effect it has tons of dependencies and is still missing some
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      coverageIdentifier,
      pdlStatus,
      pdlStatusDate,
      pdlStatusEffectiveDate,
      paTypes,
      stepTherapyCount,
      hasAdditionalSteps,
      paCriteriaDescription,
      paCriteriaSourceUrl,
      notes,
      reset,
      overridePdlStatusDate,
      hasAutoPa
    ]
  );

  return (
    <FormProvider {...form}>
      <DialogHeader
        title={mode === 'add' ? 'Add Coverage' : 'Edit Coverage'}
        subtitle={mode !== 'add' ? (
          <Box>
            <Text size="xlarge" color="contrast-1" weight="bolder">{stateName}</Text>
            {drugClass ? (
              <Text size="large" color="brand" weight="bold">
                {drugClass}
              </Text>
            ) : null}
            <Box direction="row" gap="small" align="center">
              <Text size="large" color="brand">{genericName ? (`${productName} (${genericName})`) : productName}</Text>
              {coverageTags.map(coverageTag =>
                <CoverageTagChip key={coverageTag.id} coverageTag={coverageTag} />
              )}
            </Box>
          </Box>
        ) : undefined}
        onClose={onCloseWrapper}
      />
      <DialogBody>
        <ClinicalsProductEditForm
          mode={mode === 'add' ? 'add' : 'single-edit'}
          formId={formId}
          fieldDisplayMode={mode === 'full-pdl-review' ? 'show-default-value' : 'show-no-change'}
          visibleFields={mode === 'full-pdl-review' ? visibleFields : undefined}
          onFieldShow={mode === 'full-pdl-review' ? (name) => {
            setVisibleFields((current) => [...current, name]);
          } : undefined}
          onFieldHide={mode === 'full-pdl-review' ? (name) => {
            setVisibleFields((current) => current.filter(it => it !== name));
            resetField(name);
          } : undefined}
          onSubmit={handleSubmit((data) => makeRequest(data, mode === 'full-pdl-review' ? 'next' : 'close'))}
        >
          {showNextAlert ? (
            <Alert variant="info" message={`Update successful! Now editing ${productName} for ${stateName}.`} />
          ) : null}
        </ClinicalsProductEditForm>
      </DialogBody>
      {mode === 'full-pdl-review' ? (
        <DialogActions justify={allowedDirections.includes('previous') ? 'between' : 'end'}>
          {allowedDirections.includes('previous') ? (
            <ArtiaButton
              label={<Busy busy={saving} color={ACCENT_1} content={shouldMakeRequest ? 'Save and Go Back' : 'Go Back'} />}
              type="button"
              onClick={handleSubmit((data) => makeRequest(data, 'previous'))}
              size="large"
              disabled={submitDisabled}
            />
          ) : null}
          <Box gap="small" direction="row" align="end">
            <ArtiaButton
              label="Cancel"
              type="button"
              onClick={onCloseWrapper}
              size="large"
              variant="outlined"
            />
            {shouldMakeRequest ? (
              <ArtiaButton
                label={<Busy busy={saving} color={ACCENT_1} content="Save and Close"/>}
                type="button"
                onClick={handleSubmit((data) => makeRequest(data, 'close'))}
                size="large"
                variant="outlined"
                disabled={submitDisabled}
              />
            ) : null}
            <ArtiaButton
              label={<Busy busy={saving} content={shouldMakeRequest ? 'Save and Continue' : 'Continue'} />}
              type="submit"
              ref={submitRef}
              form={formId}
              size="large"
              disabled={submitDisabled}
            />
          </Box>
        </DialogActions>
      ) : (
        <DialogActions>
          <ArtiaButton
            label="Cancel"
            type="button"
            onClick={onCloseWrapper}
            size="large"
            variant="outlined"
          />
          <ArtiaButton
            label={<Busy busy={saving} content="Save"/>}
            type="submit"
            ref={submitRef}
            form={formId}
            size="large"
            disabled={submitDisabled}
          />
        </DialogActions>
      )}
    </FormProvider>
  );
};
