import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { useHeaderDetails } from '@shared/header';
import { useNotifications } from '@shared/notifications';
import * as DateValidators from '@shared/validators/date-time-validators';
import { Box, FormField, TextInput } from 'grommet';
import { PieChart } from 'grommet-icons';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { ArtiaButton } from '@/components/artia-button';
import { Busy } from '@/components/busy';
import { FormCard } from '@/components/form-card';
import { DateTextInput } from '@/components/form-controls';
import { useBidAnalysisService } from '@/features/bid-analysis';
import { UpdateBidAnalysisRequest } from '@/features/bid-analysis/api/use-bid-analysis-service';
import { useBidAnalysisDetails } from '@/features/bid-analysis/bid-analysis-details.provider';
import { defaultEditBidAnalysisForm, EditBidAnalysisFormInputs } from '@/features/bid-analysis/types/bid-analysis-form-inputs';
import * as DateFunctions from '@/utils/date-time-functions';

const EditBidAnalysis = () => {
  const { id } = useParams();
  const { bidAnalysis, loadBidAnalysis } = useBidAnalysisDetails();
  const { updateBidAnalysis } = useBidAnalysisService();
  const { handleError } = useErrorHandler();
  const { success } = useNotifications();
  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();
  const navigate = useNavigate();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
    watch,
    trigger
  } = useForm<EditBidAnalysisFormInputs>({ mode: 'onBlur', reValidateMode: 'onBlur', defaultValues: defaultEditBidAnalysisForm });

  const updateBidAnalysisAsync = useAsync(updateBidAnalysis);
  const saving = updateBidAnalysisAsync.status === UseAsyncStatus.Pending;

  const canSave = isValid && !saving;

  React.useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  React.useEffect(() => {
    if (id === null) {
      return;
    }

    loadBidAnalysis();
  }, [id]);

  React.useEffect(() => {
    const breadcrumbs = bidAnalysis ?
      [
        { icon: PieChart, label: 'Bid Analyses', url: '/bid-analyses' },
        { label: bidAnalysis.name, url: `/bid-analyses/${bidAnalysis.id}-${bidAnalysis.slug}` },
        { label: 'Edit', url: `/bid-analyses/${bidAnalysis.id}/edit` }
      ] : [];

    setHeaderDetails({
      documentTitle: 'Edit Bid Analysis',
      pageTitle: 'Edit Bid Analysis',
      breadcrumbs
    });

    return () => {
      clearHeaderDetails();
    };
  }, [bidAnalysis]);

  React.useEffect(() => {
    if (bidAnalysis) {
      const {
        name,
        bidDueOn,
        reviewOn,
        contractTermStartsOn,
        contractTermEndsOn
      } = bidAnalysis;

      setValue('name', name);
      setValue('bidDueOn', bidDueOn);
      setValue('reviewOn', reviewOn);
      setValue('contractTermStartsOn', contractTermStartsOn);
      setValue('contractTermEndsOn', contractTermEndsOn);
    }
  }, [bidAnalysis]);

  useEffect(() => {
    const { status, error } = updateBidAnalysisAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(
        error,
        {
          title: 'Update Bid Analysis Failed',
          message: 'Unable to update Bid Analysis',
          autoClose: false
        }
      );

      return;
    }

    success({ title: 'Success', message: 'Successfully updated Bid Analysis.', autoClose: true });
    navigate('..');
  }, [updateBidAnalysisAsync.status]);

  const handleFormSubmit =  async (value: EditBidAnalysisFormInputs) => {
    const {
      name,
      bidDueOn,
      reviewOn,
      contractTermStartsOn,
      contractTermEndsOn
    } = value;

    const request: UpdateBidAnalysisRequest = {
      name,
      bidDueOn,
      reviewOn,
      contractTermStartsOn,
      contractTermEndsOn,
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      notes: bidAnalysis.notes
    };

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    updateBidAnalysisAsync.execute(bidAnalysis.id, request);
  };

  const bidAnalysisCreatedOn = useMemo(() => {
    if (!bidAnalysis?.createdAt) {
      return '';
    }

    const [date] = bidAnalysis.createdAt.split('T');
    const [year, month, day] = date.split('-');

    return `${month}/${day}/${year}`;
  }, [bidAnalysis]);

  const watchEndsOn = watch('contractTermEndsOn');
  const watchStartsOn = watch('contractTermStartsOn');
  const endDateMin = useMemo(() => {
    if (DateFunctions.isCompleteDate(watchStartsOn) && DateFunctions.isValidDate(watchStartsOn)) {
      return watchStartsOn;
    }

    return null;
  }, [watchStartsOn]);

  useEffect(() => {
    if (DateFunctions.isCompleteDate(watchEndsOn)) {
      trigger('contractTermEndsOn');
    }
  }, [endDateMin]);

  const watchReviewOn = watch('reviewOn');
  const watchDueOn = watch('bidDueOn');
  const reviewOnMin = useMemo(() => {
    const dueOnDate = new Date(watchDueOn);

    if (
      DateFunctions.isCompleteDate(watchDueOn) &&
      DateFunctions.isValidDate(watchDueOn) &&
      dueOnDate > new Date(bidAnalysisCreatedOn)
    ) {
      return watchDueOn;
    }

    return bidAnalysisCreatedOn;
  }, [watchDueOn]);

  useEffect(() => {
    if (DateFunctions.isCompleteDate(watchReviewOn)) {
      trigger('reviewOn');
    }
  }, [reviewOnMin]);

  const handleCancel = () => {
    navigate('..');
  };

  return (
    <Box>
      <FormCard>
        <Controller
          control={control}
          name="name"
          rules={{
            required: true,
            maxLength: 100
          }}
          render={({ field: { value, onChange, onBlur } }) => (
            <FormField
              required
              name="name"
              htmlFor="name"
              label="Name"
              error={errors.name?.message}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                id="name"
                name="name"
                maxLength={100}
              />
            </FormField>
          )}
        />
        <Box direction="row" gap="small">
          <Box basis="1/2">
            <Controller
              control={control}
              name="contractTermStartsOn"
              rules={{
                required: true,
                validate: {
                  valid: DateValidators.isValidDate
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormField
                  name="contractTermStartsOn"
                  htmlFor="contract-term-starts-on"
                  label="Contract Term Starts On"
                  error={errors.contractTermStartsOn?.message}
                >
                  <DateTextInput
                    name="contractTermStartsOn"
                    id="contract-term-starts-on"
                    value={value}
                    onChange={({ target }) => onChange((target as any).value)}
                    onBlur={onBlur}
                  />
                </FormField>
              )}
            />
          </Box>
          <Box basis="1/2">
            <Controller
              control={control}
              name="contractTermEndsOn"
              rules={{
                required: true,
                validate: {
                  valid: DateValidators.isValidDate,
                  // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
                  inRange: DateValidators.isInDateRange({ start: endDateMin })
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormField
                  name="contractTermEndsOn"
                  htmlFor="contract-term-ends-on"
                  label="Contract Term Ends On"
                  error={errors.contractTermEndsOn?.message}
                >
                  <DateTextInput
                    name="contractTermEndsOn"
                    id="contract-term-ends-on"
                    value={value}
                    onChange={({ target }) => onChange((target as any).value)}
                    onBlur={onBlur}
                  />
                </FormField>
              )}
            />
          </Box>
        </Box>
        <Box direction="row" gap="small">
          <Box basis="1/2">
            <Controller
              control={control}
              name="bidDueOn"
              rules={{
                validate: {
                  valid: DateValidators.isValidDate,
                  inRange: DateValidators.isInDateRange({ start: bidAnalysisCreatedOn })
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormField
                  name="bidDueOn"
                  htmlFor="bid-due-on"
                  label="Due On"
                  error={errors.bidDueOn?.message}
                >
                  <DateTextInput
                    name="bidDueOn"
                    id="bid-due-on"
                    value={value}
                    onChange={({ target }) => onChange((target as any).value)}
                    onBlur={onBlur}
                  />
                </FormField>
              )}
            />
          </Box>
          <Box basis="1/2">
            <Controller
              control={control}
              name="reviewOn"
              rules={{
                validate: {
                  valid: DateValidators.isValidDate,
                  inRange: DateValidators.isInDateRange({ start: reviewOnMin })
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormField
                  name="reviewOn"
                  htmlFor="review-on"
                  label="Review On"
                  error={errors.reviewOn?.message}
                >
                  <DateTextInput
                    name="reviewOn"
                    id="review-on"
                    value={value}
                    onChange={({ target }) => onChange((target as any).value)}
                    onBlur={onBlur}
                  />
                </FormField>
              )}
            />
          </Box>
        </Box>
        <Box flex direction="row" gap="medium" margin={{ top: 'large' }} justify="end">
          <ArtiaButton
            label="Cancel"
            onClick={handleCancel}
            size="large"
            variant="outlined"
          />

          <ArtiaButton
            label={<Busy busy={saving} content="Update" />}
            onClick={handleSubmit(handleFormSubmit)}
            disabled={!canSave}
            size="large"
          />
        </Box>
      </FormCard>
    </Box>
  );
};

export default EditBidAnalysis;
