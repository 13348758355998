import { IconProps } from 'grommet-icons/icons';
import React, { JSX }from 'react';
import { LiaAngleLeftSolid, LiaHomeSolid } from 'react-icons/lia';

import { Link } from '@/components-new/link';

type BreadcrumbsProps = {
  /**
   * List of breadcrumbs to display.
   */
  breadcrumbs: { icon?: BreadcrumbIcon, label: string, url: string }[],
}

/**
 * Creates a list of breadcrumb links for admin to follow back through the
 * link tree.
 */
export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  if (!breadcrumbs || breadcrumbs.length === 0) return;

  const previousBreadcrumb = breadcrumbs[breadcrumbs.length - 2] || { label: 'Home', url: '/' };
  return (
    <div>
      <nav aria-label="Back" className="sm:hidden">
        <Link
          href={previousBreadcrumb.url as any}
          className="flex items-center text-sm/6 font-medium text-gray-500 hover:text-gray-700"
        >
          <LiaAngleLeftSolid size="1.25rem" className=" -ml-1 mr-1 flex size-4 shrink-0 items-center" aria-hidden="true" />
          Back
        </Link>
      </nav>
      <nav aria-label="Breadcrumb" className="hidden sm:flex">
        <ol className="flex items-center space-x-3">
          <li>
            <div className="flex items-center">
              <Link
                href="/"
                className="text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-zinc-400 hover:dark:text-white">
                <LiaHomeSolid size="1.25rem" />
                <span className="sr-only">Home</span>
              </Link>
            </div>
          </li>
          {breadcrumbs.map((breadcrumb, index) => {
            const {
              label,
              url,
            } = breadcrumb;

            const last = index === breadcrumbs.length - 1;

            return (
              <li key={label + url}>
                <div className="flex items-center">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    className="size-5 shrink-0 text-gray-300"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z"/>
                  </svg>
                  <Link
                    aria-current={last ? 'page' : undefined}
                    href={url as any}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-zinc-400 hover:dark:text-white">
                    {label}
                  </Link>
                </div>
              </li>
            );
          })}
        </ol>
      </nav>
    </div>
  );
};

export type BreadcrumbIcon = React.ComponentType<IconProps & JSX.IntrinsicElements['svg']>;

