import { useHttpClient } from '@shared/http';

import { Lookup } from '@/types/lookup';
import { buildQueryString } from '@/utils/filtering-functions';

import { StateFilterData } from '../components';
import { ColumnConfiguration, ColumnConfigurationGroup, CustomState, McoCarveOutMethod,StateDrugOrClass } from '../types';

export type ColumnGroupsResponse = {
  customReports: { id: number, name: string, columns: Lookup<string>[] }[];
  systemReports: Record<ColumnConfigurationGroup, { description: string, columnConfigurations: ColumnConfiguration[] }>;
};

export const useStatesService = () => {
  const httpClient = useHttpClient();

  const getColumnGroups = (): Promise<ColumnGroupsResponse> => {
    return httpClient.get('states/groups');
  };

  const importStatesData = async (data: FormData) => {
    await httpClient.post('states/import', { data });
  };

  const importStateLives = async (data: FormData) => {
    await httpClient.post('states/import-state-lives', { data });
  };

  const exportStatesToCsv = async (
    groups: ColumnConfigurationGroup[],
    stateCodes: string[],
    clinicalsProducts: number[],
    classifications: number[]
  ) => {
    return httpClient.download(
      'GET',
      `states/export${buildQueryString({ groups, stateCodes, clinicalsProducts, classifications })}`
    );
  };

  const exportHistoricalStateLivesToCsv = async (stateCodes: string[], startDate?: string, endDate?: string) => {
    return httpClient.download(
      'GET',
      `states/export-historical-state-lives${buildQueryString({ stateCodes, startDate, endDate })}`
    );
  };

  const exportHistoricalContactsToCsv = async (stateCodes: string[], startDate?: string, endDate?: string) => {
    return httpClient.download(
      'GET',
      `states/export-historical-state-contacts${buildQueryString({ stateCodes, startDate, endDate })}`
    );
  };

  const lookupStatesWithColumnGroup = (columnGroup: ColumnConfigurationGroup, filters: StateFilterData): Promise<{ columns: ColumnConfiguration[], states: CustomState[] }> => {
    const {
      carvedOutClassifications,
      carvedOutClinicalsProducts,
      exemptClinicalsProducts,
      exemptClassifications,
      ...rest
    } = filters;

    // @ts-expect-error TS(2345): Argument of type '{ carvedOutClassifications: numb... Remove this comment to see the full error message
    return httpClient.get(`states/with-custom-columns${buildQueryString({
      groups: columnGroup,
      ...rest,
      carvedOutClassifications: carvedOutClassifications.map(it => it.id),
      carvedOutClinicalsProducts: carvedOutClinicalsProducts.map(it => it.id),
      exemptClinicalsProducts: exemptClinicalsProducts.map(it => it.id),
      exemptClassifications: exemptClassifications.map(it => it.id),
    })}`);
  };

  const getMcoCarveOuts = (): Promise<Record<string, StateDrugOrClass[]>> => {
    return httpClient.get('states/mco-carve-outs');
  };

  const createMcoCarveOut = (stateCode: string, carveOutMethod: McoCarveOutMethod, associationIdList: Lookup[]): Promise<void> => {
    return httpClient.post(`states/${stateCode}/mco-carve-outs`, {
      data: { carveOutMethod, associationIdList: associationIdList.map(it => it.id) }
    });
  };

  const deleteMcoCarveOut = (carveOutId: number): Promise<void> => {
    return httpClient.delete(`states/mco-carve-outs/${carveOutId}`);
  };

  const getPdlExemptions = (): Promise<Record<string, StateDrugOrClass[]>> => {
    return httpClient.get('states/pdl-exemptions');
  };

  const createPdlExemption = (stateCode: string, carveOutMethod: McoCarveOutMethod, associationIdList: Lookup[]): Promise<void> => {
    return httpClient.post(`states/${stateCode}/pdl-exemptions`, {
      data: { carveOutMethod, associationIdList: associationIdList.map(it => it.id) }
    });
  };

  const deletePdlExemption = (carveOutId: number): Promise<void> => {
    return httpClient.delete(`states/pdl-exemptions/${carveOutId}`);
  };

  const updateStateData = (stateCode: string, updatedColumns: Record<string, string>) => {
    return httpClient.post('states/update', {
      data: { stateCode, updatedColumns }
    });
  };

  const createNewCustomReport = async (name: string, columns: Lookup<string>[]) => {
    return httpClient.post('custom-state-reports', {
      data: { name, columns: columns.map(it => it.id) }
    });
  };

  const updateCustomReport = async (id: number, name: string, columns: Lookup<string>[]) => {
    return httpClient.put(`custom-state-reports/${id}`, {
      data: { name, columns: columns.map(it => it.id) }
    });
  };

  const deleteCustomReport = async (id: number) => {
    return httpClient.delete(`custom-state-reports/${id}`);
  };

  return {
    getColumnGroups,
    importStatesData,
    importStateLives,
    exportStatesToCsv,
    exportHistoricalStateLivesToCsv,
    exportHistoricalContactsToCsv,
    lookupStatesWithColumnGroup,
    getMcoCarveOuts,
    createMcoCarveOut,
    deleteMcoCarveOut,
    getPdlExemptions,
    createPdlExemption,
    deletePdlExemption,
    updateStateData,
    createNewCustomReport,
    updateCustomReport,
    deleteCustomReport
  };
};
