import { useErrorHandler } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import { useMutation } from '@tanstack/react-query';

import { User } from '@/features/admin/types/user';
import { useClientService } from '@/features/clients/api/use-clients-service';

export const useResetPassword = () => {
  const { resetClientUserPassword } = useClientService();
  const { success } = useNotifications();
  const { handleError } = useErrorHandler();

  return useMutation({
    mutationFn: ({ clientId, user }: { clientId: number, user: User }) => resetClientUserPassword(clientId, user.id),
    onSuccess: () => {
      success({
        title: 'Success',
        message: 'Password reset email sent.',
        autoClose: true
      });
    },
    onError: (error) => {
      handleError(
        error,
        {
          title: 'Failure',
          message: 'Unable to reset password.',
          autoClose: true
        }
      );
    }
  });
};
