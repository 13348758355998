import { Select } from 'grommet';
import React, { useEffect, useState } from 'react';

import { insertIf } from '@/utils/arrays';

/**
 * An option in a combo box.
 */
export type ComboBoxItem = {
  /**
   * The ID value of the combo box option or null if this option is a
   * user-provided value.
   */
  value?: number;

  /**
   * The name of the combo box option. For newly created options, this is the
   * value that will be sent to the server. e.g., "A New Option".
   */
  name: string;

  /**
   * The label to display for the combo box option. For most options this is the
   * same as the name, but for newly created options this will be something like
   * "Create 'A New Option'".
   */
  label?: string;
};

/**
 * A combo box combines a predefined list of options with the ability to type in
 * a custom value.
 *
 * @param options The predefined options to display in the combo box.
 * @param value The currently selected option.
 * @param placeholder Optional placeholder text to display in the control.
 * @param onChange A callback function that is called when an option is selected.
 */
export const ComboBox = ({ options, value, placeholder, onChange }: ComboBoxProps) => {
  // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
  const [selectedValue, setSelectedValue] = useState<ComboBoxItem>(undefined);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    // @ts-expect-error TS(2345): Argument of type 'ComboBoxItem | undefined' is not... Remove this comment to see the full error message
    setSelectedValue(value);
  }, [value]);

  return (
    <Select
      size="medium"
      placeholder={placeholder}
      value={selectedValue}
      options={[
        ...options.filter(option => {
          return getRegex(searchValue).test(option.name);
        }),
        ...insertIf(!!searchValue, { name: searchValue, label: `'Create' '${searchValue}'` })]}
      valueKey="value"
      labelKey={(option) => option.label ?? option.name}
      onChange={({ option }) => {
        setSelectedValue(option);
        onChange(option);
      }}
      onSearch={(text: string) => {
        setSearchValue(text);
      }}
    />
  );
};

type ComboBoxProps = {
  /**
   * The options to display in the combo box.
   */
  options: ComboBoxItem[];

  /**
   * The initial value for the combo box or undefined if there is not an initial
   * value.
   */
  value?: ComboBoxItem;

  /**
   * Optional placeholder text to display in the combo box.
   */
  placeholder?: string;

  /**
   * A callback function that is called when an option is selected.
   *
   * @param option The selected option.
   */
  onChange: (option: ComboBoxItem) => void;
};

/**
 * Returns a regular expression that matches the specified text. Used for
 * filtering the combo box as a user types.
 *
 * @param text The text to match.
 * @returns A regular expression that matches the specified text.
 */
const getRegex = (text: string) => {
  // The line below escapes regular expression special characters:
  // [ \ ^ $ . | ? * + ( )
  const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

  // Create the regular expression with modified value which
  // handles escaping special characters. Without escaping special
  // characters, errors will appear in the console
  return new RegExp(escapedText, 'i');
};
