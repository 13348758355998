import React from 'react';

import { makeId } from '@/utils/make-id';

import { type NotificationDefinition } from './notification-definition';
import {
  type NotificationOptions,
  type NotificationOptionsWithSeverity,
} from './notification-options';
import { type NotificationsPosition } from './notification-position';
import { Notifications } from './notifications';
import {
  NotificationContextProps,
  NotificationsContext
} from './notifications.context';

/**
 * Provider for global notifications.
 * @constructor
 */
export const NotificationsProvider = ({ children, position = 'bottom-right' }: NotificationsProviderProps) => {
  const [ notifications, setNotifications ] = React.useState<NotificationDefinition[]>([]);

  const closeNotification = (id: string) => {
    setNotifications(prevState => {
      const foundNotification = prevState.find(notification => notification.id === id);

      if (!foundNotification) return prevState;

      if (typeof foundNotification.onClose === 'function') {
        foundNotification.onClose();
      }

      return [
        ...prevState.filter(notification => notification.id !== id),
      ];
    });
  };

  const addNotification = (options: NotificationOptionsWithSeverity) : NotificationDefinition => {
    const id = makeId();
    const notification: NotificationDefinition = {
      ...options,
      id,
      onNotificationClose: closeNotification,
    };

    setNotifications(prevState => ([
      ...prevState,
      notification
    ]));

    return notification;
  };

  const notify = ({ severity = 'unknown', ...rest }: NotificationOptionsWithSeverity): NotificationDefinition  => {
    return addNotification({ severity, ...rest });
  };

  const error = (options: NotificationOptions) : NotificationDefinition => {
    return addNotification({ severity: 'critical', ...options });
  };

  const info = (options: NotificationOptions): NotificationDefinition => {
    return addNotification({ severity: 'info', ...options });
  };

  const warn = (options: NotificationOptions) : NotificationDefinition => {
    return addNotification({ severity: 'warning', ...options });
  };

  const success = (options: NotificationOptions) : NotificationDefinition => {
   return  addNotification({ severity: 'normal', ...options });
  };

  const loading = (options: NotificationOptions) : NotificationDefinition => {
    const nextOptions = {
      ...options,
      processing: true,
      autoClose: false,
    };

    return addNotification({ severity: 'info', ...nextOptions });
  };

  const contextValue: NotificationContextProps = {
    notify,
    error,
    info,
    warn,
    success,
    loading,
    dismiss: closeNotification,
  };

  return (
    <NotificationsContext.Provider value={contextValue}>
      <Notifications notifications={notifications} onClose={closeNotification} position={position}/>
      {children}
    </NotificationsContext.Provider>
  );
};

type NotificationsProviderProps = {
  position?: NotificationsPosition,
  children: React.ReactNode
};
