import React, { FormEvent, useEffect, useRef } from 'react';
import { UseFormWatch } from 'react-hook-form';

import { FilterForm, FilterFormProps } from '@/components/filters/filter-form';

type RhfFilterFormProps<T extends object> = {
  watch: UseFormWatch<T>;
  onReset: () => void;
  onSubmit: (event: FormEvent) => void;
} & FilterFormProps;

/**
 * Handles common styling and behavior for all filters across the app.
 *
 * The parent component must own the default state. This is typically done via the `useFilters` hook, which
 * saves the filter data to `sessionStorage`.
 */
export const RhfFilterForm = <T extends object>(props: RhfFilterFormProps<T>) => {
  const {
    watch,
    onReset,
    onSubmit,
    children,
    ...filterFormProps
  } = props;

  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Timeout'.
    let timeoutId: NodeJS.Timeout = null;
    const subscription = watch(() => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        // using .submit() uses browser's native form behavior and doesn't call the submit handler,
        // whereas .requestSubmit() does (as if a submit button were clicked)
        formRef.current?.requestSubmit();
      }, 300);
    });

    return () => {
      subscription.unsubscribe();
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  return (
    <form
      ref={formRef}
      onReset={(e) => {
        e.preventDefault();
        onReset();
      }}
      onSubmit={onSubmit}
    >
      <FilterForm {...filterFormProps}>{children}</FilterForm>
    </form>
  );
};
