import { UserSettings } from '@/features/user-settings/types/user-settings';
import { artiaProtectedApi } from '@/lib/api-client';

export const updateUserSettings = async (userSettings: UserSettings): Promise<void> => {
  const response = await artiaProtectedApi.put('users/me/user-settings', {
    name: userSettings.name,
    priorityStates: userSettings.priorityStates
  });

  return response.data;
};
