import { Box, FormField, TextInput } from 'grommet';
import { Search } from 'grommet-icons';
import React from 'react';

import { GrommetFilterForm } from '@/components/filters';

type ClientFiltersProps = {
  defaultValue: ClientFilterData;
  onApplyFilters: (data: ClientFilterData) => void;
}

export const ClientFilters = ({ defaultValue, onApplyFilters }: ClientFiltersProps) => {
  return (
    <GrommetFilterForm
      defaultValue={defaultValue}
      emptyValues={{ name: '' }}
      onChange={(value) => onApplyFilters(value)}
    >
      <Box width="medium">
        <FormField name="name" htmlFor="name" margin="none">
          <TextInput
            type="search"
            icon={<Search color="accent-1" />}
            id="name"
            name="name"
            placeholder="Search By Name"
          />
        </FormField>
      </Box>
    </GrommetFilterForm>
  );
};

export type ClientFilterData = { name?: string };
