import AppErrorFallback from '@shared/errors/app-error-fallback';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { notifyReactError } from '@/lib/bugsnag/bugsnag-utils';

type Props = {
  children: React.ReactNode;
}

const handleOnError = (error: Error, info: React.ErrorInfo) => {
  notifyReactError(error, info);
};

const AppErrorBoundary = ({ children }: Props) => {
  return (
    <ErrorBoundary
      FallbackComponent={AppErrorFallback}
      onError={handleOnError}
      key={location.pathname}
    >
      {children}
    </ErrorBoundary>
  );
};

export default AppErrorBoundary;
