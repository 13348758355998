import { Box } from 'grommet';
import React from 'react';

import { OverridableCompendiumValue } from '@/types/overridable-compendium-value';
import { formatCurrency } from '@/utils/formatting';

type WacPriceCellProps = {
  wac?: OverridableCompendiumValue
};

export const WacPriceCell = (props: WacPriceCellProps) => {
  const { wac } = props;

  return (
    <Box width={{ min: '8rem' }}>
      {wac?.overrideValue != null &&
        <span>{formatCurrency(wac.overrideValue)}</span>
      }

      {wac?.overrideValue == null && wac?.compendiumValue != null &&
        <span>{formatCurrency(wac.compendiumValue)}</span>
      }

      {wac?.overrideValue == null && wac?.compendiumValue == null &&
        <span><>&mdash;</></span>
      }
    </Box>
  );
};
