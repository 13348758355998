import { Box, Button, Card, CardBody, CardFooter, CardHeader, Heading, Layer } from 'grommet';
import { WidthType } from 'grommet/utils';
import { Close } from 'grommet-icons';
import React from 'react';

/**
 * A slide-in panel that can be used to display content in a modal fashion.
 */
type PaneProps = {
  children?: React.ReactNode;

  /**
   * Whether to trigger onClose callback when user presses the escape key.
   */
  closeOnEscape?: boolean,

  /**
   * Whether to trigger onClose callback when user clicks outside of the
   * pane content.
   */
  closeOnOutsideClick?: boolean,

  /**
   * What side of the screen the pane should be anchored to.
   */
  direction?: 'left' | 'right'

  /**
   * Footer content to display at the bottom of the pane.
   */
  footer?: any

  /**
   * Header content to display at the top of the pane.
   */
  header?: any

  /**
   * Panes don't control their own visibility. This callback is triggered when
   * a request is made internally to close the pane.
   */
  onClose?: () => void,

  /**
   * Whether the pane should display a close icon in the header.
   */
  showCloseIcon?: boolean;

  /**
   * The width of the pane content.
   */
  width?: WidthType,
};

export const Pane = (props: PaneProps) => {
  const {
    children,
    closeOnEscape = true,
    closeOnOutsideClick = true,
    direction = 'right',
    footer,
    header,
    onClose,
    showCloseIcon = true,
    width = 'medium',
  } = props;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleEsc = () => {
    if (closeOnEscape) {
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onClose();
    }
  };

  const handleOutsideClick = () => {
    if (closeOnOutsideClick) {
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onClose();
    }
  };

  return (
    <Layer
      position={direction}
      full="vertical"
      animation="slide"
      onEsc={handleEsc}
      onClickOutside={handleOutsideClick}
    >
      <Card fill="vertical" width={width}>
        {!!header && (
          <CardHeader
            pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Box flex direction="row" justify="between" align="center">
              <Heading level="3" margin="none">{header}</Heading>
              {showCloseIcon && (<Button margin={{ right: '-1rem' }} icon={<Close />} onClick={handleClose} />)}
            </Box>
          </CardHeader>
        )}
        <CardBody pad={{ horizontal: 'medium', vertical: 'small' }}>{children}</CardBody>
        {!!footer && (
          <CardFooter
            pad={{ horizontal: 'medium', vertical: 'small' }}
            background="light-1"
            border={{ side: 'top', size: 'small', style: 'solid', color: 'light-2' }}
          >
            {footer}
          </CardFooter>
        )}
      </Card>
    </Layer>
  );
};
