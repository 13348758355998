import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import { Box, CheckBox, FormField, TextInput } from 'grommet';
import React, { useEffect, useId } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ArtiaButton } from '@/components/artia-button';
import { Busy } from '@/components/busy';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { BorderlessFormField } from '@/components/form-controls';
import { useBidRecommendationsService } from '@/features/bid-analysis/api/bid-recommendations-service';

import { useBidAnalysisDetails } from '../../../bid-analysis-details.provider';

type CreateBidRecommendationDialogProps = {
  onSubmitComplete: () => void;
  onClose: () => void;
  open: boolean;
};

type CreateBidRecommendationFormInputs = {
  title: string;
  isHistorical: boolean;
};

export const CreateBidRecommendationDialog = ({ open, ...rest }: CreateBidRecommendationDialogProps) => {
  return (
    <Dialog open={open} width="large">
      <CreateBidRecommendationForm {...rest} />
    </Dialog>
  );
};

const CreateBidRecommendationForm = ({ onClose, onSubmitComplete }: Omit<CreateBidRecommendationDialogProps, 'open'>) => {
  const formId = useId();
  const { bidAnalysis } = useBidAnalysisDetails();
  const { handleError } = useErrorHandler();
  const { success } = useNotifications();
  const { createBidRecommendation } = useBidRecommendationsService();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit
  } = useForm<CreateBidRecommendationFormInputs>({
    mode: 'all',
    defaultValues: {
      title: '',
      isHistorical: false
    }
  });

  const createBidRecommendationAsync = useAsync(createBidRecommendation);
  const saving = createBidRecommendationAsync.status === UseAsyncStatus.Pending;

  const submitDisabled = !isValid || saving;

  useEffect(() => {
    const { status, error } = createBidRecommendationAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(
        error,
        {
          title: 'Bid Recommendation Creation Failed',
          message: 'Unable to create Bid Recommendation.',
          autoClose: false
        }
      );

      return;
    }

    success({ title: 'Success', message: 'Successfully created Bid Recommendation.', autoClose: true });
    onSubmitComplete();
  }, [createBidRecommendationAsync.status]);

  const handleFormSubmit = ({ title, isHistorical }: CreateBidRecommendationFormInputs) => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    createBidRecommendationAsync.execute(bidAnalysis.id, { title, isHistorical });
  };

  return (
    <>
    {/* @ts-expect-error TS(2322): Type 'false | (() => void)' is not assignable to t... Remove this comment to see the full error message */}
      <DialogHeader title="Create Bid Recommendation" onClose={!saving && onClose}/>
      <DialogBody>
        <Box id={formId} as="form" fill="horizontal" gap="medium" onSubmit={handleSubmit((data) => handleFormSubmit(data))}>
          <Controller
            control={control}
            name="title"
            rules={{
              maxLength: 50,
              required: true
            }}
            render={({ field: { ref, value, onChange, onBlur } }) => (
              <FormField
                name="title"
                htmlFor="title"
                label="Title"
                error={errors.title?.message}
              >
                <TextInput
                  ref={ref}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  id="title"
                  name="title"
                  maxLength={50}
                />
              </FormField>
            )}
          />
          <Controller
            control={control}
            name="isHistorical"
            render={({ field: { ref, value, onChange } }) => (
              <BorderlessFormField
                name="isHistorical"
                htmlFor="is-historical"
              >
                <CheckBox
                  ref={ref}
                  checked={value}
                  onChange={onChange}
                  name="isHistorical"
                  id="is-historical"
                  label="Historical Bid Recommendation"
                />
              </BorderlessFormField>
            )}
          />
        </Box>
      </DialogBody>
      <DialogActions>
        <ArtiaButton
          type="button"
          label="Cancel"
          onClick={onClose}
          disabled={saving}
          size="large"
          variant="outlined"
        />
        <ArtiaButton
          type="submit"
          form={formId}
          label={<Busy busy={saving} content="Save" />}
          disabled={submitDisabled}
          size="large"
        />
      </DialogActions>
    </>
  );
};
