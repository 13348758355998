import { z } from 'zod';

export const createUpdateCoverageTagFormSchema = z.object({
  name: z.string()
    .trim()
    .min(1, 'Name is required.')
    .max(250, { message: 'Name must be less than 250 characters.' }),
  type: z.object({
    code: z.string(),
    name: z.string()
  })
}).required();

export type CreateUpdateCoverageTagFormSchema = z.infer<typeof createUpdateCoverageTagFormSchema>;
