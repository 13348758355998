import { queryOptions, useQuery } from '@tanstack/react-query';

import { StateCoverage } from '@/features/coverage/types/state-coverage';
import { artiaProtectedApi } from '@/lib/api-client';
import { QueryConfig } from '@/lib/react-query';

const getDrugStateCoverages = (drugId: number): Promise<StateCoverage[]> => {
  return artiaProtectedApi.get(`drugs/${drugId}/state-coverages`);
};

export const makeDrugStateCoveragesQueryKey = (drugId: number) => ['drugStateCoverages', drugId];

export const getDrugStateCoveragesOptions = (drugId: number) => {
  return queryOptions({
    queryKey: makeDrugStateCoveragesQueryKey(drugId),
    queryFn: () => getDrugStateCoverages(drugId)
  });
};

type UseDrugStateCoveragesOptions = {
  drugId: number;
  queryConfig?: QueryConfig<typeof getDrugStateCoveragesOptions>
};

export const useDrugStateCoverages = ({ drugId, queryConfig }: UseDrugStateCoveragesOptions) => {
  return useQuery({
    ...getDrugStateCoveragesOptions(drugId),
    ...queryConfig
  });
};
