import { PaCriteriaDialog } from '@shared/pa-info/pa-criteria-dialog';
import { Box, Spinner } from 'grommet';
import React, { useEffect, useState } from 'react';

import { getRowState } from '@/features/bid-analysis/api/use-net-cost-comparison-row-expansion';
import { NetCostComparisonClinicalsProduct, NetCostComparisonRowState } from '@/features/bid-analysis/types/net-cost-comparison';

import { useBidAnalysisDetails } from '../../../bid-analysis-details.provider';
import { useNetCostComparisonProvider } from '../../../net-cost-comparison.provider';
import { ExportToCsvButton } from './export-to-csv-button';
import { NetCostComparisonTable } from './net-cost-comparison-table';

type ClinicalsModalState = {
  mode: 'closed' | 'view-pa-criteria';
  product?: NetCostComparisonClinicalsProduct;
};

export const NetCostComparisonPage = () => {
  const { bidAnalysis } = useBidAnalysisDetails();
  const {
    rows,
    loadingTopLevelRows,
    loadTopLevelRows,
    ndcsByStateAndProduct,
    expandRow,
    collapseRow,
    onUpdateRow
  } = useNetCostComparisonProvider();

  useEffect(() => {
    void loadTopLevelRows();
  }, []);

  const [clinicalsDialogState, setClinicalsDialogState] = useState<ClinicalsModalState>({ mode: 'closed' });

  return (
    <Box>
      <Box>
        <Box
          pad="small"
          direction="row"
          justify="end"
          gap="medium"
          style={{ background: '#00314C' }}
        >
          <ExportToCsvButton />
        </Box>

        {!loadingTopLevelRows ? (
          <NetCostComparisonTable
            rows={rows ?? []}
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            bidAnalysisType={bidAnalysis?.stateBenefits?.length > 1 ? 'pool' : 'state'}
            isRowExpanded={(args) => getRowState(ndcsByStateAndProduct, args)?.state === NetCostComparisonRowState.EXPANDED}
            onRowExpand={expandRow}
            onRowCollapse={collapseRow}
            onRowUpdate={onUpdateRow}
            onSelectCoverage={(coverage) => setClinicalsDialogState({
              mode: 'view-pa-criteria',
              product: coverage
            })}
          />
        ) : <Spinner />}

        <PaCriteriaDialog
          open={clinicalsDialogState.mode === 'view-pa-criteria'}
          onClose={() => setClinicalsDialogState({ mode: 'closed' })}
          paTypes={clinicalsDialogState.product?.paTypes}
          stepTherapyCount={clinicalsDialogState.product?.stepTherapyCount}
          hasAdditionalSteps={clinicalsDialogState.product?.hasAdditionalSteps}
          paCriteriaDescription={clinicalsDialogState.product?.paCriteriaDescription}
          paCriteriaSourceUrl={clinicalsDialogState.product?.paCriteriaSourceUrl}
        />
      </Box>
    </Box>
  );
};
