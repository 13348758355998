import { ProtectedRoute } from '@shared/auth';
import { useErrorHandler } from '@shared/errors';
import { useHeaderDetails } from '@shared/header';
import { useNotifications } from '@shared/notifications';
import { CloudDownload } from 'grommet-icons';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMedicaidDatasetsService } from '@/features/medicaid-datasets';
import { MedicaidDatasetForm } from '@/features/medicaid-datasets/medicaid-dataset-form';
import {
  MedicaidDatasetFormProvider,
  useMedicaidDatasetForm
} from '@/features/medicaid-datasets/medicaid-dataset-form.provider';

const NewMedicaidDataset = () => {
  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();
  const { form, resetForm } = useMedicaidDatasetForm();
  const { createMedicaidDataset } = useMedicaidDatasetsService();
  const { success } = useNotifications();
  const navigate = useNavigate();
  const { handleError } = useErrorHandler();

  const [submitting, setSubmitting] = useState(false);

  React.useEffect(() => {
    setHeaderDetails({
      documentTitle: 'Add Medicaid Dataset',
      pageTitle: 'Add Medicaid Dataset',
      breadcrumbs: [
        { icon: CloudDownload, label: 'Medicaid Datasets', url: '/medicaid-datasets' },
        { label: 'Add Medicaid Dataset', url: '/medicaid-datasets/new' }
      ]
    });

    return () => {
      clearHeaderDetails();
    };
  }, []);

  const handleSubmit = async () => {
    setSubmitting(true);

    try {
      await createMedicaidDataset(form);
      success({ title: 'Medicaid Dataset Added', message: 'The Medicaid dataset was added successfully.', autoClose: true });
      resetForm();
      navigate('/medicaid-datasets');
    } catch (ex) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      handleError(ex, {
        title: 'Error Adding Medicaid Dataset',
        message: 'We encountered an unexpected error while adding the Medicaid dataset.',
        autoClose: false
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    navigate('..');
  };

  return (
    <MedicaidDatasetForm onSubmit={handleSubmit} onCancel={handleCancel} submitting={submitting} />
  );
};

const NewMedicaidDatasetPage = () => {
  return (
    <MedicaidDatasetFormProvider>
      <ProtectedRoute component={NewMedicaidDataset} policies={['canManageMedicaidDatasets']} />
    </MedicaidDatasetFormProvider>
  );
};

export default NewMedicaidDatasetPage;
