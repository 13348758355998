import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { useHeaderDetails } from '@shared/header';
import { Box, Button, ColumnConfig, DataTable, FormField, TextInput } from 'grommet';
import { Edit, Search } from 'grommet-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ArtiaButton } from '@/components/artia-button';
import { TableEmptyPlaceholder, TableLoadingOverlay } from '@/components/loading';
import { useUserService } from '@/features/admin/api/use-users-service';
import { User } from '@/features/admin/types/user';

const UsersPage = () => {
  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();
  const { getUsers } = useUserService();
  const { handleError } = useErrorHandler();
  const navigate = useNavigate();

  const [filters, setFilters] = useState<{ search?: string }>({});

  const asyncUsers = useAsync(getUsers);
  const loadingUsers = asyncUsers.status === UseAsyncStatus.Pending;
  const filteredUsers = useMemo(
    () => asyncUsers.value?.filter(user =>
      !filters.search
      || user.name.toLowerCase().includes(filters.search.toLowerCase())
      || user.email.toLowerCase().includes(filters.search.toLowerCase())
    ) ?? [],
    [asyncUsers.value, filters]
  );

  useEffect(() => {
    const { status, error } = asyncUsers;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(
        error,
        {
          title: 'Failed to Load Users',
          message: 'We encountered an unexpected error while loading Users.',
          autoClose: false
        }
      );

      return;
    }
  }, [asyncUsers.status]);

  const {
    control,
    handleSubmit,
    watch,
    reset
  } = useForm<{ search?: string }>({ mode: 'all', defaultValues: { search: '' } });

  React.useEffect(() => {
    asyncUsers.execute();

    return () => {
      reset();
    };
  }, []);

  React.useEffect(() => {
    setHeaderDetails({
      documentTitle: 'Users',
      pageTitle: 'Users',
      breadcrumbs: []
    });

    return () => {
      clearHeaderDetails();
    };
  }, []);

  const onEditUser = (id: number) => {
    navigate(`/admin/users/${id}/edit`);
  };

  const columns: ColumnConfig<User>[] = [
    { property: 'name', header: 'Name' },
    { property: 'email', header: 'Email' },
    {
      property: 'actions',
      size: 'xsmall',
      sortable: false,
      render: (user: User) => (
        <Box direction="row" gap="xxsmall">
          <Button
            tip="Edit"
            hoverIndicator="background"
            margin="none"
            icon={<Edit color="accent-1" size="medium" />}
            onClick={() => onEditUser(user.id)}
          />
        </Box>
      )
    }
  ];

  useEffect(() => {
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Timeout'.
    let timeoutId: NodeJS.Timeout = null;
    const subscription = watch(() => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        handleSubmit((data) => setFilters(data))();
      }, 300);
    });

    return () => {
      subscription.unsubscribe();
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  return (
    <Box pad={{ bottom: 'medium' }}>
      <Box margin={{ top: 'small' }} gap="small">
        <Box
          as="form"
          border={{ size: 'small', color: 'light-3', side: 'bottom' }}
          pad="small"
          direction="row"
          justify="end"
          align="end"
          gap="medium"
          onReset={(e) => {
            e.preventDefault();
            reset();
          }}
        >
          <Box direction="row" gap="small" align="end">
            <Box width="medium">
              <Controller
                control={control}
                name="search"
                render={({ field: { ref, value, onChange, onBlur, name } }) => (
                  <FormField margin="none" name={name} htmlFor={name} aria-label="Search By Name or Email">
                    <TextInput
                      ref={ref}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      id={name}
                      name={name}
                      type="search"
                      icon={<Search color="accent-1" />}
                      placeholder="Search By Name or Email"
                    />
                  </FormField>
                )}
              />
            </Box>
          </Box>

          <ArtiaButton type="reset" a11yTitle="Clear" label="Clear" />
        </Box>

        <DataTable
          columns={columns}
          data={filteredUsers}
          primaryKey="id"
          sortable
          step={10}
          paginate
          placeholder={
            (loadingUsers || filteredUsers.length === 0) &&
            <Box fill>
              {loadingUsers && <TableLoadingOverlay />}
              {!loadingUsers && filteredUsers.length === 0 && <TableEmptyPlaceholder content="No User data is available." />}
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export default UsersPage;
