// noinspection CssUnusedSymbol

import 'react-tabs/style/react-tabs.css';

import React, { ReactNode } from 'react';
import { Tab, TabList, TabPanel, Tabs as ReactTabs } from 'react-tabs';
import styled from 'styled-components';

type TabItem = {
  key: string,
  name: ReactNode,
  content: ReactNode
};

type TabsProps = {
  items: TabItem[];
  activeIndex: number;
  onSelect: (newIndex: number) => void;
  disabled?: boolean;
};

export const Tabs = ({ items, activeIndex, onSelect, disabled = false }: TabsProps) => {
  return (
    <StyledContainer>
      <ReactTabs
        disabled={disabled}
        selectedIndex={activeIndex}
        onSelect={(index, _, event) => {
          onSelect(index);
          if (event.target instanceof HTMLElement) {
            event.target.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
          }
        }}
      >
        <TabList disabled={disabled}>
          {items.map((tab) => <Tab disabled={disabled} key={tab.key}>{tab.name}</Tab>)}
        </TabList>
        {items.map((tab) => <TabPanel key={tab.key}>{tab.content}</TabPanel>)}
      </ReactTabs>
    </StyledContainer>
  );
};

// styles taken from the base CSS for this library and modified to look like the default Grommet tabs
const StyledContainer = styled.div`
  & .react-tabs__tab-list {
    padding: 0;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 0;
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => `${theme.global.colors['contrast-1']} ${theme.global.colors.brand}`};
    scrollbar-gutter: both-edges;
    border-bottom: 1px solid ${({ theme }) => theme.global.colors.brand};
  }

  & .react-tabs__tab {
    border: none;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-shrink: 0;
    width: max-content;
    align-items: center;
    font-weight: 600;
    color: ${({ theme }) => theme.global.colors['contrast-1']};
    font-size: 0.875rem;
  }

  & .react-tabs__tab--selected {
    background: ${({ theme }) => theme.global.colors.brand};
    color: white;
  }
`;
