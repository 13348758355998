import { Box, FormField, TextInput } from 'grommet';
import { Search } from 'grommet-icons';
import React from 'react';

import { GrommetFilterForm } from '@/components/filters';

type MedicaidDatasetsFiltersProps = {
  defaultValue: MedicaidDatasetsFilterData;
  onSearch: (data: MedicaidDatasetsFilterData) => void;
};

export const MedicaidDatasetsFilters = ({ defaultValue, onSearch }: MedicaidDatasetsFiltersProps) => {
  return (
    <GrommetFilterForm
      defaultValue={defaultValue}
      emptyValues={{ title: '', year: '' }}
      onChange={(value) => onSearch(value)}
    >
      <Box width="medium">
        <FormField
          name="title"
          htmlFor="title"
          margin="none"
        >
          <TextInput
            icon={<Search color="accent-1"/>}
            id="title"
            type="search"
            name="title"
            placeholder="Search By Title"
          />
        </FormField>
      </Box>
      <Box width="14rem">
        <FormField
          name="year"
          htmlFor="year"
          margin="none"
        >
          <TextInput
            type="search"
            icon={<Search color="accent-1"/>}
            id="year"
            name="year"
            placeholder="Search By Year"
          />
        </FormField>
      </Box>
    </GrommetFilterForm>
  );
};

export type MedicaidDatasetsFilterData = { title?: string, year?: string };
