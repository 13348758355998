import { ProtectedRoute } from '@shared/auth';
import AppErrorBoundary from '@shared/errors/app-error-boundary';
import { Outlet } from 'react-router-dom';

import { AutoSignIn } from '@/components-new/auto-sign-in';
import { DashboardLayout } from '@/components-new/layouts';
import { useCurrentUser } from '@/hooks/use-current-user';

const Layout = () => {
  const { user } = useCurrentUser();
  return (
      <DashboardLayout user={user}>
        <AppErrorBoundary>
          <Outlet />
        </AppErrorBoundary>
      </DashboardLayout>
  );
};

const ProtectedLayout = () => {
  return(
    <AutoSignIn>
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    </AutoSignIn>
  );
};

export default ProtectedLayout;
