import { MarkdownPreview } from '@shared/markdown';
import { Anchor, Box, NameValueList, NameValuePair, Text } from 'grommet';
import { Share } from 'grommet-icons';
import React from 'react';
import styled from 'styled-components';

import { Dialog, DialogBody, DialogHeader } from '@/components/dialog';
import Nullish from '@/components/nullish';
import { Lookup } from '@/types/lookup';
import { stepTherapyDisplay } from '@/utils/state-coverage';

type PaCriteriaDialogProps = {
  open: boolean;
  onClose: () => void;
  paTypes?: Lookup[];
  stepTherapyCount?: number;
  hasAdditionalSteps?: boolean;
  paCriteriaDescription?: string;
  paCriteriaSourceUrl?: string;
};

export const PaCriteriaDialog = (props: PaCriteriaDialogProps) => {
  const {
    open,
    onClose,
    paTypes,
    stepTherapyCount,
    hasAdditionalSteps,
    paCriteriaDescription,
    paCriteriaSourceUrl
  } = props;

  return (
    <Dialog open={open}>
      <DialogHeader title="PA Criteria" onClose={onClose} />
      <DialogBody>
        <Box gap="small">
          <NameValueList>
            <NameValuePair name="PA Types">
              <Text>{paTypes?.map(it => it.label)?.join('; ') ?? <>&mdash;</>}</Text>
            </NameValuePair>
            <NameValuePair name="Step Therapy">
              <Text>
                <Nullish value={stepTherapyDisplay(stepTherapyCount, hasAdditionalSteps)} />
              </Text>
            </NameValuePair>
          </NameValueList>
          <MarkdownPreview markdown={paCriteriaDescription} />
          {paCriteriaSourceUrl ? (
            <span>
              <SourceLink href={paCriteriaSourceUrl} target="_blank" referrerPolicy="no-referrer">
                View Source
                <Share color="contrast-1" />
              </SourceLink>
            </span>
          ) : null}
        </Box>
      </DialogBody>
    </Dialog>
  );
};

const SourceLink = styled(Anchor)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: max-content;
`;
