import { useErrorHandler } from '@shared/errors';
import { Box } from 'grommet';
import { Add, Download } from 'grommet-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ArtiaButton } from '@/components/artia-button';
import { useBidAnalysisService } from '@/features/bid-analysis';
import { BidRecommendation } from '@/features/bid-analysis/types/bid-recommendation';
import { useToggle } from '@/hooks/use-toggle';

import { useBidAnalysisDetails } from '../../../bid-analysis-details.provider';
import { AddPackagingDialog } from './add-packaging-dialog';
import { BidRecommendationDetails } from './bid-recommendation-details';
import { CreateBidRecommendationDialog } from './create-bid-recommendation-dialog';
import { DeleteBidRecommendationDialog } from './delete-bid-recommendation-dialog';
import { DeletePackagingsDialog } from './delete-packagings-dialog';

export const BidRecommendations = () => {
  const { exportBidRecommendations } = useBidAnalysisService();
  const { handleError } = useErrorHandler();
  const [ showCreateDialog, toggleCreateDialog ] = useToggle(false);
  const [ addingPackagingFor, setAddingPackagingFor ] = useState<number | undefined>();

  const { id } = useParams();
  const { bidAnalysis, loadBidAnalysis } = useBidAnalysisDetails();

  useEffect(() => {

    return () => {
      loadBidAnalysis();
    };
  }, []);

  const handleCreateBidRecommendation = () => {
    toggleCreateDialog();
  };

  const handleNewRecommendationComplete = () => {
    loadBidAnalysis();
    toggleCreateDialog();
  };

  const [ deletingBidRecommendation, setDeletingBidRecommendation ] = useState<BidRecommendation | undefined>();
  const handleDelete = (bidRecommendation: BidRecommendation) => {
    setDeletingBidRecommendation(bidRecommendation);
  };

  const handleDeleteComplete = () => {
    loadBidAnalysis();
    setDeletingBidRecommendation(undefined);
  };

  const onExportBidRecommendations = async () => {
    try {
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      const csv = await exportBidRecommendations(parseInt(id));

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(csv.contents);
      link.download = csv.filename;
      link.click();
    } catch (ex) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      handleError(ex, {
        title: 'Export to CSV Failed',
        message: 'We encountered an unexpected error while exporting the bid recommendations. Please try again or contact an administrator.',
        autoClose: false
      });
    }
  };

  const handleAddPackaging = (bidRecommendationId: number) => {
    setAddingPackagingFor(bidRecommendationId);
  };

  const handleAddPackagingComplete = () => {
    loadBidAnalysis();
    setAddingPackagingFor(undefined);
  };

  const handleCloseAddPackagingDialog = () => {
    setAddingPackagingFor(undefined);
  };

  const [deletingPackagingsContext, setDeletingPackagingsContext] = useState<{ recommendation?: BidRecommendation, packagingIds?: number[] }>({});

  const handleDeletePackagings = (recommendation: BidRecommendation, packagingIds: number[]) => {
    setDeletingPackagingsContext({ recommendation, packagingIds });
  };

  const handleDeletePackagingsComplete = () => {
    loadBidAnalysis();
    setDeletingPackagingsContext({});
  };

  const handleCloseDeletePackagingsDialog = () => {
    setDeletingPackagingsContext({});
  };

  const recommendations = useMemo(() => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    return [ ...bidAnalysis.historicalBidRecommendations, ...bidAnalysis.bidRecommendations ];
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  }, [bidAnalysis.historicalBidRecommendations, bidAnalysis.bidRecommendations]);

  return (
    <Box pad={{ vertical: 'small' }}>
      <Box direction="row" justify="end" gap="small">
        <ArtiaButton
          icon={<Download/>}
          a11yTitle="Export to CSV"
          label="Export"
          tip="Export to CSV"
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          disabled={bidAnalysis.bidRecommendations.length === 0}
          onClick={onExportBidRecommendations}
        />

        <ArtiaButton
          icon={<Add/>}
          a11yTitle="Add Bid Recommendation"
          label="Add Bid Recommendation"
          onClick={handleCreateBidRecommendation}
        />
      </Box>

      {recommendations.map(recommendation => (
        <Box key={recommendation.id}>
          <BidRecommendationDetails
            bidRecommendation={recommendation}
            onDelete={handleDelete}
            onAddPackaging={handleAddPackaging}
            onDeletePackagings={handleDeletePackagings}
          />
        </Box>
      ))}

      <CreateBidRecommendationDialog
        onSubmitComplete={handleNewRecommendationComplete}
        onClose={toggleCreateDialog}
        open={showCreateDialog}
      />
      <DeleteBidRecommendationDialog
        open={!!deletingBidRecommendation}
        // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
        bidAnalysisId={bidAnalysis?.id}
        // @ts-expect-error TS(2322): Type 'BidRecommendation | undefined' is not assign... Remove this comment to see the full error message
        bidRecommendation={deletingBidRecommendation}
        onClose={() => setDeletingBidRecommendation(undefined)}
        onDeleteComplete={handleDeleteComplete}
      />

      <AddPackagingDialog
        open={!!addingPackagingFor}
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        bidAnalysisId={bidAnalysis.id}
        // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
        bidRecommendationId={addingPackagingFor}
        onSubmitComplete={handleAddPackagingComplete}
        onClose={handleCloseAddPackagingDialog}
      />

      <DeletePackagingsDialog
        open={!!deletingPackagingsContext.recommendation && !!deletingPackagingsContext.packagingIds}
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        bidAnalysisId={bidAnalysis.id}
        // @ts-expect-error TS(2322): Type 'BidRecommendation | undefined' is not assign... Remove this comment to see the full error message
        bidRecommendation={deletingPackagingsContext.recommendation}
        // @ts-expect-error TS(2322): Type 'number[] | undefined' is not assignable to t... Remove this comment to see the full error message
        packagingIds={deletingPackagingsContext.packagingIds}
        onClose={handleCloseDeletePackagingsDialog}
        onRemoveComplete={handleDeletePackagingsComplete}
      />
    </Box>
  );
};
