import React from 'react';
import { Outlet } from 'react-router-dom';

import { CreateMarketBasketProvider } from './create-market-basket.provider';
import { MarketBasketsProvider } from './market-baskets.provider';

export const MarketBasketsDependencies = () => {
  return (
    <MarketBasketsProvider>
      <CreateMarketBasketProvider>
        <Outlet />
      </CreateMarketBasketProvider>
    </MarketBasketsProvider>
  );
};
