import React, { ReactNode } from 'react';

import { ColumnConfiguration } from '../../types';
import {
  ClassCarveOutProperty,
  ClassPdlExemptionProperty,
  DrugCarveOutProperty,
  DrugPdlExemptionProperty,
  McoCarveOutCellWrapper,
  McoCarveOutFilters,
  McoCarveOutProvider,
  ProviderMode
} from './mco-carve-outs';
import { CellWrapperProps, ExternalDataConfiguration, FilterProps } from './types';

const externalDataConfigurations: ExternalDataConfiguration[] = [
  {
    id: 'mco-carve-outs',
    appliesToColumn: propertyName => [DrugCarveOutProperty, ClassCarveOutProperty].includes(propertyName),
    TableWrapper: ({ children, ...props }) => (
      <McoCarveOutProvider {...props} mode={ProviderMode.MCO_CARVE_OUTS}>
        {children}
      </McoCarveOutProvider>
    ),
    CellWrapper: McoCarveOutCellWrapper,
    Filter: (props) => <McoCarveOutFilters {...props} mode={ProviderMode.MCO_CARVE_OUTS} />
  },
  {
    id: 'pdl-exemptions',
    appliesToColumn: propertyName => [DrugPdlExemptionProperty, ClassPdlExemptionProperty].includes(propertyName),
    TableWrapper: ({ children, ...props }) => (
      <McoCarveOutProvider {...props} mode={ProviderMode.PDL_EXEMPTIONS}>
        {children}
      </McoCarveOutProvider>
    ),
    CellWrapper: McoCarveOutCellWrapper,
    Filter: (props) => <McoCarveOutFilters {...props} mode={ProviderMode.PDL_EXEMPTIONS} />
  }
];

const getExternalDataConfigurations = (columnConfigurations: ColumnConfiguration[]) => {
  // relies on object identity for uniqueness. we do this because we only want ONE instance to be rendered even if
  // multiple columns require the same dependency
  const result = new Set<ExternalDataConfiguration>();

  for (const externalDataConfiguration of externalDataConfigurations) {
    if (columnConfigurations.some(columnConfiguration => externalDataConfiguration.appliesToColumn(columnConfiguration.propertyName))) {
      result.add(externalDataConfiguration);
    }
  }

  return [...result];
};

export const ExternalDataTableProvider = ({ columnConfigurations, children }: { columnConfigurations: ColumnConfiguration[], children: ReactNode }) => {
  const externalDataConfigurations = getExternalDataConfigurations(columnConfigurations);

  let result = <>{children}</>;
  // iterate in reverse order so the ordering in the list matches its level in the component tree.
  // e.g. [A, B] should produce <A><B>{children}</B></A>. Iterating forward would produce <B><A>{children}</A></B> instead.
  for (let i = externalDataConfigurations.length - 1; i >= 0; i--) {
    const { TableWrapper } = externalDataConfigurations[i];
    result = <TableWrapper>{result}</TableWrapper>;
  }

  return result;
};

export const ExternalDataCellWrapper = ({ row, config, children }: CellWrapperProps) => {
  const externalDataConfigurations = getExternalDataConfigurations([config]);

  let result = <>{children}</>;
  // iterate in reverse order so the ordering in the list matches its level in the component tree.
  // e.g. [A, B] should produce <A><B>{children}</B></A>. Iterating forward would produce <B><A>{children}</A></B> instead.
  for (let i = externalDataConfigurations.length - 1; i >= 0; i--) {
    const { CellWrapper } = externalDataConfigurations[i];
    result = <CellWrapper row={row} config={config}>{result}</CellWrapper>;
  }

  return result;
};

export const ExternalDataFilters = ({ openColumnConfigurations, ...rest }: FilterProps) => {
  const externalDataConfigurations = getExternalDataConfigurations(openColumnConfigurations);

  const filters = [];
  for (const { id, Filter } of externalDataConfigurations) {
    if (!Filter) {
      continue;
    }
    filters.push(<Filter key={id} openColumnConfigurations={openColumnConfigurations} {...rest} />);
  }

  return <>{filters}</>;
};
