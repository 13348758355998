import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { Toast, useToaster } from 'react-hot-toast/headless';
import { LiaTimesSolid } from 'react-icons/lia';

/**
 * The main content area of a notification.
 */
export const NotificationContent = ({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) => {
  return <div className={clsx(className) } {...props} />;
};

/**
 * The heading of a notification.
 */
export const NotificationHeading = ({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) => {
  return <div {...props} className={clsx(className, 'text-sm font-semibold text-zinc-950 dark:text-white')}/>;
};

/**
 * The message contents of a notification.
 */
export const NotificationMessage = ({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) => {
  return <div {...props} className={clsx(className, 'text-sm text-gray-500')}/>;
};

/**
 * Additional actions the user can take on a notification.
 */
export const NotificationActions = ({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) => {
  return <div className={clsx(className, 'mt-2 flex space-x-2')} {...props} />;
};

const styles = {
  icon: 'data-[slot=icon]:*:size-6 data-[slot=icon]:*:shrink-0 ',
  type: {
    'success': 'data-[slot=icon]:*:text-green-400',
    'warning': 'data-[slot=icon]:*:text-yellow-400',
    'error': 'data-[slot=icon]:*:text-red-400',
    'info': 'data-[slot=icon]:*:text-blue-400',
    'loading': 'data-[slot=icon]:*:text-gray-400',
    'default': 'data-[slot=icon]:*:text-gray-400',
  }
};

export type NotificationProps = {
  type?: keyof typeof styles['type'],
  children: React.ReactNode,
  onClose: () => void;
  ariaProps?: Toast['ariaProps']
}

/**
 * A component that displays a notification message to the user.
 */
export const Notification = forwardRef((
  { children, type = 'default', onClose, ariaProps }: NotificationProps,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const { handlers } = useToaster();

  const handleClose = () => {
    handlers.endPause();
    onClose();
  };

  return (
    <div
      {...ariaProps}
      ref={ref}
      className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black/5 transition data-[closed]:data-[enter]:translate-y-2 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0"
    >
      <div className="flex justify-between gap-3 p-4">
        <div className={clsx(
          styles.icon,
          styles.type[type],
          'flex items-start gap-3'
        )}
        >
          {children}
        </div>
        {!!onClose && (
          <div className="shrink-0">
            <button
              className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-900 focus:ring-offset-2"
              onClick={handleClose}
            >
              <span className="sr-only">Close</span>
              <LiaTimesSolid aria-hidden="true" className="size-5"/>
            </button>
          </div>
        )}
      </div>
    </div>
  );
});
