import { useSuspenseQuery } from '@tanstack/react-query';

import { useClientService } from '@/features/clients/api/use-clients-service';

export const makeClientUsersQueryKey = (clientId: number | string) => ['client', clientId, 'users'];

export const useClientUsers = (clientId: number) => {
  const { getClientUsers } = useClientService();
  const { data, error, isFetching, dataUpdatedAt } = useSuspenseQuery({
    queryKey: makeClientUsersQueryKey(clientId),
    queryFn: async () => getClientUsers(clientId)
  });

  if (error && !isFetching) throw error;

  return { data, dataUpdatedAt, isFetching };
};
