export const formatDate = (value: string): string => {
  if (!value) return '';

  const parsedNumbers = value.split('-');

  if (parsedNumbers.length !== 3) {
    // try again but with the MM/DD/YYYY format
    const parsedValues = value.split('/');
    const [month, date, year] = parsedValues;
    return `${parseInt(month)}/${parseInt(date)}/${year}`;
  }

  const [year, month, date] = parsedNumbers;
  return `${parseInt(month)}/${parseInt(date)}/${year}`;
};

/**
 * Format's a date string in the ISO 8601 format of YYYY-MM or YYYY-MM-DD to
 * MMM YYYY.
 *
 * @param value The date to format.
 */
export const formatYearMonth = (value: string): string => {
  if (!value) return '';

  const [year, month] = value.split('-');

  const date = new Date();
  date.setMonth(parseInt(month) - 1);

  return `${date.toLocaleString('en-US', { month: 'short' })} ${year}`;
};

export const formatTimestamp = (value: string): string => {
  if (!value) return '';

  const date = new Date(value);

  const offsetMilliseconds = date.getTimezoneOffset() * 60000;

  const utcTime = date.getTime();

  date.setTime(utcTime - offsetMilliseconds);

  return date.toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' });
};

export const isLeapYear = (year: number): boolean => {
  // Not divisible by 4, so it's not a leap year
  if (year % 4 !== 0) return false;

  // Divisible by 4 but not 100, so it's a leap year
  if (year % 100 !== 0) return true;

  // It's a leap year if divisible by 100 and 400.
  return year % 400 === 0;
};

export const isCompleteDate = (value: string): boolean => {
  const regexp = new RegExp('^(0?[1-9]|1[0-2])/(0?[1-9]|1\\d|2\\d|3[01])/\\d{4}$');

  return regexp.test(value);
};

export const isValidDate = (value: string): boolean => {
  const [ monthStr, dayStr, yearStr ] = value.split('/');

  // Validate that date parts exist.
  if (!monthStr || !dayStr || !yearStr) {
    return false;
  }

  const month = parseInt(monthStr);
  const day = parseInt(dayStr);
  const year = parseInt(yearStr);

  if (isNaN(month) || isNaN(day) || isNaN(year)) {
    return false;
  }

  // Validate month is at least 1 and no greater than 12.
  if (month < 1 || month > 12) {
    return false;
  }

  // Validate day based on month and year.
  let maxDays = 31;

  const monthsWith30Days = [4, 6, 9, 11];
  if (monthsWith30Days.includes(month)) {
    maxDays = 30;
  }

  if (month === 2) {
    maxDays = isLeapYear(year) ? 29 : 28;
  }

  if (day < 1 || day > maxDays) {
    return false;
  }

  // Validate that year is 4 digits
  if (yearStr.length !== 4) {
    return false;
  }

  return true;
};
