/**
 * An HTTP error when communicating with the backend API.
 */
export class HttpError extends Error {
  /**
   * @param response The HTTP response.
   * @param reportable True if this error should be reported to Bugsnag, false
   * if this error doesn't represent something we can fix on the frontend.
   */
  constructor(response: Response, reportable = true) {
    super('An HTTP error occurred when making the request.');
    this.reportable = reportable;
    this.response = response;
  }

  reportable: boolean;
  response: Response;
}

/**
 * A validation error from the server.
 */
export class ValidationError extends Error {
  constructor(message: string, title: string) {
    super(message);
    this.title = title;

    Object.setPrototypeOf(this, ValidationError.prototype);
  }

  title: string;
}
