import { SelectValidators } from '@shared/validators';
import * as DateValidators from '@shared/validators/date-time-validators';
import {
  Box,
  Card,
  CardBody,
  CheckBox,
  FormField,
  Grid,
  RadioButtonGroup,
  Select,
  Text,
  TextInput
} from 'grommet';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';

import { ArtiaButton } from '@/components/artia-button';
import { BorderlessFormField, DateTextInput, NumericTextInput } from '@/components/form-controls';
import { WizardControlState } from '@/components/wizard';
import { DrugUtilizationPeriod, sortDrugUtilizationPeriods } from '@/features/drug-utilization';
import * as DateFunctions from '@/utils/date-time-functions';

import { useCreateBidAnalysis } from '../../create-bid-analysis.provider';
import { useCreateBidAnalysisForm } from '../../create-bid-analysis-form.provider';

export const BidAnalysisDetailsStep = ({ nextStep, previousActiveStep }: BidAnalysisDetailsStepProps) => {
  const { form: {
    // @ts-expect-error TS(2339): Property 'control' does not exist on type 'UseForm... Remove this comment to see the full error message
    control,
    // @ts-expect-error TS(2339): Property 'formState' does not exist on type 'UseFo... Remove this comment to see the full error message
    formState: { errors, isValid },
    // @ts-expect-error TS(2339): Property 'getValues' does not exist on type 'UseFo... Remove this comment to see the full error message
    getValues,
    // @ts-expect-error TS(2339): Property 'setValue' does not exist on type 'UseFor... Remove this comment to see the full error message
    setValue,
    // @ts-expect-error TS(2339): Property 'trigger' does not exist on type 'UseForm... Remove this comment to see the full error message
    trigger,
    // @ts-expect-error TS(2339): Property 'watch' does not exist on type 'UseFormRe... Remove this comment to see the full error message
    watch
  } } = useCreateBidAnalysisForm();

  const {
    searchMarketBaskets,
    marketBasketLookups,
    drugUtilizationPeriods
  } = useCreateBidAnalysis();

  // There's a problem where, when coming back from a future step,
  useEffect(() => {
    if (previousActiveStep > 0) {
      trigger();
    }
  }, []);

  const watchMarketBasket = watch('marketBasket');
  const productLookups = useMemo(
    () => {
      trigger('marketBasket');
      return watchMarketBasket?.children ?? [];
    },
    [watchMarketBasket]
  );

  const watchEndsOn = watch('endsOn');
  const watchStartsOn = watch('startsOn');
  const endDateMin = useMemo(() => {
    if (DateFunctions.isCompleteDate(watchStartsOn) && DateFunctions.isValidDate(watchStartsOn)) {
      return watchStartsOn;
    }

    return null;
  }, [watchStartsOn]);

  useEffect(() => {
    if (DateFunctions.isCompleteDate(watchEndsOn)) {
      trigger('endsOn');
    }
  }, [endDateMin]);

  const watchReviewOn = watch('reviewOn');
  const watchDueOn = watch('dueOn');
  const reviewOnMin = useMemo(() => {
    const today = new Date();
    const dueOnDate = new Date(watchDueOn);

    if (
      DateFunctions.isCompleteDate(watchDueOn) &&
      DateFunctions.isValidDate(watchDueOn) &&
      dueOnDate > today
    ) {
      return watchDueOn;
    }

    return today.toString();
  }, [watchDueOn]);

  useEffect(() => {
    if (DateFunctions.isCompleteDate(watchReviewOn)) {
      trigger('reviewOn');
    }
  }, [reviewOnMin]);

  useEffect(() => {
    if (productLookups.length === 1) {
      setValue('drug', productLookups[0]);
      trigger('drug');
    } else {
      setValue('drug', null);
    }
  }, [productLookups]);

  const handleUtilizationPeriodChange = (id: number, checked: boolean) => {
    const currentPeriods = getValues('periods');
    let selectedPeriods = currentPeriods.filter((period: DrugUtilizationPeriod) => period.id !== id);

    if (checked) {
      selectedPeriods = [ ...selectedPeriods, drugUtilizationPeriods.find(period => period.id === id) ];
    }

    setValue('periods', selectedPeriods.sort(sortDrugUtilizationPeriods));
    trigger('periods');
  };

  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>(null);
  const delaySearch = (searchCallback: () => void) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(setTimeout(searchCallback, 100));
  };

  const handleNext = () => {
    nextStep();
  };

  return (
    <Card pad="medium" width="large">
      <CardBody>
        <Controller
          control={control}
          name="bidType"
          rules={{
            required: true,
          }}
          render={({ field: { value, onChange, onBlur } }) => (
            <Box margin={{ bottom: 'small' }}>
              <Text as="label" margin="small" weight="bold">Bid Type</Text>
              <BorderlessFormField
                required
                htmlFor="bid-type"
                name="bidType"
                error={errors.bidType?.message}
              >
                <Box>
                  <RadioButtonGroup
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    direction="row"
                    name="bidType"
                    id="bid-type"
                    options={['State', 'Pool', 'All States']}
                  />
                </Box>
              </BorderlessFormField>
            </Box>
          )}
        />
        <Controller
          control={control}
          name="name"
          rules={{
            maxLength: 100
          }}
          render={({ field: { value, onChange, onBlur } }) => (
            <FormField
              name="name"
              htmlFor="name"
              label="Name (Optional)"
              help="If blank, name will default to Client Name - Product Name - Pool or State - Year."
              error={errors.name?.message}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                id="name"
                name="name"
                maxLength={150}
              />
            </FormField>
          )}
        />
        <Controller
          control={control}
          name="marketBasket"
          rules={{
            validate: SelectValidators.hasValue
          }}
          render={({ field: { value, onChange, onBlur } }) => (
            <FormField
              required
              name="marketBasket"
              htmlFor="marketBasket"
              label="Market Basket"
              help="You may only select market baskets which are not missing any information and which belong to a client that you are assigned to."
              error={errors.marketBasket?.message}
            >
              <Select
                value={value}
                onBlur={onBlur}
                onChange={({ value }) => onChange(value)}
                onSearch={(text) => delaySearch(() => searchMarketBaskets(text))}
                options={marketBasketLookups}
                id="marketBasket"
                name="marketBasket"
                labelKey="label"
                placeholder="Search for Market Baskets"
              />
            </FormField>
          )}
        />
        <Controller
          control={control}
          name="drug"
          rules={{
            validate: SelectValidators.hasValue
          }}
          render={({ field: { value, onChange, onBlur } }) => (
            <FormField
              required
              name="drug"
              htmlFor="drug"
              label="Product"
              error={errors.drug?.message}
            >
              <Select
                value={value}
                onBlur={onBlur}
                onChange={({ value }) => onChange(value)}
                options={productLookups}
                id="drug"
                name="drug"
                labelKey="label"
                placeholder="Select a Product"
              />
            </FormField>
          )}
        />
        <Box direction="row" gap="small">
          <Box basis="1/2">
            <Controller
              control={control}
              name="startsOn"
              rules={{
                required: true,
                validate: {
                  valid: DateValidators.isValidDate,
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormField
                  required
                  name="startsOn"
                  htmlFor="starts-on"
                  label="Contract Term Starts On"
                  error={errors.startsOn?.message}
                >
                  <DateTextInput
                    name="startsOn"
                    id="starts-on"
                    value={value}
                    onChange={({ target }) => onChange((target as any).value)}
                    onBlur={onBlur}
                  />
                </FormField>
              )}
            />
          </Box>
          <Box basis="1/2">
            <Controller
              control={control}
              name="endsOn"
              rules={{
                required: true,
                validate: {
                  valid: DateValidators.isValidDate,
                  inRange: DateValidators.isInDateRange({ start: endDateMin })
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormField
                  required
                  name="endsOn"
                  htmlFor="ends-on"
                  label="Contract Term Ends On"
                  error={errors.endsOn?.message}
                >
                  <DateTextInput
                    name="endsOn"
                    id="ends-on"
                    value={value}
                    onChange={({ target }) => onChange((target as any).value)}
                    onBlur={onBlur}
                  />
                </FormField>
              )}
            />
          </Box>
        </Box>
        <Box direction="row" gap="small">
          <Box basis="1/4">
            <Controller
              control={control}
              name="year"
              rules={{
                required: true,
                maxLength: 4
              }}
              render={({ field: { value, onChange, onBlur } }) => (
                <FormField
                  required
                  name="year"
                  htmlFor="year"
                  label="Year"
                  error={errors.year?.message}
                >
                  <NumericTextInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    id="year"
                    name="year"
                    maxWholePlaces={4}
                    maxDecimalPlaces={0}
                  />
                </FormField>
              )}
            />
          </Box>
          <Box basis="3/4" direction="row" gap="small">
            <Box basis="1/2">
              <Controller
                control={control}
                name="dueOn"
                rules={{
                  validate: {
                    valid: DateValidators.isValidDate,
                    inRange: DateValidators.isInDateRange({ start: new Date().toString() })
                  }
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    name="dueOn"
                    htmlFor="due-on"
                    label="Bid Due Date (Optional)"
                    error={errors.dueOn?.message}
                  >
                    <DateTextInput
                      name="dueOn"
                      id="due-on"
                      value={value}
                      onChange={({ target }) => onChange((target as any).value)}
                      onBlur={onBlur}
                    />
                  </FormField>
                )}
              />
            </Box>
            <Box basis="1/2">
              <Controller
                control={control}
                name="reviewOn"
                rules={{
                  validate: {
                    valid: DateValidators.isValidDate,
                    inRange: DateValidators.isInDateRange({ start: reviewOnMin })
                  }
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    name="reviewOn"
                    htmlFor="review-on"
                    label="P&T Review Date (Optional)"
                    error={errors.reviewOn?.message}
                  >
                    <DateTextInput
                      name="reviewOn"
                      id="review-on"
                      value={value}
                      onChange={({ target }) => onChange((target as any).value)}
                      onBlur={onBlur}
                    />
                  </FormField>
                )}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Text weight="bold" as="label" margin={{ vertical: 'xsmall', horizontal: 'small' }}>Drug Utilization Periods</Text>
          <Controller
            control={control}
            name="periods"
            rules={{
              validate: SelectValidators.isNonEmpty,
            }}
            render={({ field: { value } }) => (
              <BorderlessFormField
                name="periods"
                error={errors.periods?.message}
              >
                <Grid columns="1/4" pad={{ vertical: 'small' }} gap={{ row: 'small' }}>
                  {drugUtilizationPeriods.sort(sortDrugUtilizationPeriods).map(({ id, label }, index) => (
                    <CheckBox
                      key={index}
                      label={label}
                      value={id}
                      checked={value.some((period: any) => period.id === id)}
                      onChange={(event) => handleUtilizationPeriodChange(id, event.target.checked)}
                    />
                  ))}
                </Grid>
              </BorderlessFormField>
            )}
          />
        </Box>
        <Box flex direction="row" margin={{ top: 'large' }} justify="end">
          <ArtiaButton label="Next" size="large" disabled={!isValid} onClick={handleNext} />
        </Box>
      </CardBody>
    </Card>
  );
};

type BidAnalysisDetailsStepProps = {
  stepName: string;
} & WizardControlState;
