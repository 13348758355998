import { getPostLoginRedirectUrl } from '@shared/auth';
import React from 'react';
import { useAuth } from 'react-oidc-context';

import StatusCodePage from '@/features/status-codes/status-code.page';
import { Navigate } from '@/router';

const Callback = () => {
  const { error, isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={getPostLoginRedirectUrl() as any} replace={true} />;
  }

  if (error) {
    return (
      <StatusCodePage
        statusCode="500"
        statusCodeDescription={`${error.message}. Please try logging in again. If you receive this error message multiple times, please contact an administrator.`}
      />
    );
  }

  return null;
};

export default Callback;
