import { useHttpClient } from '@shared/http';
import { PaginationRequest, PaginationResult } from '@shared/pagination';
import { DateTime } from 'luxon';

import { MultiSelectOption } from '@/components/form-controls/multi-select-list';
import { BoardMeeting, RawBoardMeeting } from '@/types/board-meetings';
import { buildQueryString } from '@/utils/filtering-functions';

import { BoardMeetingFormData } from '../components/types';

export type BoardMeetingFilterData = {
  statesOrPools: MultiSelectOption<string>[];
  drugs: MultiSelectOption[];
  classes: MultiSelectOption[];
  meetingTypes: MultiSelectOption[];
  dateRangeBegin: string;
  dateRangeEnd: string;
};

export const useBoardMeetingService = () => {
  const httpClient = useHttpClient();

  const getBoardMeetings = async ({ statesOrPools, drugs, classes, meetingTypes, ...rest }: BoardMeetingFilterData & PaginationRequest): Promise<PaginationResult<BoardMeeting>> => {
    const filterData = {
      statesOrPools: statesOrPools.map(it => it.id),
      meetingTypes: meetingTypes.map(it => it.id),
      drugs: drugs.map(it => it.id),
      classes: classes.map(it => it.id),
      ...rest
    };
    const rawBoardMeetings = await httpClient.get<PaginationResult<RawBoardMeeting>, unknown>(
      `board-meetings${buildQueryString(filterData)}`
    );
    return {
      ...rawBoardMeetings,
      items: rawBoardMeetings.items.map<BoardMeeting>(it => ({
        ...it,
        startTime: DateTime.fromISO(it.startTime),
        endTime: DateTime.fromISO(it.endTime)
      }))
    };
  };

  const deleteBoardMeeting = (id: number): Promise<void> => {
    return httpClient.delete(`board-meetings/${id}`);
  };

  const createBoardMeeting = (formData: BoardMeetingFormData): Promise<void> => {
    return httpClient.post('board-meetings', { data: formToRequestData(formData) });
  };

  const updateBoardMeeting = (id: number, formData: BoardMeetingFormData): Promise<void> => {
    return httpClient.put(`board-meetings/${id}`, { data: formToRequestData(formData) });
  };

  return {
    getBoardMeetings,
    deleteBoardMeeting,
    createBoardMeeting,
    updateBoardMeeting
  };
};

const formToRequestData = ({ startDate, startTime, endTime, discussedClassifications, discussedDrugs, ...formData }: BoardMeetingFormData) => {
  // TODO: gross format shit. should just expect the ISO Date format. this is a downstream effect of
  //       how the DateTextInput is written
  const parsedStartDate = getSlashFormatDate(startDate) ?? getIsoFormatDate(startDate);
  if (parsedStartDate === null) {
    throw new Error(`Cannot parse date ${startDate}`);
  }

  // expected time format: HH:mm
  const [startTimeHour, startTimeMinute] = startTime.split(':').map(x => parseInt(x));
  const startDateTime = DateTime.fromObject({
    year: parsedStartDate.year,
    month: parsedStartDate.month,
    day: parsedStartDate.day,
    hour: startTimeHour,
    minute: startTimeMinute,
    second: 0,
    millisecond: 0
  }, {
    // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    zone: formData.ianaTimeZone
  });

  // assume the end date is the same as the start date, just with a different time
  const [endTimeHour, endTimeMinute] = endTime.split(':').map(x => parseInt(x));
  const endDateTime = startDateTime.set({ hour: endTimeHour, minute: endTimeMinute });

  if (!startDateTime.isValid || !endDateTime.isValid) {
    throw new Error(`${startDate} is not a valid date`);
  }

  return {
    ...formData,
    // timestamps need to be in UTC when sent to the server
    startTime: startDateTime.toUTC().toJSON(),
    endTime: endDateTime.toUTC().toJSON(),
    discussedClassifications: discussedClassifications.map(it => it.id),
    discussedDrugs: discussedDrugs.map(it => it.id),
  };
};

const getIsoFormatDate = (date: string): { year: number, month: number, day: number } | null => {
  const result = date.split('-').map(x => parseInt(x));

  if (result.length !== 3 || result.some(it => it === null || it === undefined || isNaN(it))) return null;

  const [year, month, day] = result;

  return { year, month, day };
};

const getSlashFormatDate = (date: string): { year: number, month: number, day: number } | null => {
  const result = date.split('/').map(x => parseInt(x));

  if (result.length !== 3 || result.some(it => it === null || it === undefined || isNaN(it))) return null;

  const [month, day, year] = result;

  return { year, month, day };
};
