import React from 'react';

import { Chip } from '@/components/chip';
import { CoverageTag } from '@/features/drugs/types/coverage-tag';

// TODO: make common

type Props = {
  coverageTag: CoverageTag;
  dismissible?: boolean;
  onDismiss?: (tag: CoverageTag) => void;
};

const CoverageTagChip = ({ coverageTag, dismissible = false, onDismiss }: Props) => {
  return (
    <Chip
      chip={{
        label: coverageTag.name,
        value: coverageTag.id,
        dismissible: dismissible
      }}
      background={coverageTag.isDefault ? 'warning' : 'accent-2'}
      onDismiss={onDismiss ? () => onDismiss(coverageTag) : undefined}
    />
  );
};

export default CoverageTagChip;
