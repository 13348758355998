import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@/components-new/button';
import { Input } from '@/components-new/input';
import { updateUserSettings } from '@/features/user-settings/api/update-user-settings';
import { UserSettings } from '@/features/user-settings/types/user-settings';
import { error, success } from '@/lib/notification/notifications';
import { queryClient } from '@/lib/react-query';

type Props = {
  fullName: string;
}

type UserSettingsNameForm = {
  name: string
}

export const UserSettingsNameField = (props: Props) => {
  const [ isEditing, setIsEditing ] = useState(false);
  const [ fullName, setFullName ] = useState(props.fullName);

  const {
    handleSubmit,
    control
  } = useForm<UserSettingsNameForm>();

  const nameMutation = useMutation({
    mutationFn: (data: UserSettings) => updateUserSettings(data),
    onSuccess: (data: void, variables: UserSettings) => {
      success({ title: 'Updated', message: 'Name has been updated.' });
      setIsEditing(false);
      setFullName(variables.name);
    },
    onError: () => {
      error({ title: 'Uh Oh', message: 'Name failed to update.' });
    },
    onSettled: async () => {
      return await queryClient.invalidateQueries({ queryKey: ['userSettings'] });
    },
  });

  const onSubmit: SubmitHandler<UserSettingsNameForm> = data => {
    nameMutation.mutate({ name: data.name } as UserSettings);
  };

  return(
    <>
      {isEditing ?
        <>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            defaultValue={fullName}
            render={({ field }) => <Input {...field} /> }
          />
          <Button color="secondary" className="text-xs" disabled={nameMutation.isPending} onClick={handleSubmit(onSubmit)}>Save</Button>
        </>
        :
        <>
          <div>{fullName}</div>
          <Button color="secondary" className="text-xs" onClick={() => setIsEditing(true)}>Update</Button>
        </>
      }
      </>
  );
};
