import React from 'react';

import { Button } from '@/components-new/button';
import { ButtonBar } from '@/components-new/button-bar';
import { Checkbox, CheckboxField, CheckboxGroup } from '@/components-new/checkbox';
import { Label } from '@/components-new/fieldset';
import { SectionDescription, SectionHeading } from '@/components-new/section';
import NotificationSetting from '@/features/user-settings/components/notification-setting';
import { UserSetting, UserSettingsList } from '@/features/user-settings/components/user-setting';

const UserNotificationSettingsPage = () => {

  return(
    <div className="mt-6 lg:mx-0 lg:max-w-3xl">
      <SectionHeading level={2}>Notification Preferences</SectionHeading>
      <SectionDescription>
        Manage how you receive notifications.
      </SectionDescription>
      <UserSettingsList>
        <UserSetting title="General">
          <CheckboxGroup>
            <CheckboxField>
              <Checkbox disabled defaultChecked />
              <Label>Receive in Capsule</Label>
            </CheckboxField>
            <CheckboxField>
              <Checkbox />
              <Label>Receive emails</Label>
            </CheckboxField>
          </CheckboxGroup>
        </UserSetting>
        <UserSetting title="Fee-for-Service (FFS)">
          <NotificationSetting />
        </UserSetting>
        <UserSetting title="Medicaid Managed Care (MMC)">
          <NotificationSetting />
        </UserSetting>
        <UserSetting title="Medicaid in the News">
          <NotificationSetting />
        </UserSetting>
        <UserSetting title="Advantage +" description="You are not subscribed to this service." />

      </UserSettingsList>
      <ButtonBar>
        <Button color="secondary">Save</Button>
      </ButtonBar>
    </div>
  );

};
export default UserNotificationSettingsPage;
