import { Box, Text } from 'grommet';
import React from 'react';

import { ArtiaButton } from '@/components/artia-button';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';

type InvalidNdcSelectionModalProps = {
  open: boolean;
  onOk: () => void;
};

export const InvalidNdcSelectionDialog = ({ onOk, open }: InvalidNdcSelectionModalProps) => {
  return (
    <Dialog open={open}>
      <DialogHeader title="Unable to Associate" onClose={onOk} />
      <DialogBody>
        <Box fill="horizontal" gap="medium">
          <Text>One or more of the selections you chose are already associated to a product. Please double-check your selections and try again.</Text>
        </Box>
      </DialogBody>
      <DialogActions>
        <ArtiaButton label="Ok" onClick={onOk} size="large"/>
      </DialogActions>
    </Dialog>
  );
};
