import { Box, Card, CardBody } from 'grommet';
import React from 'react';

export const FormCard = ({ children }: FormCardProps) => {
  return (
    <Box direction="row" justify="center">
      <Card justify="center" width="902px" style={{ background: '#ffffff' }}>
        <CardBody pad="medium">
          {children}
        </CardBody>
      </Card>
    </Box>
  );
};

type FormCardProps = {
  children: React.ReactNode;
};
