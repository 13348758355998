import { WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';

import { env } from '@/config/env';

export const oidcConfig: AuthProviderProps = {
  authority: env.OIDC_PROVIDER_URL,
  client_id: env.OIDC_CLIENT_ID,
  redirect_uri: window.location.origin + '/callback',
  extraQueryParams: {
    audience: 'artia-dashboard'
  },
  post_logout_redirect_uri: window.location.origin,
  scope:'openid profile email',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};
