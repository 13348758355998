import React from 'react';

import { bugsnagClient } from '@/lib/bugsnag/bugsnag-client';
import { formatStackTrace } from '@/utils/formatting/stack-trace';

export const notifyReactError = (error: Error, info: React.ErrorInfo) => {
  const handledState = {
    severity: 'error', unhandled: true, severityReason: { type: 'unhandledException' }
  };
  const event = bugsnagClient.Event?.create(error, true, handledState, 'Error boundary', 1);
  if (info && info.componentStack) {
    info.componentStack = formatStackTrace(info.componentStack);
  }
  event?.addMetadata('react', info);
  bugsnagClient._notify(event);
};
