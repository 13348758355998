import { useErrorHandler } from '@shared/errors';
import { useModal } from '@shared/modal';
import { useNotifications } from '@shared/notifications';
import { Box, Button, FileInput, Form, FormField, Spinner, Text } from 'grommet';
import React, { useState } from 'react';

import { useMarketBasketService } from '@/features/market-baskets';

export const ImportMarketDetailsPanel = ({ marketBasketId, getDrugMarketDetails }: { marketBasketId: number, getDrugMarketDetails: () => void }) => {
  const { handleError } = useErrorHandler();
  const { uploadMarketDetailsFile } = useMarketBasketService();
  const { closeModal } = useModal();
  const { success } = useNotifications();

  const [importing, setImporting] = useState(false);

  const validateFile = (file: File[]) => {
    if (!file || file.length === 0) {
      return 'File is required';
    }

    if (file[0].type !== 'text/csv') {
      return 'File must be a CSV';
    }
  };

  const handleSubmit = async ({ value }: any) => {
    setImporting(true);

    const file = value['market-details-file'][0];

    try {
      await uploadMarketDetailsFile(file, marketBasketId);

      closeModal();
      getDrugMarketDetails();
      success({ title: 'Import Succeeded', message: 'Successfully imported Market Details.', autoClose: true });
    } catch (ex) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      handleError(ex, {
        title: 'Import Failed',
        message: 'We encountered an unexpected error while importing Market Details. Please try again or contact an administrator.',
        autoClose: false
      });
    } finally {
      setImporting(false);
    }
  };

  return (
    <Box>
      <Text margin={{ bottom: 'small' }}>Upload a CSV file containing Market Details.</Text>
      <Form validate="blur" onSubmit={handleSubmit}>
        <FormField name="market-details-file" htmlFor="market-details-file-input" validate={validateFile}>
          <FileInput multiple={false} name="market-details-file" id="market-details-file-input" accept=".csv"/>
        </FormField>
        <Box flex="shrink" align="center" justify="center" basis="small" margin="medium">
          <Button type="submit" primary fill disabled={importing}>
            {() =>
              <Box align="center" justify="center">
                {importing ? (
                  <Spinner color="white" size="small"/>
                ) : (
                  <Text>Import</Text>
                )}
              </Box>
            }
          </Button>
        </Box>
      </Form>
    </Box>
  );
};
