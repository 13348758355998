import React, { createContext, useContext, useState } from 'react';

import { useLookupsService } from '@/hooks/use-lookups-service';
import { Lookup } from '@/types/lookup';

type CreateMarketBasketContextValue = {
  clientLookups: Lookup[];
  searchClients: (query?: string) => void;
};

const DEFAULT_CONTEXT_VALUE: CreateMarketBasketContextValue = {
  clientLookups: [],
  searchClients: () => null
};

const CreateMarketBasketContext = createContext<CreateMarketBasketContextValue>(DEFAULT_CONTEXT_VALUE);

export const useCreateMarketBasket = () => useContext<CreateMarketBasketContextValue>(CreateMarketBasketContext);

export const CreateMarketBasketProvider = ({ children }: CreateMarketBasketProps) => {
  const { getClientLookups } = useLookupsService();

  const [clientLookups, setClientLookups] = useState<Lookup[]>([]);

  const searchClients = async (query?: string) => {
    const items = await getClientLookups({ query });
    setClientLookups(items as Lookup[]);
  };

  const contextValue = {
    clientLookups,
    searchClients
  };

  return (
    <CreateMarketBasketContext.Provider value={contextValue}>
      {children}
    </CreateMarketBasketContext.Provider>
  );
};

type CreateMarketBasketProps = {
  children?: React.ReactNode
};
