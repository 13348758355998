import { NotificationOptions } from '@shared/notifications/notification-options';
import React from 'react';

export type ErrorSeverity = 'error' | 'warn';

export type ErrorHandlerContextProps = {
  handleError: (error: Error, options: NotificationOptions, severity?: ErrorSeverity) => void;
};

export const DEFAULT_PROPS: ErrorHandlerContextProps = {
  handleError: () => null,
};

export const ErrorHandlerContext = React.createContext<ErrorHandlerContextProps>(DEFAULT_PROPS);
