import { ColumnConfig, DataTable } from 'grommet';
import React from 'react';

import { Actor } from '@/types/actor';
import { formatTimestamp } from '@/utils/date-time-functions';

export type ChangeLog = {
  id: number;
  description: string;
  occurredAt: string;
  initiatedBy: Actor;
};

type ChangeLogTableProps = {
  changeLogs: ChangeLog[]
}

export const ChangeLogTable = ({ changeLogs }: ChangeLogTableProps) => {
  const columns: ColumnConfig<ChangeLog>[] = [
    { property: 'description', header: 'Description' },
    { property: 'initiatedBy', header: 'Initiated By', render: (item) => item.initiatedBy.name },
    { property: 'occurredAt', header: 'Occurred At', render: (item) => formatTimestamp(item.occurredAt) },
  ];

  return (
    <DataTable
      columns={columns}
      paginate
      step={25}
      data={changeLogs}
    />
  );
};
