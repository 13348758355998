import { ProtectedRoute } from '@shared/auth';
import { Box } from 'grommet';
import React from 'react';

import { useFilters } from '@/components/filters';
import { PackagingFilterData, PackagingFilters } from '@/features/drugs/components/packaging-filters';
import { PackagingsTable } from '@/features/drugs/components/packagings-table';
import { useParams } from '@/router';

const DrugPackagingsIndexPage = () => {
  const { id } = useParams('/drugs/:id');
  const [filters, setFilters] = useFilters<PackagingFilterData>(`drugs-${id}-packagings`, {});

  return (
    <Box direction="column" gap="small">
      <PackagingFilters defaultValue={filters} onApplyFilters={setFilters} />
      <PackagingsTable filters={filters} />
    </Box>
  );
};

const DrugPackagingsIndexPageContainer = () => {
  return (
    <ProtectedRoute policies={['isAnyArtiaUser']}>
      <DrugPackagingsIndexPage />
    </ProtectedRoute>
  );
};

export default DrugPackagingsIndexPageContainer;
