import { httpClient } from '@shared/http/http-client';
import React from 'react';
import { useAuth } from 'react-oidc-context';

import { HttpClientContext } from './http-client.context';

export const HttpClientProvider = ({ children }: HttpClientProviderProps ) => {
  const baseUrl = import.meta.env.VITE_BASE_API_URL ?? '';
  const { user } = useAuth();
  const instance = httpClient.create({ baseUrl });

  instance.addRequestInterceptor((config) => {
    const bearerToken = `Bearer ${user?.access_token}`;

    if (!config) {
      return {
        headers: {
          Authorization: bearerToken
        }
      };
    }

    if (!config.headers) {
      config.headers = {
        Authorization: bearerToken
      };

      return config;
    }

    config.headers.Authorization = bearerToken;
    return config;
  });

  return (
    <HttpClientContext.Provider value={instance}>
      {children}
    </HttpClientContext.Provider>
  );
};

type HttpClientProviderProps = {
  children?: React.ReactNode;
};
