import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import { Text } from 'grommet';
import React, { useEffect, useId, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ArtiaButton } from '@/components/artia-button';
import { Busy } from '@/components/busy';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { ChangeLogReason } from '@/features/changelog/types';
import { EditCoverageRequest, useCoverageService } from '@/features/coverage/api/use-coverage-service';
import { ClinicalsProductEditForm } from '@/features/coverage/components/clinicals-product-edit-form';
import { formatNumber } from '@/utils/formatting';

import { FilterInputs } from './index/coverage-filters';

type BulkPdlEditDialogProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  filters: FilterInputs;
  idsToUpdate: number[];
};

export const BulkPdlEditDialog = ({ open, onClose, onSuccess, filters, idsToUpdate }: BulkPdlEditDialogProps) => {
  const [visibleFields, setVisibleFields] = useState<(keyof EditCoverageRequest)[]>([]);
  const [dataToConfirm, setDataToConfirm] = useState<EditCoverageRequest | null>(null);


  const { bulkEditCoverage } = useCoverageService();
  const request = useAsync(bulkEditCoverage);
  const { handleError } = useErrorHandler();
  const { success } = useNotifications();

  useEffect(() => {
    const { status, error } = request;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(
        error,
        {
          title: 'Failed to bulk update coverage records',
          autoClose: false
        }
      );

      return;
    }

    success({ title: `Updated ${idsToUpdate.length} Coverage Records`, autoClose: true });
    clearFields();
    onSuccess();
    // TODO: review this effect, it's missing a lot of dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.status]);

  const saving = request.status === UseAsyncStatus.Pending;
  const submitDisabled = saving || visibleFields.length === 0;

  const formId = useId();
  const form = useForm<EditCoverageRequest>({
    defaultValues: {
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'PdlStatus |... Remove this comment to see the full error message
      pdlStatus: null,
      pdlStatusDate: '',
      pdlStatusEffectiveDate: '',
      paTypes: [],
      stepTherapyCount: '',
      paCriteriaDescription: '',
      paCriteriaSourceUrl: '',
      changeReason: ChangeLogReason.PDL_UPDATE
    }
  });

  const { handleSubmit, reset } = form;

  const clearFields = () => {
    setVisibleFields([]);
    setDataToConfirm(null);
    reset();
  };

  const handleClose = () => {
    clearFields();
    onClose();
  };

  const onFieldShow = (field: keyof EditCoverageRequest) => {
    setVisibleFields(x => [...x, field]);
  };

  const onFieldHide = (field: keyof EditCoverageRequest) => {
    setVisibleFields(x => x.filter(it => it !== field));
  };

  const canUseBulkPdlEdit = filters.states && filters.states.length > 0;

  return (
    <FormProvider {...form}>
      <Dialog open={open} width="large">
        <DialogHeader
          title="Bulk PDL Edit"
          subtitle={canUseBulkPdlEdit ? `Updating ${formatNumber(idsToUpdate.length, 0, 0)} Coverage Records` : null}
          onClose={handleClose}
        />
        <DialogBody>
          {canUseBulkPdlEdit ? (
            <ClinicalsProductEditForm
              formId={formId}
              mode="bulk-edit"
              onSubmit={handleSubmit((data) => setDataToConfirm(data))}
              visibleFields={visibleFields}
              onFieldShow={onFieldShow}
              onFieldHide={onFieldHide}
            />
          ) : (
            <Text>You must select at least one State or Pool to use this functionality.</Text>
          )}
        </DialogBody>
        {canUseBulkPdlEdit ? (
          <DialogActions>
            {dataToConfirm !== null ? (
              <Text weight="bold" color="error">Are you sure you want to update {formatNumber(idsToUpdate.length, 0, 0)} Coverage records?</Text>
            ) : null}
            <ArtiaButton
              label="Cancel"
              type="button"
              size="large"
              variant="outlined"
              onClick={() => {
                if (dataToConfirm !== null) {
                  setDataToConfirm(null);
                } else {
                  handleClose();
                }
              }}
            />
            {dataToConfirm !== null ? (
              <ArtiaButton
                label={<Busy busy={saving} content="Confirm" />}
                type="button"
                size="large"
                disabled={saving}
                onClick={() => {
                  void request.execute(idsToUpdate, dataToConfirm, visibleFields);
                }}
              />
            ) : (
              <ArtiaButton
                label="Save"
                type="submit"
                form={formId}
                size="large"
                disabled={submitDisabled}
              />
            )}
          </DialogActions>
        ) : (
          <DialogActions>
            <ArtiaButton label="Close" onClick={handleClose} size="large" variant="outlined" />
          </DialogActions>
        )}
      </Dialog>
    </FormProvider>
  );
};
