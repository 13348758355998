import React from 'react';

import { Seo } from '@/components-new/seo';

import { DEFAULT_HEADER_DETAILS, HeaderDetailsContext } from './header-details.context';

/**
 * @deprecated in favor of Helmet and <Seo />, remove once all pages are are setting their own SEO details.
 */
export const HeaderDetailsProvider = ({ children, siteTitle = 'Artia Capsule' }: HeaderDetailsProviderProps) => {
  const [ headerDetails, setHeaderDetails ] = React.useState(DEFAULT_HEADER_DETAILS);

  const clearHeaderDetails = () => {
    setHeaderDetails(DEFAULT_HEADER_DETAILS);
  };

  return (
    <HeaderDetailsContext.Provider
      value={{
        siteTitle,
        headerDetails,
        setHeaderDetails,
        clearHeaderDetails,
      }}
    >
      <Seo title={headerDetails.documentTitle} />
      {children}
    </HeaderDetailsContext.Provider>
  );
};

type HeaderDetailsProviderProps = {
  children?: React.ReactNode,
  siteTitle?: string,
};
