import { useAsync, UseAsyncStatus } from '@shared/async';
import { PaginationRequest, SortingInfo } from '@shared/pagination';
import { Edit } from 'grommet-icons';
import React, { useState } from 'react';

import { ArtiaButton } from '@/components/artia-button';
import { useCoverageService } from '@/features/coverage/api/use-coverage-service';

import { BulkPdlEditDialog } from './bulk-pdl-edit-dialog';
import { FilterInputs } from './index/coverage-filters';

export type BulkEditPdlButtonProps = {
  filters: FilterInputs & PaginationRequest & SortingInfo;
  onSuccess: () => void;
};

export const BulkEditPdlButton = ({ filters, onSuccess }: BulkEditPdlButtonProps) => {
  const [showBulkPdlEditDialog, setShowBulkPdlEditDialog] = useState(false);

  const { searchForAllMatchingIds } = useCoverageService();
  const request = useAsync(searchForAllMatchingIds);
  const loading = request.status === UseAsyncStatus.Pending;

  return (
    <>
      <ArtiaButton
        icon={<Edit />}
        disabled={loading}
        a11yTitle="Bulk Edit Coverage Data"
        tip="Bulk Edit Coverage Data"
        onClick={async () => {
          const canUseBulkPdlEdit = filters.states && filters.states.length > 0;

          if (canUseBulkPdlEdit) {
            await request.execute(filters);
          }

          setShowBulkPdlEditDialog(true);
        }}
      />
      <BulkPdlEditDialog
        open={showBulkPdlEditDialog}
        onClose={() => setShowBulkPdlEditDialog(false)}
        onSuccess={() => {
          setShowBulkPdlEditDialog(false);
          onSuccess();
        }}
        filters={filters}
        idsToUpdate={request.value ?? []}
      />
    </>
  );
};
