export const isGreaterThan = (min: number) => (value: string) => {
  const number = parseFloat(value);
  if (isNaN(number)) {
    return;
  }

  if (number <= min) {
    return `Value must be greater than ${min}.`;
  }
};
