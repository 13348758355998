import { useSuspenseQuery } from '@tanstack/react-query';

import { artiaProtectedApi } from '@/lib/api-client';
import { State } from '@/types/state';

const getStates = async (): Promise<State[]> => {
  return await artiaProtectedApi.get('states');
};

export const useGetStates = () => {
  const { data, error, isFetching, dataUpdatedAt } = useSuspenseQuery({
    queryKey: ['states'],
    queryFn: async () => getStates()
  });

  if (error && !isFetching) throw error;

  return { data, dataUpdatedAt, isFetching };
};
