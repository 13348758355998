import React, { JSX } from 'react';
import { Toast, toast, ToastOptions } from 'react-hot-toast/headless';
import { LiaCheckCircle, LiaExclamationTriangleSolid, LiaInfoCircleSolid, LiaTimesCircle } from 'react-icons/lia';

import { Loader } from '@/components-new/loader';
import {
  Notification, NotificationActions,
  NotificationContent,
  NotificationHeading,
  NotificationMessage
} from '@/components-new/notification';

type NotificationOptions = Omit<ToastOptions, 'position' | 'icon' | 'iconTheme' | 'style' | 'className'>;

type NotificationConfig = {
  title?: React.ReactNode;
  message?: React.ReactNode;
  actions?: React.ReactNode;
  options?: NotificationOptions
}

const createNotification = (type: React.ComponentProps<typeof Notification>['type'], icon: React.ReactNode) => {
  return ({ title, message, actions }: Omit<NotificationConfig, 'options'>): (t: Toast) => JSX.Element => {
    return ({ id, ariaProps }) => (
      <Notification
        type={type}
        onClose={() => toast.dismiss(id)}
        ariaProps={ariaProps}
      >
        {icon}
        <NotificationContent>
          <NotificationHeading>{title}</NotificationHeading>
          <NotificationMessage>{message}</NotificationMessage>
          {actions && <NotificationActions>{actions}</NotificationActions>}
        </NotificationContent>
      </Notification>
    );
  };
};

export const success = ({ options, title = 'Success', ...rest }: NotificationConfig ): string => {
  return toast.success(
    createNotification('success', <LiaCheckCircle />)({ title, ...rest }),
    { ...options }
  );
};

export const error = ({ options, title = 'Error', ...rest }: NotificationConfig ): string => {
  return toast.error(
    createNotification('error', <LiaTimesCircle />)({ title, ...rest }),
    { ...options }
  );
};

export const warn = ({ options, title = 'Warning', ...rest }: NotificationConfig ): string => {
  return toast.custom(
    createNotification('warning', <LiaExclamationTriangleSolid />)({ title, ...rest }),
    { ...options }
  );
};

export const info = ({ options, title = 'Info', ...rest }: NotificationConfig ): string => {
  return toast.custom(
    createNotification('info', <LiaInfoCircleSolid />)({ title, ...rest }),
    { ...options }
  );
};

export const loading = ({
  options, title = 'Loading', ...rest
}: NotificationConfig): string => {
  return toast.loading(
    createNotification('loading', <Loader message={null} />)({ title, ...rest }),
    { ...options }
  );
};

export const notify = (render: (t: Toast) => JSX.Element, options?: NotificationOptions): string => {
  return toast.custom(render, { ...options });
};

/**
 * Dismiss a notification by id, if no id is passed, all notifications will be dismissed.
 */
export const dismiss = (id?: string) => {
  toast.dismiss(id);
};

/**
 * Instantly remove a notification by id, if no id is passed, all notifications will be removed.
 */
export const remove = (id?: string) => {
  toast.remove(id);
};
