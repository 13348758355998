import { z } from 'zod';

export const createUpdateClientUserFormSchema = z.object({
  name: z.string()
    .trim()
    .min(1, 'Name is required.')
    .max(50, { message: 'Name must be less than 50 characters.' }),
  email: z.string()
    .trim()
    .min(1, 'Email is required.')
    .email('Must be a valid email address.')
    .min(5, 'Must be a valid email address.')
    .max(255, 'Email must be less than 255 characters.'),
  enabledFeatures: z.array(z.string())
}).required();

export type CreateUpdateClientUserFormSchema = z.infer<typeof createUpdateClientUserFormSchema>;
