import { Box, FormField } from 'grommet';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { CurrencyTextInput } from '@/components/form-controls';
import { OverridableCompendiumValue } from '@/types/overridable-compendium-value';
import { formatCurrency } from '@/utils/formatting';

type WacUnitPriceCellProps = {
  ndc: string,
  wacUnit?: OverridableCompendiumValue,
  isOverriding: boolean,
  control: Control<any>
};

export const WacUnitPriceCell = (props: WacUnitPriceCellProps) => {
  const {
    ndc,
    wacUnit,
    isOverriding,
    control
  } = props;

  const formKey = `${ndc}.wacUnit`;

  return (
    <Box width={{ min: '8rem' }}>
      {isOverriding &&
        <Controller
          control={control}
          name={formKey}
          render={({ field: { value, onChange, onBlur } }) => (
            <FormField
              required
              name={formKey}
              htmlFor={formKey}
              margin="none"
            >
              <CurrencyTextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                id={formKey}
                name={formKey}
              />
            </FormField>
          )}
        />
      }

      {!isOverriding && wacUnit?.overrideValue != null &&
        <span>{formatCurrency(wacUnit.overrideValue)}</span>
      }

      {!isOverriding && wacUnit?.overrideValue == null && wacUnit?.compendiumValue != null &&
        <span>{formatCurrency(wacUnit.compendiumValue)}</span>
      }

      {!isOverriding && wacUnit?.overrideValue == null && wacUnit?.compendiumValue == null &&
        <span><>&mdash;</></span>
      }
    </Box>
  );
};
