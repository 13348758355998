import { Checkbox, CheckboxField, CheckboxGroup } from '@/components-new/checkbox';
import { Label } from '@/components-new/fieldset';
import { Radio, RadioField, RadioGroup } from '@/components-new/radio';
import React from 'react';

const NotificationSetting = () => {
  return(
    <div className="flex-col space-y-6">
      <CheckboxGroup>
        <CheckboxField>
          <Checkbox disabled defaultChecked />
          <Label>Receive in Capsule</Label>
        </CheckboxField>
        <CheckboxField>
          <Checkbox />
          <Label>Receive emails</Label>
        </CheckboxField>
      </CheckboxGroup>
      <RadioGroup name="notifGroup" className="flex-1" defaultValue="Priority">
        <RadioField>
          <Radio value="All" />
          <Label>For all states</Label>
        </RadioField>
        <RadioField>
          <Radio value="Priority" />
          <Label>For priority states</Label>
        </RadioField>
      </RadioGroup>
    </div>
  );
};

export default NotificationSetting;
