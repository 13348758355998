import { Box, Button } from 'grommet';
import { Add } from 'grommet-icons';
import { toString } from 'lodash';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { TableLoadingOverlay } from '@/components/loading';
import { useClient } from '@/features/clients/api/use-client';
import { useClientUsers } from '@/features/clients/api/use-client-users';
import { ClientUsersTable } from '@/features/clients/components/client-users-table';
import { Link, useParams } from '@/router';

type Props = {
  clientId: number;
}

const ClientUsersScreen = ({ clientId }: Props) => {
  const { data: client } = useClient(clientId);
  const { data: users } = useClientUsers(clientId);

  return (
    <Box margin={{ top: 'small' }}>
      <Box direction="row" justify="end">
        <Link to="/clients/:id/users/new" params={{ id: toString(clientId) }}>
          <Button
            size="small"
            primary
            icon={<Add />}
            label="Add New"
          />
        </Link>
      </Box>
      <ClientUsersTable users={users} client={client}/>
    </Box>
  );
};

const ClientUsersPage = () => {
  const { id } = useParams('/clients/:id/users');

  return (
    <>
      <Suspense fallback={<TableLoadingOverlay />}>
        <ClientUsersScreen clientId={parseInt(id)} />
      </Suspense>
      <Outlet />
    </>
  );
};

export default ClientUsersPage;
