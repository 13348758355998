import { Box, Skeleton } from 'grommet';

import TextSkeleton from '@/components/loading/text-skeleton';

const CheckboxSkeleton = () => {
  return (
    <Box direction="row" gap="small" align="center">
      <Skeleton width="1.5rem" height="1.5rem" round="xsmall" />
      <TextSkeleton width="small"/>
    </Box>
  );
};

export default CheckboxSkeleton;
