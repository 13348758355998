import { Box, FormField, Text } from 'grommet';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { ArtiaButtonLink } from '@/components/artia-button';
import { CurrencyTextInput } from '@/components/form-controls';
import { CalculationDetails } from '@/types/calculation-details';
import { OverridableCalculatedValue } from '@/types/overridable-calculated-value';
import { formatCurrency } from '@/utils/formatting';

type EstimatedTotalUraCellProps = {
  ndc: string
  ura?: OverridableCalculatedValue
  onViewCalculationDetails: (calculationDetails: CalculationDetails) => void;
  isOverriding: boolean,
  control: Control<any>,
};

export const EstimatedTotalUraCell = (props: EstimatedTotalUraCellProps) => {
  const {
    ndc,
    ura,
    isOverriding,
    control,
    onViewCalculationDetails
  } = props;

  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const handleValueClick = () => onViewCalculationDetails(ura.calculatedValue);

  const formKey = `${ndc}.ura`;

  return (
    <Box width={{ min: '8rem' }}>
      {isOverriding &&
        <Controller
          control={control}
          name={formKey}
          render={({ field: { value, onChange, onBlur } }) => (
            <FormField
              required
              name={formKey}
              htmlFor={formKey}
              margin="none"
            >
              <CurrencyTextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                id={formKey}
                name={formKey}
              />
            </FormField>
          )}
        />
      }

      {!isOverriding && ura?.overrideValue != null &&
        <Text>
          {formatCurrency(ura.overrideValue, 4, 4)}
        </Text>
      }

      {!isOverriding && ura?.overrideValue == null && ura?.calculatedValue?.value != null &&
        <ArtiaButtonLink onClick={handleValueClick}>
          <Text>
            {formatCurrency(ura.calculatedValue.value, 4, 4)}
          </Text>
        </ArtiaButtonLink>
      }
    </Box>
  );
};
