import { Box } from 'grommet';
import React from 'react';

import { OverridableCompendiumValue } from '@/types/overridable-compendium-value';
import { formatCurrency } from '@/utils/formatting';

type WacUnitPriceCellProps = {
  wacUnit?: OverridableCompendiumValue,
};

export const WacUnitPriceCell = (props: WacUnitPriceCellProps) => {
  const { wacUnit } = props;

  return (
    <Box width={{ min: '8rem' }}>
      {wacUnit?.overrideValue != null &&
        <span>{formatCurrency(wacUnit.overrideValue)}</span>
      }

      {wacUnit?.overrideValue == null && wacUnit?.compendiumValue != null &&
        <span>{formatCurrency(wacUnit.compendiumValue)}</span>
      }

      {wacUnit?.overrideValue == null && wacUnit?.compendiumValue == null &&
        <span><>&mdash;</></span>
      }
    </Box>
  );
};
