import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

export type EditBidAnalysisFormValue = {
  name: string;
  bidDueOn: string;
  reviewOn: string;
  contractTermStartsOn: string;
  contractTermEndsOn: string;
};

type EditBidAnalysisContextValue = {
  form: EditBidAnalysisFormValue;
  updateForm: Dispatch<SetStateAction<EditBidAnalysisFormValue>>;
  resetForm: () => void;
};

const DEFAULT_CONTEXT_VALUE: EditBidAnalysisContextValue = {
  form: {
    name: '',
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
    bidDueOn: null,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
    reviewOn: null,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
    contractTermStartsOn: null,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
    contractTermEndsOn: null
  },
  updateForm: () => null,
  resetForm: () => null
};

const EditBidAnalysisContext = createContext(DEFAULT_CONTEXT_VALUE);

export const useEditBidAnalysis = () => useContext(EditBidAnalysisContext);

type EditBidAnalysisProps = {
  children?: React.ReactNode
};

export const EditBidAnalysisProvider = ({ children }: EditBidAnalysisProps) => {
  const [form, setForm] = useState(DEFAULT_CONTEXT_VALUE.form);

  const resetForm = () => {
    setForm(DEFAULT_CONTEXT_VALUE.form);
  };

  const contextValue: EditBidAnalysisContextValue = {
    form,
    updateForm: setForm,
    resetForm
  };

  return (
    <EditBidAnalysisContext.Provider value={contextValue}>
      {children}
    </EditBidAnalysisContext.Provider>
  );
};
