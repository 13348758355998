import { Text } from 'grommet';
import React from 'react';

import { Lookup } from '@/types/lookup';

type TooltipListProps = {
  items: Lookup[];
  singularName: string;
  pluralAddition: string;
};

export const TooltipList = ({ items, singularName, pluralAddition }: TooltipListProps) => {
  if (items.length === 0) return null;

  return (
    <Text
      style={{ textDecoration: 'dashed underline', cursor: 'default' }}
      tip={{ content: items.map(lookup => lookup.label).join(', ') }}
    >
      {items.length} {singularName}{items.length > 1 ? pluralAddition : ''}
    </Text>
  );
};
