import { State } from '@/types/state';

export enum McoCarveOutMethod {
  DRUG = 'Drug',
  CLASS = 'Class'
}

export type StateDrugOrClass = {
  id: number;
  state: State;
  discriminator: McoCarveOutMethod;
  clinicalsProduct?: {
    id: number;
    name: string;
    classification?: {
      id: number;
      name: string;
    };
  };
  classification?: {
    id: number;
    name: string;
  };
};
