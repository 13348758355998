import { useErrorHandler } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { makeClientUsersQueryKey } from '@/features/clients/api/use-client-users';
import { useClientService } from '@/features/clients/api/use-clients-service';

export const useDeleteClientUser = () => {
  const { deleteClientUser } = useClientService();
  const queryClient = useQueryClient();
  const { success } = useNotifications();
  const { handleError } = useErrorHandler();

  return useMutation({
    mutationFn: ({ clientId, userId }: { clientId: number, userId: number }) => deleteClientUser(clientId, userId),
    onSuccess: () => {
      success({ title: 'Saved', message: 'User was successfully deleted.', autoClose: true });
    },
    onError: (error) => {
      handleError(
        error,
        {
          title: 'Save Failed',
          message: 'Unable to delete user.',
          autoClose: true
        }
      );
    },
    onSettled: async (_, __, { clientId }) => {
      await queryClient.invalidateQueries({ queryKey: makeClientUsersQueryKey(clientId) });
    }
  });
};
