import { useAsync } from '@shared/async';
import React from 'react';

import { DeleteConfirmationDialog } from '@/components/delete-confirmation-dialog';
import { BoardMeeting } from '@/types/board-meetings';

import { useBoardMeetingService } from '../api';

type DeleteBoardMeetingDialogProps = {
  /**
   * The board meeting to delete. If null, this modal will be closed.
   */
  boardMeeting: BoardMeeting | null;

  /**
   * Event handler when the user cancels the operation.
   */
  onCancel: () => void;

  /**
   * Event handler once the delete has occurred on the server.
   */
  onDeleteComplete: () => void;
};

/**
 * Handles deletion of a Board Meeting.
 */
export const DeleteBoardMeetingDialog = ({ boardMeeting, onCancel, onDeleteComplete }: DeleteBoardMeetingDialogProps) => {
  const { deleteBoardMeeting } = useBoardMeetingService();
  const deleteAsync = useAsync(deleteBoardMeeting);

  return (
    <DeleteConfirmationDialog
      open={boardMeeting !== null}
      itemName="Meeting"
      onCancel={onCancel}
      onDeleteComplete={onDeleteComplete}
      // @ts-expect-error TS(2322): Type 'UseAsyncResult<[id: number], void>' is not a... Remove this comment to see the full error message
      request={deleteAsync}
      parameters={[boardMeeting?.id]}
    />
  );
};
