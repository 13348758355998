import { Box, FormField, TextInput } from 'grommet';
import { Search } from 'grommet-icons';
import React from 'react';

import { GrommetFilterForm } from '@/components/filters';

type PackagingFiltersProps = {
  defaultValue: PackagingFilterData;
  onApplyFilters: (data: PackagingFilterData) => void;
}

export const PackagingFilters = ({ defaultValue, onApplyFilters }: PackagingFiltersProps) => {
  return (
    <GrommetFilterForm
      defaultValue={defaultValue}
      emptyValues={{ ndc: '', description: '' }}
      onChange={(value) => onApplyFilters(value)}
    >
      <Box width="22rem">
        <FormField
          name="ndc"
          htmlFor="ndc"
          margin="none"
        >
          <TextInput
            type="search"
            icon={<Search color="accent-1"/>}
            id="ndc"
            name="ndc"
            placeholder="Search By NDC"
            maxLength={11}
          />
        </FormField>
      </Box>
      <Box width="22rem">
        <FormField
          name="description"
          htmlFor="description"
          margin="none"
        >
          <TextInput
            type="search"
            icon={<Search color="accent-1"/>}
            id="description"
            name="description"
            placeholder="Search By Description"
          />
        </FormField>
      </Box>
    </GrommetFilterForm>
  );
};

export type PackagingFilterData = { ndc?: string, description?: string };
