import { Suspense } from 'react';

import { useClient } from '@/features/clients/api/use-client';
import { SubscriptionTabContent } from '@/features/clients/components/subscription-tab-content';
import { useParams } from '@/router';

type Props = {
  clientId: number;
}

const ClientSubscriptionScreen = ({ clientId }: Props) => {
  const { data: client } = useClient(clientId);

  return (
    // @ts-expect-error TS(2322): Type 'Subscription | undefined' is not assignable ... Remove this comment to see the full error message
    <SubscriptionTabContent client={client} subscription={client.subscription} />
  );
};

const ClientSubscriptionPage = () => {
  const { id } = useParams('/clients/:id');

  return (
    <Suspense>
      <ClientSubscriptionScreen clientId={parseInt(id)} />
    </Suspense>
  );
};

export default ClientSubscriptionPage;
