import { Anchor, Button, ButtonExtendedProps, ButtonType } from 'grommet';
import React, { forwardRef, ReactNode } from 'react';

import { Busy } from '@/components/busy';

export const ArtiaButton = forwardRef<HTMLButtonElement, ActionButtonProps>((props, ref) => {
  const {
    label,
    icon,
    size = 'small',
    variant = 'filled',
    busy,
    ...rest
  } = props;

  const iconSize = size === 'small' && label !== undefined ? 'small' : 'medium';
  const Icon = icon ? React.cloneElement(icon, { color: 'accent-2', size: iconSize }) : undefined;

  return (
    <Button
      className="!font-sans !font-semibold !tracking-normal"
      primary={variant === 'filled' || variant === 'danger'}
      secondary={variant === 'outlined'}
      color={variant === 'danger' ? 'status-critical' : undefined}
      label={label && <Busy busy={busy} content={label} />}
      icon={Icon ? Icon : undefined}
      plain={false}
      size={size === 'small' ? 'small' : 'medium'}
      style={{ lineHeight: '24px' }}
      // JF: this is because grommet doesn't use the right type for their Button component.
      // while it's defined using forwardRef internally, they just expose it as React.FC<Props>.
      // the latter doesn't have a ref prop at all. this is why React infers it to be a legacy ref, which
      // i guess isn't compatible with forwarded refs??
      ref={(node: any) => {
        if (typeof ref === 'function') {
          ref(node);
        } else if (typeof ref === 'object' && ref) {
          ref.current = node;
        }
      }}
      {...rest}
    />
  );
});

type ActionButtonProps = {
  size?: 'small' | 'large';
  variant?: 'filled' | 'outlined' | 'danger';
  busy?: boolean;
} & ButtonType;

type ArtiaButtonLinkProps = {
  onClick: ButtonExtendedProps['onClick'];
  children: ReactNode;
};

export const ArtiaButtonLink = ({ onClick, children }: ArtiaButtonLinkProps) => {
  return (
    <Button plain onClick={onClick} className="font-sans">
      <Anchor as="span">{children}</Anchor>
    </Button>
  );
};
