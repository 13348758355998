import { Box, BoxProps } from 'grommet';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const bounceDelay = keyframes`
  0%, 80%, 100% {
    transform: scale(0.4);
  } 40% {
    transform: scale(0.8);
  }
`;

const bounceDelayRule = css`
  animation: ${bounceDelay} 1.4s infinite ease-in-out both;
`;

type DotProps = {
  delay?: string;
} & BoxProps;

const Dot = styled(Box)<DotProps>`
  width: 8px;
  height: 8px;
  ${(props) => props.color && `background-color: ${props.color};`}
  border-radius: 100%;
  display: inline-block;
  ${bounceDelayRule}
  ${(props) => props.delay && `animation-delay: ${props.delay};`}
`;

type EllipsisAnimationProps = {
  color?: string;
};

export const EllipsisAnimation = ({ color = 'white' }: EllipsisAnimationProps) => (
  <Box
    style={{ position: 'absolute' }}
    fill
    alignContent="center"
    justify="center"
  >
    <Box alignSelf="center" direction="row" gap="small">
      <Dot color={color} delay="-0.32s" />
      <Dot color={color} delay="-0.16s" />
      <Dot color={color} />
    </Box>
  </Box>
);
