import { policiesMap, PolicyMap } from '@shared/auth/policies';
import { User } from 'oidc-client-ts';

/**
 * Check if a user satisfies all specified policies.
 */
export const checkPolicies = <P>(policiesMap: PolicyMap<P>) => {
  return (user?: User) => {
    if (!user) return (_: Array<keyof P>) => false;

    const applyPolicyCheck = (policyName: keyof P) => policiesMap[policyName](user);

    return (policyNames: Array<keyof P>) => policyNames.every(applyPolicyCheck);
  };
};

export type PolicyChecker = ReturnType<typeof policyChecker>;

/**
 * Check the policies for compliance using the concrete Artia policies.
 * @see policiesMap
 */
export const policyChecker = checkPolicies(policiesMap);
