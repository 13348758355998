import { useAsync } from '@shared/async';
import { useEffect } from 'react';

import { useLookupsService } from '@/hooks/use-lookups-service';

export const useGetProductLookups = () => {
  const { getProductLookups } = useLookupsService();
  const productsAsync = useAsync(getProductLookups);

  useEffect(() => {
    productsAsync.execute({});
  }, []);

  return {
    ...productsAsync
  };
};
