import { ProtectedRoute } from '@shared/auth';
import { useQueryClient } from '@tanstack/react-query';
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table';
import { isEmpty, isNil, parseInt } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LiaFolderOpen } from 'react-icons/lia';

import Nullish from '@/components/nullish';
import { Button } from '@/components-new/button';
import { DataPaginator } from '@/components-new/data-paginator';
import { DataTable } from '@/components-new/data-table';
import { EmptyState, EmptyStateBody, EmptyStateHeading } from '@/components-new/empty-state';
import { Loader } from '@/components-new/loader';
import { Overlay } from '@/components-new/overlay';
import { SectionDescription, SectionHeading } from '@/components-new/section';
import { StateCoverage } from '@/features/coverage/types/state-coverage';
import { makeDrugStateCoveragesQueryKey, useDrugStateCoverages } from '@/features/drugs/api/use-drug-state-coverages';
import { CoverageDetailsDrawer } from '@/features/drugs/components/coverage-details-drawer';
import { PdlStateCoverageChart } from '@/features/drugs/components/pdl-state-coverage-chart';
import { PdlStatusBadge } from '@/features/drugs/components/pdl-status-badge';
import { notifications } from '@/lib/notification';
import { useParams } from '@/router';
import { stepTherapyDisplay } from '@/utils/state-coverage';

const columnHelper = createColumnHelper<StateCoverage>();

const PaTypesCell = ({ coverage }: { coverage: StateCoverage }) => {
  const {
    paTypes
  } = coverage;

  if (!paTypes || paTypes.length === 0) return <Nullish />;

  return paTypes.map(paType => paType.label).join('; ');
};

const DrugCoveragePage = () => {
  const { id } = useParams('/drugs/:id');
  const {
    data: stateCoverages,
    isLoading: isLoadingStateCoverages,
    isError: isErrorStateCoverages,
  } = useDrugStateCoverages({ drugId: parseInt(id) });
  const [coverageDetailsForReview, setCoverageDetailsForReview] = useState<StateCoverage | undefined>();
  const queryClient = useQueryClient();

  const handleRetry = useCallback(async () => {
    notifications.remove();
    await queryClient.resetQueries({ queryKey: makeDrugStateCoveragesQueryKey(parseInt(id)) });
  }, [id, queryClient]);

  // display error notification with support to retry on error
  useEffect(() => {
    if (isErrorStateCoverages) {
      notifications.error({
        message: 'We were unable to load coverage information at this time.',
        actions: (
          <Button
            plain
            color="secondary"
            onClick={handleRetry}
          >
            Retry
          </Button>
        )
      });
    }
  }, [handleRetry, isErrorStateCoverages]);

  const columns = useMemo(() => [
    columnHelper.accessor('state', {
      header: 'State',
      cell: info => info.getValue().name
    }),
    columnHelper.accessor('pdlStatus', {
      header: 'PDL Status',
      cell: info => <PdlStatusBadge pdlStatus={info.getValue()}/>
    }),
    columnHelper.accessor('paTypes', {
      header: 'PA Type',
      cell: ({ row }) => <PaTypesCell coverage={row.original} />
    }),
    columnHelper.accessor('stepTherapyCount', {
      header: 'Step Therapy',
      cell: ({ row }) => (
        <Nullish
          value={stepTherapyDisplay(row.original.stepTherapyCount, row.original.hasAdditionalSteps)}
        />
      )
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <span className="sr-only">Actions</span>
      ),
      meta: {
        className: 'max-w-12'
      },
      cell: ({ row }) => (
        <div className="flex justify-end">
          <Button
            outline
            onClick={() => setCoverageDetailsForReview(row.original)}>
            Details
          </Button>
        </div>
      )
    })
  ], []);

  const table = useReactTable({
    data: stateCoverages ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const showEmptyTableEmpty = !isLoadingStateCoverages && (isEmpty(stateCoverages) || isNil(stateCoverages));

  return (
    <div className="mt-4">
      <div className="grid gap-16 md:grid-cols-2">
        <div className="flex flex-col">
          <div>
            <SectionHeading level={2}>
              PDL Status Overview
            </SectionHeading>
            <SectionDescription>
              PDL Status of the drug within each state.
            </SectionDescription>
          </div>
          <PdlStateCoverageChart stateCoverages={stateCoverages} isLoading={isLoadingStateCoverages} />
        </div>
      </div>
      <div className="mt-4">
        <div className="sm:flex-auto">
          <SectionHeading level={2}>Coverage</SectionHeading>
          <SectionDescription>
            How each state covers the drug and their prior authorization requirements.
          </SectionDescription>
        </div>
        <div className="relative mt-8 flow-root">
          {isLoadingStateCoverages && (
            <Overlay>
              <Loader/>
            </Overlay>
          )}
          <DataTable table={table} />
          {showEmptyTableEmpty && (
            <EmptyState>
              <LiaFolderOpen className="size-12 text-gray-500"/>
              <EmptyStateHeading>No state coverage</EmptyStateHeading>
              <EmptyStateBody>
                We're hard working to bring you the latest coverage information.
              </EmptyStateBody>
            </EmptyState>
          )}
          <DataPaginator table={table} className="mt-6"/>
        </div>
        <CoverageDetailsDrawer
          coverage={coverageDetailsForReview}
          onClose={() => setCoverageDetailsForReview(undefined)}
        />
      </div>
    </div>
  );
};

const DrugCoveragePageContainer = () => {
  return (
    <ProtectedRoute>
      <DrugCoveragePage/>
    </ProtectedRoute>
  );
};

export default DrugCoveragePageContainer;
