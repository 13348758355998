import { Box, Layer } from 'grommet';
import React from 'react';

import { NotificationDefinition } from './notification-definition';
import { NotificationsPosition } from './notification-position';
import { Toast } from './toast';

export const Notifications = ({ notifications, position }: NotificationsProps) => {
  const hasNotifications = notifications && notifications.length > 0;

  if (!hasNotifications) return null;

  return (
    <Layer modal={false} position={position} background="transparent" style={{ zIndex: 999 }}>
      <Box pad="small">
        {notifications.map(notification => (
          <Box key={notification.id} margin="xsmall">
            <Toast notification={notification} />
          </Box>
        ))}
      </Box>
    </Layer>
  );
};

type NotificationsProps = {
  notifications: NotificationDefinition[],
  onClose: (id: string) => void
  position: NotificationsPosition,
}
