import { Form } from 'grommet';
import React, { useEffect, useState } from 'react';

import { FilterForm, FilterFormProps } from '@/components/filters/filter-form';

type GrommetFilterFormProps<T> = {
  /**
   * Default value of the filters, required.
   */
  defaultValue: T;

  /**
   * Change handler for the filter. Run after 200ms of inactivity or on reset.
   * @param value the new value for the filters
   */
  onChange: (value: T) => void;

  /**
   * Represents what an _empty_ form looks like, not the initial state. Used when clearing the form, required.
   */
  emptyValues: T;
} & FilterFormProps;

/**
 * Handles common styling and behavior for all filters across the app.
 *
 * The parent component must own the default state. This is typically done via the `useFilters` hook, which
 * saves the filter data to `sessionStorage`.
 *
 * TODO: this approach uses Grommet's <Form> component not because it's a better solution than React Hook Form,
 *  but rather out of expediency.It was easier to rework the non-Grommet filters to be this way instead. However,
 *  this is a stopgap and we should revisit this again in the future to make these forms use RHF instead.
 *  It'll certainly be easier with everything using common components/hooks.
 */
export const GrommetFilterForm = <T extends object>(props: GrommetFilterFormProps<T>) => {
  const {
    defaultValue,
    onChange,
    emptyValues,
    children,
    ...filterFormProps
  } = props;

  const [value, setValue] = useState<T>(defaultValue);

  // submit the form after the user has stopped typing for a period of time
  useEffect(() => {
    // don't run the debounce on mount (when defaultValue and value point to the same object)
    if (Object.is(value, defaultValue)) return;

    const timeout = setTimeout(() => onChange(value), 300);
    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Form
      value={value}
      onReset={() => setValue(emptyValues)}
      onChange={(value) => setValue(value)}
    >
      <FilterForm {...filterFormProps}>{children}</FilterForm>
    </Form>
  );
};
