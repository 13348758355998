import React from 'react';
import { Outlet } from 'react-router-dom';

import { BidAnalysesProvider } from './bid-analyses.provider';
import { CreateBidAnalysisProvider } from './create-bid-analysis.provider';
import { CreateBidAnalysisFormProvider } from './create-bid-analysis-form.provider';

export const BidAnalysisDependencies = () =>
  <BidAnalysesProvider>
    <CreateBidAnalysisProvider>
      <CreateBidAnalysisFormProvider>
        <Outlet />
      </CreateBidAnalysisFormProvider>
    </CreateBidAnalysisProvider>
  </BidAnalysesProvider>;
