import { useAsync, UseAsyncStatus } from '@shared/async';
import { ProtectedRoute } from '@shared/auth';
import { useErrorHandler } from '@shared/errors';
import { PaginationRequest, PaginationResult, SortDirection, SortingInfo } from '@shared/pagination';
import { Box, Button, Text } from 'grommet';
import { Add, Compliance, Download, Edit, Trash, View } from 'grommet-icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ArtiaButton, ArtiaButtonLink } from '@/components/artia-button';
import { useFilters } from '@/components/filters';
import { Visible } from '@/components/visible';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Seo } from '@/components-new/seo';
import { useCoverageService } from '@/features/coverage/api/use-coverage-service';
import { useEditDialog } from '@/features/coverage/api/use-edit-dialog';
import { BulkEditPdlButton } from '@/features/coverage/components/bulk-edit-pdl-button';
import {
  CoverageEditDialogAction,
  CoverageEditDialogBody
} from '@/features/coverage/components/coverage-edit-dialog-body';
import { CoverageEditWorkflowDialog } from '@/features/coverage/components/coverage-edit-workflow-dialog';
import { ConfirmCoverageDeleteDialog } from '@/features/coverage/components/index/confirm-coverage-delete-dialog';
import { CoverageFilters, FilterInputs } from '@/features/coverage/components/index/coverage-filters';
import { CoverageTable } from '@/features/coverage/components/index/coverage-table';
import { ExportToCsvDialog } from '@/features/coverage/components/index/export-to-csv-dialog';
import { ViewCoverageDialog } from '@/features/coverage/components/view-coverage-dialog';
import { StateCoverage } from '@/features/coverage/types/state-coverage';
import { useCurrentUser } from '@/hooks/use-current-user';

const INITIAL_PAGINATION = {
  page: 1,
  rpp: 52
};

const INITIAL_SORTING = {
  sortBy: 'classification',
  sortDirection: SortDirection.ASCENDING,
};

const INITIAL_SEARCH_RESULT: PaginationResult<StateCoverage> = {
  page: INITIAL_PAGINATION.page,
  total: 0,
  resultsPerPage: INITIAL_PAGINATION.rpp,
  items: [],
};

const DrugCoveragesPage = () => {
  const { hasPolicies } = useCurrentUser();
  const { handleError } = useErrorHandler();
  const canManageDrugs = hasPolicies(['canManageDrugs']);

  const { searchCoverages } = useCoverageService();
  const [sorting, setSorting] = useState<SortingInfo>(INITIAL_SORTING);
  const [pagination, setPagination] = useState<PaginationRequest>(INITIAL_PAGINATION);

  const [params] = useSearchParams();
  const [searchRequest, setSearchRequest] = useFilters<FilterInputs>(
    'clinicals-products',
    (currentSessionStorageValue) => {
      let result: FilterInputs = {
        clients: [],
        states: [],
        products: [],
        classifications: [],
        pdlStatuses: [],
        pdlStatusBeforeDate: ''
      };

      if (currentSessionStorageValue) {
        result = { ...currentSessionStorageValue };
      }

      const hasStateParams = params.get('stateCode') && params.get('stateName');
      const hasDrugParams = params.get('drugName') && params.get('drugId');

      if (hasStateParams) {
        result = {
          ...result,
          // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
          states: [{ label: params.get('stateName'), id: params.get('stateCode') }]
        };
      }

      if (hasDrugParams) {
        result = {
          ...result,
          // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
          products: [{ label: params.get('drugName'), id: parseInt(params.get('drugId')) }]
        };
      }

      return result;
    }
  );

  const openAddCoverageDialog = useCallback(() => {
    dispatch({ type: 'add' });
    // TODO: revisit, missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshData = async (searchRequest: FilterInputs & PaginationRequest & SortingInfo) => {
    try {
      return await searchAsync.execute(searchRequest);
    } catch (error) {
      handleError(
        // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        error,
        {
          title: 'Drug Coverage Search Failed',
          message: 'Unable to search for Drug Coverage.',
          autoClose: false
        }
      );

      throw error;
    }
  };

  const updateSearchFiltersAndRefresh = async ({ sortBy, sortDirection, page, rpp, ...searchRequest }: FilterInputs & PaginationRequest & SortingInfo) => {
    setSearchRequest(searchRequest);

    setSorting((current) => {
      if (current.sortBy === sortBy && current.sortDirection === sortDirection) return current;
      return { sortBy, sortDirection };
    });

    setPagination((current) => {
      if (current.page === page && current.rpp === rpp) return current;
      return { page, rpp };
    });

    return await refreshData({ sortBy, sortDirection, page, rpp, ...searchRequest });
  };

  useEffect(() => {
    void refreshData({ ...searchRequest, ...sorting, ...pagination });
    // TODO: revisit, missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchAsync = useAsync(searchCoverages, true);
  const searchResult = searchAsync.value ?? INITIAL_SEARCH_RESULT;
  const searching = searchAsync.status === UseAsyncStatus.Pending;

  const handleApplyFilters = ({ clients, states, products, classifications, pdlStatuses, ...rest }: FilterInputs) => {
    void updateSearchFiltersAndRefresh({
      ...searchRequest,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      clients: clients?.length > 0 ? clients : undefined,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      states: states?.length > 0 ? states : undefined,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      products: products?.length > 0 ? products : undefined,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      classifications: classifications?.length > 0 ? classifications : undefined,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      pdlStatuses: pdlStatuses?.length > 0 ? pdlStatuses : undefined,
      ...rest,
      ...pagination,
      ...sorting,
      // Reset page
      page: 1,
    });
  };

  const handlePaginationChange = (page: number) => {
    void updateSearchFiltersAndRefresh({
      ...searchRequest,
      ...sorting,
      ...pagination,
      page
    });
  };

  const handleSortingChange = (sortingInfo: SortingInfo) => {
    void updateSearchFiltersAndRefresh({
      ...searchRequest,
      ...pagination,
      ...sortingInfo,
      // reset page
      page: 1
    });
  };

  const [deletingProduct, setDeletingProduct] = useState<StateCoverage | null>(null);
  const handleDeleteCancel = () => setDeletingProduct(null);
  const handleDeleteSuccess = () => {
    setDeletingProduct(null);
    void refreshData({ ...searchRequest, ...pagination, ...sorting });
  };

  const [editDialogState, dispatch] = useEditDialog();

  const handleEditingProduct = (product: StateCoverage) => {
    dispatch({ type: 'open', mode: 'single-edit', payload: product });
  };

  const handleEditingSuccess = async (
    updatedStateProfile: StateCoverage | null,
    action: CoverageEditDialogAction
  ) => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const numPages = Math.ceil(searchResult.total / pagination.rpp);
    const isLastPage = pagination.page === numPages;
    const foundIndex = editDialogState
      ? searchResult.items.findIndex((p) => p.id === editDialogState.currentCoverage?.id)
      : -1;
    const isLastRowOnPage = foundIndex === searchResult.items.length - 1;

    const isViewingPreviousRecords = editDialogState.previousOffset > 0;
    const isOnLastRowOfLastPage = isLastRowOnPage && isLastPage;
    const canGoToNextPage = isLastRowOnPage && !isLastPage;

    // if we have to go to the next page OR the state profile was actually updated, make the request.
    // otherwise, nothing changed, so don't re-fetch anything.
    let newSearchResult = searchResult;
    if (canGoToNextPage || updatedStateProfile || editDialogState.mode === 'add') {
      newSearchResult = await updateSearchFiltersAndRefresh({
        ...searchRequest,
        ...pagination,
        ...sorting,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        page: canGoToNextPage ? pagination.page + 1 : pagination.page
      });
    }

    // The following code assumes that the order will remain stable across refreshes. There's code on the server
    // that ensures that the results are at least ordered by the product's name.

    switch (action) {
      case 'next': {
        // if we're not at the latest record, move up by one. the product to edit will automatically
        // be set by the useEditDialog hook
        if (isViewingPreviousRecords) {
          dispatch({ type: 'next', updatedData: updatedStateProfile });
        }
        // if this is the last row of the last page, just close the dialog since there's nothing to go to
        else if (isOnLastRowOfLastPage) {
          dispatch({ type: 'close' });
        }
        // if this is the last row of the current page but there are still more pages left,
        // start editing the first product of the next page
        else if (canGoToNextPage) {
          dispatch({
            type: 'next',
            updatedData: updatedStateProfile,
            nextProduct: newSearchResult.items[0]
          });
        }
        // otherwise, take the next row in the table/next item in history if we're not at the current
        else {
          dispatch({
            type: 'next',
            updatedData: updatedStateProfile,
            nextProduct: searchResult.items[foundIndex + 1]
          });
        }

        break;
      }

      case 'close': {
        dispatch({ type: 'close' });
        break;
      }

      case 'previous': {
        dispatch({ type: 'previous', updatedData: updatedStateProfile });
        break;
      }
    }
  };

  const handleEditingCancel = () => {
    dispatch({ type: 'close' });
  };

  const [viewingCoverage, setViewingCoverage] = useState<StateCoverage>();
  const handleViewCoverage = (drug: StateCoverage) => setViewingCoverage(drug);
  const handleCloseViewCoverage = () => setViewingCoverage(undefined);

  const [csvExportModalOpen, setCsvExportModalOpen] = useState(false);

  const coverageIdentifier = useMemo(() => {
    const id = editDialogState.currentCoverage?.id;
    return id ? { type: 'state-profile', id } as const : null;
  }, [editDialogState.currentCoverage?.id]);

  return (
    <>
      <Seo title="Drug Coverage" />
      <PageTitleRow title="Drug Coverage">
        {canManageDrugs && (
          <ArtiaButton
            icon={<Add/>}
            a11yTitle="Add Drug Coverage"
            label="Add New"
            onClick={openAddCoverageDialog}
          />
        )}
      </PageTitleRow>
      <div className="mt-6">
        <CoverageFilters
          disabled={searching}
          defaultFilters={searchRequest}
          onApplyFilters={handleApplyFilters}
        >
          <ArtiaButton
            icon={<Download/>}
            a11yTitle="Export to CSV"
            tip="Export to CSV"
            onClick={() => setCsvExportModalOpen(true)}
          />
          <Visible when={canManageDrugs}>
            <BulkEditPdlButton
              filters={{ ...searchRequest, ...pagination, ...sorting }}
              onSuccess={() => refreshData({ ...searchRequest, ...pagination, ...sorting })}
            />
          </Visible>
          <Visible when={canManageDrugs}>
            <ArtiaButton
              icon={<Compliance />}
              a11yTitle="Perform Full PDL Review"
              tip="Perform Full PDL Review"
              disabled={searchResult.items.length === 0}
              onClick={() => dispatch({ type: 'open-date-select' })}
            />
          </Visible>
          <ExportToCsvDialog
            open={csvExportModalOpen}
            filters={{ ...searchRequest, ...pagination, ...sorting }}
            onCancel={() => setCsvExportModalOpen(false)}
            onSubmitComplete={() => setCsvExportModalOpen(false)}
          />
        </CoverageFilters>

        <CoverageTable
          data={searchResult.items}
          loading={searching}
          onSort={handleSortingChange}
          sort={sorting}
          // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
          page={pagination.page}
          // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
          step={pagination.rpp}
          totalItems={searchResult.total}
          onPaginate={handlePaginationChange}
          additionalColumns={[
            {
              property: 'actions',
              size: 'small',
              sortable: false,
              render: (row) => (
                <Box direction="row" gap="xxsmall" justify="end">
                  <Visible
                    when={canManageDrugs}
                    fallback={
                      <Button
                        tip="View PDL/PA Information"
                        pad="small"
                        hoverIndicator="background"
                        margin="none"
                        icon={<View color="accent-1" size="medium" />}
                        onClick={() => handleViewCoverage(row)}
                      />
                    }
                  >
                    <Button
                      tip="Edit PDL/PA Information"
                      pad="small"
                      hoverIndicator="background"
                      margin="none"
                      icon={<Edit color="accent-1" size="medium" />}
                      onClick={() => handleEditingProduct(row)}
                    />
                    <Button
                      tip="Delete"
                      pad="small"
                      hoverIndicator="background"
                      margin="none"
                      icon={<Trash color="accent-1" size="medium" />}
                      onClick={() => setDeletingProduct(row)}
                    />
                  </Visible>
                </Box>
              )
            }
          ]}
          emptyMessage={
            <Text>
              Can't find the drug you're looking for? <ArtiaButtonLink onClick={openAddCoverageDialog}>Add coverage data.</ArtiaButtonLink>
            </Text>
          }
        />

        <CoverageEditWorkflowDialog
          editDialogState={editDialogState}
          onCancelDateSelect={() => dispatch({ type: 'close' })}
          onDateSelect={(pdlStatusDate) => dispatch({
            type: 'open',
            pdlStatusDate,
            mode: 'full-pdl-review',
            payload: searchResult.items[0]
          })}
        >
          <CoverageEditDialogBody
            allowedDirections={editDialogState.atEnd ? ['next'] : ['next', 'previous']}
            mode={editDialogState.mode}
            onClose={handleEditingCancel}
            onSubmitComplete={handleEditingSuccess}
            coverageIdentifier={coverageIdentifier}
            // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            productName={editDialogState.currentCoverage?.name}
            // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            genericName={editDialogState.currentCoverage?.genericName}
            // @ts-expect-error TS(2322): Type 'CoverageTag | undefined' is not assignable t... Remove this comment to see the full error message
            drug={editDialogState.currentCoverage?.drug}
            coverageTags={editDialogState.currentCoverage?.coverageTags ?? []}
            drugClass={editDialogState.currentCoverage?.classification?.label}
            // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            stateName={editDialogState.currentCoverage?.state?.name}
            // don't do an initial override if we're going back through history records, only if we're going forward
            // AND on a record we haven't seen until now
            initialOverrides={editDialogState.mode === 'full-pdl-review' && editDialogState.atFront
              ? { pdlStatusDate: editDialogState.pdlStatusDate }
              : undefined
            }
            defaultValues={editDialogState.currentCoverage ? {
              pdlStatus: editDialogState.currentCoverage?.pdlStatus,
              pdlStatusDate: editDialogState.currentCoverage?.pdlStatusDate,
              pdlStatusEffectiveDate: editDialogState.currentCoverage?.pdlStatusEffectiveDate,
              paTypes: editDialogState.currentCoverage?.paTypes,
              hasAdditionalSteps: editDialogState.currentCoverage?.hasAdditionalSteps,
              stepTherapyCount: editDialogState.currentCoverage?.stepTherapyCount,
              paCriteriaDescription: editDialogState.currentCoverage?.paCriteriaDescription,
              paCriteriaSourceUrl: editDialogState.currentCoverage?.paCriteriaSourceUrl,
              notes: editDialogState.currentCoverage?.notes,
              hasAutoPa: editDialogState.currentCoverage?.hasAutoPa
            } : {}}
          />
        </CoverageEditWorkflowDialog>

        <ConfirmCoverageDeleteDialog
          coverageToDelete={deletingProduct}
          onDeleteComplete={handleDeleteSuccess}
          onCancel={handleDeleteCancel}
        />

        <ViewCoverageDialog
          open={!!viewingCoverage}
          // @ts-expect-error TS(2322): Type 'ClinicalsProductStateProfile | undefined' is... Remove this comment to see the full error message
          coverage={viewingCoverage}
          onClose={handleCloseViewCoverage}
        />
      </div>
    </>
  );
};

const DrugCoveragePageContainer = () => {
  return (
    <ProtectedRoute policies={['isAnyArtiaUser']}>
      <DrugCoveragesPage />
    </ProtectedRoute>
  );
};

export default DrugCoveragePageContainer;
