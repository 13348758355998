import React from 'react';

import { type HttpClient } from './http-client';

const DEFAULT_HTTP_CLIENT: HttpClient = {
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'HttpClient'... Remove this comment to see the full error message
  create: () => null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Promise<T>'... Remove this comment to see the full error message
  post: () => null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Promise<T>'... Remove this comment to see the full error message
  put: () => null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Promise<voi... Remove this comment to see the full error message
  patch: () => null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Promise<T>'... Remove this comment to see the full error message
  get: () => null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Promise<voi... Remove this comment to see the full error message
  delete: () => null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Promise<Fil... Remove this comment to see the full error message
  download: () => null,
  addRequestInterceptor: () => null
};

export const HttpClientContext = React.createContext<HttpClient>(DEFAULT_HTTP_CLIENT);
