import { RowPinSelectorOption } from '@/components/row-pin-selector';
import {
  getDrugPackagesBelongingToMarketBasket,
  getDrugPackagesNeedingAttention,
  MarketBasketDrugPackaging
} from '@/features/market-baskets/types/market-basket';

export enum RowPinKeys {
  belongsToMarketBasket = 'BELONGS_TO_MARKET_BASKET',
  needsAttention = 'NEEDS_ATTENTION'
}

type RowPinner = (items: MarketBasketDrugPackaging[]) => MarketBasketDrugPackaging[];

type RowPinners = {
  [key in RowPinKeys]: RowPinner;
}

export const rowPinOptions: RowPinSelectorOption[] = [
  { value: RowPinKeys.belongsToMarketBasket, label: 'Belongs to Client' },
  { value: RowPinKeys.needsAttention, label: 'Needs Attention' }
];

export const rowPinners: RowPinners = {
  [RowPinKeys.belongsToMarketBasket]: getDrugPackagesBelongingToMarketBasket,
  [RowPinKeys.needsAttention]: getDrugPackagesNeedingAttention
};

export const resolvePinners = (keys: RowPinKeys[]) => keys.map(key => rowPinners[key]);

export const resolvePinning = (
  pinners: RowPinner[],
  sorter: (items: MarketBasketDrugPackaging[]) => MarketBasketDrugPackaging[],
  items: MarketBasketDrugPackaging[]
) => {
  return pinners
    .map(pinner => pinner(items))
    .reduce((acc, curr) => acc.concat(sorter(curr)), []);
};
