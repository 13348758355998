import { hasAnyOfRoles, Role } from '@shared/auth';
import { policyChecker } from '@shared/auth/authorize';
import { policiesMap } from '@shared/auth/policies';
import { isNil } from 'lodash';
import React, { ComponentType, JSX } from 'react';

import { Loader } from '@/components-new/loader';
import { Overlay } from '@/components-new/overlay';
import StatusCodePage from '@/features/status-codes/status-code.page';
import { useAuthFlow } from '@/hooks/use-auth-flow';
import { Navigate } from '@/router';

/**
 * Creates an authentication and authorization aware component for protecting routes from unauthorized access.
 */
const ProtectedRoute = (props: ProtectedRouteProps) => {
  const {
    roles,
    policies,
    component: Component,
    children
  } = props;

  const { user, isAuthenticated, isAuthenticating, isLoadingUserDetails } = useAuthFlow();

  if (isAuthenticating) return null;

  if (isLoadingUserDetails) {
    return (
      <Overlay solid>
        <Loader
          indicator={<img className="h-auto w-20" src="/artia-capsule-logo.png" alt="artia capsule logo" /> }
        />
      </Overlay>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  const hasPolicies = policyChecker(user);

  const hasChildren = React.Children.count(children) > 0;

  const requiresPolicies = !isNil(policies) && policies.length > 0;
  const hasRequiredPolicies = requiresPolicies ? hasPolicies(policies) : true;

  const requiresRoles = !isNil(roles) && roles.length === 0;
  const hasRequiredRoles = requiresRoles ? hasAnyOfRoles(user!, roles) : true;

  if (!hasRequiredPolicies || !hasRequiredRoles) {
    return (
      <StatusCodePage statusCode="403" statusCodeDescription="Unauthorized">
        You are not authorized to access this page.
      </StatusCodePage>
    );
  }

  if (hasChildren && Component) throw new Error('Either children or Component is required.');

  // @ts-expect-error TS(2604): JSX element type 'Component' does not have any con... Remove this comment to see the full error message
  return hasChildren ? <>{children}</> : <Component />;
};

type ProtectedRouteProps = {
  roles?: Role[];
  policies?: Array<keyof typeof policiesMap>;
  component?: ComponentType;
  /**
   * @deprecated no longer used.
   */
  onRedirecting?: () => JSX.Element;
  children?: React.ReactNode;
}

ProtectedRoute.defaultProps = { requiredRoles: [] };

export default ProtectedRoute;
