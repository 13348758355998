import { PaginationResult } from '@shared/pagination';
import { Box, FormField, Select, SelectExtendedProps, Tip } from 'grommet';
import { StatusInfo } from 'grommet-icons';
import React, { ChangeEvent, ForwardedRef, forwardRef, ReactElement, ReactNode, useId } from 'react';

import { SelectOption } from '@/components/form-controls/selects/select-option';
import { Lookup } from '@/types/lookup';

import { useLazyLoadOptions } from './use-lazy-load-options';

type RestrictedGrommetSelectProps = Omit<SelectExtendedProps,
  | 'replace'
  | 'options'
  | 'value'
  | 'onChange'
  | 'labelKey'
  | 'valueKey'
  | 'onSearch'
  | 'onMore'
  | 'ref'
  | 'onClose'
>;

type LazyLoadSelectListProps<TLookupId> = {
  /**
   * The selected option, required.
   */
  value: Lookup<TLookupId> | undefined;

  /**
   * Event handler called whenever a value is changed:
   * - the user clears everything
   * - the user selects or deselects an option
   * @param value the selected options after this action
   */
  onChange?: (value: Lookup<TLookupId> | ChangeEvent) => void;

  /**
   * The request to use to populate the options, required.
   * @param searchTerm the text in the input field
   * @param page the current page number (starts at 1)
   * @param rpp the number of results per page
   */
  lazyLoadRequest: (searchTerm: string, page: number, rpp: number) => Promise<PaginationResult<Lookup<TLookupId>>>;

  /**
   * The number of results per page, defaults to 100.
   */
  resultsPerPage?: number;

  /**
   * Visible label for the form field, optional but recommended.
   */
  label?: string;

  /**
   * Help text to display in a tooltip next to the label, optional.
   */
  help?: ReactNode;

  /**
   * Error message to display when the field is invalid, optional.
   */
  error?: ReactNode;

  /**
   * List of dependencies to refresh the options will, optional. This is used if you have
   * some external state that impacts when fetching should occur.
   */
  dependencies?: unknown[];
} & RestrictedGrommetSelectProps;

const Inner = <TLookupId = number>(
  {
    value,
    onChange,
    lazyLoadRequest,
    resultsPerPage = 100,
    dependencies,
    id,
    label,
    name,
    help,
    required,
    error,
    placeholder,
    ...rest
  }: LazyLoadSelectListProps<TLookupId>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const {
    options,
    handleSearch,
    handleLoadMore,
    handleClose
  } = useLazyLoadOptions({ value: value ? [value] : [], resultsPerPage, lazyLoadRequest, dependencies });

  const generatedId = useId();
  const fieldId = id ?? generatedId;

  return (
    <FormField
      required={required}
      htmlFor={fieldId}
      name={name}
      margin="none"
      error={error}
      label={label ? (
        <Box direction="row" gap="xsmall" align="center">
          {help ? (
            <Tip content={help}>
              <StatusInfo />
            </Tip>
          ) : null}
          <span>{label}</span>
        </Box>
      ) : undefined}
    >
      <Select
        // this makes the component less performant (since it isn't virtualizing its options)
        // but it fixes the weird scrolling issue
        replace={false}
        id={fieldId}
        name={name}
        required={required}
        labelKey="label"
        valueKey="id"
        options={options}
        // the <Select> component removes the value if it isn't included in the options list anymore.
        // so, to prevent a flash of white as the user searches, we set the placeholder to the current value.
        // the placeholder will still be respected if the field is empty
        placeholder={value?.label ?? placeholder}
        value={value}
        onChange={({ value }) => onChange?.(value)}
        onSearch={handleSearch}
        onMore={handleLoadMore}
        onClose={handleClose}
        {...rest}
        ref={ref}
      >
        {(option: Lookup<TLookupId>, _index, _options, state) => (
          <SelectOption option={option} {...state} />
        )}
      </Select>
    </FormField>
  );
};

/**
 * A wrapper around Grommet's <SelectMultiple> component that handles lazy-loading options for you. It works something
 * like this:
 * - if the user scrolls to the bottom of the page, it fetches the next page of options
 * - if the user searches for data, it fetches the first page of options that match that search criteria
 *
 * It doesn't do any caching outside what the user LAST searched.
 */
export const LazyLoadSelectList = forwardRef<HTMLInputElement, LazyLoadSelectListProps<number>>(Inner) as <TLookupId = number> (props: LazyLoadSelectListProps<TLookupId> & { ref?: ForwardedRef<HTMLInputElement> }) => ReactElement;
