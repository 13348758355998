export enum ChangeLogReason {
  COVERAGE_CHANGE = 'CoverageChange',
  PDL_UPDATE = 'PdlUpdate',
  CORRECTION = 'Correction'
}

export function displayChangeLogReason(reason: ChangeLogReason): string | null {
  switch (reason) {
    case ChangeLogReason.CORRECTION:
        return 'Correction';
    case ChangeLogReason.PDL_UPDATE:
        return 'PDL Update';
    case ChangeLogReason.COVERAGE_CHANGE:
        return 'Coverage Change';
  }
}

export const changeLogReasonOptions = [
  ChangeLogReason.COVERAGE_CHANGE,
  ChangeLogReason.PDL_UPDATE,
  ChangeLogReason.CORRECTION
].map((reason) => ({ id: reason, label: displayChangeLogReason(reason) }));
