import { useErrorHandler } from '@shared/errors';
import { PaginationInfo } from '@shared/pagination';
import React, { createContext, useContext, useState } from 'react';

import { useFilters } from '@/components/filters';
import {
  DrugUtilizationSearchRequest,
  useDrugUtilizationsService
} from '@/features/drug-utilization/api/use-drug-utilizations-service';
import { DrugUtilization } from '@/features/drug-utilization/types/drug-utilization';
import { useLookupsService } from '@/hooks/use-lookups-service';
import { Lookup } from '@/types/lookup';
import { State } from '@/types/state';

type DrugUtilizationsContextValue = {
  drugUtilizations: DrugUtilization[];
  searchInfo: {
    year?: number;
    state?: string;
    ndc?: string;
    product?: string;
  };
  pagination: PaginationInfo;
  searchingDrugUtilizations: boolean;
  searchDrugUtilizations: (searchRequest: DrugUtilizationSearchRequest) => void;
  stateLookups: State[],
  yearLookups: Lookup[],
  loadingLookups: boolean,
  loadLookups: () => void
};

const DEFAULT_CONTEXT_VALUE: DrugUtilizationsContextValue = {
  drugUtilizations: [],
  searchInfo: {
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'number | un... Remove this comment to see the full error message
    year: null,
    state: '',
    ndc: '',
    product: ''
  },
  pagination: {
    page: 1,
    resultsPerPage: 10,
    total: 0
  },
  searchingDrugUtilizations: false,
  searchDrugUtilizations: () => null,
  stateLookups: [],
  yearLookups: [],
  loadingLookups: false,
  loadLookups: () => null
};


const DrugUtilizationsContext = createContext<DrugUtilizationsContextValue>(DEFAULT_CONTEXT_VALUE);

export const useDrugUtilizations = () => useContext<DrugUtilizationsContextValue>(DrugUtilizationsContext);

type DrugUtilizationsProps = {
  children?: React.ReactNode
};

export const DrugUtilizationsProvider = ({ children }: DrugUtilizationsProps) => {
  const { searchDrugUtilizations } = useDrugUtilizationsService();
  const { handleError } = useErrorHandler();
  const { getStates, getDrugUtilizationYears } = useLookupsService();

  const [drugUtilizations, setDrugUtilizations] = useState(DEFAULT_CONTEXT_VALUE.drugUtilizations);
  const [loadingLookups, setLoadingLookups] = useState(DEFAULT_CONTEXT_VALUE.loadingLookups);
  const [searchInfo, setSearchInfo] = useFilters('drug-utilizations', DEFAULT_CONTEXT_VALUE.searchInfo);
  const [pagination, setPagination] = useState(DEFAULT_CONTEXT_VALUE.pagination);
  const [searchingDrugUtilizations, setSearchingDrugUtilizations] = useState(DEFAULT_CONTEXT_VALUE.searchingDrugUtilizations);
  const [stateLookups, setStateLookups] = useState(DEFAULT_CONTEXT_VALUE.stateLookups);
  const [yearLookups, setYearLookups] = useState(DEFAULT_CONTEXT_VALUE.yearLookups);

  const search = async (searchRequest: DrugUtilizationSearchRequest) => {
    setSearchingDrugUtilizations(true);
    setSearchInfo((current) => ({
      ...current,
      year: searchRequest.year,
      state: searchRequest.state,
      ndc: searchRequest.ndc,
      product: searchRequest.product,
    }));
    // @ts-expect-error TS(2345): Argument of type '(current: PaginationInfo) => { p... Remove this comment to see the full error message
    setPagination((current) => ({
      ...current,
      page: searchRequest.page,
      resultsPerPage: searchRequest.rpp
    }));

    try {
      const result = await searchDrugUtilizations(searchRequest);

      setDrugUtilizations(result.items);
      setPagination({
        page: result.page,
        resultsPerPage: result.resultsPerPage,
        total: result.total
      });
    } catch (ex) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      handleError(ex, {
        title: 'Drug Utilizations Search Failed',
        message: 'We encountered an unexpected error while search for Drug Utilizations. Please try again or contact an administrator.',
        autoClose: false
      });
    } finally {
      setSearchingDrugUtilizations(false);
    }
  };

  const loadLookups = async () => {
    setLoadingLookups(true);

    try {
      const [states, drugUtilizationYears] = await Promise.all([
        getStates(),
        getDrugUtilizationYears()
      ]);

      setStateLookups(states);
      setYearLookups(drugUtilizationYears);
    } finally {
      setLoadingLookups(false);
    }
  };

  const contextValue: DrugUtilizationsContextValue = {
    drugUtilizations,
    searchInfo,
    pagination,
    searchDrugUtilizations: search,
    searchingDrugUtilizations,
    stateLookups,
    yearLookups,
    loadingLookups,
    loadLookups
  };

  return (
    <DrugUtilizationsContext.Provider value={contextValue}>
      {children}
    </DrugUtilizationsContext.Provider>
  );
};
