import { TextInput } from 'grommet';
import React, { ChangeEventHandler, forwardRef } from 'react';

type DateTextInputProps = {
  id?: string;
  name?: string;
  onChange?: ChangeEventHandler;
  onBlur?: (event: React.FocusEvent) => any;
  value?: string;
  max?: string;
  min?: string;
};

export const DateTextInput = forwardRef<HTMLInputElement, DateTextInputProps>(({ max = '9999-12-31', min = '1900-01-01', ...props }, ref) => {
  return <TextInput {...props} max={max} min={min} type="date" ref={ref} />;
});
