import { Box } from 'grommet';
import React from 'react';

import { formatCurrency } from '@/utils/formatting';

type NumericReadonlyCellProps = {
  value: string;
  type: 'numeric' | 'currency' | 'percent'
};

export const NumericReadonlyCell = (props: NumericReadonlyCellProps) => {
  const {
    value,
    type
  } = props;

  const ReadonlyComponent = readonlyComponentMap[type];

  return <ReadonlyComponent value={value} />;
};

const ReadonlyNumeric = ({ value }: { value: string }) => (
  <Box pad="xxsmall">
    {value?.length > 0 && value && parseFloat(value).toFixed(4)}
    {!value?.length && <span><>&mdash;</></span>}
  </Box>
);

const ReadonlyCurrency = ({ value }: { value: string }) => (
  <Box pad="xxsmall">
    {/*@ts-expect-error TS(2345): Argument of type 'number | ""' is not assignable t... Remove this comment to see the full error message */}
    {value?.length > 0 && formatCurrency(value && parseFloat(value), 4, 4)}
    {!value?.length && <span><>&mdash;</></span>}
  </Box>
);

const ReadonlyPercent = ({ value }: { value: string }) => (
  <Box pad="xxsmall">
    {value?.length > 0 && <span>{parseFloat(value).toFixed(2)}%</span>}
    {!value?.length && <span><>&mdash;</></span>}
  </Box>
);

const readonlyComponentMap = {
  numeric: ReadonlyNumeric,
  currency: ReadonlyCurrency,
  percent: ReadonlyPercent
};
