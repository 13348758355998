import { useHttpClient } from '@shared/http';
import { PaginationRequest, PaginationResult } from '@shared/pagination';
import { DateTime } from 'luxon';

import { formatTimestamp } from '@/utils/date-time-functions';
import { buildQueryString } from '@/utils/filtering-functions';

import { ClinicalsChangeLog } from '../types';

type ClinicalsChangeLogFilters = {
  updatedAtFrom?: string;
  updatedAtUntil?: string;
  states?: string[];
  clientIds?: number[];
  product?: string;
  user?: string;
  properties?: number[];
  propertySearchType?: string;
};

export type ClinicalsChangeLogSearchRequest = ClinicalsChangeLogFilters & PaginationRequest;

export const useClinicalsChangeLogService = () => {
  const httpClient = useHttpClient();

  const fetchChangeLog = async (request: ClinicalsChangeLogSearchRequest): Promise<PaginationResult<ClinicalsChangeLog>> => {
    const result = await httpClient.get<PaginationResult<ClinicalsChangeLog>, unknown>(
      `clinicals/change-log${buildQueryString(request)}`
    );
    // TODO: really, this mapping shouldn't happen at all, and we should instead make sure all
    //  timestamps come to the front-end in UTC (which include the Z at the end).
    //  the old method used here (formatTimestamp) treats it as UTC but expects there
    //  to not be a timestamp signifier, whereas the new method (using Luxon) does.
    //  this is because the old clinicals changelog uses timestamp instead of timestamptz
    //  for the underlying data type
    return {
      ...result,
      items: result.items.map(it => ({
        ...it,
        audits: it.audits.map(audit => ({
          ...audit,
          updatedAt: audit.updatedAt.endsWith('Z')
            ? DateTime.fromISO(audit.updatedAt).toLocaleString({
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric'
            })
            : formatTimestamp(audit.updatedAt)
        }))
      }))
    };
  };

  const exportChangeLog = (request: ClinicalsChangeLogFilters) => {
    return httpClient.download('POST', `clinicals/change-log/export${buildQueryString(request)}`);
  };

  return {
    exportChangeLog,
    fetchChangeLog
  };
};
