import { useHeaderDetails } from '@shared/header';
import {
  Anchor,
  Box,
  ColumnConfig,
  DataTable
} from 'grommet';
import { Add } from 'grommet-icons';
import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ArtiaButton } from '@/components/artia-button';
import { Timestamp } from '@/components/date-time';
import { useFilters } from '@/components/filters';
import { TableEmptyPlaceholder, TableLoadingOverlay } from '@/components/loading';
import { PageTitleRow } from '@/components-new/page-title-row';
import { useMarketBaskets } from '@/features/market-baskets';
import { MarketBasket } from '@/features/market-baskets';
import { MarketBasketFilterData, MarketBasketFilters } from '@/features/market-baskets/components/index/market-basket-filters';

const MarketBasketsPage = () => {
  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();
  const navigate = useNavigate();
  const { marketBaskets, loadMarketBaskets, loadingMarketBaskets } = useMarketBaskets();

  const [filters, setFilters] = useFilters<MarketBasketFilterData>('market-baskets-index', {});

  const filteredMarketBaskets = useMemo(() => {
    const { name, client } = filters;

    return marketBaskets.filter(marketBasket =>
      (!name || marketBasket.name.toLowerCase().includes(name.toLowerCase()))
      && (!client || marketBasket.client.label.toLowerCase().includes(client.toLowerCase()))
    );
  }, [filters, marketBaskets]);

  React.useEffect(() => {
    setHeaderDetails({
      documentTitle: 'Market Baskets',
      pageTitle: (
        <PageTitleRow title="Market Baskets">
          <Box>
            <ArtiaButton
              icon={<Add/>}
              a11yTitle="Add Market Basket"
              label="Add New"
              onClick={onCreateMarketBasket}
            />
          </Box>
        </PageTitleRow>
      ),
      breadcrumbs: [],
    });

    loadMarketBaskets();

    return () => {
      clearHeaderDetails();
    };

    // TODO: revisit this, missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreateMarketBasket = () => {
    navigate('/market-baskets/new');
  };

  const columns: ColumnConfig<MarketBasket>[] = [
    {
      property: 'name',
      header: 'Name',
      render: (marketBasket: MarketBasket) => (
        <Link to={`/market-baskets/${marketBasket.id}`} style={{ textDecoration: 'none' }}>
          <Anchor as="span" label={marketBasket.name}/>
        </Link>
      )
    },
    {
      property: 'client.label',
      header: 'Client'
    },
    {
      property: 'lastRefreshedAt',
      header: 'Last Refreshed At',
      render: (marketBasket: MarketBasket) => <Timestamp value={marketBasket.lastRefreshedAt} />
    }
  ];

  return (
    <Box>
      <Box margin={{ top: 'small' }} gap="small">
        <MarketBasketFilters defaultValue={filters} onApplyFilters={setFilters} />
        <DataTable
          columns={columns}
          data={filteredMarketBaskets}
          sortable
          step={10}
          paginate
          placeholder={
            (loadingMarketBaskets || filteredMarketBaskets.length === 0) &&
            <Box fill>
              {loadingMarketBaskets && <TableLoadingOverlay />}
              {!loadingMarketBaskets && filteredMarketBaskets.length === 0 && <TableEmptyPlaceholder content="No market baskets have been created." />}
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export default MarketBasketsPage;
