import React, { useMemo } from 'react';

import { InMemoryMultiSelectList } from '@/components/form-controls';
import { MultiSelectOption } from '@/components/form-controls/multi-select-list';
import { Pool } from '@/types/pool';
import { State } from '@/types/state';

type StateAndPoolFilterProps = {
  /**
   * The list of States to choose from, required. Will be combined with the Pools.
   */
  states: State[];

  /**
   * The list of Pools to choose from, required. Will be combined with the States.
   */
  pools: Pool[];

  /**
   * The list of currently-selected options, required.
   */
  selected: MultiSelectOption<string>[];

  /**
   * Event handler for updating the current selection.
   * @param selected the new value of the multi-select
   */
  onSelectionChange: (selected: MultiSelectOption<string>[]) => void;
};

/**
 * Search filter component that lets the user select multiple states or pools
 */
export const StateAndPoolFilter = ({ states, pools, selected, onSelectionChange }: StateAndPoolFilterProps) => {
  const stateOptions = useMemo<MultiSelectOption<string>[]>(() => {
    return [
      ...(pools?.map((pool) => ({ label: pool.name, id: pool.code })) ?? []),
      ...(states?.map((state) => ({ label: state.name, id: state.code })) ?? [])
    ];
  }, [pools, states]);

  return (
    <InMemoryMultiSelectList
      placeholder="Search by State/Pool"
      options={stateOptions}
      value={selected}
      onChange={onSelectionChange}
    />
  );
};
