import { FormField, Select, TextInput } from 'grommet';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useCoverageTagTypes } from '@/features/coverage/api/get-coverage-tag-types';
import { CoverageTag } from '@/features/drugs/types/coverage-tag';
import { CreateUpdateCoverageTagFormSchema } from '@/features/drugs/types/create-update-coverage-tag-form-schema';
import { Drug } from '@/products';

type Props = {
  drug: Drug;
  coverageTag?: CoverageTag;
}

const CreateUpdateCoverageTagForm = ({ coverageTag }: Props) => {
  const { data: coverageTagTypes } = useCoverageTagTypes();

  const form = useFormContext<CreateUpdateCoverageTagFormSchema>();

  return (
    <form>
      <Controller
        control={form.control}
        name="name"
        defaultValue={coverageTag?.name ?? ''}
        render={({ field, fieldState: { error } }) => (
          <FormField
            required
            name={field.name}
            label="Name"
            error={error?.message}
          >
            <TextInput {...field} placeholder="Capsule; 30mg; etc." />
          </FormField>
        )}
      />
      <Controller
        control={form.control}
        name="type"
        defaultValue={coverageTag?.type}
        render={({ field, fieldState: { error } }) => (
          <FormField
            required
            name={field.name}
            label="Type"
            error={error?.message}
          >
            <Select
              name={field.name}
              placeholder="Select a Type"
              options={coverageTagTypes ?? []}
              value={field.value}
              labelKey="name"
              valueKey="code"
              onChange={({ value }) => field.onChange(value)}
              onBlur={field.onBlur}
              ref={field.ref}
            />
          </FormField>
        )}
      />
    </form>
  );
};

export default CreateUpdateCoverageTagForm;
