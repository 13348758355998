import { isReactComponent } from '@shared/type-guards';
import { Box } from 'grommet';
import React,
{
  Children,
  cloneElement,
  ReactNode,
  useEffect,
  useState
} from 'react';

import { type WizardControlState } from '@/components/wizard';
import { WizardNav } from '@/components/wizard/wizard-nav';

import { type WizardAnimation } from './wizard-animation';
import { WizardStep } from './wizard-step';

/**
 * Creates a step-able wizard component that passes shared control to each step.
 */
export const Wizard = ({ children }: WizardProps) => {
  const initialState: WizardState = {
    activeStep: 0,
    stepDefs: [],
    animation: null,
  };

  const [ state, setState ] = useState<WizardState>(initialState);

  const isValidStep = (step: number) => step > 0 || step <= getTotalSteps();

  const getTotalSteps = () => getSteps().length;

  const getSteps = () => Children.toArray(children);

  const goToStep = (step: number) => {
    const {
      activeStep,
    } = state;

    // skip if step number is the same as the current step
    if (activeStep === step) return;

    // skip if step number is invalid
    if (!isValidStep(step)) return;

    setState((prevState) => ({
      ...prevState,
      previousActiveStep: activeStep,
      activeStep: step,
      animation: activeStep < step ? 'slideLeft' : 'slideRight',
    }));
  };

  const getStepNames = () => getSteps().map((step: ReactNode, index: number) => {
    if (isReactComponent(step)) {
      return step.props.stepName || index.toString(10);
    }

    return index.toString(10);
  });

  const firstStep = () => goToStep(1);

  const lastStep = () => goToStep(getTotalSteps() - 1);

  const nextStep = () => goToStep(state.activeStep + 1);

  const previousStep = () => goToStep(state.activeStep - 1);

  useEffect(() => {
    const childrenWithProps = Children.map(getSteps(), (child, index) => {
      if (!child) return null;

      const isActive = index === state.activeStep;

      const childProps: WizardControlState = {
        activeStep: state.activeStep,
        previousActiveStep: state.previousActiveStep,
        totalSteps: getTotalSteps(),
        nextStep: nextStep,
        goToStep: goToStep,
        previousStep: previousStep,
        lastStep: lastStep,
        firstStep: firstStep,
      };

      if (isActive) {
        return (
          <WizardStep {...childProps} animation={state.animation}>
            {
              isReactComponent(child) ?
                cloneElement(child, childProps) :
                child
            }
          </WizardStep>
        );
      }

      return null;
    });

    setState((prevState) => ({
      ...prevState,
      stepDefs: childrenWithProps,
    }));

  }, [ state.activeStep ]);

  return (
    <Box>
      <WizardNav stepNames={getStepNames()} activeStep={state.activeStep} totalSteps={getTotalSteps()} />
      <Box pad="small" background="#fff">
        {state.stepDefs}
      </Box>
    </Box>
  );
};

type WizardProps = {
  children?: ReactNode;
}

type WizardState = {
  /**
   * Current step the wizard is on.
   */
  activeStep: number;

  /**
   * The previous step that the wizard was on.
   */
  previousActiveStep?: number;

  /**
   * Tracks the definition of each step this way update props can be passed to each step.
   */
  stepDefs: ReactNode[];

  /**
   * The next animation to be applied to the active step.
   */
  animation: WizardAnimation;
};
