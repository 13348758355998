import { PaginationResult } from '@shared/pagination';
import React from 'react';

import { LazyLoadMultiSelectList } from '@/components/form-controls';
import { MultiSelectOption } from '@/components/form-controls/multi-select-list';
import { useLookupsService } from '@/hooks/use-lookups-service';
import { Lookup } from '@/types/lookup';

type ClinicalsProductFilterProps = {
  /**
   * Indicates where to pull data from, defaults to 'clinicals-products'. The options are:
   * - 'clinicals-products': the old way that uses a separate list of products
   * - 'drugs': the new way that uses a unified list of products
   *
   * As we do the migration, we'll remove this in favor of just using the new way.
   */
  dataSource?: 'clinicals-products' | 'drugs';

  /**
   * Label to appear above the field, optional.
   */
  label?: string;

  /**
   * Placeholder text, defaults to "Search by Drug"
   */
  placeholder?: string;

  /**
   * Used to filter down the drug list to any clients that are tracking those drugs, optional.
   */
  clients?: MultiSelectOption[];

  /**
   * Used to filter down the drug list to those with one of these classifications, optional.
   */
  classifications?: MultiSelectOption[];

  /**
   * The list of currently-selected options, required.
   */
  selected: MultiSelectOption[];

  /**
   * Event handler for updating the current selection.
   * @param selected the new value of the multi-select
   */
  onSelectionChange: (selected: MultiSelectOption[]) => void;

  /**
   * Whether to show chips below, defaults to false.
   */
  showChips?: boolean;
};

const EMPTY_ARRAY: MultiSelectOption[] = [];

/**
 * Lazy-load filter for Clinicals Products.
 */
export const ClinicalsProductFilter = (props: ClinicalsProductFilterProps) => {
  const {
    dataSource = 'clinicals-products',
    label,
    placeholder = 'Search by Drug',
    clients = EMPTY_ARRAY,
    classifications = EMPTY_ARRAY,
    selected,
    onSelectionChange,
    showChips = false
  } = props;

  const { getProductLookups } = useLookupsService();

  return (
    <LazyLoadMultiSelectList
      label={label}
      placeholder={placeholder}
      value={selected}
      onChange={onSelectionChange}
      showSelectedInline={showChips}
      dependencies={[clients, classifications, dataSource]}
      lazyLoadRequest={async (searchTerm, page, rpp) => {
        const result = await getProductLookups({
          query: searchTerm,
          page,
          rpp,
          classifications: classifications.map(it => it.id),
          trackedByClients: clients.map(it => it.id)
        });

        return result as PaginationResult<Lookup>;
      }}
    />
  );
};
