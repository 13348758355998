import { Box, FormField, MaskedInput, Spinner } from 'grommet';
import { WidthType } from 'grommet/utils';
import React, { KeyboardEventHandler, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { InlineEdit } from '@/components/form-controls';
import { MonospaceText } from '@/components/monospace-text';
import { BidRecommendation, BidRecommendationDrugPackaging } from '@/features/bid-analysis/types/bid-recommendation';

import * as BidRecommendationFunctions from './bid-recommendation.functions';
import { BidRecommendationPackagingFormInputs } from './bid-recommendation-packaging-form-inputs';

type NdcCellProps = {
  bidRecommendation: BidRecommendation;
  packaging: BidRecommendationDrugPackaging;
  editingFieldId?: string;
  savingFieldId?: string;
  onFocus: (fieldId: string) => void;
  onCancel: () => void;
  onSave: (
    bidRecommendation: BidRecommendation,
    row: BidRecommendationDrugPackaging,
    fieldId: string,
    form: BidRecommendationPackagingFormInputs) => void;
  cellWidth?: WidthType
};

export const NdcCell = (props: NdcCellProps) => {
  const {
    bidRecommendation,
    packaging,
    editingFieldId,
    savingFieldId,
    onFocus,
    onCancel,
    onSave,
    cellWidth = '8rem'
  } = props;

  const id = BidRecommendationFunctions.buildFieldId({
    bidRecommendationId: bidRecommendation.id,
    isHistorical: bidRecommendation.isHistorical,
    packagingId: packaging.id,
    fieldName: 'ndc'
  });

  const editing = editingFieldId === id;
  const saving = savingFieldId === id;

  const {
    control,
    getValues,
    formState: { errors, isDirty  },
    reset
  } = useForm<BidRecommendationPackagingFormInputs>({ mode: 'onChange', reValidateMode: 'onChange', defaultValues: { ndc: packaging.ndc } });

  useEffect(() => {
    reset({ ndc: packaging.ndc });
  }, [packaging.ndc]);

  const handleFocus = () => {
    onFocus(id);
  };

  const handleSave = async (form: BidRecommendationPackagingFormInputs) => {
    if (isDirty) {
      onSave(bidRecommendation, packaging, id, { ...form });
    } else {
      onCancel();
      reset();
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      handleSave(getValues());
    }

    if (event.key === 'Escape') {
      reset();
      onCancel();
    }
  };

  return (
    <Box width={cellWidth} fill="vertical">
      {!bidRecommendation.isHistorical && <MonospaceText>{packaging.ndc}</MonospaceText>}

      {bidRecommendation.isHistorical &&
        <Controller
          control={control}
          name="ndc"
          rules={{
            maxLength: 11,
            minLength: 11
          }}
          render={({ field: { value, onChange } }) => (
            <InlineEdit
              editing={editing}
              onFocus={handleFocus}
              readonlyComponent={<MonospaceText>{packaging.ndc ?? <>&mdash;</>}</MonospaceText>}
              inputComponent={
                <FormField
                  name="ndc"
                  htmlFor={id}
                  error={errors.supplementalRebate?.message}
                >
                  <MaskedInput
                    id={id}
                    value={value}
                    onChange={onChange}
                    onBlur={() => handleSave(getValues())}
                    onKeyDown={handleKeyDown}
                    name="ndc"
                    icon={saving ? <Spinner size="xsmall" color="brand" /> : undefined}
                    maxLength={11}
                    minLength={11}
                    reverse
                    mask={[
                      { regexp: /^[0-9]{0,11}$/ }
                    ]}
                  />
                </FormField>
              }
            />
          )}
        />
      }
    </Box>
  );
};
