import * as Headless from '@headlessui/react';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

type TabPanelsProps = (
  { children: React.ReactNode, className?: string } &
  (Omit<Headless.TabPanelsProps, 'as' | 'className'>)
  );

export const TabPanels = forwardRef((
  { children, className, ...props }: TabPanelsProps,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  return (
    <Headless.TabPanels
      className={clsx(className)}
      ref={ref}
      {...props}
    >
      {children}
    </Headless.TabPanels>
  );
});

type TabGroupProps = (
  { children: React.ReactNode, className?: string } &
  (Omit<Headless.TabGroupProps, 'as' | 'className'>)
  );

export const TabGroup = forwardRef((
  { children, className, ...props }: TabGroupProps,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  return (
    <Headless.TabGroup
      className={clsx(className)}
      ref={ref}
      {...props}
    >
      {children}
    </Headless.TabGroup>
  );
});

type TabListProps = (
  { children: React.ReactNode, className?: string } &
  (Omit<Headless.TabListProps, 'as' | 'className'>)
);

export const TabList = forwardRef((
  { children, className, ...props }: TabListProps,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  return (
    <Headless.TabList
      className={clsx(
        '-mb-px flex space-x-8 border-b border-gray-200',
        className
      )}
      ref={ref}
      {...props}
    >
      {children}
    </Headless.TabList>
  );
});



type TabProps = (
  { children: React.ReactNode, className?: string } &
  (Omit<Headless.TabProps, 'as' | 'className'>)
);

export const Tab = forwardRef((
  { children, className, ...props }: TabProps,
  ref: React.ForwardedRef<HTMLElement>
) => {
  return (
    <Headless.Tab
      {...props}
      className={clsx(
        [
          'whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-semibold text-gray-500 hover:border-gray-300 hover:text-gray-700',
          'disabled:opacity-50',
          'data-[selected]:border-primary-950 data-[selected]:text-primary-950'
        ],
        className,
      )}
      ref={ref}
    >
      <span className="flex items-center gap-2">
        {children}
      </span>
    </Headless.Tab>
  );
});
