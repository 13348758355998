import Link from '@tiptap/extension-link';
import { Placeholder } from '@tiptap/extension-placeholder';
import { EditorEvents, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import { useEffect } from 'react';

import { TextEditorOptions } from '@/components/text-editor/text-editor-config';

/**
 * Creates a configured Editor instance.
 */
export const useTextEditor = ({
  placeholder,
  onChange,
  value,
  editable = true,
  ...textEditorOptions
  }: TextEditorOptions) => {

  const editor = useEditor({
    content: value,
    editable,
    extensions: [
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      configurePlaceholder(placeholder),
      configureStarterKit(),
      configureLink(),
    ],
    onUpdate: ({ editor }: EditorEvents['update']) => {

      const html = editor.getHTML();
      onChange?.(html);
    },
    ...textEditorOptions
  });

  useEffect(() => {
    if (!editor) return;

    if (!value && editor.isEmpty) return;
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    editor.commands.setContent(value);
  }, [editor]);

  useEffect(() => {
    if (!editor) return;

    editor.setOptions({
      editable
    });
  }, [editor, editable]);

  return { editor };
};

/**
 * Configure the TipTap starter kit extension.
 * @see https://tiptap.dev/docs/editor/extensions/functionality/starterkit
 */
const configureStarterKit = () => StarterKit.configure({
  heading: {
    levels: [1, 2, 3]
  },
  code: false,
  codeBlock: false
});

/**
 * Configure the TipTap placeholder extension.
 * @see https://tiptap.dev/docs/editor/extensions/functionality/placeholder
 */
const configurePlaceholder = (placeholder: string) => Placeholder.configure({
  placeholder,
});

/**
 * Configure the TipTap link extension.
 * @see https://tiptap.dev/docs/editor/extensions/marks/link
 */
const configureLink = () => Link.configure({
  openOnClick: false,
});

