import { Box, Text } from 'grommet';
import React from 'react';

import { Chip } from '@/components/chip';
import { PdlStatus } from '@/features/coverage/types/pdl-status';

type PdlStatusCellProps = {
  pdlStatus?: PdlStatus;
};

export const PdlStatusCell = ({ pdlStatus }: PdlStatusCellProps) => {
  if (!pdlStatus) {
    return <Text>&mdash;</Text>;
  }
  switch (pdlStatus) {
    case PdlStatus.BLANK:
      return <Text>&mdash;</Text>;
    case PdlStatus.PREFERRED:
      return (
        <Chip
          chip={{
            label: (
              <Text style={{ width: 'max-content' }}>Preferred</Text>
            ),
            value: pdlStatus
          }}
          background="accent-1"
        />
      );

    case PdlStatus.NON_PREFERRED:
      return (
        <Chip
          chip={{
            label: (
              <Text style={{ width: 'max-content' }}>Non-Preferred</Text>
            ),
            value: pdlStatus
          }}
          background="error"
        />
      );

    case PdlStatus.NON_PDL:
      return (
        <Chip
          chip={{
            label: (
              <Text style={{ width: 'max-content' }}>Non-PDL</Text>
            ),
            value: pdlStatus
          }}
          background="light-3"
        />
      );

    case PdlStatus.NO_STATE_PDL:
      return (
        <Chip
          chip={{
            label: (
              <Text style={{ width: 'max-content' }}>No State PDL</Text>
            ),
            value: pdlStatus
          }}
          background="light-3"
        />
      );

    case PdlStatus.PREFERRED_NOT_LISTED:
      return (
        <Chip
          chip={{
            label: (
              <Box>
                <Text style={{ width: 'max-content' }}>Preferred</Text>
                <Text style={{ width: 'max-content' }}>(Not Listed)</Text>
              </Box>
            ),
            value: pdlStatus
          }}
          background="accent-1"
        />
      );

    case PdlStatus.NON_PREFERRED_NOT_LISTED:
      return (
        <Chip
          chip={{
            label: (
              <Box>
                <Text style={{ width: 'max-content' }}>Non-Preferred</Text>
                <Text style={{ width: 'max-content' }}>(Not Listed)</Text>
              </Box>
            ),
            value: pdlStatus
          }}
          background="error"
        />
      );
  }
};
