import { queryOptions, useQuery } from '@tanstack/react-query';

import { CoverageTagType } from '@/features/drugs/types/coverage-tag';
import { artiaProtectedApi } from '@/lib/api-client';
import { QueryConfig } from '@/lib/react-query';

export const makeCoverageTagTypesQueryKey = () => ['coverage-tag-types'];

const getCoverageTagTypes = async (): Promise<CoverageTagType[]> => {
  return await artiaProtectedApi.get('lookups/coverage-tag-types');
};

export const getCoverageTagTypesOptions = () => {
  return queryOptions({
    queryKey: makeCoverageTagTypesQueryKey(),
    queryFn: getCoverageTagTypes
  });
};

type UseCoverageTagTypesOptions = {
  queryConfig?: QueryConfig<typeof getCoverageTagTypesOptions>;
};

export const useCoverageTagTypes = ({ queryConfig }: UseCoverageTagTypesOptions = {}) => {
  return useQuery({
    ...getCoverageTagTypesOptions(),
    ...queryConfig
  });
};
