import { SortDirection, Sorter, sortNumbers, sortStrings } from '@shared/sorting';
import { Comparator } from '@shared/sorting';
import { get as _get } from 'lodash';

import { MarketBasketDrugPackaging } from '@/features/market-baskets/types/market-basket';
import { OverridableCalculatedValue } from '@/types/overridable-calculated-value';
import { OverridableCompendiumValue } from '@/types/overridable-compendium-value';
import { ProprietaryCalculatedValue } from '@/types/proprietary-calculated-value';

const makeDrugPackagingsSort = (
  key: string,
  comparator: Comparator<any> = sortStrings
): Sorter<MarketBasketDrugPackaging> => {
  return (items: MarketBasketDrugPackaging[], direction: SortDirection) => {
    return items.sort((left, right) => {
      return comparator(_get(left, key), _get(right, key), direction);
    });
  };
};

const toBrandOrGenericStatus = (packaging: MarketBasketDrugPackaging) => {
  // authorized generics come first
  if (packaging.licenseType === 'AG') {
    return 1;
  }
  // then brands
  if (packaging.brandGenericStatus === 'Brand') {
    return 2;
  }
  // otherwise, it's regular generic
  return 3;
};

const sortBrandOrGenericStatus: Sorter<MarketBasketDrugPackaging> = (items, direction) => {
  return items.sort((left, right) => {
    const leftValue = toBrandOrGenericStatus(left);
    const rightValue = toBrandOrGenericStatus(right);
    if (direction === 'desc') {
      return rightValue - leftValue;
    }
    return leftValue - rightValue;
  });
};

const makeDrugPackagingOverrideableCompendiumSort = (extractor: (packaging: MarketBasketDrugPackaging) => OverridableCompendiumValue): Sorter<MarketBasketDrugPackaging> => {
  return (items, direction) => {
    return items.sort((left, right) => {
      const leftValue = extractor(left)?.overrideValue ?? extractor(left)?.compendiumValue;
      const rightValue = extractor(right)?.overrideValue ?? extractor(right)?.compendiumValue;
      // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      return sortNumbers(leftValue, rightValue, direction);
    });
  };
};

const makeDrugPackagingOverrideableCalculatedSort = (extractor: (packaging: MarketBasketDrugPackaging) => OverridableCalculatedValue): Sorter<MarketBasketDrugPackaging> => {
  return (items, direction) => {
    return items.sort((left, right) => {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const leftValue = extractor(left)?.overrideValue ?? extractor(left)?.calculatedValue.value;
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const rightValue = extractor(right)?.overrideValue ?? extractor(right)?.calculatedValue.value;
      // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      return sortNumbers(leftValue, rightValue, direction);
    });
  };
};

const makeDrugPackagingProprietaryCalculatedSort = (extractor: (packaging: MarketBasketDrugPackaging) => ProprietaryCalculatedValue): Sorter<MarketBasketDrugPackaging> => {
  return (items, direction) => {
    return items.sort((left, right) => {
      const leftValue = extractor(left)?.proprietaryValue?.value ?? extractor(left)?.calculatedValue?.value;
      const rightValue = extractor(right)?.proprietaryValue?.value ?? extractor(right)?.calculatedValue?.value;
      // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      return sortNumbers(leftValue, rightValue, direction);
    });
  };
};


export type MarketBasketDrugPackagingSorterKeys = |
  'ndc' |
  'productName' |
  'brandGenericStatus' |
  'amp' |
  'name' |
  'currentWacPrice' |
  'currentWacUnitPrice' |
  'acaFederalUpperLimitUnitPrice' |
  'acaWeightedAverageManufacturersPrice' |
  'totalUra' |
  'uraPercentWac' |
  'estimatedUroa' |
  'default';

type DrugPackagingsSorters = {
  [key in MarketBasketDrugPackagingSorterKeys]: Sorter<MarketBasketDrugPackaging>;
};

export const drugPackagingsSorters: DrugPackagingsSorters = {
  'ndc': makeDrugPackagingsSort('ndc'),
  'productName': makeDrugPackagingsSort('productName'),
  'brandGenericStatus': sortBrandOrGenericStatus,
  'amp': makeDrugPackagingOverrideableCalculatedSort(packaging => packaging.amp),
  'name': makeDrugPackagingsSort('name'),
  // @ts-expect-error TS(2322): Type 'OverridableCompendiumValue | undefined' is n... Remove this comment to see the full error message
  'currentWacPrice': makeDrugPackagingOverrideableCompendiumSort(packaging => packaging.currentWacPrice),
  // @ts-expect-error TS(2322): Type 'OverridableCompendiumValue | undefined' is n... Remove this comment to see the full error message
  'currentWacUnitPrice': makeDrugPackagingOverrideableCompendiumSort(packaging => packaging.currentWacUnitPrice),
  'acaFederalUpperLimitUnitPrice': makeDrugPackagingsSort('acaFederalUpperLimitUnitPrice', sortNumbers),
  'acaWeightedAverageManufacturersPrice': makeDrugPackagingsSort('acaWeightedAverageManufacturersPrice', sortNumbers),
  'totalUra': makeDrugPackagingOverrideableCalculatedSort(packaging => packaging.totalUra),
  'uraPercentWac': makeDrugPackagingProprietaryCalculatedSort(packaging => packaging.uraPercentWac),
  'estimatedUroa': makeDrugPackagingOverrideableCalculatedSort(packaging => packaging.uroa),
  'default': (items: MarketBasketDrugPackaging[], _: SortDirection) => (items)
};
