import { UseStorage, useStorage } from './use-storage';

export type UseSessionStorage<T> = UseStorage<T>;

/**
 * Utility hook for using Session Storage
 * @param key the key for the item in storage
 * @param initialValue either the initial value OR a function that lets you override the value in session storage
 */
export const useSessionStorage = <T>(key: string, initialValue?: T | ((sessionStorageValue: T | null) => T)): UseSessionStorage<T> => {
  return useStorage(key, initialValue, window.sessionStorage);
};
