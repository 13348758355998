import { useAsync } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import React, { useId } from 'react';

import { useStatesService } from '@/features/state-reports/api';
import { CustomStateReportForm, useStatesProvider } from '@/features/state-reports/components';

const NewReportPage = () => {
  const formId = useId();
  const { createNewCustomReport } = useStatesService();
  const createRequest = useAsync(createNewCustomReport);

  const { handleError } = useErrorHandler();
  const { success } = useNotifications();

  const { reloadColumnGroups } = useStatesProvider();

  return (
    <CustomStateReportForm
      id={formId}
      heading="New Report"
      onSubmit={async ({ name, columns }) => {
        try {
          await createRequest.execute(name, columns);
          success({
            title: 'Custom state report created',
            autoClose: true
          });
          reloadColumnGroups();
        } catch (error) {
          // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
          handleError(error, {
            title: 'Failed to create custom state report',
            autoClose: false
          });

          throw error;
        }
      }}
    />
  );
};

export default NewReportPage;
