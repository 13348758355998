import { useSuspenseQuery } from '@tanstack/react-query';

import { useClientService } from '@/features/clients/api/use-clients-service';

export const makeClientUserQueryKey = (clientId: number | string, userId: number | string) => ['client', clientId, 'user', userId];

export const useClientUser = (clientId: number, userId: number) => {
  const { getClientUser } = useClientService();

  const { data, error, isFetching, dataUpdatedAt } = useSuspenseQuery({
    queryKey: makeClientUserQueryKey(clientId, userId),
    queryFn: async () => getClientUser(clientId, userId),
  });

  if (error && !isFetching) throw error;

  return { data, dataUpdatedAt };
};
