export const formatPercent = (number: number, minimumFractionDigits = 2, maximumFractionDigits = 5): string => {
  return number.toLocaleString(
    undefined,
    {
      style: 'percent',
      minimumFractionDigits,
      maximumFractionDigits
    }
  );
};
