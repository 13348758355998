import {
  DrugNetCostComparison,
  NdcsByStateAndProduct,
  NetCostComparisonDrugTotal,
  NetCostComparisonRow,
  NetCostComparisonRowState,
  NetCostComparisonStateSummary,
  NetCostComparisonTotal,
  PackagingNetCostComparison,
  SUPPRESSABLE_PROPERTIES,
  TagNetCostComparison
} from '@/features/bid-analysis/types/net-cost-comparison';
import { State } from '@/types/state';

import { getRowState } from './use-net-cost-comparison-row-expansion';

const stateHeaderRow = ({ state }: NetCostComparisonStateSummary): NetCostComparisonRow => ({
  rowType: 'state',
  key: state.code,
  groupKey: state.code,
  state
});

const stateTotalRow = ({ state, total }: NetCostComparisonStateSummary): NetCostComparisonRow => ({
  ...total,
  key: `${state.code}-total`,
  groupKey: `${state.code}-total`,
  rowType: 'state-total',
  state
});

const drugRows = (state: State, drugs: DrugNetCostComparison[], ndcs: NdcsByStateAndProduct): NetCostComparisonRow[] => {
  return drugs.flatMap(drug => {
    const drugRow: NetCostComparisonRow = {
      ...drug,
      rowType: 'drug',
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      key: `${state.code}-${drug.drug.id}`,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      groupKey: `${state.code}-${drug.drug.id}`,
      drug: drug.drug,
      state
    };

    const suppressedValues: (keyof NetCostComparisonRow)[] = [];
    if (drug.isSuppressionUsed) {
      Object.keys(drugRow)
        // @ts-expect-error TS(2769): No overload matches this call.
        .filter((key: keyof NetCostComparisonRow) => SUPPRESSABLE_PROPERTIES.includes(key))
        // @ts-expect-error TS(2345): Argument of type '(key: keyof NetCostComparisonRow... Remove this comment to see the full error message
        .forEach((key: keyof NetCostComparisonRow) => {
          const value = drugRow[key];
          if (!value) {
            suppressedValues.push(key);
          }
        });
    }

    drugRow.suppressedValues = suppressedValues;

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const expansionState = getRowState(ndcs, { stateCode: state.code, productId: drug.drug.id });

    if (!expansionState || expansionState.state !== NetCostComparisonRowState.EXPANDED) return [drugRow];

    return [
      drugRow,
      // these are unassociated NDCs. tagged NDCs will be rendered by tagRows
      // @ts-expect-error TS(2345): Argument of type 'PackagingNetCostComparison[] | u... Remove this comment to see the full error message
      ...ndcRows(state, drug, expansionState.packagings),
      // @ts-expect-error TS(2345): Argument of type 'TagNetCostComparison[] | undefin... Remove this comment to see the full error message
      ...tagRows(state, drug, ndcs, expansionState.tags),
    ];
  });
};

const tagRows = (state: State, drug: DrugNetCostComparison, ndcs: NdcsByStateAndProduct, tags: TagNetCostComparison[]): NetCostComparisonRow[] => {
  return tags.flatMap(tag => {
    const tagRow: NetCostComparisonRow = {
      ...tag,
      rowType: 'tag',
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      key: `${state.code}-${drug.drug.id}-${tag.tag.id}`,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      groupKey: `${state.code}-${drug.drug.id}`,
      drug: drug.drug,
      state,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      inheritedClinicalsInformation: tag.coverages.length === 0 && drug.coverages.length > 0
    };

    const suppressedValues: (keyof NetCostComparisonRow)[] = [];
    if (tag.isSuppressionUsed) {
      Object.keys(tagRow)
        // @ts-expect-error TS(2769): No overload matches this call.
        .filter((key: keyof NetCostComparisonRow) => SUPPRESSABLE_PROPERTIES.includes(key))
        // @ts-expect-error TS(2345): Argument of type '(key: keyof NetCostComparisonRow... Remove this comment to see the full error message
        .forEach((key: keyof NetCostComparisonRow) => {
          const value = tagRow[key];
          if (!value) {
            suppressedValues.push(key);
          }
        });
    }

    tagRow.suppressedValues = suppressedValues;

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const expansionState = getRowState(ndcs, { stateCode: state.code, productId: drug.drug.id, tagId: tag.tag.id });

    if (!expansionState || expansionState.state !== NetCostComparisonRowState.EXPANDED) return [tagRow];

    return [
      tagRow,
      // @ts-expect-error TS(2345): Argument of type 'PackagingNetCostComparison[] | u... Remove this comment to see the full error message
      ...ndcRows(state, drug, expansionState.packagings, tag)
    ];
  });
};

const ndcRows = (state: State, drug: DrugNetCostComparison, packagings: PackagingNetCostComparison[], tag?: TagNetCostComparison): NetCostComparisonRow[] => {
  return packagings.map(ndc => ({
    ...ndc,
    rowType: 'ndc',
    key: tag
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      ? `${state.code}-${drug.drug.id}-${tag.tag.id}-${ndc.ndc}`
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      : `${state.code}-${drug.drug.id}-${ndc.ndc}`,
    groupKey: tag
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      ? `${state.code}-${drug.drug.id}-${tag.tag.id}`
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      : `${state.code}-${drug.drug.id}`,
    ndc: ndc.ndc,
    ndcDescription: ndc.ndcDescription,
    drug: drug.drug,
    tag: tag?.tag,
    state,
    coverages: ndc.coverages ?? drug.coverages,
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    inheritedClinicalsInformation: ndc.coverages.length === 0 && (tag?.coverages?.length > 0 || drug.coverages.length > 0)
  }));
};

const drugTotalRows = (drugTotals: NetCostComparisonDrugTotal[]): NetCostComparisonRow[] => {
  // @ts-expect-error TS(2322): Type '{ state: null; unitsReimbursed?: number | un... Remove this comment to see the full error message
  return drugTotals.map(drugTotal => ({
    rowType: 'drug-total',
    key: `${drugTotal.drug.label}-total`,
    groupKey: `${drugTotal.drug.label}-total`,
    drug: drugTotal.drug,
    ...drugTotal.total,
    state: null
  }));
};

const grandTotalRow = (grandTotal: NetCostComparisonTotal): NetCostComparisonRow => ({
  rowType: 'grand-total',
  key: 'grand-total',
  groupKey: 'grand-total',
  ...grandTotal,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'State | und... Remove this comment to see the full error message
  state: null
});

export {
  drugRows,
  drugTotalRows,
  grandTotalRow,
  stateHeaderRow,
  stateTotalRow
};
