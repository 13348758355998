import { useAuthorization } from '@shared/auth/use-authorization';
import { Anchor, Text } from 'grommet';
import React from 'react';
import { Link } from 'react-router-dom';

import { MonospaceText } from '@/components/monospace-text';
import { DrugPackaging } from '@/features/drugs/types/drug-packaging';
import { Drug } from '@/products';

type NdcCellProps = {
  product: Drug;
  packaging: DrugPackaging;
}

const NdcCell = ({ product, packaging }: NdcCellProps) => {
  const { checkPolicies } = useAuthorization();

  const canManagePackagings = checkPolicies(['canManageDrugPackagings']);

  return (
    canManagePackagings
      ? (
        <Link to={`/drugs/${product.id}/packagings/${packaging.ndc}`} style={{ textDecoration: 'none' }}>
          <Anchor as={MonospaceText}>
            {packaging.ndc}
          </Anchor>
        </Link>
      )
      : (
        <Text>
          {packaging.ndc}
        </Text>
      )
  );
};

export default NdcCell;
