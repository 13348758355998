type Validator = (value: any) => string | undefined;

export const required = (message = 'Value is required'): Validator => {
  return (value: any) => {
    if (typeof value === 'string') {
      return value.length > 0 ? undefined : message;
    }

    return value ? undefined : message;
  };
};

export const validateIf = (condition: boolean, validator: Validator): Validator => {
  return condition ? validator : () => undefined;
};

export const maxLength = (max: number, message = `Value must be less than ${max} characters`): Validator => {
  return (value: string) => {
    return value?.length > max ? message : undefined;
  };
};
