import { Layer, Text } from 'grommet';
import React from 'react';

import { Prompt } from '@/components/prompt';
import { useDeleteCoverageTag } from '@/features/drugs/api/use-delete-coverage-tag';
import { CoverageTag } from '@/features/drugs/types/coverage-tag';
import { Drug } from '@/products';

type Props = {
  coverageTag: CoverageTag,
  drug: Drug,
  onClose: () => void;
}
const DeleteCoverageTagPrompt = ({ coverageTag, drug, onClose }: Props) => {
  const {
    mutate: deleteCoverageTag,
    isPending,
  } = useDeleteCoverageTag();

  const handleConfirm = () => {
    deleteCoverageTag(
      { drugId: drug.id, coverageTagId: coverageTag.id },
      {
        onSuccess: () => {
          onClose();
        }
      }
    );
  };

  return (
    <Layer onClickOutside={onClose}>
      <Prompt
        header="Delete Coverage Tag"
        actions={[
          { name: 'Cancel', onClick: onClose, variant: 'outlined' },
          { name: 'Delete', onClick: handleConfirm, disabled: () => isPending, variant: 'danger' },
        ]}
      >
        <Text>
          Are you sure you want to delete <strong>{coverageTag.name}</strong>?
        </Text>
      </Prompt>
    </Layer>
  );
};

export default DeleteCoverageTagPrompt;
