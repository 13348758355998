import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { LiaEnvelope, LiaEnvelopeOpen, LiaPlusSolid, LiaTrashSolid } from 'react-icons/lia';

import { Timestamp } from '@/components/date-time';
import { Button } from '@/components-new/button';
import { Checkbox, CheckboxGroup } from '@/components-new/checkbox';
import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/components-new/dialog';
import { PageTitleRow } from '@/components-new/page-title-row';
import {
  Pagination,
  PaginationGap,
  PaginationList,
  PaginationNext,
  PaginationPage,
  PaginationPrevious
} from '@/components-new/pagination';
import { Seo } from '@/components-new/seo';
import { updateNotificationStatus } from '@/features/notifications/api/update-notification-status';
import { useNotifications } from '@/features/notifications/api/use-notifications';
import { Notification } from '@/features/notifications/types/notification';
import { NotificationStatus } from '@/features/notifications/types/notification-status';
import { queryClient } from '@/lib/react-query';

const NotificationsPage = () => {
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState<Notification[]>([]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentNotification, setCurrentNotification] = useState<Notification | undefined>(undefined);
  const [readStateAction, setReadStateAction] = useState<'read' | 'unread'>('read');

  const { data: notifications } = useNotifications();

  const notificationsMutation = useMutation({
    mutationFn: (data: NotificationStatus[]) => updateNotificationStatus(data),
    // onMutate: () => {
    //   var cachedData =
    // },
    onError: () => {
      // TODO: reset read or deleted status...
    },
    onSettled: async () => {
      return await queryClient.invalidateQueries({ queryKey: ['user-notifications'] });
    },
  });

  const canManageNotifications = true; // TODO: Permissions

  useLayoutEffect(() => {
    const isIndeterminate = selectedNotifications.length > 0 && selectedNotifications.length < notifications.length;
    setChecked(selectedNotifications.length === notifications.length);
    setIndeterminate(isIndeterminate);
  }, [selectedNotifications]);

  const toggleAll = () => {
    setSelectedNotifications(checked || indeterminate ? [] : notifications);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  const markSelectedAsRead = () => {
    selectedNotifications.forEach(notification => notification.read = true);
    const readStatuses = selectedNotifications.map((selectedNotification) => {
      return { id: selectedNotification.id, read: true, delete: false };
    });
    notificationsMutation.mutate(readStatuses, { onSuccess: () => setSelectedNotifications([]) });
  };

  const markSelectedAsUnread = () => {
    selectedNotifications.forEach(notification => notification.read = false);
    const readStatuses = selectedNotifications.map((selectedNotification) => {
      return { id: selectedNotification.id, read: false, delete: false };
    });
    notificationsMutation.mutate(readStatuses);
    setSelectedNotifications([]);
  };

  const deleteSelected = () => {
    //TODO
    const readStatuses = selectedNotifications.map((selectedNotification) => {
      return { id: selectedNotification.id, read: selectedNotification.read, delete: true };
    });
    //notificationsMutation.mutate();
    setSelectedNotifications([]);
  };

  useEffect(() => {
    const markUnread = selectedNotifications.some(notification => notification.read);
    setReadStateAction(markUnread ? 'unread' : 'read');
  }, [selectedNotifications]);

  return (
    <>
      <Seo title="Notifications"/>
      <PageTitleRow title="Notifications">
        {canManageNotifications && (
          <Button
            color="secondary"
            aria-label="New Post"
            href="/notifications/new"
          >
            <LiaPlusSolid />
            Add New
          </Button>
        )}
      </PageTitleRow>

      <div className="relative mt-8">
        {selectedNotifications.length > 0 && (
          <div className="absolute left-16 top-0 flex h-12 items-center space-x-3 bg-white sm:left-16">
            {readStateAction == 'unread' && (
              <Button outline title="Mark as unread" onClick={markSelectedAsUnread}>
                <LiaEnvelope className="size-5"/>
              </Button>
            )}
            {readStateAction == 'read' && (
              <Button outline title="Mark as read" onClick={markSelectedAsRead}>
                <LiaEnvelopeOpen className="size-5"/>
              </Button>
            )}
            <Button outline title="Delete" onClick={deleteSelected}>
              <LiaTrashSolid className="size-5"/>
            </Button>
          </div>
        )}

        <table className="min-w-full table-fixed divide-y divide-gray-300">
          <thead>
          <tr>
            <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
              <CheckboxGroup>
                <Checkbox
                  color="secondary"
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={toggleAll}
                />
              </CheckboxGroup>
            </th>
            <th scope="col" className="min-w-48 py-3.5 pr-3 text-left text-sm font-normal text-zinc-700">
              Message
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-zinc-700">
              Sent At
            </th>
          </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
          {notifications.map((notification) => (
            <tr key={notification.id} className={selectedNotifications.includes(notification) ? 'bg-gray-50' : undefined}>
              <td className="relative px-7 sm:w-12 sm:px-6">
                {selectedNotifications.includes(notification) && (
                  <div className="absolute inset-y-0 left-0 w-0.5 bg-primary-950"/>
                )}
                <CheckboxGroup>
                  <Checkbox
                    color="secondary"
                    checked={selectedNotifications.includes(notification)}
                    value={notification.id.toString()}
                    onChange={(e) =>
                      setSelectedNotifications(
                        e
                          ? [...selectedNotifications, notification]
                          : selectedNotifications.filter((it) => it !== notification),
                      )
                    }
                  />
                </CheckboxGroup>
              </td>
              <td
                className={clsx(
                  'cursor-pointer whitespace-nowrap py-4 pr-3 text-sm font-medium',
                  selectedNotifications.includes(notification) ? 'text-primary-950' : 'text-gray-900',
                )}
                onClick={() => {
                  setCurrentNotification(notification);
                  setDialogOpen(true);
                  notificationsMutation.mutate([{ id: notification.id, read: true, delete: false }]);
                  notification.read = true;
                }}
              >
                <p className={clsx(
                  notification.read ? 'font-normal' : 'font-bold'
                )}>{notification.title}</p>
                <p dangerouslySetInnerHTML={{ __html: notification.abstract }}/>
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <Timestamp wordBreak="break-word" color="text-strong" value={notification.sentAt}/>
              </td>
            </tr>
          ))}
          </tbody>
        </table>

        <Pagination className="mt-2">
          <PaginationPrevious/>
          <PaginationList>
            <PaginationPage href="?page=1">1</PaginationPage>
            <PaginationPage href="?page=2">2</PaginationPage>
            <PaginationPage href="?page=3" current>3</PaginationPage>
            <PaginationPage href="?page=4">4</PaginationPage>
            <PaginationGap/>
            <PaginationPage href="?page=9">9</PaginationPage>
            <PaginationPage href="?page=10">10</PaginationPage>
          </PaginationList>
          <PaginationNext href="?page=4"/>
        </Pagination>
      </div>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} size="5xl">
        <DialogTitle>{currentNotification?.title}</DialogTitle>
        <DialogBody className="text-sm/6 text-zinc-900 dark:text-white">
          {currentNotification?.content && (
            <div dangerouslySetInnerHTML={{ __html: currentNotification.content }}/>
          )}
        </DialogBody>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationsPage;
