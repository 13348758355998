import { Box, Text } from 'grommet';
import React from 'react';

import { ArtiaButtonLink } from '@/components/artia-button';
import { CalculationDetails } from '@/types/calculation-details';
import { ProprietaryCalculatedValue } from '@/types/proprietary-calculated-value';
import { formatPercent } from '@/utils/formatting';

type UraPercentWacCellProps = {
  uraPercentWac: ProprietaryCalculatedValue
  onViewCalculationDetails: (calculationDetails: CalculationDetails) => void;
};

export const UraPercentWacCell = ({ uraPercentWac, onViewCalculationDetails }: UraPercentWacCellProps) => {
  const handleViewCalculatedCalculationDetails = () => {
    // @ts-expect-error TS(2345): Argument of type 'CalculationDetails | undefined' ... Remove this comment to see the full error message
    onViewCalculationDetails(uraPercentWac.calculatedValue);
  };

  const handleViewProprietaryCalculationDetails = () => {
    // @ts-expect-error TS(2345): Argument of type 'CalculationDetails | undefined' ... Remove this comment to see the full error message
    onViewCalculationDetails(uraPercentWac.proprietaryValue);
  };

  return (
    <Box width={{ min: '8rem' }}>
      {uraPercentWac?.proprietaryValue?.value != null &&
        <ArtiaButtonLink onClick={() => handleViewProprietaryCalculationDetails()}>
          <Text>
            {formatPercent(uraPercentWac.proprietaryValue?.value, 2, 2)}
          </Text>
        </ArtiaButtonLink>
      }

      {uraPercentWac?.proprietaryValue?.value == null && uraPercentWac?.calculatedValue?.value != null &&
        <ArtiaButtonLink onClick={() => handleViewCalculatedCalculationDetails()}>
          <Text>
            {formatPercent(uraPercentWac.calculatedValue?.value, 2, 2)}
          </Text>
        </ArtiaButtonLink>
      }
    </Box>
  );
};
