import { Box, Button } from 'grommet';
import { View } from 'grommet-icons';
import React from 'react';

type ClinicalsChangeLogActionsCellProps = {
  onViewSnapshot: () => void;
};

export const ClinicalsChangeLogActionsCell = (props: ClinicalsChangeLogActionsCellProps) => {
  const { onViewSnapshot } = props;

  const handleViewSnapshot = () => onViewSnapshot();

  return (
    <Box direction="row" align="center">
      <Button
        tip="View Snapshot"
        a11yTitle="View Snapshot"
        hoverIndicator="background"
        margin="none"
        icon={<View color="accent-1" size="medium" />}
        onClick={handleViewSnapshot}
      />
    </Box>
  );
};
