import { useReducer } from 'react';

import {
  NdcsByStateAndProduct,
  NetCostComparisonRowExpansion,
  NetCostComparisonRowExpansionArgs,
  NetCostComparisonRowState,
  PackagingNetCostComparison,
  TagNetCostComparison
} from '@/features/bid-analysis/types/net-cost-comparison';

/**
 * State hook for managing the Net Cost Comparison Row Expansion
 */
const useNetCostComparisonRowExpansion = () => {
  return useReducer(rowExpansionStateReducer, {});
};

const getKey = ({ stateCode, productId, tagId }: NetCostComparisonRowExpansionArgs) => tagId
  ? `${stateCode}:${productId}:${tagId}`
  : `${stateCode}:${productId}`;

const getRowState = (
  ndcsByStateAndProduct: NdcsByStateAndProduct,
  args: NetCostComparisonRowExpansionArgs
): NetCostComparisonRowExpansion | null => {
  return ndcsByStateAndProduct[getKey(args)];
};

type RowStateAction = {
  type: 'expand' | 'collapse' | 'fetch' | 'update' | 'update-packaging';
  tags?: TagNetCostComparison[];
  packagings?: PackagingNetCostComparison[];
} & NetCostComparisonRowExpansionArgs;

const rowExpansionStateReducer = (state: NdcsByStateAndProduct, action: RowStateAction): NdcsByStateAndProduct => {
  const { type, tags, packagings, stateCode, productId, tagId } = action;
  const key = getKey({ stateCode, productId, tagId });
  const keyWithoutTag = getKey({ stateCode, productId });
  const rowType = tagId ? 'tag' : 'product';

  switch (type) {
    case 'update': {
      return {
        ...state,
        [key]: {
          ...(state[key] ?? {}),
          state: NetCostComparisonRowState.EXPANDED,
          tags,
          packagings
        }
      };
    }
    case 'expand':
      return {
        ...state,
        [key]: {
          ...state[key], // assume that this only gets called if there's already data here
          state: NetCostComparisonRowState.EXPANDED
        }
      };
    case 'collapse': {
      switch (rowType) {
        case 'tag': {
          // if there's no data here, assume it's still collapsed and do nothing
          if (!state[key]) return state;

          return {
            ...state,
            [key]: {
              ...state[key],
              state: NetCostComparisonRowState.COLLAPSED
            }
          };
        }
        case 'product': {
          const expandedChildRows = Object.keys(state).filter(it => it.startsWith(keyWithoutTag));

          if (expandedChildRows.length === 0) return state;

          const result = { ...state };

          for (const expandedChildRow of expandedChildRows) {
            result[expandedChildRow].state = NetCostComparisonRowState.COLLAPSED;
          }

          return result;
        }
        default:
          // rowType should be `never` if you hover over it
          throw new Error(`Expected product or tag, got ${rowType} instead!`);
      }
    }
    case 'fetch':
      return {
        ...state,
        [key]: {
          state: NetCostComparisonRowState.LOADING
        }
      };
    case 'update-packaging': {
      const result = {
        ...state
      };

      if (tags && state[keyWithoutTag]) {
        result[keyWithoutTag] = {
          ...result[keyWithoutTag],
          tags
        };
      }
      // @ts-expect-error TS(2548): Type 'PackagingNetCostComparison[] | undefined' is... Remove this comment to see the full error message
      const [updatedNdc] = packagings;

      for (const rowKey in result) {
        const value = result[rowKey];

        if (!value || !value.packagings) continue;

        result[rowKey] = {
          ...value,
          packagings: value.packagings.map(it => {
            if (it.bidAnalysisNetCostComparisonPackagingId === updatedNdc.bidAnalysisNetCostComparisonPackagingId) {
              return {
                ...updatedNdc,
                coverages: it.coverages
              };
            }

            return it;
          })
        };
      }

      return result;
    }
  }
};

export { getRowState, useNetCostComparisonRowExpansion };
