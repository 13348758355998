import { Lookup } from '@/types/lookup';

export type CreateMarketBasketFormInputs = {
  name: string;
  client: Lookup;
  currentCpiu: string;
  currentCpiuDate: string;
  marketDetailsFile: File;
};

export const defaultCreateMarketBasketForm: CreateMarketBasketFormInputs = {
  name: '',
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Lookup<numb... Remove this comment to see the full error message
  client: null,
  currentCpiu: '',
  currentCpiuDate: '',
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'File'.
  marketDetailsFile: null
};

export type EditMarketBasketFormInputs = {
  name: string;
  currentCpiu: string;
  currentCpiuDate: string;
};

export const defaultEditMarketBasketForm: EditMarketBasketFormInputs = {
  name: '',
  currentCpiu: '',
  currentCpiuDate: ''
};
