import { Claim } from '@shared/auth/claims';
import { User } from 'oidc-client-ts';

export enum Role {
  Administrator = 'Administrator',
  ArtiaStaff = 'Artia Staff',
  FinancialsManager = 'Financials Manager',
  FinancialsAnalyst = 'Financials Analyst',
  ClinicalsManager = 'Clinicals Manager',
  ClinicalsAnalyst = 'Clinicals Analyst',
}

export const getUserRoles = (user: User): Role[] => {
  if (!user || !user.profile) return [];

  return (user.profile[Claim.Roles] ?? []) as Role[];
};

export const hasRole = (user: User, role: Role) => {
  return getUserRoles(user).includes(role);
};

export const hasAnyOfRoles = (user: User, roles: Role[]) => {
  const userHasRole = (role: Role) => hasRole(user, role);
  return roles.some(userHasRole);
};
