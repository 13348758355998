import { FileDownload, RequestOptions, ResultSet, useHttpClient } from '@shared/http';

import { BidAnalysis, BidAnalysisBase } from '@/features/bid-analysis/types/bid-analysis';
import {
  BID_ANALYSIS_STATUS_CLOSED,
  BID_ANALYSIS_STATUS_OPEN,
  BidAnalysisStatusType
} from '@/features/bid-analysis/types/bid-analysis-status-type';
import { GetBidAnalysesRequest } from '@/features/bid-analysis/types/get-bid-analyses-request';
import { useFileDownload } from '@/hooks/use-file-download';

type CreateBidAnalysisRequest = {
  name: string;
  states: { state: string; benefit: string }[],
  pool?: string;
  marketBasketId: number;
  drugId: number;
  year: string;
  bidDueOn: string;
  reviewOn: string;
  contractTermStartsOn: string;
  contractTermEndsOn: string;
  periods: string[]
};

type UpdateBidAnalysisRequest = {
  name: string;
  bidDueOn: string;
  reviewOn: string;
  contractTermStartsOn: string;
  contractTermEndsOn: string;
  notes: string;
};

type CreateNewClinicalsProductRequest = {
  productName: string;
  classificationId: number;
  stateCode?: string;
};

const useBidAnalysisService = () => {
  const httpClient = useHttpClient();

  const getBidAnalysis = (id: number): Promise<BidAnalysis> => {
    return httpClient.get(`bid-analyses/${id}`);
  };

  const getBidAnalyses = (request?: GetBidAnalysesRequest): Promise<ResultSet<BidAnalysisBase>> => {
    const statusMap = {
      [BID_ANALYSIS_STATUS_OPEN]: 'open',
      [BID_ANALYSIS_STATUS_CLOSED]: 'closed',
      default: 'open'
    };

    const status = statusMap[request?.status ?? 'default'];
    return httpClient.get('bid-analyses', { params: { status } });
  };

  const downloadAttachment = useFileDownload();

  const createBidAnalysis = (request: CreateBidAnalysisRequest): Promise<any> => {
    const options: RequestOptions<any> = {
      data: request
    };

    return httpClient.post('bid-analyses', options);
  };

  const deleteBidAnalysis = (id: number): Promise<void> => {
    return httpClient.delete(`bid-analyses/${id}`);
  };

  const updateBidAnalysis = (bidAnalysisId: number, request: UpdateBidAnalysisRequest): Promise<any> => {
    if (request.bidDueOn === '') {
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
      request.bidDueOn = null;
    }

    if (request.reviewOn === '') {
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
      request.reviewOn = null;
    }

    const options: RequestOptions<any> = {
      data: request
    };

    return httpClient.put(`bid-analyses/${bidAnalysisId}`, options);
  };

  const uploadAttachment = (file: File, bidAnalysisId: number): Promise<any> => {
    const data = new FormData();

    data.append('Upload', file);

    const options: RequestOptions<any> = {
      data
    };

    return httpClient.post(`bid-analyses/${bidAnalysisId}/attachments`, options);
  };

  const refreshMarketDetails = (bidAnalysisId: number): Promise<any> => {
    return httpClient.post(`bid-analyses/${bidAnalysisId}/refresh`);
  };

  const exportBidRecommendations = (bidAnalysisId: number): Promise<FileDownload> => {
    return httpClient.download('POST', `bid-analyses/${bidAnalysisId}/recommendations/export`);
  };

  const updateBidAnalysisStatus = (bidAnalysisId: number, status: BidAnalysisStatusType ) => {
    return httpClient.put(`bid-analyses/${bidAnalysisId}/status`, { data: { status } });
  };

  const reconcileDrug = (bidAnalysisId: number, drugId: number, clinicalsProductId: number) => {
    return httpClient.post(`bid-analyses/${bidAnalysisId}/drugs/${drugId}/reconcile`, {
      data: clinicalsProductId
    });
  };

  const reconcileDrugWithNewClinicalsProduct = (bidAnalysisId: number, drugId: number, request: CreateNewClinicalsProductRequest) => {
    return httpClient.post(`bid-analyses/${bidAnalysisId}/drugs/${drugId}/reconcile-new`, {
      data: request
    });
  };

  const reconcileNdc = (bidAnalysisId: number, ndc: string, clinicalsProductId: number, stateCode: string) => {
    return httpClient.post(`bid-analyses/${bidAnalysisId}/packagings/${ndc}/reconcile`, {
      data: { clinicalsProductId, stateCode }
    });
  };

  const reconcileNdcWithNewClinicalsProduct = (bidAnalysisId: number, ndc: string, request: CreateNewClinicalsProductRequest) => {
    return httpClient.post(`bid-analyses/${bidAnalysisId}/packagings/${ndc}/reconcile-new`, {
      data: request
    });
  };

  return {
    getBidAnalysis,
    getBidAnalyses,
    deleteBidAnalysis,
    downloadAttachment,
    createBidAnalysis,
    updateBidAnalysis,
    uploadAttachment,
    refreshMarketDetails,
    exportBidRecommendations,
    updateBidAnalysisStatus,
    reconcileDrug,
    reconcileDrugWithNewClinicalsProduct,
    reconcileNdc,
    reconcileNdcWithNewClinicalsProduct
  };
};

export { CreateBidAnalysisRequest, UpdateBidAnalysisRequest, useBidAnalysisService };
