/**
 * Provides theme overrides for Grommet components.
 */
import { grommet, ThemeType } from 'grommet';
import { deepMerge } from 'grommet/utils';

import {
  ACCENT_1,
  ACCENT_2,
  ACCENT_WEAK,
  BRAND,
  CONTRAST_1,
  DARK_1,
  DARK_2,
  DARK_3,
  LIGHT_1,
  LIGHT_2,
  LIGHT_3,
  STATUS_WARNING_WEAK
} from './colors';

export default deepMerge(
  grommet,
  {
  text: {
    medium: {
      size: '0.875rem',
      height: '1.25rem'
    }
  },
  anchor: {
    color: 'contrast-1',
    textDecoration: 'underline',
    hover: {
      extend: `color: ${BRAND}; text-decoration-thickness: 2px`
    },
    fontWeight: 500,
  },
  button: {
    padding: {
      horizontal: '0.625rem',
      vertical: '0.375rem'
    },
    default: {
      color: 'accent-1',
      font: {
        weight: 500
      },
      padding: {
        horizontal: '0.625rem',
        vertical: '0.375rem'
      }
    },
    size: {
      small: {
        border: {
          radius: '0.5rem',
        },
        pad: {
          horizontal: '0.625rem',
          vertical: '0.375rem'
        },
      },
      medium: {
        border: {
          radius: '0.5rem',
        },
        pad: {
          horizontal: '0.625rem',
          vertical: '0.375rem'
        }
      }
    },
    hover: {
      color: 'brand',
      border: {
        color: 'brand'
      },
      primary: {
        background: 'brand',
        color: '#fff'
      }
    },
    primary: {
      background: 'accent-1',
      color: '#fff',
      border: {
        color: 'accent-1'
      },
      font: {
        weight: 500
      },
      padding: {
        horizontal: '0.625rem',
        vertical: '0.375rem'
      }
    },
    secondary: {
      background: '#fff',
      color: 'accent-1',
      border: {
        color: 'accent-1',
        width: '1px'
      },
      font: {
        weight: 500
      },
      padding: {
        horizontal: '0.625rem',
        vertical: '0.375rem'
      }
    },
  },
  card: {
    container: {
      elevation: 'none',
      round: {
        size: 'xsmall'
      }
    }
  },
  dataTable: {
    pinned: {
      header: {
        background: { color: 'brand', opacity: 100 }
      }
    },
    header: {
      pad: '1rem',
      extend: 'font-family: GT-Super-Text-Book, serif',
      font: {
        size: '0.87rem',
        weight: 'bold'
      }
    },
    body: {
      extend: 'font-family: degular-text, sans-serif, font-size: 0.875rem !important',
    }
  },
  radioButton: {
    border: {
      width: '1px'
    },
    size: '1rem',
  },
  checkBox: {
    border: {
      width: '1px',
    },
    check: {
      radius: '0.3125rem',
      thickness: '2px'
    },
    size: '1rem'
  },
  formField: {
    border: {
      style: 'solid',
      color: 'border',
      size: '1px',
      side: 'all',
    },
    content: {
      pad: {
        horizontal: '0.625rem',
        vertical: '0.375rem'
      }
    },
    round: '0.5rem',
    checkBox: {
      pad: 'none'
    },
    label: {
      requiredIndicator: true,
      weight: 'bold',
      margin: {
        horizontal: 'none'
      }
    }
  },
  global: {
    edgeSize: {
      small: '0.75rem'
    },
    colors: {
      brand: BRAND,
      'accent-1': ACCENT_1,
      'accent-2': ACCENT_2,
      'accent-weak': ACCENT_WEAK,
      'contrast-1': CONTRAST_1,
      focus: ACCENT_1,
      'light-1': LIGHT_1,
      'light-2': LIGHT_2,
      'light-3': LIGHT_3,
      'dark-1': DARK_1,
      'dark-2': DARK_2,
      'dark-3': DARK_3,
      placeholder: 'rgb(0, 0, 0, 0.4)',
      'warning': STATUS_WARNING_WEAK,
      'error': '#fd5157',
    },
    drop: {
      background: 'white'
    },
    font: {
      family: 'degular-text, sans-serif',
      size: '0.875rem',
      height: '1.25rem'
    },
    input: {
      weight: 400,
      padding: {
        vertical: '0.375rem'
      },
      font: {
        height: '1.25rem'
      },
      extend: '--tw-ring-color: none !important;'
    },
    control: {
      border: {
        color: 'border',
        radius: 'calc(0.5rem - 1px)',
        width: '1px'
      },
    },
  },
  heading: {
    color: 'brand',
    font: {
      family: 'GT-Super-Text-Book, serif'
    }
  },
  icon: {
    size: {
      medium: '1rem'
    }
  },
  notification: {
    container: {
      round: 'small',
      pad: { horizontal: 'small', vertical: 'small' },
      elevation: 'medium',
      background: {
        color: 'background-front',
      }
    }
  },
  pagination: {
    container: {
      background: '#fff',
      border: {
        color: '#D9D9D9',
        size: 'xsmall',
        side: 'bottom'
      },
      pad: 'small',
      justify: 'center',
      alignSelf: 'center',
      extend: 'align-self: center'
    },
    button: {
      color: 'accent-1',
      default: {
        font: {
          size: '10px'
        }
      },
      size: {
        medium: {
          font: '0.75rem',
          pad: {
            horizontal: '0.625rem',
            vertical: '0.375rem'
          }
        } as any,
      },
      active: {
        color: '#fff',
        background: 'accent-1',
        font: {
          weight: 500
        }
      },
      hover: {
        color: '#fff',
        background: 'brand',
      }
    }
  },
  select: {
    control: {
      extend: 'background: white; border-radius: calc(0.5rem - 1px); border: none;'
    }
  },
  tip: {
    content: {
      background: 'white',
      elevation: 'none',
      border: {
        size: '1px',
        color: 'light-3',
      }
    }
  },
  table: {
    header: {
      background: 'brand',
      pad: '0.5rem',
      border: 'horizontal',
    },
    body: {
      background: '#fff',
      pad: '1rem',
      border: 'bottom',
    }
  },
  tabs: {
    header: {
      border: {
        side: 'bottom',
        size: 'xsmall',
        color: 'brand'
      }
    }
  },
  tab: {
    active: {
      color: 'light-1',
      background: 'brand'
    },
    background: 'light-1',
    border: undefined,
    color: 'contrast-1',
    margin: undefined,
    pad: {
      top: 'xsmall',
      horizontal: 'medium'
    },
    extend: 'border-radius: 5px 5px 0 0; font-weight: 700;'
  },
  maskedInput: {
    extend: 'background: white; border: none; :focus { box-shadow: none }'
  },
  textInput: {
    extend: 'background: white; color: black; box-shadow: none'
  },
  textArea: {
    extend: 'background: white; border: none; border-radius: calc(0.5rem - 1px); box-shadow: none'
  }
} satisfies Partial<ThemeType>);
