import { useAsync } from '@shared/async';
import React from 'react';

import { DeleteConfirmationDialog } from '@/components/delete-confirmation-dialog';
import { useProductsService } from '@/products/api/use-products-service';

type DeleteProductDialogProps = {
  open: boolean;
  productId: number;
  onClose: () => void;
  onDeleteComplete: () => void;
};

export const DeleteProductDialog = (props: DeleteProductDialogProps) => {
  const {
    open,
    productId,
    onClose,
    onDeleteComplete
  } = props;

  const { deleteProduct } = useProductsService();
  const deleteAsync = useAsync(deleteProduct);

  return (
    <DeleteConfirmationDialog
      open={open}
      itemName="Product"
      onCancel={onClose}
      onDeleteComplete={onDeleteComplete}
      request={deleteAsync}
      parameters={[productId]}
    />
  );
};
