import { Box, Card, CardBody, Form, FormField, TextInput } from 'grommet';
import React from 'react';

import { Button, ButtonLoadingIcon } from '@/components-new/button';

import { useMedicaidDatasetForm } from './medicaid-dataset-form.provider';

export const MedicaidDatasetForm = ({ onSubmit, onCancel, submitting }: { onSubmit: () => void, onCancel: () => void, submitting: boolean }) => {
  const { form, updateForm } = useMedicaidDatasetForm();

  return (
    <Box direction="row" justify="center">
      <Card justify="center" width="902px" style={{ background: '#fff' }}>
        <CardBody pad="medium">
          <Box pad={{ vertical: 'medium' }}>
            <Form
              value={form}
              onChange={updateForm}
              validate="blur"
              messages={{ required: 'ID is required.' }}
            >
              <Box direction="column" gap="medium">
                <FormField
                  required
                  name="id"
                  htmlFor="id"
                  label="ID"
                >
                  <TextInput
                    id="id"
                    name="id"
                    maxLength={36}
                    placeholder="99315a95-37ac-4eee-946a-3c523b4c481e"
                  />
                </FormField>
              </Box>
            </Form>

            <Box direction="row" justify="end" gap="small">
              <Box>
                <Button onClick={onCancel} outline>Cancel</Button>
              </Box>
              <Button
                color="secondary"
                disabled={submitting || !form.id}
                onClick={onSubmit}>
                {submitting ? (
                  <>
                    <ButtonLoadingIcon/>
                    Saving...
                  </>
                ) : (
                  <>Save</>
                )}
              </Button>
            </Box>
          </Box>
        </CardBody>
      </Card>
    </Box>
  );
};
