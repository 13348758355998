import { flexRender, RowData, Table as TableInstance } from '@tanstack/react-table';
import React from 'react';

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components-new/table';

type DataTableProps<T extends RowData> = {
  table: TableInstance<T>
}

/**
 * Renders a managed data table.
 */
const DataTable = <T extends RowData>({
  table
}: DataTableProps<T>) => {
  return (
    <Table>
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHeader
                colSpan={header.colSpan}
                key={header.id}
                className={header.column.columnDef.meta?.className}>
                {
                  header.isPlaceholder ?
                    null :
                    flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )
                }
              </TableHeader>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map(row => (
          <TableRow key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id} className={cell.column.columnDef.meta?.className}>
                {
                  flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                  )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export { DataTable };
