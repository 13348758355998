import { isNil } from 'lodash';
import markdownit from 'markdown-it';
import TurndownService from 'turndown';


/**
 * Creates a Markdown Serializer object.
 *
 * @example
 * const serializer = makeMarkdownSerializer();
 * const markdown = '# Heading';
 * const html = serializer.deserialize(markdown);
 * console.log(html); // Output: '<h1>Heading</h1>'
 *
 * const serializedMarkdown = serializer.serialize(html);
 * console.log(serializedMarkdown); // Output: '# Heading'
 */
export const makeMarkdownSerializer = () => {
  const markdownParser = markdownit();
  const markdownSerializer = new TurndownService({
    headingStyle: 'atx',
  });

  const serialize = (html = '') => {
    if (isNil(html)) return '';

    return markdownSerializer.turndown(html);
  };

  const deserialize = (markdown = '') => {
    if (isNil(markdown)) return '';

    return markdownParser.render(markdown);
  };

  return {
    serialize,
    deserialize,
  };
};

export const markdownSerializer = makeMarkdownSerializer();
