/**
 * Generates a simple random id. This may not always be unique, but it's good enough for our purposes.
 */
export const makeId = (): string => {
  let Id = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  for ( let i = 0; i < 12; i++ ) {
    Id += characters.charAt(Math.floor(Math.random() * 36));
  }
  return Id;
};
