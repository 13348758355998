import { MaskedInput } from 'grommet';
import React, { ChangeEventHandler, CSSProperties, forwardRef, KeyboardEventHandler } from 'react';

export type NumericTextInputProps = {
  id?: string;
  name?: string;
  onChange?: ChangeEventHandler;
  onBlur?: (event: React.FocusEvent) => any;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  value?: string;
  icon?: React.JSX.Element;
  reverse?: boolean;
  maxWholePlaces?: number;
  maxDecimalPlaces?: number;
  style?: CSSProperties;
  readOnly?: boolean;
  disabled?: boolean;
  placeholder?: string;
};

export const NumericTextInput = forwardRef<HTMLInputElement, NumericTextInputProps>(
  (props, ref) => {
    const {
      maxWholePlaces = 10,
      maxDecimalPlaces = 6,
      onChange,
      placeholder
    } = props;

    const regexp = new RegExp(`^(?!0\\d)\\d{1,${maxWholePlaces}}\\.?$|^(?!0\\d)\\d{1,${maxWholePlaces}}(?:\\.\\d{0,${maxDecimalPlaces}})?$`);

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      if (isNaN(parseFloat(event.target.value))) {
        event.target.value = '';
        // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        onChange(event);
        return;
      }

      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onChange(event);
    };

    return (
      <MaskedInput
        {...props}
        onChange={handleChange}
        mask={[ { regexp } ]}
        ref={ref}
        style={{ border: 'none', ...props.style }}
        placeholder={placeholder}
      />
    );
  }
);
