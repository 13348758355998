import { useHttpClient } from '@shared/http';
import { PaginationRequest, PaginationResult } from '@shared/pagination';

import { DrugUtilizationPeriod } from '@/features/drug-utilization';
import { Benefit } from '@/types/benefit';
import { CoverageTagLookup, coverageTagToLookup } from '@/types/coverage-tag-lookup';
import { Lookup } from '@/types/lookup';
import { NestedLookup } from '@/types/nested-lookup';
import { Pool } from '@/types/pool';
import { State } from '@/types/state';
import { buildQueryString } from '@/utils/filtering-functions';

export const useLookupsService = () => {
  const httpClient = useHttpClient();

  /**
   * Returns a collection of lookups.
   *
   * @param query The query (client name) to match against.
   * @param assignedOnly True if the results should be limited to clients the
   * user is assigned to, false otherwise.
   */
  const getClientLookups = ({ query: q, assignedOnly = false, ...rest }: ClientsLookupQuery): Promise<PaginationResult<Lookup> | Lookup[]> => {
    return httpClient.get(`lookups/clients${buildQueryString({ q, 'assigned-only': assignedOnly, ...rest })}`);
  };

  const getMarketBasketLookups = (query?: string, assignedOnly = false): Promise<NestedLookup[]> => {
    let url = `lookups/market-baskets?assigned-only=${assignedOnly}`;

    if (query) {
      url = `${url}&q=${query}`;
    }

    return httpClient.get(url);
  };

  const getPaTypeLookups = (): Promise<Lookup[]> => {
    return httpClient.get('lookups/pa-types');
  };

  const getProductLookups = ({ query: q, ...rest }: ProductsLookupQuery = {}): Promise<PaginationResult<Lookup>> => {
    return httpClient.get(`lookups/products${buildQueryString({ q, ...rest })}`);
  };

  const getStates = (): Promise<State[]> => {
    return httpClient.get('states');
  };

  const getClassifications = ({ query: q, ... rest }: ClassificationsLookupQuery = {}): Promise<PaginationResult<Lookup> | Lookup[]> => {
    const url = `lookups/classifications${buildQueryString({ q, ...rest })}`;
    return httpClient.get(url);
  };

  const getPools = (): Promise<Pool[]> => {
    return httpClient.get('states/pools');
  };

  const getBenefits = (): Promise<Benefit[]> => {
    return httpClient.get('benefits');
  };

  const getDrugUtilizationYears = (): Promise<Lookup[]> => {
    return httpClient.get('lookups/drug-utilization-years');
  };

  const getDrugUtilizationPeriods = (): Promise<DrugUtilizationPeriod[]> => {
    return httpClient.get('lookups/drug-utilization-periods');
  };

  const getAnalystLookups = async ({ query: q, ...rest }: AnalystsLookupQuery = {}): Promise<PaginationResult<Lookup> | Lookup[]> => {
    return httpClient.get(`lookups/analysts${buildQueryString({ q, ...rest })}`);
  };

  const getClinicalsChangeTypes = (): Promise<Lookup[]> => {
    return httpClient.get('lookups/clinicals-change-types');
  };

  const getBoardMeetingTypes = (): Promise<Lookup[]> => {
    return httpClient.get('lookups/board-meeting-types');
  };

  const getSupportedTimezones = (): Promise<Lookup<string>[]> => {
    return httpClient.get('lookups/supported-timezones');
  };

  const getCoverageTags = async ({ query: q, ...rest }: CoverageTagsLookupQuery): Promise<PaginationResult<Lookup>> => {
    const result = await httpClient.get<PaginationResult<CoverageTagLookup>, unknown>(
      `lookups/coverage-tags${buildQueryString({ q, ...rest })}`
    );

    return {
      ...result,
      items: result.items.map(coverageTagToLookup)
    };
  };

  return {
    getAnalystLookups,
    getClientLookups,
    getDrugUtilizationPeriods,
    getDrugUtilizationYears,
    getMarketBasketLookups,
    getPaTypeLookups,
    getProductLookups,
    getStates,
    getPools,
    getClassifications,
    getBenefits,
    getClinicalsChangeTypes,
    getBoardMeetingTypes,
    getSupportedTimezones,
    getCoverageTags
  };
};

type CoverageTagsLookupQuery = {
  query?: string;
  defaultOnly?: boolean;
  drugId?: number;
  removeIfCarvedOutInState?: string;
  removeIfPdlExemptInState?: string;
} & PaginationRequest;

type ProductsLookupQuery = {
  query?: string;
  classifications?: number[];
  trackedByClients?: number[];
} & PaginationRequest;

type AnalystsLookupQuery = {
  query?: string;
} & PaginationRequest;

type ClientsLookupQuery = {
  query?: string;
  assignedOnly?: boolean;
} & PaginationRequest;

type ClassificationsLookupQuery = {
  query?: string;
  trackedByClients?: number[];
  removeIfCarvedOutInState?: string;
  removeIfPdlExemptInState?: string;
} & PaginationRequest;
