import { ProtectedRoute } from '@shared/auth';
import { useErrorHandler } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { OverlayLoader } from '@/components/loading';
import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Seo } from '@/components-new/seo';
import { useProductsService } from '@/products/api/use-products-service';
import { ProductDetailsProvider, useProductDetails } from '@/products/product-details.provider';
import { ProductForm } from '@/products/product-form';
import { ProductFormProvider, ProductFormValue, useProductForm } from '@/products/product-form.provider';
import { useParams } from '@/router';

const EditProductPageContainer = () => {
  const { handleError } = useErrorHandler();
  const navigate = useNavigate();
  const { success } = useNotifications();
  const { id } = useParams('/drugs/:id/edit');
  const { product, loadProduct, loadingProduct } = useProductDetails();
  const { form, updateForm, resetForm } = useProductForm();
  const { updateProduct, uploadDrugPackagingsFile } = useProductsService();

  const [submitting, setSubmitting] = useState(false);

  React.useEffect(() => {
    if (id === null) return;

    loadProduct(parseInt(id));

    // TODO: revisit missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  React.useEffect(() => {
    if (!product) {
      return;
    }

    const initialFormValue: ProductFormValue = {
      name: product.name,
      // @ts-expect-error TS(2322): Type '{ id: number; label: string; } | null' is no... Remove this comment to see the full error message
      client: product.client != null ? {
        id: product.client.id,
        label: product.client.name
      } : null,
      // @ts-expect-error TS(2322): Type '{ value: number; name: string; } | null' is ... Remove this comment to see the full error message
      classification: product.classification ? { value: product.classification.id, name: product.classification.name } : null,
      drugPackagingsFile: []
    };

    updateForm(initialFormValue);
    // TODO: revisit missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const handleSubmit = async () => {
    setSubmitting(true);

    try {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      await updateProduct(product.id, form);

      const triggerSuccessMessage = () => success({ title: 'Product Updated', message: 'Successfully updated Product.', autoClose: true });
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const triggerNavigation = () => navigate(`/drugs/${product.id}`);

      const drugPackagingsFile = form.drugPackagingsFile[0];
      if (!drugPackagingsFile) {
        triggerSuccessMessage();
        triggerNavigation();
        return Promise.resolve();
      }

      try {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        await uploadDrugPackagingsFile(drugPackagingsFile, product.id);

        triggerSuccessMessage();
      } catch (fileUploadError) {
        // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        handleError(fileUploadError, {
          title: 'Gold Standard Upload Failed',
          message: 'Product was updated successfully, but the Gold Standard file could not be parsed. Please try importing the data again.',
          autoClose: false
        }, 'warn');
      } finally {
        triggerNavigation();
      }

      resetForm();
    } catch (ex) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      handleError(ex, {
        title: 'Failed to Update',
        message: 'We encountered an unexpected error while updating the Product. Please try again or contact an administrator.',
        autoClose: false
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    navigate('..');
  };

  if (loadingProduct) return <OverlayLoader />;

  return (
    <>
      <Seo title="Edit Drug" />
      <Breadcrumbs breadcrumbs={[
        { label: 'Drugs', url: '/drugs' },
        { label: product?.name ?? '', url: `/drugs/${product?.id}` },
        { label: 'Edit', url: `/drugs/${product?.id}/edit` }
      ]} />
      <PageTitleRow title="Edit Drug" />
      <ProductForm onSubmit={handleSubmit} onCancel={handleCancel} submitting={submitting} />
    </>
  );
};

const EditProductPage = () => {
  return (
      <ProtectedRoute policies={['canManageDrugs']}>
        <ProductDetailsProvider>
          <ProductFormProvider>
            <EditProductPageContainer />
          </ProductFormProvider>
        </ProductDetailsProvider>
      </ProtectedRoute>
  );
};

export default EditProductPage;
