import { useHttpClient } from '@shared/http';

/**
 * A function that provides file download functionality using the provided httpClient.
 * @returns A function that accepts a string URL and initiates the file download request.
 */
export const useFileDownload = () => {
  const httpClient = useHttpClient();

  return (url: string) => {
    // prevent duplicate attachment URL fragments from being appended
    // TODO: probably not ideal to solve this problem here, should do it on the backend where Url.Action is called
    const baseUrl = import.meta.env.VITE_BASE_API_URL;
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const lastSlashIndex = baseUrl.lastIndexOf('/');
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const finalPathFragment = baseUrl.substring(lastSlashIndex);
    let downloadUrl = url;
    if (downloadUrl.startsWith(finalPathFragment)) {
      downloadUrl = downloadUrl.substring(finalPathFragment.length);
    }
    // if there's a leading slash, remove it
    if (downloadUrl.startsWith('/')) {
      downloadUrl = downloadUrl.substring(1);
    }
    return httpClient.download('GET', downloadUrl);
  };
};
