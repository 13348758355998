import { useSessionStorage } from '@/hooks/use-session-storage';
import { UseStorage } from '@/hooks/use-storage';

/**
 * Utility hook for managing filters that should be pre-populated from Session Storage
 * @param key the key for the item in storage (will be prepended with 'filters:').
 * @param initialValue either the initial value OR a function that lets you override the value in session storage
 */
export const useFilters = <TFilterData>(
  key: string,
  initialValue?: TFilterData | ((sessionStorageValue: TFilterData | null) => TFilterData)
): UseStorage<TFilterData> => {
  return useSessionStorage(`filters:${key}`, initialValue);
};
