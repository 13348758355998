import { useErrorHandler } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import { useState } from 'react';

import { useBidAnalysisService } from '@/features/bid-analysis/api/use-bid-analysis-service';
import {
  BID_ANALYSIS_STATUS_CLOSED,
  BID_ANALYSIS_STATUS_OPEN,
  BidAnalysisStatusType
} from '@/features/bid-analysis/types/bid-analysis-status-type';

type UseUpdateBidAnalysisStatus = {
  /**
   * Make request to update bid analysis status.
   */
  updateBidAnalysisStatus: (bidAnalysisId: number, status: BidAnalysisStatusType) => Promise<void>,
  /**
   * Whether the bid analysis status is updating or not.
   */
  updatingBidAnalysisStatus: boolean
}

/**
 * Handles updating the status of a Bid Analysis.
 */
export const useUpdateBidAnalysisStatus = (): UseUpdateBidAnalysisStatus => {
  const { updateBidAnalysisStatus: updateBidAnalysisStatusHttp } = useBidAnalysisService();
  const [ updatingBidAnalysisStatus, setUpdatingBidAnalysisStatus ] = useState(false);
  const { handleError } = useErrorHandler();
  const {
    dismiss: dismissNotification,
    loading: showLoadingNotification,
    success: showSuccessNotification
  } = useNotifications();

  const updateBidAnalysisStatus = async (bidAnalysisId: number, status: BidAnalysisStatusType ) => {
    const statusTranslationMap = {
      [BID_ANALYSIS_STATUS_OPEN]: {
        loading: 'Reopening Bid Analyses...',
        success: 'Bid Analyses reopened successfully.',
        error: 'Failed to reopen Bid Analyses.'
      },
      [BID_ANALYSIS_STATUS_CLOSED]: {
        loading: 'Closing Bid Analyses...',
        success: 'Bid Analyses closed successfully.',
        error: 'Failed to close Bid Analyses.'
      }
    };

    const statusTranslation = statusTranslationMap[status];

    const processingNotification = showLoadingNotification({ title: 'Updating', message: statusTranslation.loading });
    setUpdatingBidAnalysisStatus(true);
    try {
      await updateBidAnalysisStatusHttp(bidAnalysisId, status);
      showSuccessNotification({ title: 'Success', message: statusTranslation.success, autoClose: true });
    } catch (ex) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      handleError(ex, {
        title: statusTranslation.error,
        message: 'We encountered an unexpected error while updating Bid Analyses. Please try again or contact an administrator.',
        autoClose: false
      });
    } finally {
      dismissNotification(processingNotification.id);
      setUpdatingBidAnalysisStatus(false);
    }
  };

  return {
    updateBidAnalysisStatus,
    updatingBidAnalysisStatus
  };
};
