import { RequestOptions, useHttpClient } from '@shared/http';

import { MedicaidDataset } from '@/features/medicaid-datasets';
import { RefreshMedicaidDatasetRequest } from '@/features/medicaid-datasets/types/refresh-medicaid-dataset-request';

import { MedicaidDatasetFormValue } from '../medicaid-dataset-form.provider';

export type CreateMedicaidDatasetRequest = {
  id: string;
};

export const useMedicaidDatasetsService = () => {
  const httpClient = useHttpClient();

  const getMedicaidDatasets = (): Promise<MedicaidDataset[]> => {
    return httpClient.get('medicaid/datasets');
  };

  const createMedicaidDataset = ({ id }: MedicaidDatasetFormValue): Promise<any> => {
    const data: CreateMedicaidDatasetRequest = { id };

    const options: RequestOptions<any> = {
      data
    };

    return httpClient.post('medicaid/datasets', options);
  };

  const deleteMedicaidDataset = (id: string): Promise<any> => {
    return httpClient.delete(`medicaid/datasets/${id}`);
  };

  const refreshMedicaidDataset = (id: string, { importMethod, quarters, states }: RefreshMedicaidDatasetRequest): Promise<any> => {
    return httpClient.post(`medicaid/datasets/${id}/refresh`, {
      data: {
        importMethod,
        quarters,
        states
      }
    });
  };

  return {
    getMedicaidDatasets,
    createMedicaidDataset,
    deleteMedicaidDataset,
    refreshMedicaidDataset
  };
};
