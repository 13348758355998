import { ValidationError } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import { NotificationOptions } from '@shared/notifications/notification-options';
import React from 'react';

import { bugsnagClient } from '@/lib/bugsnag/bugsnag-client';

import { ErrorHandlerContext, ErrorSeverity } from './error-handler.context';

export const ErrorHandlerProvider = ({ children }: ErrorHandlerProviderProps) => {
  const { warn, error } = useNotifications();

  const handleError = (ex: Error, options: NotificationOptions, severity: ErrorSeverity = 'error') => {
    if (ex instanceof ValidationError) {
      if (severity === 'warn') {
        warn({
          ...options,
          title: ex.title,
          message: ex.message
        });
      } else {
        error({
          ...options,
          title: ex.title,
          message: ex.message
        });
      }
    } else {
      bugsnagClient.notify(ex);
      if (severity === 'warn') {
        warn(options);
      } else {
        error(options);
      }
    }
  };

  return (
    <ErrorHandlerContext.Provider value={{ handleError }}>
      {children}
    </ErrorHandlerContext.Provider>
  );
};

type ErrorHandlerProviderProps = {
  children?: React.ReactNode
};
