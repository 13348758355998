import { UseAsyncStatus } from '@shared/async';
import { PaginationResult } from '@shared/pagination';
import { Box } from 'grommet';
import { Add } from 'grommet-icons';
import React, { useEffect, useId } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ArtiaButton } from '@/components/artia-button';
import { Busy } from '@/components/busy';
import { LazyLoadMultiSelectList } from '@/components/form-controls';
import { useLookupsService } from '@/hooks/use-lookups-service';
import { Lookup } from '@/types/lookup';

import { McoCarveOutMethod } from '../../../types';
import { ProviderMode } from './mco-carve-out-provider';

type AddCarveOutFormProps = {
  mode: ProviderMode;
  carveOutMethod: McoCarveOutMethod;
  requestStatus:  UseAsyncStatus;
  onSubmit: (carveOutMethod: McoCarveOutMethod, associationIdList: Lookup[]) => Promise<void>;
  stateCode: string;
};

export const AddMcoCarveOutForm = ({ mode, carveOutMethod, requestStatus, onSubmit, stateCode }: AddCarveOutFormProps) => {
  const formId = useId();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, submitCount, isSubmitSuccessful }
  } = useForm<AddCarveOutFormData>({
    defaultValues: {
      associationIdList: []
    }
  });

  // reset the form once the submission request is complete
  useEffect(() => {
    if (requestStatus === UseAsyncStatus.Success) {
      reset({ associationIdList: [] });
    }
  }, [requestStatus]);

  const handleSubmission = async ({ associationIdList }: AddCarveOutFormData) => {
    await onSubmit(carveOutMethod, associationIdList);
  };

  let requiredMessage = '';
  let placeholder = '';
  switch (carveOutMethod) {
    case McoCarveOutMethod.DRUG:
      requiredMessage = 'Drug is required';
      placeholder = 'Select a Drug';
      break;
    case McoCarveOutMethod.CLASS:
      requiredMessage = 'Class is required';
      placeholder = 'Select a Class';
      break;
  }

  const { getCoverageTags, getClassifications } = useLookupsService();

  return (
    <Box id={formId} as="form" gap="medium" direction="row" onSubmit={handleSubmit(handleSubmission)}>
      <Controller
        name="associationIdList"
        control={control}
        rules={{
          validate: {
            required: (value) => value.length === 0 ? requiredMessage : true
          }
        }}
        render={({ field, fieldState: { error } }) => (
          <div style={{ flexGrow: '2' }}>
            <LazyLoadMultiSelectList
              {...field}
              required
              placeholder={placeholder}
              error={error?.message}
              dependencies={[stateCode, submitCount > 0 && isSubmitSuccessful]}
              lazyLoadRequest={async (searchTerm, page, rpp) => {
                const parameters = {
                  query: searchTerm,
                  page,
                  rpp,
                  removeIfCarvedOutInState: mode === ProviderMode.MCO_CARVE_OUTS ? stateCode : undefined,
                  removeIfPdlExemptInState: mode === ProviderMode.PDL_EXEMPTIONS ? stateCode : undefined
                };

                switch (carveOutMethod) {
                  case McoCarveOutMethod.DRUG: {
                    return await getCoverageTags(parameters);
                  }
                  case McoCarveOutMethod.CLASS: {
                    return await getClassifications(parameters) as PaginationResult<Lookup>;
                  }
                }
              }}
            />
          </div>
        )}
      />
      <div>
        <ArtiaButton
          type="submit"
          form={formId}
          reverse
          icon={<Add />}
          label={<Busy busy={false} content="Add" />}
          disabled={!isValid}
          size="large"
        />
      </div>
    </Box>
  );
};

type AddCarveOutFormData = {
  associationIdList: Lookup[];
};
