import { PdlStatus } from '@/features/coverage/types/pdl-status';
import { Lookup } from '@/types/lookup';
import { State } from '@/types/state';

export type NetCostComparisonDrugTotal = {
  drug: Lookup;
  total: NetCostComparisonTotal;
};

export type NetCostComparisonRoot = {
  states: NetCostComparisonStateSummary[];
  drugTotals: { drug: Lookup, total: NetCostComparisonTotal }[];
  grandTotal: NetCostComparisonTotal;
};

export type NetCostComparisonStateSummary = {
  state: State;
  drugs: DrugNetCostComparison[];
  total: NetCostComparisonTotal;
};

export type NetCostComparisonTotal = {
  unitsReimbursed?: number;
  numberOfPrescriptions?: number;
  totalAmountReimbursed?: number;
  totalWac?: number;
  totalUra?: number;
  totalPharmacyReimbursement?: number;
};

export type DrugNetCostComparison = {
  drug?: Lookup;
} & NetCostComparison;

export type TagNetCostComparison = {
  type: 'tag';
  tag: Lookup;
} & NetCostComparison;

export type PackagingNetCostComparison = {
  type: 'ndc';
  ndc: string;
  ndcDescription: string;
} & NetCostComparison;

export type NetCostComparisonClinicalsProduct = {
  stateProfileId: number;
  name: string;
  classification: Lookup;
  pdlStatusDate?: string;
  pdlStatusEffectiveDate?: string;
  pdlStatus?: PdlStatus;
  paTypes?: Lookup[];
  stepTherapyCount?: number;
  hasAdditionalSteps?: boolean;
  paCriteriaDescription?: string;
  paCriteriaSourceUrl?: string;
  notes?: string;
};

export type NetCostComparison = {
  bidAnalysisNetCostComparisonPackagingId?: number;
  isSuppressionUsed?: boolean;
  unitsReimbursed?: number;
  numberOfPrescriptions?: number;
  unitsPerRx?: number;
  marketShare?: number;
  totalAmountReimbursed?: number;
  totalWac?: number;
  totalUra?: number;
  pharmacyReimbursementUnit?: number;
  totalPharmacyReimbursement?: number;
  estimatedUraPercentWac?: number;
  brandGenericStatus?: string;
  acaFulUnitPrice?: number;
  acaWampUnitPrice?: number;
  wacPerRx?: number;
  pharmacyReimbursementPerRx?: number;
  estimatedUraPerRx?: number;
  estimatedUroaPerRx?: number;
  estimatedNetCostPerRx?: number;
  coverages?: NetCostComparisonClinicalsProduct[];
};

export type NetCostComparisonRowType = 'ndc' | 'drug' | 'state' | 'state-total' | 'drug-total' | 'grand-total' | 'tag';

export type NetCostComparisonRow = {
  rowType: NetCostComparisonRowType;
  key: string;
  groupKey?: string;
  state?: State;
  drug?: Lookup;
  tag?: Lookup;
  ndc?: string;
  ndcDescription?: string;
  suppressedValues?: (keyof NetCostComparisonRow)[];
  inheritedClinicalsInformation?: boolean;
} & NetCostComparison;

export type NetCostComparisonRowExpansion = {
  state: NetCostComparisonRowState;
  tags?: TagNetCostComparison[];
  packagings?: PackagingNetCostComparison[];
};

export type NdcsByStateAndProduct = Record<string, NetCostComparisonRowExpansion>;

export type NetCostComparisonRowExpansionArgs = {
  stateCode: string;
  productId: number;
  tagId?: number;
};

export enum NetCostComparisonRowState {
  COLLAPSED,
  LOADING,
  EXPANDED
}

export const SUPPRESSABLE_PROPERTIES: (keyof NetCostComparisonRow)[] = [
  'unitsReimbursed',
  'numberOfPrescriptions',
  'totalAmountReimbursed'
];
