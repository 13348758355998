import { CoverageTag } from '@/features/drugs/types/coverage-tag';

import { ChangeLogReason } from './change-log-reason';

export type ClinicalsChangeLog = {
  audits: { userId: number, name: string, updatedAt: string }[];
  state: string;
  product: string;
  reason: ChangeLogReason;
  updatedProperties?: ChangelogProperties;
  coverageTags: CoverageTag[];
};

type ChangelogProperties = {
  pdlStatus: ChangelogProperty<string>;
  pdlStatusDate: ChangelogProperty<string>;
  pdlStatusEffectiveDate: ChangelogProperty<string>;
  paTypes: ChangelogProperty<string[]>;
  stepTherapyCount: ChangelogProperty<number>;
  hasAdditionalSteps: ChangelogProperty<boolean>;
  paCriteriaDescription: ChangelogProperty<string>;
  paCriteriaSourceUrl: ChangelogProperty<string>;
  notes: ChangelogProperty<string>;
  hasAutoPa: ChangelogProperty<boolean>;
};

export type ChangelogProperty<T> = {
  isUpdated: boolean;
  old?: T;
  new?: T;
};

export const getUpdatedProperties = (updated: ChangelogProperties): string[] => {
  const updatedProperties: string[] = [];

  if (!updated) return [];

  if (updated.pdlStatus.isUpdated) {
    updatedProperties.push('PDL Status');
  }

  if (updated.pdlStatusDate.isUpdated) {
    updatedProperties.push('PDL Status Date');
  }

  if (updated.pdlStatusEffectiveDate.isUpdated) {
    updatedProperties.push('PDL Status Effective Date');
  }

  if (updated.paTypes.isUpdated) {
    updatedProperties.push('PA Types');
  }

  if (updated.stepTherapyCount.isUpdated || updated.hasAdditionalSteps.isUpdated) {
    updatedProperties.push('Step Therapy');
  }

  if (updated.paCriteriaDescription.isUpdated) {
    updatedProperties.push('PA Criteria Description');
  }

  if (updated.paCriteriaSourceUrl.isUpdated) {
    updatedProperties.push('PA Criteria Source URL');
  }

  if (updated.notes.isUpdated) {
    updatedProperties.push('Notes');
  }

  if (updated.hasAutoPa.isUpdated) {
    updatedProperties.push('Has Auto PA');
  }

  return updatedProperties;
};
