import { Box, Heading, Meter } from 'grommet';
import React from 'react';

import { WizardControlState } from '@/components/wizard';

type WizardNavProps = {
  stepNames: string[]
} & WizardControlState;

export const WizardNav = ({ stepNames, totalSteps, activeStep }: WizardNavProps) => {
  const getStepProgress = () => {
    return ((activeStep + 1) / totalSteps) * 100;
  };

  return (
    <Box>
      <Box flex direction="row" justify="between">
        {stepNames.map((stepName: string) => (
          <Heading level={3} key={stepName} margin="none">
            {stepName}
          </Heading>
        ))}
      </Box>
      <Meter
        background="light-3"
        thickness="9px"
        margin={{ top: 'medium' }}
        color="brand"
        value={getStepProgress()}
        max={100}
        size="full"
      />
    </Box>
  );
};
