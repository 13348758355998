import React, { createContext, useContext, useState } from 'react';

import { DrugUtilizationPeriod } from '@/features/drug-utilization';
import {  useLookupsService } from '@/hooks/use-lookups-service';
import { Benefit, StateBenefit } from '@/types/benefit';
import {  NestedLookup } from '@/types/nested-lookup';
import {  Pool } from '@/types/pool';
import {  State } from '@/types/state';

type CreateBidAnalysisContextValue = {
  marketBasketLookups: NestedLookup[];
  states: State[];
  pools: Pool[];
  drugUtilizationPeriods: DrugUtilizationPeriod[];
  stateBenefits: { [key: string]: StateBenefit[] };
  loading: boolean;
  loadLookups: () => void;
  searchMarketBaskets: (query?: string) => void;
};

const DEFAULT_CONTEXT_VALUE: CreateBidAnalysisContextValue = {
  marketBasketLookups: [],
  states: [],
  pools: [],
  drugUtilizationPeriods: [],
  stateBenefits: {},
  loading: false,
  loadLookups: () => null,
  searchMarketBaskets: () => null
};

const CreateBidAnalysisContext = createContext<CreateBidAnalysisContextValue>(DEFAULT_CONTEXT_VALUE);

export const useCreateBidAnalysis = () => useContext<CreateBidAnalysisContextValue>(CreateBidAnalysisContext);

export const CreateBidAnalysisProvider = ({ children }: CreateBidAnalysisProps) => {
  const {
    getDrugUtilizationPeriods,
    getMarketBasketLookups,
    getStates,
    getPools,
    getBenefits
  } = useLookupsService();

  const [marketBasketLookups, setMarketBasketLookups] = useState<NestedLookup[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [pools, setPools] = useState<Pool[]>([]);
  const [drugUtilizationPeriods, setDrugUtilizationPeriods] = useState<DrugUtilizationPeriod[]>([]);
  const [benefits, setBenefits] = useState<Benefit[]>([]);
  const [stateBenefits, setStateBenefits] = useState<{ [key: string]: StateBenefit[] }>({});
  const [loading, setLoading] = useState<boolean>(false);

  React.useEffect(() => {
    const next: { [key: string]: StateBenefit[] } = {};

    states.forEach(state => {
      next[state.code] = benefits.map(benefit => ({ ...benefit, state: state.code }));
    });

    setStateBenefits(next);
  }, [states, benefits]);

  const loadLookups = async () => {
    setLoading(true);

    try {
      const [states, pools, benefits, drugUtilizationPeriods] = await Promise.all([
        getStates(),
        getPools(),
        getBenefits(),
        getDrugUtilizationPeriods()
      ]);

      setStates(states);
      setPools(pools);
      setBenefits(benefits);
      setDrugUtilizationPeriods(drugUtilizationPeriods);
    } finally {
      setLoading(false);
    }
  };

  const searchMarketBaskets = async (query?: string) => {
    const items = await getMarketBasketLookups(query, true);
    setMarketBasketLookups(items);
  };

  const contextValue = {
    marketBasketLookups,
    states,
    pools,
    drugUtilizationPeriods,
    stateBenefits,
    loading,
    loadLookups,
    searchMarketBaskets
  };

  return (
    <CreateBidAnalysisContext.Provider value={contextValue}>
      {children}
    </CreateBidAnalysisContext.Provider>
  );
};

type CreateBidAnalysisProps = {
  children?: React.ReactNode
};
