import { PaginationResult } from '@shared/pagination';
import React from 'react';

import { LazyLoadMultiSelectList } from '@/components/form-controls';
import { MultiSelectOption } from '@/components/form-controls/multi-select-list';
import { useLookupsService } from '@/hooks/use-lookups-service';
import { Lookup } from '@/types/lookup';

type ClassificationFilterProps = {
  /**
   * Label to appear above the field, optional.
   */
  label?: string;

  /**
   * Placeholder text, defaults to "Search by Class"
   */
  placeholder?: string;

  /**
   * Used to filter down the classification list to any clients that are tracking those classifications, optional.
   */
  clients?: MultiSelectOption[];

  /**
   * The list of currently-selected options, required.
   */
  selected: MultiSelectOption[];

  /**
   * Event handler for updating the current selection.
   * @param selected the new value of the multi-select
   */
  onSelectionChange: (selected: MultiSelectOption[]) => void;

  /**
   * Whether to show chips below, defaults to false.
   */
  showChips?: boolean;
};

const EMPTY_ARRAY: MultiSelectOption[] = [];

/**
 * Lazy-load filter for Classifications.
 */
export const ClassificationFilter = ({ label, placeholder = 'Search by Class', clients = EMPTY_ARRAY, selected, onSelectionChange, showChips = false }: ClassificationFilterProps) => {
  const { getClassifications } = useLookupsService();

  return (
    <LazyLoadMultiSelectList
      label={label}
      placeholder={placeholder}
      value={selected}
      onChange={onSelectionChange}
      showSelectedInline={showChips}
      dependencies={[clients]}
      lazyLoadRequest={async (searchTerm, page, rpp) => {
        const result = await getClassifications({
          query: searchTerm,
          page,
          rpp,
          trackedByClients: clients.map(it => it.id)
        });
        return result as PaginationResult<Lookup>;
      }}
    />
  );
};
