import { useSuspenseQuery } from '@tanstack/react-query';

import { artiaProtectedApi } from '@/lib/api-client';
import { Drug } from '@/products';

const getDrug = (id: number | string): Promise<Drug> => {
  return artiaProtectedApi.get(`drugs/${id}`);
};

export const makeDrugQueryKey = (id: string | number) => ['drug', typeof id === 'string' ? parseInt(id) : id];

export const useDrug = (drugId: string | number) => {
  const { data, error, isFetching, dataUpdatedAt } = useSuspenseQuery({
    queryKey: makeDrugQueryKey(drugId),
    queryFn: async () => getDrug(drugId)
  });

  if (error && !isFetching) throw error;

  return { data, dataUpdatedAt, isFetching };
};
