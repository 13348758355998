import { State } from '@/types/state';

export type DrugUtilization = {
  state: State;
  period: DrugUtilizationPeriod;
  ndc: string;
  productName: string;
  utilizationType: string;
  isSuppressionUsed: boolean;
  unitsReimbursed?: number;
  numberOfPrescriptions?: number;
  totalAmountReimbursed?: number;
  medicaidAmountReimbursed?: number;
  nonMedicaidAmountReimbursed?: number;
};

export type DrugUtilizationPeriod = {
  id: number;
  year: number;
  quarter: number;
  label: string;
};

// Sorts periods chronologically with the most recent periods first.
export const sortDrugUtilizationPeriods = (first: DrugUtilizationPeriod, second: DrugUtilizationPeriod): number => {
  if (first.year > second.year) {
    return -1;
  }

  if (first.year < second.year) {
    return 1;
  }

  // If we reach this point, then we are comparing options within the same year, so compare quarters.
  if (first.quarter > second.quarter) {
    return -1;
  }

  return 1;
};
