import { Level } from '@tiptap/extension-heading';
import { Editor } from '@tiptap/react';
import { Select } from 'grommet';
import React from 'react';

/**
 * Component for selecting typography for a text editor.
 */
export const TextEditorTypographySelect = ({ editor }: TextEditorTypographySelectProps) => {
  const options: Option[] = [
    createNormalTextOption(editor),
    ...headingLevels.map(level => createHeadingOption(editor, level)),
  ];

  const getSelectedOption = () => {
    return options.find(option => option.isActive) || options[0];
  };

  const handleOnChange = (value: Option) => {
    value.onSelect();
  };

  return (
    <Select
      valueKey="value"
      labelKey="label"
      size="small"
      width="7rem"
      options={options}
      value={getSelectedOption()}
      onChange={({ option }) => handleOnChange(option)}
    />
  );
};

type TextEditorTypographySelectProps = {
  editor: Editor;
}

type Option = {
  value: string;
  label: string;
  isActive: boolean;
  onSelect: () => void;
}

const headingLevels: Level[] = [1, 2, 3];

const createNormalTextOption = (editor: Editor): Option => {
  return {
    value: 'normal-text',
    label: 'Normal Text',
    isActive: editor.isActive('paragraph'),
    onSelect: () => editor.chain().focus().setParagraph().run(),
  };
};

const createHeadingOption = (editor: Editor, level: Level ) => {
  return {
    value: `heading-${level}`,
    label: `Heading ${level}`,
    isActive: editor.isActive('heading', { level }),
    onSelect: () => editor.chain().focus().toggleHeading({ level }).run(),
  };
};
