import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { BidAnalysisDetailsProvider } from './bid-analysis-details.provider';
import { EditBidAnalysisProvider } from './edit-bid-analysis.provider';
import { NetCostComparisonProvider } from './net-cost-comparison.provider';

export const BidAnalysisDetailsDependencies = () => {
  const { id } = useParams<{ id?: string }>();
  // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  const bidAnalysisId = parseInt(id);

  return (
    <BidAnalysisDetailsProvider id={bidAnalysisId}>
      <NetCostComparisonProvider bidAnalysisId={bidAnalysisId}>
        <EditBidAnalysisProvider>
          <Outlet />
        </EditBidAnalysisProvider>
      </NetCostComparisonProvider>
    </BidAnalysisDetailsProvider>
  );
};
