import clsx from 'clsx';
import { ReactNode } from 'react';

type ButtonBarProps = {
  children: ReactNode;
  className?: string;
}
export const ButtonBar = ({ className, children }: ButtonBarProps) => {
  return (
    <div className={clsx(className, 'mt-6 flex flex-row justify-end gap-1')}>
      {children}
    </div>
  );
};
