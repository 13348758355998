import { Box, Text } from 'grommet';
import React from 'react';

import { ArtiaButtonLink } from '@/components/artia-button';
import { CalculationDetails } from '@/types/calculation-details';
import { OverridableCalculatedValue } from '@/types/overridable-calculated-value';
import { formatCurrency } from '@/utils/formatting';

type EstimatedTotalUraCellProps = {
  ura?: OverridableCalculatedValue
  onViewCalculationDetails: (calculationDetails: CalculationDetails) => void;
};

export const EstimatedTotalUraCell = (props: EstimatedTotalUraCellProps) => {
  const { ura, onViewCalculationDetails } = props;

  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const handleValueClick = () => onViewCalculationDetails(ura.calculatedValue);

  return (
    <Box width={{ min: '8rem' }}>
      {ura?.overrideValue != null &&
        <Text>
          {formatCurrency(ura.overrideValue, 4, 4)}
        </Text>
      }

      {ura?.overrideValue == null && ura?.calculatedValue?.value != null &&
        <ArtiaButtonLink onClick={handleValueClick}>
          <Text>
            {formatCurrency(ura.calculatedValue.value, 4, 4)}
          </Text>
        </ArtiaButtonLink>
      }
    </Box>
  );
};
