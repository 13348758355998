import { FormField, TextArea, TextInput } from 'grommet';
import React, { useId } from 'react';
import { Control, Controller } from 'react-hook-form';
import styled from 'styled-components';

import { LookupSelect } from '@/components/form-controls';
import { State } from '@/types/state';

import { BoardMeetingFormData } from './types';

type InPersonMeetingLocationFormProps = {
  control: Control<BoardMeetingFormData>;
  states: State[];
};

/**
 * Wrapper around the fields needed for the in-person meeting location portion of the BoardMeetingForm.
 * Only really used to reduce noise in the main file.
 */
export const InPersonMeetingLocationForm = ({ control, states }: InPersonMeetingLocationFormProps) => {
  const id = useId();

  return (
    <PhysicalLocation>
      <Controller
        name="venueName"
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            style={{ gridColumnStart: 'span 2' }}
            name={name}
            htmlFor={`${id}-${name}`}
            label="Venue Name"
            error={error?.message}
            margin="none"
          >
            <TextInput
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
              value={value}
              ref={ref}
            />
          </FormField>
        )}
      />
      <Controller
        name="meetingRoom"
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            style={{ gridColumnStart: 'span 2' }}
            name={name}
            htmlFor={`${id}-${name}`}
            label="Room Number"
            error={error?.message}
            margin="none"
          >
            <TextInput
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
              value={value}
              ref={ref}
            />
          </FormField>
        )}
      />
      <Controller
        name="locationStreetLine1"
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            style={{ gridColumnStart: 'span 4' }}
            name={name}
            htmlFor={`${id}-${name}`}
            label="Street Line 1"
            error={error?.message}
            margin="none"
          >
            <TextInput
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
              value={value}
              ref={ref}
            />
          </FormField>
        )}
      />
      <Controller
        name="locationStreetLine2"
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            style={{ gridColumnStart: 'span 4' }}
            name={name}
            htmlFor={`${id}-${name}`}
            label="Street Line 2"
            error={error?.message}
            margin="none"
          >
            <TextInput
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
              value={value}
              ref={ref}
            />
          </FormField>
        )}
      />
      <Controller
        name="locationCity"
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            style={{ gridColumnStart: 'span 2' }}
            name={name}
            htmlFor={`${id}-${name}`}
            label="City"
            error={error?.message}
            margin="none"
          >
            <TextInput
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
              value={value}
              ref={ref}
            />
          </FormField>
        )}
      />
      <Controller
        name="locationState"
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <LookupSelect
            multiple={false}
            options={states.map((state) => ({ id: state.code, label: state.name }))}
            required
            name={name}
            placeholder="Select a State"
            label="State"
            // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
            value={value}
            error={error?.message}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
          />
        )}
      />
      {/* TODO: validation for zip code? */}
      <Controller
        name="locationZipCode"
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            name={name}
            htmlFor={`${id}-${name}`}
            label="ZIP Code"
            error={error?.message}
            margin="none"
          >
            <TextInput
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
              value={value}
              ref={ref}
            />
          </FormField>
        )}
      />
      <Controller
        name="locationNotes"
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            name={name}
            style={{ gridColumnStart: 'span 4' }}
            htmlFor={`${id}-${name}`}
            label="Notes"
            error={error?.message}
            margin="none"
          >
            <TextArea
              style={{ background: 'white' }}
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
              value={value}
              ref={ref}
            />
          </FormField>
        )}
      />
    </PhysicalLocation>
  );
};

const PhysicalLocation = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, max-content);
  gap: 1rem;
  background: ${({ theme }) => theme.global.colors['light-3']};
`;
