import { Box, Layer, Text } from 'grommet';
import React from 'react';

import { Alert } from '@/components/alert';
import { Prompt } from '@/components/prompt';
import { User } from '@/features/admin/types/user';
import { Client } from '@/features/clients';
import { useUpdateClientPrimaryAdmin } from '@/features/clients/api/use-update-client-primary-admin';

type Props = {
  user: User
  client: Client;
  onClose: () => void;
}
const MakePrimaryAdminPrompt = ({ client, user, onClose }: Props) => {
  const {
    mutate: updatePrimaryAdmin,
    isPending,
  } = useUpdateClientPrimaryAdmin();

  const handleConfirm = () => {
    updatePrimaryAdmin(
      { clientId: client.id, user },
      {
        onSuccess: () => onClose()
      });
  };

  return (
    <Layer onClickOutside={onClose}>
      <Prompt
        header="Assign Primary Admin"
        actions={[
          { name: 'Cancel', onClick: onClose, variant: 'outlined' },
          { name: 'Confirm', onClick: handleConfirm, disabled: () => isPending, variant: 'filled' },
        ]}
      >
        {client.primaryUser && (
          <Box margin={{ bottom: 'small' }}>
            <Alert
              variant="info"
              message={`${client.name} already has ${client.primaryUser.name} as it's primary admin. This action will replace them.`}
            />
          </Box>
        )}
        <Text>
          Are you sure you want to assign <strong>{user.name}</strong> as the primary admin?
        </Text>
      </Prompt>
    </Layer>
  );
};

export default MakePrimaryAdminPrompt;
