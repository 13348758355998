import { ProtectedRoute } from '@shared/auth';
import React, { Suspense } from 'react';
import { LiaBellSolid, LiaUserCogSolid } from 'react-icons/lia';
import { Outlet } from 'react-router-dom';

import { OverlayLoader } from '@/components-new/overlay-loader';
import { Page } from '@/components-new/page';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Tab, TabGroup, TabList, TabPanels } from '@/components-new/tab';
import { useTabs } from '@/hooks/use-tabs';

const UserSettingsLayoutScreen = () => {

  const {
    tabs,
    activeTabIndex,
    onChange
  } = useTabs([
    {
      label: (
        <>
          <LiaUserCogSolid />
          General
        </>
      ),
      href: '/user-settings',
    },
    {
      label: (
        <>
          <LiaBellSolid />
          Notifications
        </>
      ),
      href: '/user-settings/notifications',
    }
  ]);

  return(
    <Page
      title="User Settings"
      header={({ title }) => (
            <PageTitleRow title={title} />
      )}
    >
      <TabGroup selectedIndex={activeTabIndex} onChange={onChange}>
        <TabList>
          {tabs.map((tab) => (
            <Tab key={tab.href}>
              {tab.label}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <div className="mt-2">
            <Outlet />
          </div>
        </TabPanels>
      </TabGroup>
    </Page>
  );

};
const UserSettingsLayout = () => {
  return (
    <ProtectedRoute>
      <Suspense fallback={<OverlayLoader />}>
        <UserSettingsLayoutScreen />
      </Suspense>
    </ProtectedRoute>
  );
};

export default UserSettingsLayout;
