import { ProtectedRoute } from '@shared/auth';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { OverlayLoader } from '@/components/loading';
import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { Page } from '@/components-new/page';
import { PageTitleRow } from '@/components-new/page-title-row';
import { useDrug } from '@/features/drugs/api/use-drug';
import { useParams } from '@/router';

const DrugPackagingLayout = () => {
  const { id: drugId, ndc } = useParams('/drugs/:id/packagings/:ndc');
  const { data: drug } = useDrug(drugId);

  return (
    <Page
      title="Edit Drug Packaging"
      header={({ title }) => (
        <>
          <Breadcrumbs
            breadcrumbs={[
              { label: 'Drugs', url: '/drugs' },
              { label: drug.name, url: `/drugs/${drug.id}` },
              { label: 'Packagings', url: `/drugs/${drug.id}/packagings` },
              { label: ndc.toString(), url: `/drugs/${drug.id}/packagings/${ndc}` },
            ]}
          />
          <PageTitleRow title={title}>
          </PageTitleRow>
        </>
      )}
    >
      <Outlet />
    </Page>
  );
};

const DrugPackagingLayoutWrapper = () => {
  return (
    <ProtectedRoute policies={['canManageDrugs']}>
      <Suspense fallback={<OverlayLoader />}>
        <DrugPackagingLayout/>
      </Suspense>
    </ProtectedRoute>
  );
};

export default DrugPackagingLayoutWrapper;
