import { Box, Tip, } from 'grommet';
import { Flag } from 'grommet-icons';
import React from 'react';

import { MonospaceText } from '@/components/monospace-text';

type NdcCellProps = {
  ndc: string;
  needsAttention: boolean;
};

const NdcLabel = (props: { ndc: string, needsAttention: boolean }) => {
  const { ndc, needsAttention } = props;
  return (
    <Box direction="row" align="center" gap="small">
      {needsAttention && (<Flag color="status-warning" />)}
      <MonospaceText>{ndc}</MonospaceText>
    </Box>
  );
};

export const NdcCell = (props: NdcCellProps) => {
  const {
    ndc,
    needsAttention
  } = props;

  return (
    <>
      {needsAttention && (
        <Tip
          content={<Box width={{ max: 'medium' }}>This NDC is missing information. Market Basket Calculations cannot be performed until the missing information is provided.</Box>}
        >
          <Box> {/* Box required for tip to render correctly. */}
            <NdcLabel ndc={ndc} needsAttention={needsAttention} />
          </Box>
        </Tip>
      )}
      {!needsAttention && (<NdcLabel ndc={ndc} needsAttention={needsAttention} />)}
    </>
  );
};
