import { EditorContent } from '@tiptap/react';
import { Box, ThemeType } from 'grommet';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { TextEditorOptions } from '@/components/text-editor/text-editor-config';
import { TextEditorToolbar } from '@/components/text-editor/text-editor-toolbar';
import { useTextEditor } from '@/components/text-editor/use-text-editor';
import { useThemeContext } from '@/styles/use-theme-context';

/**
 * A text editor component that allows admin to edit and display rich text.
 */
export const TextEditor = forwardRef<HTMLDivElement, TextEditorProps>((
  props, ref
) => {
  const theme = useThemeContext();

  const {
    onChange,
    value,
    editable,
    placeholder,
    ...rest
  } = { ...defaultProps, ...props };
  const { editor } = useTextEditor({
    onChange,
    value,
    editable,
    placeholder,
    ...rest,
  });

  if (!editable) {
    return <TextEditorContent editor={editor} theme={theme} className="prose" />;
  }

  return (
      <Box background="white" pad="none" direction="column" fill round="small">
        {editor && <TextEditorToolbar editor={editor} />}
        <Box fill pad="small" overflow="auto" round="small">
          <TextEditorContent editor={editor} ref={ref} className="prose"/>
        </Box>
      </Box>
  );
});

const TextEditorContent = styled(EditorContent)<{ theme: ThemeType }>`
  color: #000000;
  font-family: degular-text, sans-serif;
  height: 100%;
  width: 100%;
  overflow: auto;

  & .tiptap p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  & .tiptap {
    box-sizing: border-box;
    :first-child {
      margin-top: 0;
    }

    :last-child {
      border-bottom: 0;
    }

    outline: none;
  }
`;

export type TextEditorProps = object & TextEditorOptions;

const defaultProps = {
  editable: true,
} satisfies Partial<TextEditorProps>;
