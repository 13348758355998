import React from 'react';

import {
  DEFAULT_OPTIONS,
  ModalContext,
  type ModalContextValue,
  type ModalOptions,
  type ModalParts
} from './modal.context';

type ModalProviderProps = {
  children: React.ReactNode;
};

export const ModalProvider = ({ children }: ModalProviderProps) => {
  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [ modalParts, setModalParts ] = React.useState<ModalParts>(null);
  const [ options, setOptions ] = React.useState(DEFAULT_OPTIONS);

  const openModal = (modalParts: ModalParts, options?: ModalOptions) => {
    setModalParts({ ...modalParts });
    setOptions({ ...DEFAULT_OPTIONS, ...options });
  };

  const closeModal = () => {
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setModalParts(null);
    setOptions(DEFAULT_OPTIONS);
  };

  const modalContextValue: ModalContextValue = {
    closeModal,
    modalParts,
    openModal,
    options,
  };

  return (
    <ModalContext.Provider value={modalContextValue}>
      {children}
    </ModalContext.Provider>
  );
};
