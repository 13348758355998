import { Box, FormField, Spinner, TextInput } from 'grommet';
import { WidthType } from 'grommet/utils';
import React, { KeyboardEventHandler, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { InlineEdit } from '@/components/form-controls';
import { BidRecommendation, BidRecommendationDrugPackaging } from '@/features/bid-analysis/types/bid-recommendation';

import * as BidRecommendationFunctions from './bid-recommendation.functions';
import { BidRecommendationPackagingFormInputs } from './bid-recommendation-packaging-form-inputs';

type TextInlineEditCellProps = {
  fieldName: keyof BidRecommendationPackagingFormInputs,
  bidRecommendation: BidRecommendation,
  drugPackaging: BidRecommendationDrugPackaging,
  editingFieldId?: string;
  savingFieldId?: string;
  onFocus: (fieldId: string) => void;
  onCancel: () => void;
  onSave: (
    bidRecommendation: BidRecommendation,
    row: BidRecommendationDrugPackaging,
    fieldId: string,
    form: BidRecommendationPackagingFormInputs) => void;
  cellWidth?: WidthType
};

export const TextInlineEditCell = (props: TextInlineEditCellProps) => {
  const {
    fieldName,
    bidRecommendation,
    drugPackaging,
    editingFieldId,
    savingFieldId,
    onFocus,
    onCancel,
    onSave,
    cellWidth = 'large'
  } = props;

  const id = BidRecommendationFunctions.buildFieldId({
    bidRecommendationId: bidRecommendation.id,
    isHistorical: bidRecommendation.isHistorical,
    packagingId: drugPackaging.id,
    fieldName
  });

  const editing = editingFieldId === id;
  const saving = savingFieldId === id;

  const {
    control,
    getValues,
    formState: { errors, isDirty  },
    reset
  } = useForm<BidRecommendationPackagingFormInputs>({ mode: 'onChange', reValidateMode: 'onChange', defaultValues: { [fieldName]: drugPackaging[fieldName as keyof BidRecommendationDrugPackaging] } });

  useEffect(() => {
    reset({ [fieldName]: drugPackaging[fieldName as keyof BidRecommendationDrugPackaging] });
  }, [drugPackaging[fieldName as keyof BidRecommendationDrugPackaging]]);

  const handleFocus = () => {
    onFocus(id);
  };

  const handleSave = async (form: BidRecommendationPackagingFormInputs) => {
    if (isDirty) {
      onSave(bidRecommendation, drugPackaging, id, { ...form });
    } else {
      onCancel();
      reset();
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      handleSave(getValues());
    }

    if (event.key === 'Escape') {
      reset();
      onCancel();
    }
  };

  return (
    <Box width={cellWidth} fill="vertical">
      <Controller
        control={control}
        name={fieldName}
        rules={{
          maxLength: 100,
          required: true
        }}
        render={({ field: { value, onChange } }) => (
          <InlineEdit
            editing={editing}
            onFocus={handleFocus}
            readonlyComponent={<Box fill pad="xxsmall"><span>{value ?? <>&mdash;</>}</span></Box>}
            inputComponent={
              <FormField
                name={fieldName}
                htmlFor={id}
                error={errors.supplementalRebate?.message}
              >
                <TextInput
                  id={id}
                  value={value}
                  onChange={onChange}
                  onBlur={() => handleSave(getValues())}
                  onKeyDown={handleKeyDown}
                  name={fieldName}
                  icon={saving ? <Spinner size="xsmall" color="brand" /> : undefined}
                  maxLength={100}
                  reverse
                />
              </FormField>
            }
          />
        )}
      />
    </Box>
  );
};
