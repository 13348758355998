import { useContext } from 'react';

import { PromptContext } from '@/components/interactions/prompt-context';

export const usePromptContext = () => {
  const context = useContext(PromptContext);

  if (!context) throw new Error('usePromptContext must be used within PromptContextProvider.');

  return context;
};
