import { Box, ButtonExtendedProps, Menu, Text } from 'grommet';
import { FormDown } from 'grommet-icons';
import React, { useMemo } from 'react';

import {
  BID_ANALYSIS_STATUS_CLOSED,
  BID_ANALYSIS_STATUS_OPEN,
  BidAnalysisStatusType
} from '@/features/bid-analysis/types/bid-analysis-status-type';

type BidAnalysisStatusProps = {
  /**
   * The status of the bid analysis.
   */
  status: BidAnalysisStatusType;
  /**
   * Called when the user clicks the close bid analysis button.
   * @param status - The new status of the bid analysis.
   */
  onBidAnalysisStatusChange?: (status: BidAnalysisStatusType) => void;
};

/**
 * Displays the status of a bid analysis with a toggle to change the status.
 */
export const BidAnalysisStatus = (props: BidAnalysisStatusProps) => {
  const {
    status,
    onBidAnalysisStatusChange,
  } = props;

  const items: ButtonExtendedProps[] = useMemo(() => {
    const closeBidAnalysis: ButtonExtendedProps = {
      label: 'Close Bid Analysis',
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onClick: () => onBidAnalysisStatusChange(BID_ANALYSIS_STATUS_CLOSED),
    };

    const openBidAnalysis: ButtonExtendedProps = {
      label: 'Reopen Bid Analysis',
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onClick: () => onBidAnalysisStatusChange(BID_ANALYSIS_STATUS_OPEN),
    };

    return status === BID_ANALYSIS_STATUS_OPEN ? [closeBidAnalysis] : [openBidAnalysis];
  }, [status]);

  return (
    <Menu
      plain
      items={items}
      label={<StatusLabel status={status}/> }
    >
      <Box direction="row" gap="small" align="center" pad="small">
        <StatusLabel status={status} />
        <FormDown />
      </Box>
    </Menu>
  );
};

type StatusIconProps = {
  status: BidAnalysisStatusType;
};

/**
 * Displays a small colored box based on the status..
 */
const StatusIcon = ({ status }: StatusIconProps) => {
  const color = status === BID_ANALYSIS_STATUS_CLOSED ?
    'status-critical' :
    'status-ok';

  return (
    <Box height="0.75rem" width="0.75rem" round="0.25rem" background={color} />
  );
};

/**
 * Label display for the status with the correct icon based on the status.
 */
const StatusLabel = ({ status }: StatusIconProps) => {
  const label = status === BID_ANALYSIS_STATUS_CLOSED ? 'Closed' : 'Open';
  return (
    <Box direction="row" align="center" gap="small">
      <StatusIcon status={status} /><Text>{label}</Text>
    </Box>
  );
};
