import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useAuth } from 'react-oidc-context';
import { Outlet } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { AppProvider } from '@/app/app-provider';
import { makeMeQueryKey } from '@/features/home/api/get-me';
import { NotificationsRoot } from '@/lib/notification';

const SilentRefreshBoundary = () => {
  const queryClient = useQueryClient();
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // TODO: we may want to prompt the user to continue their session or time them out
    return auth.events.addAccessTokenExpiring(async () => {
      try {
        await auth.signinSilent();
        await queryClient.invalidateQueries({ queryKey: makeMeQueryKey() });
      } catch (error) {
        console.error('Unable to refresh token: ', error);
        navigate('/');
      }
    });
  }, [auth, navigate, queryClient]);

  return null;
};

const App = () => {

  return (
    <AppProvider>
      <Helmet
        defaultTitle="Capsule"
        titleTemplate="%s - Capsule"
      >
        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <meta name="description" content="Capsule - Drug management and analyses app provided by Artia Solutions."/>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
        <link rel="icon" sizes="32x32" href="/favicon-16x16.png"/>
        <link rel="icon" sizes="16x16" href="/favicon-32x32.png"/>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous"/>
        <link rel="stylesheet" href="/fonts/gt-super.css"/>
        <link rel="stylesheet" href="https://use.typekit.net/bpt8gla.css"/>
      </Helmet>
      <SilentRefreshBoundary/>
      <Outlet/>
      <NotificationsRoot />
    </AppProvider>
  );
};

export default App;
