import './index.css';

import { Routes } from '@generouted/react-router';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { bugsnagClient } from '@/lib/bugsnag/bugsnag-client';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _ = bugsnagClient;

createRoot(document.getElementById('root')!).render(<Routes />);
