import { PolicyChecker, policyChecker } from '@shared/auth/authorize';
import { User } from 'oidc-client-ts';
import { useAuth } from 'react-oidc-context';

import { useMe } from '@/features/home/api/get-me';
import { CurrentUser } from '@/types/auth';
import { Me } from '@/types/me';

type UseCurrentUser = {
  user: CurrentUser
  hasPolicies: PolicyChecker
}

export const makeCurrentUser = (me: Me, user: User): CurrentUser => {
  return {
    ...me.user,
    ...user,
    features: me.features
  } as CurrentUser;
};

export const useCurrentUser = (): UseCurrentUser => {
  const { user } = useAuth();
  const { data: me } = useMe({ user });

  if (!user) throw new Error('useCurrentUser called without an authorized user.');
  if (!me) throw new Error('useCurrentUser called without a user from the API.');

  const currentUser = makeCurrentUser(me, user);

  return {
    user: currentUser,
    hasPolicies: policyChecker(currentUser)
  };
};
