import { Box, DataTable, Text } from 'grommet';
import { join } from 'lodash';
import React from 'react';

import { TableEmptyPlaceholder, TableLoadingOverlay } from '@/components/loading';

import { ClinicalsChangeLog, displayChangeLogReason, getUpdatedProperties } from '../../types';
import { ClinicalsChangeLogActionsCell } from './clinicals-change-log-actions-cell';

type ClinicalsChangeLogTableProps = {
  data: ClinicalsChangeLog[];
  loading: boolean;
  onViewSnapshot: (row: ClinicalsChangeLog) => void;
};

export const ClinicalsChangeLogTable = ({ data, loading, onViewSnapshot }: ClinicalsChangeLogTableProps) => {
  return (
    <DataTable
      columns={[
        {
          property: 'updatedAt',
          header: 'Updated At',
          render: ({ audits }) => (
            <Text>{audits[0].updatedAt}</Text>
          )
        },
        {
          property: 'updatedBy',
          header: 'Updated By',
          render: ({ audits }) => (
            <Text>{audits[0].name}{audits.length > 1 ? ` + ${audits.length - 1} more` : ''}</Text>
          )
        },
        {
          property: 'reason',
          header: 'Reason for Change',
          render: ({ reason }) => {
            return <Text>{displayChangeLogReason(reason)}</Text>;
          }
        },
        {
          property: 'state',
          header: 'State',
        },
        {
          property: 'drug',
          header: 'Drug'
        },
        {
          property: 'coverageTags',
          header: 'Coverage Tags',
          render: ({ coverageTags }) => {
            return <Text>{join(coverageTags.filter(tag => !tag.isDefault).map(tag => tag.name), ', ')}</Text>;
          }
        },
        {
          property: 'updatedProperties',
          header: 'Updated Properties',
          // @ts-expect-error TS(2345): Argument of type 'ChangelogProperties | undefined'... Remove this comment to see the full error message
          render: ({ updatedProperties }) => getUpdatedProperties(updatedProperties).join(', ')
        },
        {
          property: 'actions',
          sortable: false,
          render: (row) => <ClinicalsChangeLogActionsCell onViewSnapshot={() => onViewSnapshot(row)} />
        }
      ]}
      data={data}
      placeholder={
        (loading || data.length === 0) &&
        <Box fill>
          {loading && <TableLoadingOverlay />}
          {!loading && data.length === 0 && <TableEmptyPlaceholder content="No changelogs available." />}
        </Box>
      }
    />
  );
};
