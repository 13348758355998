import { createContext } from 'react';

export type PromptOptions = {
  title: string;
  text: string;
  defaultValue?: string;
  cancelText?: string;
  confirmText?: string;
}

export type PromptFn = (options: PromptOptions) => Promise<string>;

export type PromptContextValue = {
  prompt: PromptFn;
}

export const PromptContext = createContext<PromptContextValue | null>(null);
