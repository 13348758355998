import { Box, Card, Heading, Text } from 'grommet';
import React from 'react';

import { ArtiaButton } from '@/components/artia-button';

type PromptActionOptions = {
  name: string;
  variant?: 'filled' | 'outlined' | 'danger';
  onClick: () => void;
  disabled?: () => boolean;
}

type PromptOptions = {
  header: string;
  message?: string;
  actions: PromptActionOptions[];
  children?: React.ReactNode;
  loading?: boolean;
};

export const Prompt = (options: PromptOptions) => (
  <Card width={{ min: '36rem', max: '72rem' }}>
    <Box pad="medium" border={{ side: 'bottom' }}>
      <Heading level="3" margin="none">{options.header}</Heading>
    </Box>
    <Box pad="medium">
      <Text>{options.message}</Text>
      {options.children}
    </Box>
    {options.actions.length > 0 && (
      <Box direction="row" justify="end" gap="xsmall" pad="small" border={{ side: 'top' }} >
        {options.actions.map((action, index) => (
          <ArtiaButton
            key={index}
            label={action.name}
            onClick={action.onClick}
            disabled={(action.disabled && action.disabled()) ?? false}
            size="large"
            variant={action.variant}
          />
        ))}
      </Box>
    )}
  </Card>
);
