import { useAsync } from '@shared/async';
import React from 'react';

import { DeleteConfirmationDialog } from '@/components/delete-confirmation-dialog';
import { useClientService } from '@/features/clients/api/use-clients-service';

type DeleteClientDialogProps = {
  open: boolean;
  clientId: number;
  name: string;
  onClose: () => void;
  onDeleteComplete: () => void;
};

export const DeleteClientDialog = (props: DeleteClientDialogProps) => {
  const {
    open,
    clientId,
    name,
    onClose,
    onDeleteComplete
  } = props;

  const { deleteClient } = useClientService();
  const deleteAsync = useAsync(deleteClient);

  return (
    <DeleteConfirmationDialog
      open={open}
      itemName="client"
      title="Delete Client"
      message={`Are you sure you want to delete the client "${name}"?`}
      onCancel={onClose}
      onDeleteComplete={onDeleteComplete}
      request={deleteAsync}
      parameters={[clientId]}
    />
  );
};
