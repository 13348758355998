import { useAsync } from '@shared/async';
import React, { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';

import { useLookupsService } from '@/hooks/use-lookups-service';
import { Lookup } from '@/types/lookup';
import { Pool } from '@/types/pool';
import { State } from '@/types/state';

type BoardMeetingsProviderData = {
  states: State[];
  pools: Pool[];
  meetingTypes: Lookup[];
  supportedTimezones: Lookup<string>[];
};

const BoardMeetingsContext = createContext<BoardMeetingsProviderData | null>(null);

export const BoardMeetingsProvider = ({ children }: { children: ReactNode }) => {
  const { getStates, getPools, getBoardMeetingTypes, getSupportedTimezones } = useLookupsService();
  const lookupsRequest = useAsync(() => {
    return Promise.all([getStates(), getPools(), getBoardMeetingTypes(), getSupportedTimezones()]);
  });

  useEffect(() => {
    lookupsRequest.execute();
  }, []);

  const value = useMemo<BoardMeetingsProviderData>(() => {
    const [
      states,
      pools,
      meetingTypes,
      supportedTimezones
    ] = lookupsRequest.value ?? [[], [], [], []];
    return { states, pools, meetingTypes, supportedTimezones };
  }, [lookupsRequest.value]);

  return (
    <BoardMeetingsContext.Provider value={value}>
      {children}
    </BoardMeetingsContext.Provider>
  );
};

export const useBoardMeetingsProvider = () => {
  const boardMeetingsProviderData = useContext(BoardMeetingsContext);

  if (!boardMeetingsProviderData) throw new Error('Must be called within a BoardMeetingsProvider');

  return boardMeetingsProviderData;
};
