import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { PageTitleRow } from '@/components-new/page-title-row';
import { Seo } from '@/components-new/seo';
import { Calendar } from '@/features/calendar/components/calendar';
import { MeetingCard, MeetingDetailsDrawer } from '@/features/calendar/components/meetings';
import { Meeting } from '@/features/calendar/types/meeting';

const meetings: Meeting[] = [
  {
    id: 2,
    name: 'Maryland &mdash; DUR Meeting',
    date: 'September 5th, 2024',
    time: '9:15 AM EDT',
    datetime: '2024-09-10T01:30',
    format: 'in_person',
    type: 'DUR',
    drugs: ['Adderall', 'Ritalin', 'Vyvanse'],
    classifications: ['Stimulants'],
    boardMembers: 'Dr. John Doe, Dr. Jane Doe',
    location: {
      venue: 'Maryland State House',
      address: {
        line1: '100 State Cir',
        city: 'Annapolis',
        state: 'MD',
        zipcode: '21401'
      }
    },
    resources: []
  },
  {
    id: 1,
    name: 'Rhode Island &mdash; P&T Meeting',
    date: 'September 9th, 2024',
    time: '7:30 AM EDT',
    datetime: '2024-09-10T11:30',
    format: 'virtual',
    type: 'P&T',
    drugs: ['Humira', 'Enbrel', 'Remicade'],
    classifications: ['Miracle Drugs'],
    boardMembers: 'Dr. John Doe, Dr. Jane Doe',
    webinar: {
      url: 'https://example.com',
      phone: '1-123-456-7890',
      meetingId: '123 456 789',
      password: 'password'
    }
  }
];

const CalendarPage = () => {
  const [meeting, setMeeting] = useState<Meeting | undefined>();

  const initialMonth = DateTime.now();
  const [month, setMonth] = useState<DateTime>(initialMonth);

  const showMeetingDetails = (meeting: Meeting) => {
    setMeeting(meeting);
  };

  const onCalendarNavigate = (newMonth: DateTime) => {
    // TODO: load events for the new month
    setMonth(newMonth);
  };

  return (
    <>
      <Seo title="Calendar"/>
      <PageTitleRow title="Calendar"/>

      <div className="lg:grid lg:grid-cols-12 lg:gap-x-16">
        <Calendar
          current={month}
          min="2024-01"
          max="2024-12"
          daysWithEvents={['2024-09-22', '2024-09-30']}
          onChange={onCalendarNavigate}
        />

        <ol className="mt-4 divide-y divide-gray-100 leading-6 lg:col-span-7 xl:col-span-8">
          {meetings.map((meeting) => (
            <MeetingCard key={meeting.id} meeting={meeting} showMeetingDetails={showMeetingDetails}/>
          ))}
        </ol>
      </div>

      <MeetingDetailsDrawer meeting={meeting} onClose={() => setMeeting(undefined)}/>
    </>
  );
};

export default CalendarPage;
