import { markdownSerializer } from '@shared/markdown/markdown-serialization';

/**
 * Handle processing of markdown.
 */
export const useMarkdown = () => {
  const toHtml = (markdown: string) => {
    return markdownSerializer.deserialize(markdown);
  };

  const fromHtml = (html: string) => {
    return markdownSerializer.serialize(html);
  };

  return {
    toHtml,
    fromHtml
  };
};
