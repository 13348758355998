import { Box, Button } from 'grommet';
import { Archive, Revert, Trash } from 'grommet-icons';
import React from 'react';

import {
  BID_ANALYSIS_STATUS_CLOSED,
  BID_ANALYSIS_STATUS_OPEN,
  BidAnalysisStatusType
} from '@/features/bid-analysis/types/bid-analysis-status-type';

type BidAnalysesRowActionsCellProps = {
  bidAnalysisId: number;

  /**
   * The status of the bid analysis.
   */
  forStatus: BidAnalysisStatusType;

  /**
   * Called when the user clicks the close bid analysis button.
   */
  onCloseBidAnalysis: (bidAnalysisId: number) => void;

  /**
   * Called when the user clicks the reopen bid analysis button.
   */
  onReopenBidAnalysis: (bidAnalysisId: number) => void;

  /**
   * Whether the user should be given the option to delete the bid analysis.
   */
  canDelete: boolean;

  /**
   * Called when the user clicks the delete bid analysis button.
   */
  onDeleteBidAnalysis: (bidAnalysisId: number) => void;
};

/**
 * Displays a set of actions based on the status of the bid analysis.
 */
export const BidAnalysesRowActionsCell = (props: BidAnalysesRowActionsCellProps) => {
  const {
    bidAnalysisId,
    forStatus,
    onCloseBidAnalysis,
    onReopenBidAnalysis,
    canDelete,
    onDeleteBidAnalysis
  } = props;

  const handleCloseBidAnalysis = () => {
    onCloseBidAnalysis(bidAnalysisId);
  };

  const handleOpenBidAnalysis = () => {
    onReopenBidAnalysis(bidAnalysisId);
  };

  const handleDeleteBidAnalysis = () => {
    onDeleteBidAnalysis(bidAnalysisId);
  };

  const isClosed = forStatus === BID_ANALYSIS_STATUS_CLOSED;
  const isOpen = forStatus === BID_ANALYSIS_STATUS_OPEN;
  return (
    <Box direction="row" align="center" gap="xsmall">
      {isOpen && <OpenStatusActions onCloseBidAnalysisClick={handleCloseBidAnalysis} />}
      {isClosed && <ClosedStatusActions onOpenBidAnalysisClick={handleOpenBidAnalysis} />}

      {canDelete && <Button
        tip="Delete Bid Analysis"
        a11yTitle="Delete Bid Analysis"
        hoverIndicator="background"
        margin="none"
        icon={<Trash color="accent-1" size="medium"/>}
        onClick={handleDeleteBidAnalysis}
      />}
    </Box>
  );
};

type OpenStatusActionsProps = {
  onCloseBidAnalysisClick: () => void;
};

/**
 * Actions for when the bid analysis is open.
 */
const OpenStatusActions = ({ onCloseBidAnalysisClick }: OpenStatusActionsProps) => {
  return (
    <Button
      tip="Close Bid Analysis"
      a11yTitle="Close Bid Analysis"
      hoverIndicator="background"
      margin="none"
      icon={<Archive color="accent-1" size="medium"/>}
      onClick={onCloseBidAnalysisClick}
    />
  );
};


type ClosedStatusActionsProps = {
  onOpenBidAnalysisClick: () => void;
};

/**
 * Actions for when the bid analysis is closed.
 */
const ClosedStatusActions = ({ onOpenBidAnalysisClick }: ClosedStatusActionsProps) => {
  return (
    <Button
      tip="Reopen Bid Analysis"
      a11yTitle="Reopen Bid Analysis"
      hoverIndicator="background"
      margin="none"
      icon={<Revert color="accent-1" size="medium"/>}
      onClick={onOpenBidAnalysisClick}
    />
  );
};
