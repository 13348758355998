import { Box } from 'grommet';
import React, { ReactNode } from 'react';

import { type WizardAnimation } from './wizard-animation';

/**
 * Creates a wrapper for step contents to control animation.
 */
export const WizardStep = ({ children, animation }: WizardStepProps ) => {
  const fadeAnimation = {
    type: 'fadeIn' as any,
    duration: 300,
    delay: 0,
    size: 'small' as any,
  };

  const transitionAnimation = animation && {
    type: animation,
    duration: 500,
    delay: 0,
    size: 'xlarge' as any,
  };

  return (
    <Box animation={fadeAnimation}>
      {/* @ts-expect-error TS(2322): Type '{ type: "slideLeft" | "slideRight"; duration... Remove this comment to see the full error message */}
      <Box animation={transitionAnimation}>
        {children}
      </Box>
    </Box>
  );
};

type WizardStepProps = {
  children?: ReactNode,
  animation?: WizardAnimation,
};
