import { useMutation } from '@tanstack/react-query';
import React, { Suspense } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { useGetStates } from '@/api/use-states';
import { Button } from '@/components-new/button';
import { ButtonBar } from '@/components-new/button-bar';
import { Checkbox, CheckboxField, CheckboxGroup } from '@/components-new/checkbox';
import { Label } from '@/components-new/fieldset';
import { SectionDescription, SectionHeading } from '@/components-new/section';
import { updateUserSettings } from '@/features/user-settings/api/update-user-settings';
import { useUserSettings } from '@/features/user-settings/api/use-user-settings';
import { UserSetting, UserSettingsList } from '@/features/user-settings/components/user-setting';
import { UserSettingsNameField } from '@/features/user-settings/components/user-settings-name-field';
import { UserSettingsResetPassword } from '@/features/user-settings/components/user-settings-reset-password';
import { UserSettings } from '@/features/user-settings/types/user-settings';
import { error, success } from '@/lib/notification/notifications';
import { queryClient } from '@/lib/react-query';

type PriorityStatesForm = {
  priorityStates: string[];
}

const UserGeneralSettingsPage = () => {
  const { data: userSettings } = useUserSettings();
  const { data: stateOptions } = useGetStates();

  const priorityStatesMutation = useMutation({
    mutationFn: (data: UserSettings) => updateUserSettings(data),
    onSuccess: () => {
      success({ title: 'Updated', message: 'Priority states have been updated.' });
    },
    onError: () => {
        error({ title: 'Uh Oh', message: 'Priority states failed to update.' });
    },
    onSettled: async () => {
      return await queryClient.invalidateQueries({ queryKey: ['userSettings'] });
    },
  });

  const {
    getValues,
    handleSubmit,
    control,
  } = useForm<PriorityStatesForm>();

  const onSubmit: SubmitHandler<PriorityStatesForm> = data => {
    priorityStatesMutation.mutate({ priorityStates: data.priorityStates } as UserSettings);
  };

  return (
    <div className="mt-6 space-y-12 lg:mx-0 lg:max-w-4xl">
      <div>
        <SectionHeading level={2}>Profile</SectionHeading>
        <SectionDescription>
          Account information.
        </SectionDescription>
        <Suspense>
          <UserSettingsList>
            <UserSetting title="Full name">
              <UserSettingsNameField fullName={userSettings.name} />
            </UserSetting>
            <UserSetting title="Email address">{userSettings.email}</UserSetting>
            <UserSetting title="Password" >
              <UserSettingsResetPassword />
            </UserSetting>
          </UserSettingsList>
        </Suspense>
      </div>
      <div>
        <SectionHeading level={2}>Priority States</SectionHeading>
        <SectionDescription>
          Select the states relevant to your work.
        </SectionDescription>
          <Controller
            name="priorityStates"
            control={control}
            rules={{ required: true }}
            defaultValue={userSettings.priorityStates}
            render={({ field: { onChange } }) => {
              return (
              <CheckboxGroup className="mt-6 border-t pt-6 sm:columns-2 md:columns-3">
                {stateOptions.map((stateOption) => {
                  return(
                    <CheckboxField key={stateOption.code}>
                      <Checkbox
                        defaultChecked={userSettings.priorityStates.includes(stateOption.code)}
                        value={stateOption.code}
                        onChange={(checked) => {
                          checked ?
                            onChange([...getValues('priorityStates'), stateOption.code]) :
                            onChange(getValues('priorityStates').filter((stateCode ) => stateCode != stateOption.code));
                        }}
                      />
                      <Label>{stateOption.name}</Label>
                    </CheckboxField>
                  );
                })}
              </CheckboxGroup>
              );}}
              />

        <ButtonBar>
            <Button color="secondary" disabled={priorityStatesMutation.isPending} onClick={handleSubmit(onSubmit)}>Save</Button>
        </ButtonBar>
      </div>
    </div>
  );
};

export default UserGeneralSettingsPage;
