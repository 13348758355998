import { FormField, Select, SelectMultiple, Spinner } from 'grommet';
import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
  useId,
  useMemo,
  useState
} from 'react';
import { Noop } from 'react-hook-form';

import { Lookup } from '@/types/lookup';

type LookupSelectProps<TLookupId = number, TMultiple extends boolean = false> = {
  options: Lookup<TLookupId>[];
  multiple?: TMultiple;
  placeholder?: string;
  required?: boolean;
  loading?: boolean;
  disabled?: boolean;
  name: string;
  value?: TMultiple extends true ? TLookupId[] : TLookupId;
  error?: ReactNode;
  onBlur?: Noop;
  onChange: (nextValue: TMultiple extends true ? TLookupId[] : TLookupId) => void;
  label?: string;
  clear?: { label: string };
};

const InnerLookupSelect = <TLookupId = number, TMultiple extends boolean = false>(props: LookupSelectProps<TLookupId, TMultiple>, ref: ForwardedRef<HTMLInputElement>) => {
  const {
    options,
    multiple,
    placeholder= 'Select...',
    required,
    loading,
    disabled  ,
    name,
    value,
    error,
    onBlur,
    onChange,
    label,
    clear
  } = props;

  const id = useId();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOptions = useMemo(() => {
    if (!options) {
      return [];
    }
    if (!searchTerm) {
      return options;
    }
    return options.filter(
      (lookup) => lookup.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [options, searchTerm]);

  return (
    <FormField
      required={required}
      name={name}
      htmlFor={id}
      disabled={disabled}
      error={error}
      label={label}
      margin="none"
      style={{ width: '100%' }}
    >
      {multiple ? (
        <SelectMultiple
          required={required}
          name={name}
          id={id}
          placeholder={placeholder}
          options={filteredOptions}
          value={value ? options.filter(({ id }) => (value as TLookupId[]).includes(id)) : []}
          labelKey="label"
          valueKey="id"
          disabled={loading || disabled}
          onSearch={setSearchTerm}
          onClose={() => setSearchTerm('')}
          sortSelectedOnClose={false}
          icon={loading ? <Spinner /> : true}
          onBlur={onBlur}
          onChange={(value: { value: Lookup<TLookupId>[] }) => {
            // @ts-expect-error TS(2345): Argument of type 'TLookupId | TLookupId[]' is not ... Remove this comment to see the full error message
            onChange(value.value.map((lookup) => lookup.id) as TLookupId | TLookupId[]);
          }}
          ref={ref}
        />
      ) : (
        <Select
          required={required}
          name={name}
          id={id}
          placeholder={placeholder}
          options={filteredOptions}
          // @ts-expect-error TS(2322): Type 'Lookup<TLookupId> | null | undefined' is not... Remove this comment to see the full error message
          value={value ? options.find(({ id }) => id === value) : null}
          labelKey="label"
          valueKey="id"
          disabled={loading || disabled}
          onSearch={setSearchTerm}
          onClose={() => setSearchTerm('')}
          icon={loading ? <Spinner /> : true}
          onBlur={onBlur}
          onChange={({ value: nextValue }) => onChange(nextValue?.id ?? null)}
          ref={ref}
          clear={clear}
        />
      )}
    </FormField>
  );
};

export const LookupSelect = forwardRef(InnerLookupSelect) as <TLookupId = number, TMultiple extends boolean = false> (props: LookupSelectProps<TLookupId, TMultiple> & { ref?: ForwardedRef<HTMLInputElement> }) => ReactElement;
