import { useReducer } from 'react';

import { CoverageEditDialogMode } from '@/features/coverage/components/coverage-edit-dialog-body';
import { StateCoverage } from '@/features/coverage/types/state-coverage';

/**
 * Defines a state machine (via `useReducer`) for the Coverage Edit Dialog.
 */
export const useEditDialog = () => {
  return useReducer(reducer, {
    mode: 'closed',
    history: [],
    previousOffset: 0,
    atFront: true,
    atEnd: true
  });
};

export type EditDialogState = {
  /**
   * Indicates the "mode" the dialog is in. Either:
   * - closed => the modal is closed
   * - single-pdl-edit => only editing one coverage row at a time
   * - full-pdl-review => allows moving forward and backwards between rows
   * - open-date-select => the first step of the Full PDL Review workflow: selecting a default PDL Status Date
   * - add => shows the "edit" form empty with a few additional fields necessary to create a coverage record
   */
  mode: CoverageEditDialogMode;

  /**
   * The PDL Status Date to use for the Full PDL Review workflow.
   */
  pdlStatusDate?: string;

  /**
   * The coverage row currently being edited.
   */
  currentCoverage?: StateCoverage;

  /**
   * A list of coverage rows that have been edited already, including the most recent one.
   *
   * This is used to facilitate the "previous" functionality, since it's possible that the user
   */
  history: StateCoverage[];

  /**
   * The offset from the _last element_ of the current record being viewed. By default it's 0, but as you
   * run the 'previous' action it increments. It gets used like this:
   * ```js
   * history[history.length - 1 - previousOffset]
   * ```
   */
  previousOffset: number;

  /**
   * Indicates whether we are at the most recent record we've looked at.
   */
  atFront: boolean;

  /**
   * Indicates whether we're at the very FIRST record we looked at, meaning there aren't
   * any more records to go back to.
   */
  atEnd: boolean;
};

type EditDialogAction = {
  type: 'close' | 'add';
} | {
  type: 'open';
  mode: Exclude<CoverageEditDialogMode, 'open-date-select' | 'closed'>;
  pdlStatusDate?: string;
  payload: StateCoverage;
} | {
  type: 'open-date-select';
} | {
  type: 'previous';
  updatedData: StateCoverage | null;
} | {
  type: 'next';
  updatedData: StateCoverage | null;
  nextProduct?: StateCoverage;
};

const reducer = (state: EditDialogState, action: EditDialogAction): EditDialogState => {
  switch (action.type) {
    // on close, reset the history and... close the dialog
    case 'close':
      return {
        mode: 'closed',
        // no product to edit
        history: [],
        previousOffset: 0,
        atFront: true,
        atEnd: true
      };

    case 'add':
      return {
        mode: 'add',
        // no product to edit since we're working on a brand new one
        history: [],
        previousOffset: 0,
        atFront: true,
        atEnd: true
      };

    // on open, we... open the dialog with the specified mode and product
    case 'open':
      if (state.mode === 'open-date-select' && !action.pdlStatusDate) {
        throw new Error('A PDL Status Date must be provided using this workflow!');
      }
      return {
        mode: action.mode,
        currentCoverage: action.payload,
        history: [action.payload],
        pdlStatusDate: action.pdlStatusDate,
        previousOffset: 0,
        atFront: true,
        atEnd: true
      };

    case 'previous': {
      const nextOffset = state.previousOffset + 1;
      const lastIndexOfHistory = state.history.length - 1;

      // if we've gone all the way back, just close the dialog
      if (nextOffset > lastIndexOfHistory) {
        return {
          mode: 'closed',
          // no product to edit
          history: [],
          previousOffset: 0,
          atFront: true,
          atEnd: true
        };
      }

      // otherwise, keep moving back

      // update the existing history record with the updated data. if the user goes back to it,
      // they'll see the values they entered
      let newHistory = state.history;
      if (action.updatedData) {
        newHistory = [...state.history];
        newHistory[lastIndexOfHistory - state.previousOffset] = action.updatedData;
      }

      return {
        ...state,
        history: newHistory,
        currentCoverage: state.history[lastIndexOfHistory - nextOffset],
        // increase the offset to move the "head" back by one index
        previousOffset: nextOffset,
        atFront: false,
        atEnd: nextOffset === lastIndexOfHistory
      };
    }

    case 'next': {
      // if we're already at the top, just push the new item onto the history "stack"
      if (state.previousOffset === 0) {
        const newHistory = [...state.history];
        // update the existing history record with the updated data. if the user goes back to it,
        // they'll see the values they entered
        if (action.updatedData) {
          newHistory[newHistory.length - 1] = action.updatedData;
        }
        // append the next record from the table
        // @ts-expect-error TS(2345): Argument of type 'ClinicalsProductStateProfile | u... Remove this comment to see the full error message
        newHistory.push(action.nextProduct);

        return {
          ...state,
          currentCoverage: action.nextProduct,
          history: newHistory,
          // sanity check
          previousOffset: 0,
          atFront: true,
          atEnd: false
        };
      }

      // otherwise, we're still looking at previous records, so no need to update history

      const nextOffset = Math.max(state.previousOffset - 1, 0);
      const lastIndexOfHistory = state.history.length - 1;
      const newHistory = [...state.history];
      // update the existing history record with the updated data. if the user goes back to it,
      // they'll see the values they entered
      if (action.updatedData) {
        newHistory[lastIndexOfHistory - state.previousOffset] = action.updatedData;
      }

      return {
        ...state,
        currentCoverage: state.history[lastIndexOfHistory - nextOffset],
        history: newHistory,
        // clamp it to 0 since it should never be negative
        previousOffset: nextOffset,
        atEnd: false
      };
    }

    case 'open-date-select':
      return { ...state, mode: 'open-date-select' };

    default:
      // if you hover over action, its type should be `never` if the switch cases are exhaustive.
      // type checks at the call-site for the dispatch function should prevent this from ever happening,
      // but doing this as a sanity check
      throw new Error(`Couldn't extract the type of action from ${JSON.stringify(action)}`);
  }
};
