import { useAsync, UseAsyncStatus } from '@shared/async';
import { ProtectedRoute } from '@shared/auth';
import { useErrorHandler } from '@shared/errors';
import { useHeaderDetails } from '@shared/header';
import { useNotifications } from '@shared/notifications';
import { Box } from 'grommet';
import { Stakeholder } from 'grommet-icons';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormCard } from '@/components/form-card';
import { useClientService } from '@/features/clients/api/use-clients-service';
import { ClientForm } from '@/features/clients/client-form';
import { ClientFormInputs } from '@/features/clients/models/client-form-inputs';

const NewClientPageContainer = () => {
  const { createClient } = useClientService();
  const { handleError } = useErrorHandler();
  const { success } = useNotifications();
  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();
  const navigate = useNavigate();

  const createClientAsync = useAsync(createClient);
  const saving = createClientAsync.status === UseAsyncStatus.Pending;

  React.useEffect(() => {
    setHeaderDetails({
      documentTitle: 'Add Client',
      pageTitle: 'Add Client',
      breadcrumbs: [
        { icon: Stakeholder, label: 'Clients', url: '/clients' },
        { label: 'Add Client', url: '/clients/new' }
      ]
    });

    return () => {
      clearHeaderDetails();
    };
  }, []);

  useEffect(() => {
    const { status, error } = createClientAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(
        error,
        {
          title: 'Create Client Failed',
          message: 'Unable to create Client',
          autoClose: false
        }
      );

      return;
    }

    success({ title: 'Success', message: 'Successfully created Client.', autoClose: true });
    navigate('/clients');
  }, [createClientAsync.status]);

  const handleFormSubmit = async (value: ClientFormInputs) => {
    await createClientAsync.execute(value);
  };

  const handleCancel = () => {
    navigate('/clients');
  };

  return (
    <Box gap="small">
      <FormCard>
        <ClientForm
          saving={saving}
          onSubmit={handleFormSubmit}
          onCancel={handleCancel}
        />
      </FormCard>
    </Box>
  );
};

const NewClientPage = () => {
  return <ProtectedRoute component={NewClientPageContainer} policies={['canManageClients']} />;
};

export default NewClientPage;
