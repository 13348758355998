import { LinkProps } from '@generouted/react-router/client';
import * as Headless from '@headlessui/react';
import React, { forwardRef } from 'react';

import { Link as RouterLink, Params, Path } from '@/router';

export const Link = forwardRef(function Link(
  props: { href: string | LinkProps<Path, Params> } & Omit<LinkProps<Path, Params>, 'to'>,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const hrefPropObject = typeof props.href === 'string' ? { to: props.href } : props.href;
  return (
    <Headless.DataInteractive>
      <RouterLink {...props} ref={ref} {...hrefPropObject as any} />
    </Headless.DataInteractive>
  );
});
