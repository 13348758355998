import { Box, FormField, Select, TextInput } from 'grommet';
import { Search } from 'grommet-icons';
import React from 'react';

import { GrommetFilterForm } from '@/components/filters';
import { useDrugUtilizations } from '@/features/drug-utilization';

type DrugUtilizationFiltersProps = {
  defaultValue: DrugUtilizationFilterData;
  onSearch: (data: DrugUtilizationFilterData) => void;
};

export const DrugUtilizationFilters = ({ defaultValue, onSearch }: DrugUtilizationFiltersProps) => {
  const {
    loadingLookups,
    stateLookups,
    yearLookups
  } = useDrugUtilizations();

  return (
    <GrommetFilterForm
      defaultValue={defaultValue}
      emptyValues={{
        ndc: '',
        product: '',
        state: '',
        // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'number | un... Remove this comment to see the full error message
        year: null
      }}
      onChange={(value) => onSearch(value)}
    >
      <Box width="medium">
        <FormField
          name="ndc"
          htmlFor="ndc"
          margin="none"
        >
          <TextInput
            type="search"
            icon={<Search color="accent-1" />}
            id="ndc"
            name="ndc"
            placeholder="Enter an NDC"
          />
        </FormField>
      </Box>
      <Box width="14rem">
        <FormField
          name="product"
          htmlFor="product"
          margin="none"
        >
          <TextInput
            type="search"
            icon={<Search color="accent-1" />}
            id="product"
            name="product"
            placeholder="Search by Product"
          />
        </FormField>
      </Box>
      {/*
        For the two Select components below, Grommet's <Form> component can't automatically
        set the default value, so we have to do it ourselves.
        */}
      <Box width="14rem">
        <FormField
          name="state"
          htmlFor="state"
          margin="none"
        >
          <Select
            id="state"
            name="state"
            options={stateLookups}
            labelKey="name"
            disabled={loadingLookups}
            defaultValue={defaultValue.state}
            valueKey={{ key: 'code', reduce: true }}
            placeholder={loadingLookups ? 'Select a State' : 'All States'}
            dropHeight="medium"
            clear={{ label: 'Select All States' }}
          />
        </FormField>
      </Box>
      <Box width="14rem">
        <FormField
          name="year"
          htmlFor="year"
          margin="none"
        >
          <Select
            id="year"
            name="year"
            options={yearLookups}
            defaultValue={defaultValue.year}
            disabled={loadingLookups}
            valueKey={{ key: 'id', reduce: true }}
            placeholder="Select a Year"
            dropHeight="medium"
            clear={{ label: 'Clear Selection' }}
          />
        </FormField>
      </Box>
    </GrommetFilterForm>
  );
};

export type DrugUtilizationFilterData = {
  ndc?: string;
  product?: string;
  state?: string;
  year?: number;
};
