import { Box } from 'grommet';

import TextInputSkeleton from '@/components/loading/text-input-skeleton';
import TextSkeleton from '@/components/loading/text-skeleton';

const FormFieldSkeleton = () => {
  return (
    <Box gap="xsmall">
      <TextSkeleton width="small" />
      <TextInputSkeleton />
    </Box>
  );
};

export default FormFieldSkeleton;
