import { FormField } from 'grommet';
import React, { useId } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ClassificationFilter } from '@/components/classification-filter';
import { ClinicalsProductFilter } from '@/components/clinicals-product-filter';
import { RhfFilterForm } from '@/components/filters';
import { DateTextInput, InMemoryMultiSelectList } from '@/components/form-controls';
import { StateAndPoolFilter } from '@/components/state-and-pool-filter';

import { BoardMeetingFilterData, generateDefaultBoardMeetingFilters } from '../api';
import { useBoardMeetingsProvider } from './board-meetings-provider';

type BoardMeetingFiltersProps = {
  /**
   * Initial value for the form, required.
   */
  defaultValues: BoardMeetingFilterData;

  /**
   * Submit handler to update.
   */
  onSubmit: (filterData: BoardMeetingFilterData) => void;
};

/**
 * Filters for the Board Meeting list.
 */
export const BoardMeetingFilters = ({ defaultValues, onSubmit }: BoardMeetingFiltersProps) => {
  const {
    control,
    handleSubmit,
    reset,
    watch
  } = useForm<BoardMeetingFilterData>({
    defaultValues
  });
  const { states, pools, meetingTypes } = useBoardMeetingsProvider();
  const id = useId();

  return (
    <RhfFilterForm
      watch={watch}
      onReset={() => reset(generateDefaultBoardMeetingFilters())}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        control={control}
        name="statesOrPools"
        render={({ field: { value, onChange } }) => (
          <StateAndPoolFilter
            states={states}
            pools={pools}
            selected={value}
            onSelectionChange={onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="meetingTypes"
        render={({ field: { value, onChange } }) => (
          <InMemoryMultiSelectList
            placeholder="Search by Meeting Type"
            options={meetingTypes}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="classes"
        render={({ field: { value, onChange } }) => (
          <ClassificationFilter selected={value} onSelectionChange={onChange} />
        )}
      />
      <Controller
        control={control}
        name="drugs"
        render={({ field: { value, onChange } }) => (
          <ClinicalsProductFilter
            selected={value}
            onSelectionChange={onChange}
            dataSource="drugs"
          />
        )}
      />
      <Controller
        control={control}
        name="dateRangeBegin"
        render={({ field: { name, onChange, onBlur, value } }) => (
          <FormField
            required
            name={name}
            htmlFor={`${id}-${name}`}
            label="Start"
            margin="none"
          >
            <DateTextInput
              name={name}
              id={`${id}-${name}`}
              value={value}
              onChange={({ target }) => onChange((target as any).value)}
              onBlur={onBlur}
            />
          </FormField>
        )}
      />
      <Controller
        control={control}
        name="dateRangeEnd"
        render={({ field: { name, onChange, onBlur, value } }) => (
          <FormField
            required
            name={name}
            label="End"
            htmlFor={`${id}-${name}`}
            margin="none"
          >
            <DateTextInput
              name={name}
              id={`${id}-${name}`}
              value={value}
              onChange={({ target }) => onChange((target as any).value)}
              onBlur={onBlur}
            />
          </FormField>
        )}
      />
    </RhfFilterForm>
  );
};
