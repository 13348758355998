/**
 * Returns the number of minutes since midnight for a given time. For example,
 * '7:30' would return 450 (7 * 60 + 30).
 *
 * @param time The time string, such as from a time input control.
 */
export const getMinutesFromTime = (time: string) => {
  const [hours, minutes] = time.split(':').map(segment => parseInt(segment));
  return hours * 60 + minutes;
};
