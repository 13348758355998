import { useHttpClient } from '@shared/http';

import { User } from '@/features/admin/types/user';
import { Client } from '@/features/clients';
import { CreateUpdateClientUserFormSchema } from '@/features/clients/models/create-update-client-user-form-schema';
import { SubscriptionFormInputs } from '@/features/clients/models/subscription-form-inputs';

import { ClientFormInputs } from '../models/client-form-inputs';

type ClientRequest = {
  name: string
  assignedAnalysts: number[],
  trackedClinicalsProducts?: number[],
  trackedClassifications?: number[]
};

export const useClientService = () => {
  const httpClient = useHttpClient();

  const getClients = (): Promise<Client[]> => {
    return httpClient.get('clients');
  };

  const getClient = (id: number): Promise<Client> => {
    return httpClient.get(`clients/${id}`);
  };

  const getClientUsers = (id: number): Promise<User[]> => {
    return httpClient.get(`clients/${id}/users`);
  };

  const getClientUser = (clientId: number, userId: number): Promise<User> => {
    return httpClient.get(`clients/${clientId}/users/${userId}`);
  };

  const createClientRequest = ({ name, assignedAnalysts }: ClientFormInputs): ClientRequest => {
    return {
      name,
      assignedAnalysts: assignedAnalysts.map(analyst => analyst.id)
    };
  };

  const createClient = (formInputs: ClientFormInputs): Promise<any> => {
    return httpClient.post('clients', { data: createClientRequest(formInputs) });
  };

  const updateClient = (id: number | string, formInputs: ClientFormInputs): Promise<any> => {
    return httpClient.put(`clients/${id}`, { data: createClientRequest(formInputs) });
  };

  const deleteClient = (id: number): Promise<void> => {
    return httpClient.delete(`clients/${id}`);
  };

  const updateSubscription = (id: number, formInputs: SubscriptionFormInputs): Promise<any> => {
    return httpClient.put(`clients/${id}/subscription`, {
      data: {
        isActive: formInputs.isActive,
        numberOfUsers: formInputs.numberOfUsers ? parseInt(formInputs.numberOfUsers) : null,
        enabledFeatures: formInputs.premiumFeatures,
        trackedDrugs: formInputs.trackedDrugs.map(drug => drug.id),
        trackedClassifications: formInputs.trackedClassifications.map(classification => classification.id)
      }
    });
  };

  const createClientUser = (clientId: number, formInputs: CreateUpdateClientUserFormSchema): Promise<{ userId: number }> => {
    return httpClient.post(`clients/${clientId}/users`, {
      data: {
        ...formInputs
      }
    });
  };

  const deleteClientUser = (clientId: number, userId: number): Promise<void> => {
    return httpClient.delete(`clients/${clientId}/users/${userId}`);
  };

  const updateClientUser = (clientId: number, userId: number, formInputs: CreateUpdateClientUserFormSchema): Promise<void> => {
    return httpClient.put(`clients/${clientId}/users/${userId}`, {
      data: {
        ...formInputs,
      }
    });
  };

  const updateClientPrimaryAdmin = (clientId: number, userId: number): Promise<void> => {
    return httpClient.put(`clients/${clientId}/primary-admin`, {
      data: {
        userId
      }
    });
  };

  const resetClientUserPassword = (clientId: number, userId: number): Promise<void> => {
    return httpClient.post(`clients/${clientId}/users/${userId}/reset-password`, {
      data: {
        userId
      }
    });
  };

  return {
    deleteClient,
    getClients,
    getClient,
    getClientUsers,
    getClientUser,
    createClient,
    updateClient,
    deleteClientUser,
    updateSubscription,
    createClientUser,
    updateClientUser,
    updateClientPrimaryAdmin,
    resetClientUserPassword
  };
};
