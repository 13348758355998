import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, Button } from 'grommet';
import { Drag, Trash } from 'grommet-icons';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Lookup } from '@/types/lookup';

type ColumnDndProps = {
  value: Lookup<string>[];
  onChange: (newValue: Lookup<string>[]) => void;
};

export const ColumnDnd = ({ value, onChange }: ColumnDndProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={({ active, over }) => {
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        if (active.id !== over.id) {
          const oldIndex = value.findIndex((c) => c.id === active.id);
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          const newIndex = value.findIndex((c) => c.id === over.id);
          onChange(arrayMove(value, oldIndex, newIndex));
        }
      }}
    >
      <SortableContext items={value} strategy={verticalListSortingStrategy}>
        <Box gap="medium">
          {value.map((column) => (
            <SortableItem key={column.id} id={column.id}>
              <Box direction="row" justify="between" align="center" width="100%">
                <span>{column.label}</span>
                <Button
                  a11yTitle="Remove"
                  title="Remove"
                  type="button"
                  onClick={() => onChange(value.filter((c) => c.id !== column.id))}
                  icon={<Trash/>}
                />
              </Box>
            </SortableItem>
          ))}
        </Box>
      </SortableContext>
    </DndContext>
  );
};

const SortableItem = ({ id, children }: { id: string, children: ReactNode }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <SortableItemContainer ref={setNodeRef} style={style}>
      <DragHandle {...attributes} {...listeners} />
      {children}
    </SortableItemContainer>
  );
};

const SortableItemContainer = styled.div`
  background: ${({ theme }) => theme.global.colors['light-2']};
  padding: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;

const DragHandle = styled(Drag)`
  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
`;
