import { useAsync, UseAsyncStatus } from '@shared/async';
import { ProtectedRoute } from '@shared/auth';
import { useErrorHandler } from '@shared/errors';
import { useHeaderDetails } from '@shared/header';
import { useNotifications } from '@shared/notifications';
import { PaginationResult } from '@shared/pagination';
import { Box, Pagination } from 'grommet';
import { Download } from 'grommet-icons';
import React, { useEffect, useMemo, useState } from 'react';

import { ArtiaButton } from '@/components/artia-button';
import { useFilters } from '@/components/filters';
import {
  ClinicalsChangeLogSearchRequest,
  useClinicalsChangeLogService
} from '@/features/changelog/api/use-clinicals-change-log-service';
import {
  ClinicalsChangeLogFilters,
  ClinicalsChangeLogSnapshotDialog,
  ClinicalsChangeLogTable
} from '@/features/changelog/components/index';
import { FilterInputs } from '@/features/changelog/components/index/clinicals-change-log-filters';
import { ClinicalsChangeLog } from '@/features/changelog/types';

const INITIAL_PAGINATION = {
  page: 1,
  total: 0,
  resultsPerPage: 25
};

const ChangelogContainer = () => {
  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();
  const { handleError } = useErrorHandler();
  const { loading, dismiss } = useNotifications();

  const [searchRequest, setSearchRequest] = useFilters<FilterInputs>('changelog', {
    updatedAtFrom: '',
    updatedAtUntil: '',
    states: [],
    clientIds: [],
    product: '',
    properties: [],
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'PropertySea... Remove this comment to see the full error message
    propertySearchType: null,
    user: ''
  });
  const [pagination, setPagination] = useState(INITIAL_PAGINATION);
  const [searchResult, setSearchResult] = useState<PaginationResult<ClinicalsChangeLog>>({
    ...INITIAL_PAGINATION,
    items: []
  });

  const { exportChangeLog, fetchChangeLog } = useClinicalsChangeLogService();

  const fetchAsync = useAsync(fetchChangeLog);

  useEffect(() => {
    setHeaderDetails({
      documentTitle: 'Changelog',
      pageTitle: 'Changelog',
      breadcrumbs: []
    });
    return () => {
      clearHeaderDetails();
    };
  }, []);

  useEffect(() => {
    const request: ClinicalsChangeLogSearchRequest = {
      ...searchRequest,
      ...pagination,
      properties: searchRequest.properties ?? [],
      propertySearchType: searchRequest.propertySearchType ?? 'onlySelected',
      rpp: pagination.resultsPerPage
    };

    void fetchAsync.execute(request);
  }, [searchRequest, pagination]);

  const loadingLogs = useMemo(
    () => fetchAsync.status === UseAsyncStatus.Pending,
    [fetchAsync.status]
  );

  useEffect(() => {
    const { status, error, value } = fetchAsync;

    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(
        error,
        {
          title: 'Failed to get Changelog',
          message: 'Unable to get changelog.',
          autoClose: false
        }
      );

      return;
    }

    setSearchResult(value);
  }, [fetchAsync.status]);

  const handleApplyFilters = async (filterInputs: FilterInputs) => {
    const {
      updatedAtFrom,
      updatedAtUntil,
      states,
      clientIds,
      product,
      user,
      properties,
      propertySearchType,
      ...rest
    } = filterInputs;
    setSearchRequest((current) => ({
      ...current,
      ...rest,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      updatedAtFrom: updatedAtFrom?.length > 0 ? updatedAtFrom : undefined,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      updatedAtUntil: updatedAtUntil?.length > 0 ? updatedAtUntil : undefined,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      states: states?.length > 0 ? states : undefined,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      clientIds: clientIds?.length > 0 ? clientIds : undefined,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      product: product?.length > 0 ? product : undefined,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      user: user?.length > 0 ? user: undefined,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      properties: properties?.length > 0 ? properties : undefined,
      propertySearchType
    }));
    setPagination((current) => ({
      ...current,
      page: 1
    }));
  };

  const handlePaginationChange = ({ page }: { page: number }) => {
    setPagination((current) => ({
      ...current,
      page
    }));
  };

  const [viewingSnapshot, setViewingSnapshot] = useState<ClinicalsChangeLog>();

  const handleViewSnapshot = (row: ClinicalsChangeLog) => setViewingSnapshot(row);

  const handleCloseSnapshot = () => setViewingSnapshot(undefined);

  const handleExport = async (filters: FilterInputs) => {
    const loadingNotification = loading({
      title: 'Exporting Changelogs',
      message: 'Please wait while we prepare your export.'
    });

    const {
      updatedAtFrom,
      updatedAtUntil,
      states,
      clientIds,
      product,
      user,
      properties,
      propertySearchType
    } = filters;

    try {
      const request = {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        updatedAtFrom: updatedAtFrom?.length > 0 ? updatedAtFrom : undefined,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        updatedAtUntil: updatedAtUntil?.length > 0 ? updatedAtUntil : undefined,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        states: states?.length > 0 ? states : undefined,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        clientIds: clientIds?.length > 0 ? clientIds : undefined,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        product: product?.length > 0 ? product : undefined,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        user: user?.length > 0 ? user: undefined,
        propertySearchType: propertySearchType ?? 'onlySelected',
      };

      const requestProperties = properties != null && properties.length > 0 ? properties : undefined;

      const file = await exportChangeLog({
        ...request,
        properties: requestProperties
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(file.contents);
      link.download = file.filename;
      link.click();
    } catch (ex) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      handleError(ex, {
        title: 'Failed to Export',
        message: 'Unable to export changelogs.',
        autoClose: false
      });
    } finally {
      dismiss(loadingNotification.id);
    }
  };

  return <>
    <ClinicalsChangeLogFilters
      disabled={loadingLogs}
      defaultFilters={searchRequest}
      onApplyFilters={handleApplyFilters}
    >
      <ArtiaButton
        icon={<Download />}
        a11yTitle="Export to CSV"
        tip="Export to CSV"
        onClick={() => handleExport(searchRequest)}
      />
    </ClinicalsChangeLogFilters>

    <ClinicalsChangeLogTable data={searchResult.items} loading={loadingLogs} onViewSnapshot={handleViewSnapshot} />
    {searchResult?.total > 0 && <Box direction="row" justify="center" pad={{ top: '20px' }}>
      <Pagination
        size="medium"
        page={searchResult.page}
        step={searchResult.resultsPerPage}
        numberItems={searchResult.total}
        onChange={handlePaginationChange}
      />
    </Box>}

    <ClinicalsChangeLogSnapshotDialog
      open={!!viewingSnapshot}
      row={viewingSnapshot}
      onClose={handleCloseSnapshot}
    />
  </>;
};

const Changelog = () => {
  return <ProtectedRoute component={ChangelogContainer} policies={['isAnyArtiaUser']} />;
};

export default Changelog;
