import { Box, Text } from 'grommet';
import React from 'react';

import { OverridableCalculatedValue } from '@/types/overridable-calculated-value';
import { formatCurrency } from '@/utils/formatting';

type AmpCellProps = {
  amp?: OverridableCalculatedValue;
};

export const AmpCell = (props: AmpCellProps) => {
  const { amp } = props;

  return (
    <Box width={{ min: '8rem' }}>
      {amp?.overrideValue != null &&
        <Text>
          {formatCurrency(amp.overrideValue, 2, 2)}
        </Text>
      }

      {amp?.overrideValue == null && amp?.calculatedValue?.value != null &&
        <Text>
          {formatCurrency(amp.calculatedValue.value, 2, 2)}
        </Text>
      }
    </Box>
  );
};
