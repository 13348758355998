import { Box, RadioButtonGroup, Text } from 'grommet';
import React from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import styled from 'styled-components';

import { BorderlessFormField } from '@/components/form-controls';
import { displayMeetingFormat, MeetingFormat } from '@/types/board-meetings';

import { useBoardMeetingsProvider } from './board-meetings-provider';
import { InPersonMeetingLocationForm } from './in-person-meeting-location-form';
import { BoardMeetingFormData } from './types';
import { VirtualMeetingLocationForm } from './virtual-meeting-location-form';

type MeetingLocationSectionProps = {
  id: string;
  control: Control<BoardMeetingFormData>
};

/**
 * Indicates where the location is taking place. Can be virtual, in person, or both.
 */
export const MeetingLocationSection = ({ id, control }: MeetingLocationSectionProps) => {
  const { states } = useBoardMeetingsProvider();
  const meetingFormat = useWatch({ name: 'format', control });

  return (
    <ThirdSection>
      <Controller
        name="format"
        rules={{
          required: 'Format is a required field'
        }}
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <Box style={{ gridColumnStart: 'span 4' }}>
            <Text as="label" margin="small" weight="bold">Meeting Format</Text>
            <BorderlessFormField
              name={name}
              htmlFor={`${id}-${name}`}
              error={error?.message}
              required
            >
              <RadioButtonGroup
                onBlur={onBlur}
                onChange={onChange}
                // @ts-expect-error TS(2322): Type 'MeetingFormat | null' is not assignable to t... Remove this comment to see the full error message
                value={value}
                direction="row"
                name={name}
                id={`${id}-${name}`}
                ref={ref}
                options={[
                  MeetingFormat.VIRTUAL,
                  MeetingFormat.IN_PERSON,
                  MeetingFormat.HYBRID
                ].map(x => ({ id: x, label: displayMeetingFormat(x), value: x }))}
              />
            </BorderlessFormField>
          </Box>
        )}
      />
      {meetingFormat === MeetingFormat.IN_PERSON || meetingFormat === MeetingFormat.HYBRID ? (
        <InPersonMeetingLocationForm control={control} states={states} />
      ) : null}
      {meetingFormat === MeetingFormat.VIRTUAL || meetingFormat === MeetingFormat.HYBRID ? (
        <VirtualMeetingLocationForm control={control} />
      ) : null}
    </ThirdSection>
  );
};

const ThirdSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${({ theme }) => theme.global.colors['light-3']};
  padding: 1.5rem;
`;
