import { ButtonExtendedProps, Menu } from 'grommet';
import { Configure } from 'grommet-icons';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { StateDataTab } from './state-data-tabs';

type OpenReportsMenuProps = {
  allTabs: Omit<StateDataTab, 'active'>[];
  openedTabs: StateDataTab[];
  setOpenedTabs: Dispatch<SetStateAction<StateDataTab[]>>;
};

export const OpenReportsMenu = ({ allTabs, openedTabs, setOpenedTabs }: OpenReportsMenuProps) => {
  const navigate = useNavigate();

  const unopenedTabs = useMemo(() => {
    const unopenedTabs = [...allTabs];

    for (const openedTab of openedTabs) {
      const openedTabIndex = unopenedTabs.findIndex((tab) => tab.key === openedTab.key);
      if (openedTabIndex === -1) {
        continue;
      }
      unopenedTabs.splice(openedTabIndex, 1);
    }

    unopenedTabs.sort((a, b) => a.name.localeCompare(b.name));

    return unopenedTabs;
  }, [allTabs, openedTabs]);

  const addNewTab = (newTab: Omit<StateDataTab, 'active'>) => {
    setOpenedTabs((current) => {
      const result = current.map((tab) => ({
        ...tab,
        active: false
      }));
      result.push({ ...newTab, active: true });
      return result;
    });
  };

  const unopenedUserTabs: Omit<StateDataTab, 'active'>[] = [];
  const unopenedSystemTabs: Omit<StateDataTab, 'active'>[] = [];

  for (const tab of unopenedTabs) {
    if (tab.userCreated) {
      unopenedUserTabs.push(tab);
    } else {
      unopenedSystemTabs.push(tab);
    }
  }

  const menuItems: ButtonExtendedProps[][] = [
    [{
      key: 'manage-reports',
      label: 'Manage Reports',
      icon: <Configure />,
      gap: 'xsmall',
      onClick: () => navigate('/state-reports/manage-reports')
    }]
  ];

  if (unopenedUserTabs.length > 0) {
    menuItems.push(unopenedUserTabs.map((unopenedTab) => ({
      label: unopenedTab.name,
      key: unopenedTab.key,
      onClick: () => addNewTab(unopenedTab)
    })));
  }

  if (unopenedSystemTabs.length > 0) {
    menuItems.push(unopenedSystemTabs.map((unopenedTab) => ({
      label: unopenedTab.name,
      key: unopenedTab.key,
      onClick: () => addNewTab(unopenedTab)
    })));
  }

  return (
    <Menu
      a11yTitle="Open Report"
      label="Open Report"
      items={menuItems}
    />
  );
};
