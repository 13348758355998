import { useErrorHandler } from '@shared/errors';
import { PaginationInfo } from '@shared/pagination';
import React, { createContext, useContext, useMemo, useState } from 'react';

import { useFilters } from '@/components/filters';
import { Nadac } from '@/features/nadacs';
import { NadacSearchRequest, useNadacsService } from '@/features/nadacs';

type NadacsContextValue = {
  nadacs: Nadac[];
  searchInfo: { ndc: string, description: string } & PaginationInfo;
  searchingNadacs: boolean;
  searchNadacs: (searchRequest: NadacSearchRequest) => void;
};

const DEFAULT_CONTEXT_VALUE: NadacsContextValue = {
  nadacs: [],
  searchInfo: {
    ndc: '',
    description: '',
    page: 1,
    resultsPerPage: 10,
    total: 0
  },
  searchingNadacs: false,
  searchNadacs: () => null
};

const NadacsContext = createContext<NadacsContextValue>(DEFAULT_CONTEXT_VALUE);

export const useNadacs = () => useContext<NadacsContextValue>(NadacsContext);

type NadacsProps = {
  children?: React.ReactNode
};

export const NadacsProvider = ({ children }: NadacsProps) => {
  const { handleError } = useErrorHandler();
  const { searchNadacs } = useNadacsService();

  const [nadacs, setNadacs] = useState<Nadac[]>([]);
  const [searchInfo, setSearchInfo] = useFilters('nadacs', {
    ndc: DEFAULT_CONTEXT_VALUE.searchInfo.ndc,
    description: DEFAULT_CONTEXT_VALUE.searchInfo.description
  });
  const [pagination, setPagination] = useState<PaginationInfo>({
    page: 1,
    resultsPerPage: 10,
    total: 0
  });
  const [searchingNadacs, setSearchingNadacs] = useState(false);

  const search = async (searchRequest: NadacSearchRequest) => {
    setSearchingNadacs(true);
    setSearchInfo({
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      ndc: searchRequest.ndc,
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      description: searchRequest.description
    });
    // @ts-expect-error TS(2345): Argument of type '(pagination: PaginationInfo) => ... Remove this comment to see the full error message
    setPagination((pagination) => ({
      ...pagination,
      page: searchRequest.page,
      resultsPerPage: searchRequest.rpp
    }));

    try {
      const result = await searchNadacs(searchRequest);

      setNadacs(result.items);
      setSearchInfo({
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        ndc: searchRequest.ndc,
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        description: searchRequest.description,
      });
      setPagination({
        page: result.page,
        resultsPerPage: result.resultsPerPage,
        total: result.total
      });
    } catch (ex) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      handleError(ex, {
        title: 'NADAC Search Failed',
        message: 'We encountered an unexpected error while search for NADAC information. Please try again or contact an administrator.',
        autoClose: false
      });
    } finally {
      setSearchingNadacs(false);
    }
  };

  const combinedSearch = useMemo(() => ({
    ...searchInfo,
    ...pagination
  }), [searchInfo, pagination]);

  const contextValue: NadacsContextValue = {
    nadacs,
    searchInfo: combinedSearch,
    searchNadacs: search,
    searchingNadacs
  };

  return (
    <NadacsContext.Provider value={contextValue}>
      {children}
    </NadacsContext.Provider>
  );
};
