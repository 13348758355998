import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { useModal } from '@shared/modal';
import { useNotifications } from '@shared/notifications';
import { type GrommetOnSort } from '@shared/sorting';
import { Box, DataTable } from 'grommet';
import { Calculator, Download, Link as LinkIcon, Trash, Upload } from 'grommet-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ArtiaButton } from '@/components/artia-button';
import { DrugMarketDetailsFilters } from '@/components/drug-market-details-filters';
import { useFilters } from '@/components/filters';
import { TableEmptyPlaceholder, TableLoadingOverlay } from '@/components/loading';
import { RowPinSelector } from '@/components/row-pin-selector';
import { UpdateOverridesRequest, useMarketBasketService } from '@/features/market-baskets/api/use-market-baskets-service';
import { CalculationDetails, MarketBasketDrugPackaging } from '@/features/market-baskets/types/market-basket';
import { MarketBasketOverridesFormInputs } from '@/features/market-baskets/types/market-basket-overrides-form-inputs';
import { useCurrentUser } from '@/hooks/use-current-user';
import { useToggle } from '@/hooks/use-toggle';
import { ACCENT_WEAK, STATUS_WARNING_WEAK } from '@/styles';
import { formatCurrency } from '@/utils/formatting';

import { useMarketBasketDetails } from '../../market-basket-details.provider';
import { AssociateNdcsDialog } from '../index/associate-ndcs-dialog';
import { CalculationLogDialog } from '../index/calculation-log-dialog';
import { DeleteNdcsDialog } from '../index/delete-ndcs-dialog';
import { ImportMarketDetailsPanel } from '../index/import-market-details-panel';
import { InvalidNdcSelectionDialog } from '../index/invalid-ndc-selection-dialog';
import { resolvePinners, resolvePinning, RowPinKeys, rowPinOptions } from '../index/row-pinning';
import { drugPackagingsSorters, MarketBasketDrugPackagingSorterKeys } from '../index/sorters';
import { ActionsCell } from './actions-cell';
import { AmpCell } from './amp-cell';
import { BrandGenericStatusIndicator } from './brand-generic-status-indicator';
import { EditDrugPackagingDialog } from './edit-drug-packaging-dialog';
import { EditingActionsCell } from './editing-actions-cell';
import { EstimatedTotalUraCell } from './estimated-total-ura-cell';
import { EstimatedUroaCell } from './estimated-uroa-cell';
import { NdcCell } from './ndc-cell';
import { UraPercentWacCell } from './ura-percent-wac-cell';
import { WacPriceCell } from './wac-price-cell';
import { WacUnitPriceCell } from './wac-unit-price-cell';

export const DrugMarketDetails = () => {
  const { marketBasket, loadingMarketBasket, loadMarketBasket } = useMarketBasketDetails();
  const { handleError } = useErrorHandler();
  const location = useLocation();
  const { deleteMarketBasketRows, exportMarketBasket, recalculate, updateOverrides } = useMarketBasketService();
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const { success, loading, dismiss } = useNotifications();
  const { id } = useParams();

  const [ filters, setFilters ] = useFilters<{ ndc?: string, name?: string, product?: string }>(`market-baskets-${id}-drug-market-details`, {});
  const [ recalculating, setRecalculating ] = useState(false);
  const [ selectedNdcs, setSelectedNdcs ] = useState<string[]>([]);
  const [ selectedRowPinIds, setSelectedRowPinIds ] = useState<(string | number | readonly string[])[]>([RowPinKeys.belongsToMarketBasket]);
  const [ sort, setSort ] = useState<GrommetOnSort>({ property: 'productName', direction: 'asc' });
  const [ showInvalidNdcSelectionDialog, toggleInvalidNdcSelectionDialog ] = useToggle(false);
  const [ showAssociateUnknownPackagingsDialog, toggleAssociateUnknownPackagingsDialog ] = useToggle(false);
  const [ showDeleteRowsDialog, toggleDeleteRowsDialog ] = useToggle(false);
  const [ showCalculationLogDialog, toggleCalculationLogDialog ] = useToggle(false);
  const [ drugPackagingToEdit, setDrugPackagingToEdit ] = useState<MarketBasketDrugPackaging>();
  const [ deletingRows, setDeletingRows ] = useState(false);
  const [ calculationDetails, setCalculationDetails ] = useState<CalculationDetails>();
  const [ drugPackagingsToOverride, setDrugPackagingsToOverride ] = useState<MarketBasketDrugPackaging[]>([]);
  const [lastNdcOverridden, setLastNdcOverridden] = useState<string>();

  const {
    control,
    setValue,
    getValues,
    getFieldState,
    resetField,
    formState: { dirtyFields }
  } = useForm<MarketBasketOverridesFormInputs>({ mode: 'all' });

  const { user } = useCurrentUser();

  // @ts-expect-error TS(2322): Type '{} | undefined' is not assignable to type 'M... Remove this comment to see the full error message
  const initialFormValues: MarketBasketOverridesFormInputs = useMemo(() => {
    if (!marketBasket) return;

    const { drugPackagings } = marketBasket;

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    return drugPackagings.reduce(
      (previous, current) => {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        const wac = current.currentWacPrice.overrideValue?.toString() ?? current.currentWacPrice.compendiumValue?.toString() ?? '';
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        const wacUnit = current.currentWacUnitPrice.overrideValue?.toString() ?? current.currentWacUnitPrice.compendiumValue?.toString() ?? '';
        const amp = current.amp.overrideValue?.toString() ?? current.amp.calculatedValue?.value?.toString() ?? '';
        const ura = current.totalUra.overrideValue?.toString() ?? current.totalUra.calculatedValue?.value?.toString() ?? '';
        const uroa = current.uroa.overrideValue?.toString() ?? current.uroa.calculatedValue?.value?.toString() ?? '';

        return { ...previous, [current.ndc]: { wac, wacUnit, amp, ura, uroa } };
      },
      {}
    );
    // TODO: revisit missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketBasket?.drugPackagings]);

  useEffect(() => {
    if (!initialFormValues) {
      return;
    }

    const dirtyNdcs = Object.keys(dirtyFields);

    Object.keys(initialFormValues)
      .filter(key => !dirtyNdcs.includes(key))
      .forEach(key => setValue(key, initialFormValues[key]));

    // TODO: revisit missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormValues]);

  const showEditDrugPackagingDialog = !!drugPackagingToEdit;

  const selectedDrugPackagings = useMemo(() => {
    return marketBasket?.drugPackagings?.filter(packaging => selectedNdcs.includes(packaging.ndc)) || [];
    // TODO: revisit missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNdcs]);

  /**
   * The selected row pins, as a list of options.
   */
  const selectedRowPins = useMemo(() => {
    return rowPinOptions
      .filter(option => selectedRowPinIds.includes(option.value as any));
  }, [selectedRowPinIds]);

  /**
   * Drug packagings, filtered, sorted and pinned.
   */
  const filteredPackagings = useMemo(() => {
    if (!marketBasket?.drugPackagings) {
      return [];
    }

    const { ndc, name, product } = filters;

    const filtered = marketBasket.drugPackagings.filter(packaging =>
      (!ndc || packaging.ndc?.includes(ndc))
      && (!name || packaging.name?.toLowerCase().includes(name.toLowerCase()))
      && (!product || packaging.productName?.toLowerCase().includes(product.toLowerCase()))
    );

    const sorter = (items: MarketBasketDrugPackaging[]) =>
      (drugPackagingsSorters[sort.property as MarketBasketDrugPackagingSorterKeys] || drugPackagingsSorters.default)(items, sort.direction);

    const rowPinKeys = selectedRowPins.map(pin => pin.value as RowPinKeys);
    const rowPinners = resolvePinners(rowPinKeys);

    const sortedRowsForPinning = resolvePinning(rowPinners, sorter, filtered);
    const remainingRows = sorter(filtered.filter(packaging => !sortedRowsForPinning.includes(packaging)));

    return [...sortedRowsForPinning, ...remainingRows];
  }, [filters, marketBasket, sort, selectedRowPins]);

  React.useEffect(() => {
    if (location.hash === '#import') {
      openModal(
        {
          // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          component: <ImportMarketDetailsPanel marketBasketId={parseInt(id)} getDrugMarketDetails={() => loadMarketBasket(parseInt(id))} />,
          header: 'Import Market Details'
        },
        { onClose: () => closeImportModal() }
      );
    }
    // TODO: revisit missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const openAssociateNdcsDialog = async () => {
    const hasInvalidSelection = selectedDrugPackagings
      .some(packaging => packaging.productName !== null);

    if (hasInvalidSelection) {
      toggleInvalidNdcSelectionDialog();
      return;
    }

    toggleAssociateUnknownPackagingsDialog();
  };

  const handleTableSort = (sort: GrommetOnSort) => {
    setSort(sort);
  };

  /**
   * Called when a user confirms their choice to delete one or more NDCs (drug
   * packagings) from the market basket.
   */
  const requestDeleteRows = async () => {
    setDeletingRows(true);

    try {
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      const intId = parseInt(id);
      await deleteMarketBasketRows(intId, selectedNdcs);
      loadMarketBasket(intId);
    } catch (ex) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      handleError(ex, {
        title: 'Deletion Failed',
        message: 'We encountered an unexpected error while deleting the rows you selected.',
        autoClose: false
      });
    } finally {
      setSelectedNdcs([]);
      setDeletingRows(false);
      toggleDeleteRowsDialog();
    }
  };

  const onExport = async () => {
    try {
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      const csv = await exportMarketBasket(parseInt(id));

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(csv.contents);
      link.download = csv.filename;
      link.click();
    } catch (ex) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      handleError(ex, {
        title: 'Export to CSV Failed',
        message: 'We encountered an unexpected error while exporting the market basket. Please try again or contact an administrator.',
        autoClose: false
      });
    }
  };

  const onRecalculate = async () => {
    setRecalculating(true);
    const loadingNotification = loading({ title: 'Recalculating', message: 'Please wait while we recalculate the market basket.' });

    try {
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      const intId = parseInt(id);
      await recalculate(intId);
      loadMarketBasket(intId);
      success({ title: 'Recalculation Succeeded', message: 'Successfully recalculated.', autoClose: true });
    } catch (ex) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      handleError(ex, {
        title: 'Recalculation Failed',
        message: 'We encountered an unexpected error while recalculating. Please try again or contact an administrator.',
        autoClose: false
      });
    } finally {
      dismiss(loadingNotification.id);
      setRecalculating(false);
    }
  };

  const openImportModal = () => {
    navigate(location.pathname + '#import', { replace: false });
  };

  const closeImportModal = () => {
    navigate(location.pathname, { replace: false });
    closeModal();
  };

  const viewCalculationDetails = (calculationDetails: CalculationDetails) => {
    setCalculationDetails(calculationDetails);
    toggleCalculationLogDialog();
  };

  const handleRowPinSelectionChange = (selected: (string | number | readonly string[])[]) => {
    setSelectedRowPinIds(selected);
  };

  /**
   * Called when a user toggles the select checkboxes on the data table.
   *
   * @param ndcs The newly selected NDCs.
   */
  const onSelect = (ndcs: string[]) => {
    setSelectedNdcs(ndcs);
  };

  const handleAssociateUnknownPackagingsSuccess = () => {
    setSelectedNdcs([]);
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    loadMarketBasket(parseInt(id));
    toggleAssociateUnknownPackagingsDialog();
  };

  const handleEditMarketBasketDrugPackaging = (productId: number, ndc: string) => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const drugPackaging = marketBasket
      .drugPackagings
      .find(packaging => packaging.productId === productId && packaging.ndc === ndc);

    setDrugPackagingToEdit(drugPackaging);
  };

  const cancelEditMarketBasketDrugPackaging = () => {
    setDrugPackagingToEdit(undefined);
  };

  const handleEditMarketBasketDrugPackagingSuccess = () => {
    setDrugPackagingToEdit(undefined);
    void onRecalculate();
  };

  const handleOverride = (ndc: string) => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const drugPackaging = marketBasket
      .drugPackagings
      .find(packaging => packaging.ndc === ndc);

    // @ts-expect-error TS(2322): Type 'MarketBasketDrugPackaging | undefined' is no... Remove this comment to see the full error message
    setDrugPackagingsToOverride([ ...drugPackagingsToOverride, drugPackaging ]);
  };

  const handleCancelOverride = (ndc: string) => {
    resetField(ndc, { defaultValue: initialFormValues[ndc] });
    // setValue(ndc, initialFormValues[ndc]);

    setDrugPackagingsToOverride([
      ...drugPackagingsToOverride.filter(packaging => packaging.ndc !== ndc)
    ]);
  };

  const updateOverridesAsync = useAsync(updateOverrides);
  const updatingOverrides = updateOverridesAsync.status === UseAsyncStatus.Pending;

  useEffect(() => {
    const { status, error } = updateOverridesAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(
        error,
        {
          title: 'Failed to Override.',
          message: 'Unable to override NDC prices.',
          autoClose: false
        }
      );

      return;
    }

    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    resetField(lastNdcOverridden);

    setDrugPackagingsToOverride(
      drugPackagingsToOverride.filter(packaging => packaging.ndc !== lastNdcOverridden)
    );

    setLastNdcOverridden(undefined);

    success({ title: 'Success', message: 'Overrides updated successfully.', autoClose: true });

    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    loadMarketBasket(parseInt(id));
    // TODO: revisit missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOverridesAsync.status]);

  const handleSaveOverride = async (ndc: string) => {
    if (lastNdcOverridden) {
      return;
    }

    const { wac, wacUnit, amp, ura, uroa } = getValues(ndc);

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const drugPackaging = marketBasket.drugPackagings.find(packaging => packaging.ndc === ndc);

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const includeWac = getFieldState(`${ndc}.wac`).isDirty || drugPackaging.currentWacPrice.overrideValue != null;
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const includeWacUnit = getFieldState(`${ndc}.wacUnit`).isDirty || drugPackaging.currentWacUnitPrice.overrideValue != null;
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const includeAmp = getFieldState(`${ndc}.amp`).isDirty || drugPackaging.amp.overrideValue != null;
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const includeUra = getFieldState(`${ndc}.ura`).isDirty || drugPackaging.totalUra.overrideValue != null;
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const includeUroa = getFieldState(`${ndc}.uroa`).isDirty || drugPackaging.uroa.overrideValue != null;

    const request: UpdateOverridesRequest = {
      wacPrice: includeWac && wac.length > 0 ? wac : undefined,
      wacUnitPrice: includeWacUnit && wacUnit.length > 0 ? wacUnit : undefined,
      amp: includeAmp && amp.length > 0 ? amp : undefined,
      totalUra: includeUra && ura.length > 0 ? ura : undefined,
      uroa: includeUroa && uroa.length > 0 ? uroa : undefined
    };

    setLastNdcOverridden(ndc);

    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    void updateOverridesAsync.execute(id, ndc, request);
  };

  const isOverridingRow = (ndc: string) => drugPackagingsToOverride.some(packaging => packaging.ndc === ndc);

  const buildRowProps = (drugMarketDetails: MarketBasketDrugPackaging[]) => drugMarketDetails.reduce((
    previous,
    current
  ) => {
    if (current.needsAttention) {
      return {
        ...previous,
        [current.ndc]: { background: [STATUS_WARNING_WEAK] }
      };
    }

    if (current.belongsToMarketBasketDrug) {
      return {
        ...previous,
        [current.ndc]: { background: [ACCENT_WEAK] }
      };
    }

    return { ...previous };
  }, {});

  const assignedToThisClient = user.assignedClients?.some(it => it.id === marketBasket?.client?.id) ?? [];

  return (
    <Box>
      <Box>
        <DrugMarketDetailsFilters defaultValue={filters} onApplyFilters={setFilters}>
          {filteredPackagings.some(pkg => pkg.productName === null) &&
            <ArtiaButton
              type="button"
              icon={<LinkIcon/>}
              a11yTitle="Associate Selected Rows"
              tip="Associate Selected Rows"
              disabled={selectedNdcs.length == 0}
              onClick={openAssociateNdcsDialog}
            />
          }
          <ArtiaButton
            type="button"
            icon={<Trash/>}
            a11yTitle="Delete Selected Rows"
            tip="Delete Selected Rows"
            disabled={selectedNdcs.length == 0}
            onClick={toggleDeleteRowsDialog}
          />
          <ArtiaButton
            type="button"
            icon={<Calculator/>}
            a11yTitle="Recalculate"
            tip="Recalculate"
            disabled={recalculating}
            onClick={onRecalculate}
          />
          <ArtiaButton
            type="button"
            icon={<Upload/>}
            a11yTitle="Import"
            tip="Import"
            onClick={() => openImportModal()}
          />
          <ArtiaButton
            type="button"
            icon={<Download/>}
            a11yTitle="Export to CSV"
            tip="Export to CSV"
            onClick={() => onExport()}
          />
        </DrugMarketDetailsFilters>

        <RowPinSelector
          tip="Pin rows to the top of the results"
          selected={selectedRowPins}
          options={rowPinOptions}
          onPinSelectionChange={handleRowPinSelectionChange}
        />
        <div style={{ overflowX: 'scroll' }}>
          <DataTable
            columns={[
              {
                property: 'ndc',
                header: 'NDC',
                pin: true,
                align: 'end',
                render: (row: MarketBasketDrugPackaging) => (<NdcCell {...row} />)
              },
              {
                property: 'name',
                header: 'Description',
              },
              {
                property: 'productName',
                header: 'Product',
              },
              {
                property: 'brandGenericStatus',
                header: 'Brand/Generic Status',
                render: (detail) => {
                  return <BrandGenericStatusIndicator
                    brandGenericStatus={detail.brandGenericStatus}
                    licenseType={detail.licenseType}
                  />;
                }
              },
              {
                property: 'currentWacPrice',
                header: 'WAC Price',
                align: 'end',
                render: ({ ndc, currentWacPrice }) => <WacPriceCell
                  ndc={ndc}
                  wac={currentWacPrice}
                  isOverriding={isOverridingRow(ndc)}
                  control={control}
                />
              },
              {
                property: 'currentWacUnitPrice',
                header: 'WAC Unit Price',
                align: 'end',
                render: ({ ndc, currentWacUnitPrice }) => <WacUnitPriceCell
                  ndc={ndc}
                  wacUnit={currentWacUnitPrice}
                  isOverriding={isOverridingRow(ndc)}
                  control={control}
                />
              },
              {
                property: 'amp',
                header: 'AMP',
                align: 'end',
                render: ({ ndc, amp }) => <AmpCell
                  ndc={ndc}
                  amp={amp}
                  isOverriding={assignedToThisClient && isOverridingRow(ndc)}
                  control={control}
                />
              },
              {
                property: 'acaFederalUpperLimitUnitPrice',
                header: 'ACA-FUL Unit Price',
                align: 'end',
                render: (detail: MarketBasketDrugPackaging) => detail.acaFederalUpperLimitUnitPrice ? formatCurrency(detail.acaFederalUpperLimitUnitPrice) : <>&mdash;</>
              },
              {
                property: 'acaWeightedAverageManufacturersPrice',
                header: 'ACA-WAMP Unit Price',
                align: 'end',
                render: (detail: MarketBasketDrugPackaging) => detail.acaWeightedAverageManufacturersPrice ? formatCurrency(detail.acaWeightedAverageManufacturersPrice) : <>&mdash;</>
              },
              {
                property: 'totalUra',
                header: 'Estimated Total URA',
                align: 'end',
                render: ({ ndc, totalUra }) => <EstimatedTotalUraCell
                  ndc={ndc}
                  ura={totalUra}
                  isOverriding={assignedToThisClient && isOverridingRow(ndc)}
                  control={control}
                  onViewCalculationDetails={viewCalculationDetails}
                />
              },
              {
                property: 'uraPercentWac',
                header: 'URA % WAC',
                align: 'end',
                render: ({ uraPercentWac }) => <UraPercentWacCell
                  uraPercentWac={uraPercentWac}
                  onViewCalculationDetails={viewCalculationDetails}
                />
              },
              {
                property: 'estimatedUroa',
                header: 'Estimated UROA',
                align: 'end',
                render: ({ ndc, uroa }) => <EstimatedUroaCell
                  ndc={ndc}
                  uroa={uroa}
                  isOverriding={assignedToThisClient && isOverridingRow(ndc)}
                  control={control}
                  onViewCalculationDetails={viewCalculationDetails}
                />
              },
              {
                property: 'actions',
                header: '',
                align: 'center',
                size: 'xsmall',
                sortable: false,
                render: (row: MarketBasketDrugPackaging) =>
                  (isOverridingRow(row.ndc)) ?
                  (
                    <EditingActionsCell
                      disabled={!!lastNdcOverridden}
                      saving={updatingOverrides && lastNdcOverridden === row.ndc}
                      onCancel={() => handleCancelOverride(row.ndc)}
                      onSave={() => handleSaveOverride(row.ndc)}
                    />
                  ) : (
                    <ActionsCell {...row} onEditClick={handleEditMarketBasketDrugPackaging} onOverrideClick={handleOverride}/>
                  )
              }
            ]}
            data={filteredPackagings}
            select={selectedNdcs}
            sortable
            sort={{ ...sort, external: true }}
            step={10}
            paginate
            primaryKey="ndc"
            rowProps={buildRowProps(filteredPackagings)}
            // @ts-expect-error TS(2769): No overload matches this call.
            onSelect={onSelect}
            onSort={handleTableSort}
            placeholder={
              (loadingMarketBasket || filteredPackagings.length === 0) &&
              <Box fill>
                {loadingMarketBasket && <TableLoadingOverlay />}
                {!loadingMarketBasket && filteredPackagings.length === 0 && <TableEmptyPlaceholder content="No Drug Packaging data is available." />}
              </Box>
            }
          />
        </div>
      </Box>
      {/* BEGIN DIALOGS */}
      <AssociateNdcsDialog
        onSubmitComplete={handleAssociateUnknownPackagingsSuccess}
        onClose={toggleAssociateUnknownPackagingsDialog}
        open={showAssociateUnknownPackagingsDialog}
        selectedDrugPackagings={selectedDrugPackagings}
      />
      <InvalidNdcSelectionDialog
        open={showInvalidNdcSelectionDialog}
        onOk={toggleInvalidNdcSelectionDialog}
      />
      <DeleteNdcsDialog
        open={showDeleteRowsDialog}
        onCancel={toggleDeleteRowsDialog}
        onSubmit={requestDeleteRows}
        busy={deletingRows}
        selectedDrugPackagings={selectedDrugPackagings}
      />
      <CalculationLogDialog
        open={showCalculationLogDialog}
        onClose={toggleCalculationLogDialog}
        calculationDetails={calculationDetails}
      />
      <EditDrugPackagingDialog
        // @ts-expect-error TS(2322): Type 'MarketBasketDrugPackaging | undefined' is no... Remove this comment to see the full error message
        marketBasketDrugPackaging={drugPackagingToEdit}
        isOpen={showEditDrugPackagingDialog}
        onClose={cancelEditMarketBasketDrugPackaging}
        onSaved={handleEditMarketBasketDrugPackagingSuccess}
      />
      {/* END DIALOGS */}
    </Box>
  );
};
