import { Editor } from '@tiptap/react';
import { Box, Button, Header, Toolbar } from 'grommet';
import { Bold, Italic, Link, List, OrderedList, Redo, Undo, Unlink } from 'grommet-icons';
import { ReactElement } from 'react';

import { usePromptContext } from '@/components/interactions';
import { PromptFn } from '@/components/interactions/prompt-context';
import { TextEditorTypographySelect } from '@/components/text-editor/text-editor-typography-select';

/**
 * Component representing a toolbar for a text editor, provides the rich text tools.
 */
export const TextEditorToolbar = ({ editor }: TextEditorToolbarProps) => {
  const { prompt } = usePromptContext();

  const menu: MenuItemGroupConfig[] = [
    {
      name: 'text-marks',
      items: [
        {
          name: 'bold',
          title: 'Bold',
          icon: <Bold/>,
          onClick: () => editor.chain().focus().toggleBold().run(),
          disabled: !editor.can().chain().focus().toggleBold().run(),
          active: editor.isActive('bold')
        },
        {
          name: 'italic',
          title: 'Italic',
          icon: <Italic/>,
          onClick: () => editor.chain().focus().toggleItalic().run(),
          disabled: !editor.can().chain().focus().toggleItalic().run(),
          active: editor.isActive('italic')
        },
      ]
    },
    {
      name: 'lists',
      items: [
        {
          name: 'bullet-list',
          title: 'Bullet List',
          icon: <List />,
          onClick: () => editor.chain().focus().toggleBulletList().run(),
          disabled: !editor.can().chain().focus().toggleBulletList().run(),
          active: editor.isActive('bulletList')
        },
        {
          name: 'ordered-list',
          title: 'Ordered List',
          icon: <OrderedList/>,
          onClick: () => editor.chain().focus().toggleOrderedList().run(),
          disabled: !editor.can().chain().focus().toggleOrderedList().run(),
          active: editor.isActive('orderedList')
        },
      ]
    },
    {
      name: 'links',
      items: [
        {
          name: 'set-link',
          title: 'Set Link',
          icon: <Link />,
          onClick: () => handleSetLink(editor, prompt)
        },
        {
          name: 'unset-link',
          title: 'Unset Link',
          icon: <Unlink />,
          onClick: () => editor.chain().focus().unsetLink().run(),
        },
      ]
    },
    {
      name: 'history',
      items: [
        {
          name: 'undo',
          title: 'Undo',
          icon: <Undo />,
          onClick: () => editor.chain().undo().run(),
          disabled: !editor.can().chain().focus().undo().run()
        },
        {
          name: 'redo',
          title: 'Redo',
          icon: <Redo />,
          onClick: () => editor.chain().redo().run(),
          disabled: !editor.can().chain().focus().redo().run()
        },
      ]
    }
  ];

  const checkWhenFocused = (editor: Editor) => {
    return (check: boolean, whenNotFocused = false) => {
      return !editor.isFocused ? whenNotFocused : check;
    };
  };

  return (
    <Header sticky="scrollup" fill="horizontal">
      <Toolbar
        round={{ corner: 'top', size: 'small' }}
        fill
        background="white"
        pad="none"
        border={{ size: 'xsmall', color: 'border', side: 'bottom' }}
        gap="xxsmall"
        align="center"
      >
        <TextEditorTypographySelect editor={editor} />
        {menu.map((group, index) => (
          <Box direction="row" key={`${group.name}_${index}`} fill="vertical" gap="xxsmall">
            {index === 0 && <GroupDivider />}
            {group.items.map((item, index) => (
              <Button
                key={`${group.name}_${item.name}_${index}`}
                onClick={item.onClick}
                // @ts-expect-error TS(2345): Argument of type 'boolean | undefined' is not assi... Remove this comment to see the full error message
                disabled={checkWhenFocused(editor)(item.disabled)}
                icon={item.icon}
                title={item.title}
                aria-label={item.title}
                size="small"
                color="brand"
                // @ts-expect-error TS(2345): Argument of type 'boolean | undefined' is not assi... Remove this comment to see the full error message
                primary={checkWhenFocused(editor)(item.active)}
              />
            ))}
            {index !== menu.length - 1 && <GroupDivider />}
          </Box>
        ))}
      </Toolbar>
    </Header>
  );
};

/**
 * Sets the link for the provided editor.
 * If the editor already has an active link, it will update the existing link with the new URL.
 * If the provided URL is empty, it will remove the active link from the editor.
 */
const handleSetLink = (editor: Editor, prompt: PromptFn) =>  {
  const existingHref = editor.isActive('link') ? editor.getAttributes('link').href : '';
  prompt({ title: 'Add link', text: 'Enter the URL for this link.', defaultValue: existingHref })
    .then((value: string) => {
      const trimmedValue = value.trim();

      if (trimmedValue) {
        editor.chain().focus().setLink({ href: trimmedValue }).run();
      } else if (editor.isActive('link')) {
        editor.chain().focus().unsetLink().run();
      }
    });
};

type TextEditorToolbarProps = {
  editor: Editor;
}

type MenuItemConfig = {
  name: string;
  title: string;
  icon: ReactElement;
  onClick: () => void;
  disabled?: boolean;
  active?: boolean;
}

type MenuItemGroupConfig = {
  name: string;
  items: MenuItemConfig[];
}

const GroupDivider = () => <Box width="1px" background="border" alignSelf="stretch" height="auto" />;
