import { useAsync } from '@shared/async';
import React from 'react';

import { DeleteConfirmationDialog } from '@/components/delete-confirmation-dialog';
import { useBidRecommendationsService } from '@/features/bid-analysis/api/bid-recommendations-service';
import { BidRecommendation } from '@/features/bid-analysis/types/bid-recommendation';

type DeleteBidRecommendationDialogProps = {
  open: boolean;
  bidAnalysisId: number;
  bidRecommendation: BidRecommendation;
  onClose: () => void;
  onDeleteComplete: () => void;
};

export const DeleteBidRecommendationDialog = (props: DeleteBidRecommendationDialogProps) => {
  const {
    open,
    bidAnalysisId,
    bidRecommendation,
    onClose,
    onDeleteComplete
  } = props;

  const { deleteBidRecommendation } = useBidRecommendationsService();
  const deleteBidRecommendationAsync = useAsync(deleteBidRecommendation);

  return (
    <DeleteConfirmationDialog
      open={open}
      itemName="Bid Recommendation"
      onCancel={onClose}
      onDeleteComplete={onDeleteComplete}
      // @ts-expect-error TS(2322): Type 'UseAsyncResult<[bidAnalysisId: number, bidRe... Remove this comment to see the full error message
      request={deleteBidRecommendationAsync}
      parameters={[bidAnalysisId, bidRecommendation?.id, bidRecommendation?.isHistorical]}
    />
  );
};
