import { useHttpClient } from '@shared/http';

import { Classification } from '@/features/classifications';
import { ClassificationFormInputs } from '@/features/classifications/types/classification-form-inputs';

type ClassificationRequest = {
  name: string;
};

export const useClassificationService = () => {
  const httpClient = useHttpClient();

  const getClassifications = (): Promise<Classification[]> => {
    return httpClient.get('classifications');
  };

  const getClassification = (id: number): Promise<Classification> => {
    return httpClient.get(`classifications/${id}`);
  };

  const createClassificationRequest = ({ name }: ClassificationFormInputs): ClassificationRequest => {
    return {
      name
    };
  };

  const createClassification = (formInputs: ClassificationFormInputs): Promise<any> => {
    return httpClient.post('classifications', { data: createClassificationRequest(formInputs) });
  };

  const updateClassification = (id: number | string, formInputs: ClassificationFormInputs): Promise<any> => {
    return httpClient.put(`classifications/${id}`, { data: createClassificationRequest(formInputs) });
  };

  const deleteClassification = (id: number | string): Promise<any> => {
    return httpClient.delete(`classifications/${id}`);
  };

  return {
    getClassifications,
    getClassification,
    createClassification,
    updateClassification,
    deleteClassification
  };
};
