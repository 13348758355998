import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { useHeaderDetails } from '@shared/header';
import { useNotifications } from '@shared/notifications';
import {
  Anchor,
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  NameValueList,
  NameValuePair,
  Spinner,
  Text,
  TextArea
} from 'grommet';
import { Attachment, Edit, PieChart, Refresh } from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { ArtiaButton } from '@/components/artia-button';
import { AttachmentUploadDialog } from '@/components/attachment-upload-dialog';
import { AttachmentsTable } from '@/components/attachments-table';
import { Date, Timestamp, YearMonth } from '@/components/date-time';
import { Tabs } from '@/components/tabs';
import { PageTitleRow } from '@/components-new/page-title-row';
import { useBidAnalysisService } from '@/features/bid-analysis';
import { useRefreshMarketDetailsDialog } from '@/features/bid-analysis/api/use-refresh-market-details-dialog';
import { useBidAnalysisDetails } from '@/features/bid-analysis/bid-analysis-details.provider';
import { BidAnalysisStatus } from '@/features/bid-analysis/components/show/bid-analysis-status';
import { BidRecommendations } from '@/features/bid-analysis/components/show/bid-recommendations';
import { DrugMarketDetails } from '@/features/bid-analysis/components/show/drug-market-details';
import { MedicaidLives } from '@/features/bid-analysis/components/show/medicaid-lives';
import { NetCostComparisonPage } from '@/features/bid-analysis/components/show/net-cost-comparison';
import { RefreshMarketDetailsDialog } from '@/features/bid-analysis/components/show/refresh-market-details-dialog';
import { BidAnalysisStatusType } from '@/features/bid-analysis/types/bid-analysis-status-type';
import { useUpdateBidAnalysisStatus } from '@/features/bid-analysis/use-update-bid-analysis-status';

const ShowBidAnalysisPage = () => {
  const { loadBidAnalysis, bidAnalysis, loadingBidAnalysis } = useBidAnalysisDetails();
  const { updateBidAnalysisStatus } = useUpdateBidAnalysisStatus();

  const {
    dialogOpen: refreshMarketDetailsDialogOpen,
    openDialog: openRefreshMarketDetailsDialog,
    closeDialog: closeRefreshMarketDetailsDialog,
  } = useRefreshMarketDetailsDialog();
  const { updateBidAnalysis, uploadAttachment } = useBidAnalysisService();
  const { downloadAttachment } = useBidAnalysisService();
  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();
  const { id } = useParams();
  const { success } = useNotifications();
  const navigate = useNavigate();
  const { handleError } = useErrorHandler();

  const [notes, setNotes] = useState('');
  const [updatingNotes, setUpdatingNotes] = useState(false);

  React.useEffect(() => {
    const breadcrumbs = bidAnalysis ?
    [
      { icon: PieChart, label: 'Bid Analyses', url: '/bid-analyses' },
      { label: bidAnalysis.name, url: `/bid-analyses/${bidAnalysis.id}-${bidAnalysis.slug}` }
    ] : [];

    setHeaderDetails({
      documentTitle: 'Bid Analysis Details',
      pageTitle: bidAnalysis && (
        <PageTitleRow title={bidAnalysis?.name}>
          <Box>
            <ArtiaButton
              icon={<Edit/>}
              a11yTitle="Edit Bid Analysis"
              label="Edit"
              onClick={() => navigate('edit')}
            />
          </Box>
          <Box>
            <ArtiaButton
              icon={<Refresh />}
              a11yTitle="Refresh Bid Analysis"
              label="Refresh"
              onClick={handleRefreshMarketDetailsClick}
            />
          </Box>
        </PageTitleRow>
      ),
      actions: bidAnalysis && (
        <BidAnalysisStatus status={bidAnalysis.status} onBidAnalysisStatusChange={handleUpdateBidAnalysisStatus} />
      ),
      breadcrumbs
    });

    setNotes(bidAnalysis?.notes ?? '');

    return () => {
      clearHeaderDetails();
    };

    // TODO: revisit this, missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidAnalysis]);

  React.useEffect(() => {
    if (id === null || loadingBidAnalysis) {
      return;
    }

    void loadBidAnalysis();
    // TODO: revisit this, missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleRefreshMarketDetailsClick = () => {
    openRefreshMarketDetailsDialog();
  };

  const handleCloseRefreshMarketDetailsDialog = async (submitted: boolean) => {
    closeRefreshMarketDetailsDialog();

    if (submitted) {
      await loadBidAnalysis();
    }
  };

  const handleUpdateBidAnalysisStatus = async (status: BidAnalysisStatusType) => {
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await updateBidAnalysisStatus(parseInt(id), status);
    await loadBidAnalysis();
  };

  // TODO: refactor this duplicated code
  const onDownload = async (url: string) => {
    try {
      const attachment = await downloadAttachment(url);

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(attachment.contents);
      link.download = attachment.filename;
      link.click();
    } catch (ex) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      handleError(ex, {
        title: 'Download Failed',
        message: 'We encountered an unexpected error while downloading your file. Please try again or contact an administrator.',
        autoClose: false
      });
    }
  };

  const [ showUploadAttachmentDialog, setShowUploadAttachmentDialog ] = useState(false);

  const handleUploadAttachment = () => {
    setShowUploadAttachmentDialog(true);
  };

  const handleCancelUploadAttachment = () => {
    setShowUploadAttachmentDialog(false);
  };

  const uploadAttachmentAsync = useAsync(uploadAttachment);

  const uploading = uploadAttachmentAsync.status === UseAsyncStatus.Pending;

  useEffect(() => {
    if (uploadAttachmentAsync.status === UseAsyncStatus.Success) {
      success({ title: 'Upload Successful', message: 'Successfully uploaded attachment.', autoClose: true });
      void loadBidAnalysis();
      setShowUploadAttachmentDialog(false);
    }

    if (uploadAttachmentAsync.status === UseAsyncStatus.Error) {
      handleError(
        uploadAttachmentAsync.error,
        {
          title: 'Upload Failed',
          message: 'Unable to upload attachment.',
          autoClose: false
        }
      );
    }
    // TODO: revisit this, missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadAttachmentAsync.status]);

  const handleSubmitAttachment = (file: File) => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    void uploadAttachmentAsync.execute(file, bidAnalysis.id);
  };

  const onUpdateNotes = async () => {
    setUpdatingNotes(true);

    try {
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      await updateBidAnalysis(parseInt(id), {
        notes,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        name: bidAnalysis.name,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        bidDueOn: bidAnalysis.bidDueOn?.slice(0, 10),
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        reviewOn: bidAnalysis.reviewOn?.slice(0, 10),
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        contractTermStartsOn: bidAnalysis.contractTermStartsOn?.slice(0, 10),
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        contractTermEndsOn: bidAnalysis.contractTermEndsOn?.slice(0, 10)
      });

      void loadBidAnalysis();
      success({ title: 'Update Succeeded', message: 'Successfully updated notes.', autoClose: true });
    } catch (ex) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      handleError(ex, {
        title: 'Update Failed',
        message: 'Failed to update notes.', autoClose: false
      });
    } finally {
      setUpdatingNotes(false);
    }
  };

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <>
      <Box direction="column" pad={{ bottom: 'medium' }}>
        {!bidAnalysis && <Box width="100%" height="24rem" align="center" justify="center">
          <Spinner size="large" color="brand" />
          <Heading level={4}>Loading Bid Analysis...</Heading>
        </Box>}
        {bidAnalysis && <Box direction="row" gap="medium" pad={{ bottom: 'medium' }}>
          <Box width="34%" gap="small">
            <Card style={{ background: '#fff' }}>
              <CardBody pad="medium">
                <NameValueList nameProps={{ width: '40%' }} valueProps={{ width: '60%' }} gap={{ row: 'small', column: 'none' }}>
                  <NameValuePair name="Client">
                    <Text wordBreak="break-word" color="text-strong">{bidAnalysis?.client?.label}</Text>
                  </NameValuePair>
                  <NameValuePair name="Market Basket">
                    <span>
                      <Link to={`/market-baskets/${bidAnalysis?.marketBasket?.id}`}>
                        <Anchor as="span">
                          <Text wordBreak="break-word" color="text-strong">{bidAnalysis?.marketBasket?.label}</Text>
                        </Anchor>
                      </Link>
                    </span>
                  </NameValuePair>
                  <NameValuePair name="Drug">
                    <span>
                      <Link to={`/drugs/${bidAnalysis?.product?.id}`}>
                        <Anchor as="span">
                          <Text wordBreak="break-word" color="text-strong">{bidAnalysis?.product?.label}</Text>
                        </Anchor>
                      </Link>
                    </span>
                  </NameValuePair>
                  <NameValuePair name="Year">
                    <Text color="text-strong">{bidAnalysis?.year}</Text>
                  </NameValuePair>
                  <NameValuePair name="Utilization">
                    <Text color="text-strong">{bidAnalysis?.drugUtilizationPeriods.join(', ')}</Text>
                  </NameValuePair>
                  <NameValuePair name="Created Date">
                    <Timestamp wordBreak="break-word" color="text-strong" value={bidAnalysis?.createdAt} />
                  </NameValuePair>
                  <NameValuePair name="Created By">
                    <Text color="text-strong">{bidAnalysis?.createdBy?.name}</Text>
                  </NameValuePair>
                  <NameValuePair name="Current CPI-U">
                    <Text color="text-strong">
                      {bidAnalysis?.currentCpiu &&
                        <span>
                    {bidAnalysis?.currentCpiu}
                          {bidAnalysis?.currentCpiuDate &&
                            <span> (as of <YearMonth value={bidAnalysis.currentCpiuDate} />)</span>
                          }
                        </span>
                      }
                    </Text>
                  </NameValuePair>
                  <NameValuePair name="Bid Due Date">
                    <Date color="text-strong" value={bidAnalysis?.bidDueOn} />
                  </NameValuePair>
                  <NameValuePair name="P&T Review Date">
                    <Date color="text-strong" value={bidAnalysis?.reviewOn} />
                  </NameValuePair>
                  <NameValuePair name="Contract Term">
                    <span>
                      <Date color="text-strong" value={bidAnalysis?.contractTermStartsOn} /> &ndash; <Date color="text-strong" value={bidAnalysis?.contractTermEndsOn} />
                    </span>
                  </NameValuePair>
                </NameValueList>
              </CardBody>
            </Card>
          </Box>
          <Box width="66%">
            {bidAnalysis?.stateBenefits &&
              <Box>
                <MedicaidLives states={bidAnalysis?.stateBenefits}/>
              </Box>
            }
          </Box>
        </Box>}

        <Tabs
          disabled={!bidAnalysis}
          activeIndex={activeTabIndex}
          onSelect={setActiveTabIndex}
          items={[
            {
              key: 'drugMarketDetails',
              name: 'Drug Market Details',
              content: (
                <Box pad="none">
                  {/* @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message */}
                  <DrugMarketDetails bidAnalysisId={parseInt(id)} />
                </Box>
              )
            },
            {
              key: 'netCostComparison',
              name: 'Net Cost Comparison',
              content: (
                <Box pad="none">
                  <NetCostComparisonPage />
                </Box>
              )
            },
            {
              key: 'bidRecommendations',
              name: 'Bid Recommendations',
              content: (
                <Box pad="none">
                  <BidRecommendations />
                </Box>
              )
            },
            {
              key: 'attachments',
              name: 'Attachments',
              content: (
                <Box gap="small">
                  <AttachmentsTable attachments={bidAnalysis?.attachments ?? []} onDownloadHandler={onDownload} />
                  <Box align="end">
                    <Box>
                      <ArtiaButton
                        icon={<Attachment/>}
                        a11yTitle="Upload Attachment"
                        label="Upload Attachment"
                        onClick={handleUploadAttachment}
                      />
                    </Box>
                  </Box>
                </Box>
              )
            },
            {
              key: 'notes',
              name: 'Notes',
              content: (
                <Box pad="small" gap="small">
                  <TextArea
                    value={notes}
                    resize="vertical"
                    onChange={({ target }) => setNotes(target.value)}
                    style={{ minHeight: '8rem' }}
                  />
                  {notes !== (bidAnalysis?.notes ?? '') && <Box direction="row" justify="end" gap="small">
                    <ArtiaButton label="Cancel" onClick={() => setNotes(bidAnalysis?.notes ?? '')} size="large" variant="outlined"/>
                    <Button primary disabled={updatingNotes} onClick={() => onUpdateNotes()}>
                      {() =>
                        <Box align="center" justify="center">
                          {updatingNotes ? (
                            <Spinner color="white" size="small"/>
                          ) : (
                            <Text>Save</Text>
                          )}
                        </Box>
                      }
                    </Button>
                  </Box>}
                </Box>
              )
            }
          ]}
        />
      </Box>
      <RefreshMarketDetailsDialog
        open={refreshMarketDetailsDialogOpen}
        onClose={handleCloseRefreshMarketDetailsDialog}
        // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
        bidAnalysisId={bidAnalysis?.id}
      />
      <AttachmentUploadDialog
        open={showUploadAttachmentDialog}
        submitting={uploading}
        accept=".csv,.docx,.jpeg,.jpg,.pdf,.png,.xlsx"
        onSubmit={handleSubmitAttachment}
        onClose={handleCancelUploadAttachment}
      />
    </>
  );
};

export default ShowBidAnalysisPage;
