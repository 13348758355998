import React from 'react';
import styled from 'styled-components';

/**
 * Wrap component with a styled component that has a transition on all properties.
 * @param component - Component to wrap.
 * @param duration - Duration of the transition.
 * @param easing - Easing of the transition.
 */
export const transitionAll = <T>(component: React.FC<T>, duration = '0.2s', easing = 'ease-in-out') => {
  return styled(component)`
    transition: all ${duration} ${easing};
  `;
};
