import React, { useRef } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { LiaRedoAltSolid } from 'react-icons/lia';
import { useLocation } from 'react-router-dom';

import { Button } from '@/components-new/button';
import StatusCodePage from '@/features/status-codes/status-code.page';

type Props = object & FallbackProps;

const AppErrorFallback = ({ resetErrorBoundary } : Props) => {
    const location = useLocation();
    const errorLocation = useRef(location.pathname);

    React.useEffect(() => {
      if (errorLocation.current !== location.pathname) {
        resetErrorBoundary();
      }
    }, [location.pathname, resetErrorBoundary]);

    return (
      <>
      <StatusCodePage
        actions={(
          <>
            <Button onClick={resetErrorBoundary} outline>
              <LiaRedoAltSolid />
              Retry
            </Button>
          </>
        )}
        statusCode="500"
        statusCodeDescription="Sorry, something went wrong."
      >
        We are working on the issue, give it another try by refreshing the page. If the issue continues click the button below to go home.
      </StatusCodePage>
      </>
    );
};

export default AppErrorFallback;
