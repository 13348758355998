import { Box, BoxProps, Text } from 'grommet';
import React from 'react';

export const CaretTip = ({ message, ...props }: { message: string } & BoxProps) => (
  <Box direction="row" align="center" {...props}>
    <Box direction="row" pad="small" round="xsmall" background={{ color: 'light-1' }} border="all">
      <Text color="brand">{message}</Text>
    </Box>
  </Box>
);
