import { useErrorHandler } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { makeCoverageTagsQueryKey } from '@/features/drugs/api/use-coverage-tags';
import { artiaProtectedApi } from '@/lib/api-client';

const deleteCoverageTag = (drugId: number, coverageTagId: number): Promise<void> => {
  return artiaProtectedApi.delete(`drugs/${drugId}/coverage-tags/${coverageTagId}`);
};

export const useDeleteCoverageTag = () => {
  const queryClient = useQueryClient();
  const { success } = useNotifications();
  const { handleError } = useErrorHandler();

  return useMutation({
    mutationFn: ({ drugId, coverageTagId }: { drugId: number, coverageTagId: number }) => deleteCoverageTag(drugId, coverageTagId),
    onSuccess: () => {
      success({ title: 'Saved', message: 'The coverage tag was successfully deleted.', autoClose: true });
    },
    onError: (error) => {
      handleError(
        error,
        {
          title: 'Save Failed',
          message: 'Unable to delete coverage tag.',
          autoClose: true
        }
      );
    },
    onSettled: async (_, __, { drugId }) => {
      await queryClient.invalidateQueries({ queryKey: makeCoverageTagsQueryKey(drugId) });
    }
  });
};
