import { Box, Button, Text } from 'grommet';
import { BackgroundType } from 'grommet/utils';
import { Close } from 'grommet-icons';
import React, { Children, useMemo } from 'react';

type ChipProps = {
  /**
   * Configuration definition of the chip.
   */
  chip: ChipConfig

  /**
   * Trigger if the chip is dismissible, and the user clicks the dismiss button.
   * @param value
   */
  onDismiss?: (value: ChipValue) => void;

  /**
   * Background color of the chip.
   */
  background?: ChipBackground;
};

type ChipBackground = BackgroundType;

/**
 * Compact element that can represent a filter, tag, or other small piece of
 * information.
 *
 * @param props
 */
export const Chip = ({ chip, onDismiss, background = 'accent-1' }: ChipProps) => {
  const { label, dismissible, value } = chip;

  const handleDismiss = () => {
    onDismiss?.(value);
  };

  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      background={background}
      style={{ color: background === 'error' ? 'white' : undefined }}
      pad={{ horizontal: '0.375rem', vertical: '0.125rem' }}
      round="0.375rem"
      width={{ min: 'min-content', max: 'max-content' }}
    >
      <Text size="small">{label}</Text>
      {
        dismissible && (
          <Button
            margin={{ right: '-0.375rem' }}
            size="small"
            icon={<Close size="small" color="#fff" />}
            onClick={handleDismiss}
          />
        )
      }
    </Box>
  );
};

export type ChipConfig = {
  /**
   * The label to display on the chip.
   */
  label: React.ReactNode;

  /**
   * A programmatic value given to the chip.
   */
  value: ChipValue;

  /**
   * Whether the chip can be dismissed.
   */
  dismissible?: boolean;
}

type ChipListProps = {
  children?: React.ReactNode;
  /**
   * Text to display when there are no chips.
   */
  emptyText?: string;
  emptyTextColor?: string;
};

/**
 * Displays a collection of chips.
 * @param props
 */
export const ChipList = ({ children, emptyText = 'No chips', emptyTextColor = 'dark-2' }: ChipListProps) => {
  const hasChildren = useMemo(() => {
    return Children.count(children) > 0;
  }, [children]);

  return (
    <Box direction="row" gap="small" align="center">
      {hasChildren && children}
      {!hasChildren && <Text size="small" color={emptyTextColor}>{emptyText}</Text>}
    </Box>
  );
};

export type ChipValue = string | number | readonly string[];
