import { Box, CheckBoxGroup, CheckBoxType, DropButton } from 'grommet';
import { Compare } from 'grommet-icons';
import React, { useMemo } from 'react';

import { Chip, ChipList, ChipValue } from '@/components/chip';

type RowPinSelectorProps = {
  /**
   * Hint text to display when the user hovers over the menu button.
   */
  tip?: string;

  /**
   * Label to display above the list of options.
   */
  label?: string;

  /**
   * List of pin options available to the user.
   */
  options: RowPinSelectorOption[];

  /**
   * List of pin options that are currently selected.
   */
  selected: RowPinSelectorOption[];

  /**
   * Trigger when the user selects or deselects a pin option.
   * @param selected
   */
  onPinSelectionChange: (selected: ChipValue[]) => void;
};

export interface RowPinSelectorOption extends CheckBoxType {}

/**
 * Input control that allows the user to select one or more row pins.
 * These selections can be used by data tables to maintain a fixed set of rows
 * at the top of the table.
 * @param props
 */
export const RowPinSelector = (props: RowPinSelectorProps) => {
  const {
    tip = 'Pin rows to top',
    options = [],
    selected = [],
    onPinSelectionChange,
    label = 'Pinned rows'
  } = props;

  const checkboxGroupValue = useMemo(() => {
    return selected?.map((option) => option.value as string | number);
  }, [selected]);

  const handleDismiss = (id: ChipValue) => {
    const nextSelection = selected?.filter((option) => option.value !== id)
      .map(option => option.value);
    // @ts-expect-error TS(2345): Argument of type '(string | number | readonly stri... Remove this comment to see the full error message
    onPinSelectionChange(nextSelection);
  };

  const handlePinSelectionChange = (event: { value: any } ) => {
    const { value } = event;
    onPinSelectionChange(value);
  };

  return (
    <Box
      direction="row"
      gap="medium"
      align="center"
      pad="small"
      style={{ background: '#00314C' }}
    >
      <Box>
        <DropButton
          icon={<Compare />}
          label={label}
          a11yTitle={tip}
          tip={tip}
          hoverIndicator
          secondary
          dropContent={
            <Box pad="medium">
              <CheckBoxGroup
                // @ts-expect-error TS(2322): Type '(event: { value: any; }) => void' is not ass... Remove this comment to see the full error message
                onChange={handlePinSelectionChange}
                value={checkboxGroupValue}
                options={options}
              />
            </Box>
          }
          size="small"
        />
      </Box>
      <ChipList emptyText="No row pins selected" emptyTextColor="light-1">
        {
          selected?.map((option) => (
            <Chip
              key={option.value as any}
              // @ts-expect-error TS(2322): Type 'string | number | readonly string[] | undefi... Remove this comment to see the full error message
              chip={{ label: option.label, value: option.value, dismissible: true }}
              onDismiss={handleDismiss}
            />
          ))
        }
      </ChipList>
    </Box>
  );
};
