import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

export type MedicaidDatasetFormValue = {
  id: string;
};

type MedicaidDatasetFormContextValue = {
  form: MedicaidDatasetFormValue;
  updateForm: Dispatch<SetStateAction<MedicaidDatasetFormValue>>;
  resetForm: () => void;
};

const DEFAULT_CONTEXT_VALUE: MedicaidDatasetFormContextValue = {
  form: {
    id: ''
  },
  updateForm: () => null,
  resetForm: () => null
};

const MedicaidDatasetFormContext = createContext<MedicaidDatasetFormContextValue>(DEFAULT_CONTEXT_VALUE);

export const useMedicaidDatasetForm = () => useContext<MedicaidDatasetFormContextValue>(MedicaidDatasetFormContext);

type MedicaidDatasetFormProps = {
  children?: React.ReactNode
};

export const MedicaidDatasetFormProvider = ({ children }: MedicaidDatasetFormProps) => {
  const [form, setForm] = useState(DEFAULT_CONTEXT_VALUE.form);

  const resetForm = () => {
    setForm(DEFAULT_CONTEXT_VALUE.form);
  };

  const contextValue: MedicaidDatasetFormContextValue = {
    form,
    updateForm: setForm,
    resetForm
  };

  return (
    <MedicaidDatasetFormContext.Provider value={contextValue}>
      {children}
    </MedicaidDatasetFormContext.Provider>
  );
};
