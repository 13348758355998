import { useState } from 'react';

type UseRefreshMarketDetailsDialog = {
  dialogOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
}

/**
 * Hook for managing the state of the refresh market details dialog.
 */
export const useRefreshMarketDetailsDialog = (): UseRefreshMarketDetailsDialog => {
  const [ dialogOpen, setDialogOpen ] = useState(false);

  return {
    dialogOpen,
    openDialog: () => setDialogOpen(true),
    closeDialog: () => setDialogOpen(false),
  };
};
