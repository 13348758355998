import { isAcceptedType } from '@shared/validators/file-upload-validators';
import { Box, FileInput, FormField } from 'grommet';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ArtiaButton } from '@/components/artia-button';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';

type AttachmentUploadDialogProps = {
  open: boolean;
  submitting: boolean;
  accept?: string;
  onSubmit: (file: File) => void;
  onClose: () => void;
};

export const AttachmentUploadDialog = (props: AttachmentUploadDialogProps) => {
  const {
    open,
    submitting,
    accept,
    onSubmit,
    onClose
  } = props;

  const {
    control,
    watch,
    formState: { errors, isValid },
    setError,
    handleSubmit,
    reset
  } = useForm<{ file: File }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'File | unde... Remove this comment to see the full error message
    defaultValues: { file: null }
  });

  useEffect(() => reset(), [open]);

  const fileWatch = watch('file');

  const canSubmit = useMemo(() => {
    return fileWatch != null;
  }, [fileWatch]);

  const handleFormSubmit = (form: { file: File }) => {
    onSubmit(form.file);
  };

  const handleCancel = () => {
    onClose();
  };

  // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  const validationFnCallback = useCallback(() => isAcceptedType(accept), [accept]);

  return (
    <Dialog width="large" open={open}>
      <DialogHeader
        title="Upload Attachment"
        onClose={onClose}
      />
      <DialogBody>
        <Controller
          control={control}
          name="file"
          rules={{
            required: true,
            validate: (value) => {
              const validationFn = validationFnCallback();
              const result = validationFn(value);

              if (result != null) {
                setError('file', { message: result });
              }

              // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
              setError('file', undefined);
              return result;
            }
          }}
          render={({ field: { onChange } }) => (<FormField
            required
            name="file"
            htmlFor="file"
            label="Upload"
            error={errors.file?.message}
          >
            <FileInput
              // @ts-expect-error TS(2339): Property 'target' does not exist on type 'ChangeEv... Remove this comment to see the full error message
              onChange={({ target: { files } }) => onChange(files.length > 0 && files[0])}
              multiple={false}
              accept={accept}
              name="file"
              id="file"
              messages={{
                browse: 'Browse'
              }}
            />
          </FormField>)}
        />
      </DialogBody>
      <DialogActions>
        <Box flex direction="row" justify="end" gap="medium">
          <ArtiaButton label="Cancel" onClick={handleCancel} size="large" variant="outlined" />
          <ArtiaButton label="Submit" size="large" disabled={!isValid || !canSubmit} onClick={handleSubmit(handleFormSubmit)} busy={submitting} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
