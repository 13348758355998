import clsx from 'clsx';
import React from 'react';

export const MetaData = ({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className={clsx(className, 'flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6')} {...props} />
  );
};

export const MetaDataItem = ({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) => {
  return <div {...props} className={clsx(className, 'mt-2 flex items-center text-sm text-gray-500') }/>;
};
