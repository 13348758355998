import { BidRecommendationPackagingFormInputs } from './bid-recommendation-packaging-form-inputs';

export const buildFieldId = (
  {
    bidRecommendationId,
    isHistorical,
    packagingId,
    fieldName } : {
    bidRecommendationId: number,
    isHistorical: boolean,
    packagingId?: number,
    fieldName: string
  }) => {
  return `${isHistorical ? 'historical' : 'current'}-${bidRecommendationId}-${packagingId ?? '0'}-${fieldName}`;
};

export const parseFieldId = (fieldId: string) => {
  const [ type, bidRecommendationId, packagingIdStr, fieldName ] = fieldId.split('-');

  let packagingId = parseInt(packagingIdStr);
  if (!packagingId || isNaN(packagingId)) {
    // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'number... Remove this comment to see the full error message
    packagingId = undefined;
  }

  return {
    isHistorical: type === 'historical',
    bidRecommendationId,
    packagingId,
    fieldName: fieldName as keyof BidRecommendationPackagingFormInputs
  };
};
