import { useErrorHandler } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import React, { createContext, useContext, useState } from 'react';

import { BidAnalysis, useBidAnalysisService } from './index';

type BidAnalysisDetailsContextValue = {
  bidAnalysis?: BidAnalysis;
  loadingBidAnalysis: boolean;
  loadBidAnalysis: () => Promise<void>;
};

const DEFAULT_CONTEXT_VALUE: BidAnalysisDetailsContextValue = {
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'BidAnalysis... Remove this comment to see the full error message
  bidAnalysis: null,
  loadingBidAnalysis: false,
  loadBidAnalysis: () => Promise.resolve(),
};

const BidAnalysisDetailsContext = createContext<BidAnalysisDetailsContextValue>(DEFAULT_CONTEXT_VALUE);

export const useBidAnalysisDetails = () => useContext<BidAnalysisDetailsContextValue>(BidAnalysisDetailsContext);

export const BidAnalysisDetailsProvider = ({ id, children }: BidAnalysisDetailsProps) => {
  const { getBidAnalysis } = useBidAnalysisService();
  const { handleError } = useErrorHandler();
  const { dismiss, error, loading } = useNotifications();

  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [bidAnalysis, setBidAnalysis] = useState<BidAnalysis>(null);
  const [loadingBidAnalysis, setLoadingBidAnalysis] = useState<boolean>(false);

  const loadBidAnalysis = async () => {
    setLoadingBidAnalysis(true);

    let loadingNotification;

    if (bidAnalysis != null) {
      loadingNotification = loading({ title: 'Loading Bid Analysis', message: 'Please wait while we load the Bid Analysis information.' });
    }

    try {
      const bidAnalysis = await getBidAnalysis(id);
      setBidAnalysis(bidAnalysis);
    } catch (ex) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (ex.response && ex.response.status === 403) {
        error({
          title: 'Access Denied',
          message: 'You do not have access to view this bid analysis.',
          autoClose: false
        });
      } else {
        // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        handleError(ex, {
          title: 'Failed to Load Bid Analysis',
          message: 'We encountered an unexpected error while fetching Bid Analysis information. Please try again or contact an administrator.',
          autoClose: false
        });
      }
    } finally {
      setLoadingBidAnalysis(false);

      if (loadingNotification != null) {
        dismiss(loadingNotification.id);
      }
    }
  };

  const contextValue: BidAnalysisDetailsContextValue = {
    bidAnalysis,
    loadingBidAnalysis,
    loadBidAnalysis,
  };

  return (
    <BidAnalysisDetailsContext.Provider value={contextValue}>
      {children}
    </BidAnalysisDetailsContext.Provider>
  );
};

type BidAnalysisDetailsProps = {
  id: number;
  children?: React.ReactNode
};
