import { useCallback, useEffect, useState } from 'react';

/**
 * Sets up a delayed close functionality, useful for animated UIs based on derived state.
 */
export const useDelayedClose = (initialState: boolean, onClose: () => void, delayMs = 300) => {
  const [isOpen, setIsOpen] = useState(initialState);

  useEffect(() => {
    if (!isOpen) {
      const timer = setTimeout(onClose, delayMs);

      return () => clearTimeout(timer);
    }
  }, [isOpen, delayMs, onClose]);

  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => setIsOpen(false), []);

  return { isOpen, handleClose, handleOpen };
};
