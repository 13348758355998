import { Box } from 'grommet';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { RhfFilterForm } from '@/components/filters';
import { LookupSelect } from '@/components/form-controls';
import { Lookup } from '@/types/lookup';
import { Pool } from '@/types/pool';

import { ColumnConfiguration } from '../types';
import { ExternalDataFilters } from './external-data-providers';
import { useStatesProvider } from './states-provider';

type StateDataFiltersProps = {
  filterData: StateFilterData;
  onFilterChange: (filterData: StateFilterData) => void;
  openColumnConfigurations: ColumnConfiguration[];
};

export const StateDataFilters = ({ filterData, onFilterChange, openColumnConfigurations }: StateDataFiltersProps) => {
  const { states, pools, loading } = useStatesProvider();
  const {
    control,
    handleSubmit,
    reset,
    watch
  } = useForm<StateFilterData>({
    mode: 'all',
    defaultValues: filterData
  });

  const stateOrPoolOptions = useMemo(() => {
    const options: Lookup<string | null>[] = [];

    const base: Pool[] = [
      {
        // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
        code: null,
        name: 'Independent',
        states: []
      },
      ...pools
    ];
    for (const pool of base) {
      options.push({
        label: pool.name,
        id: pool.code
      });
    }

    for (const state of states) {
      options.push({
        label: state.name,
        id: state.code
      });
    }

    return options;
  }, [states, pools]);

  return (
    <RhfFilterForm
      variant="table"
      onReset={() => reset({
        statesAndPools: [],
        carvedOutClassifications: [],
        carvedOutClinicalsProducts: [],
        exemptClassifications: [],
        exemptClinicalsProducts: []
      })}
      onSubmit={handleSubmit((data) => onFilterChange(data))}
      watch={watch}
    >
      <ExternalDataFilters
        openColumnConfigurations={openColumnConfigurations}
        control={control}
        disabled={loading}
      />
      <Box width="16rem">
        <Controller
          control={control}
          name="statesAndPools"
          render={({ field: { ref, value, onChange, onBlur } }) => (
            <LookupSelect
              name="states"
              multiple={true}
              options={stateOrPoolOptions}
              disabled={loading}
              placeholder="Search by State"
              ref={ref}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </Box>
    </RhfFilterForm>
  );
};

export type StateFilterData = {
  statesAndPools: (string | null)[];
  carvedOutClassifications: Lookup[];
  carvedOutClinicalsProducts: Lookup[];
  exemptClassifications: Lookup[];
  exemptClinicalsProducts: Lookup[];
};
