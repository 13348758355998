import { useHttpClient } from '@shared/http';
import { PaginationRequest, PaginationResult } from '@shared/pagination';

import { Nadac } from '@/features/nadacs';
import { buildQueryString } from '@/utils/filtering-functions';

export type NadacSearchRequest = {
  ndc?: string;
  description?: string;
} & PaginationRequest;

export const useNadacsService = () => {
  const httpClient = useHttpClient();

  const searchNadacs = (request: NadacSearchRequest): Promise<PaginationResult<Nadac>> => {
    return httpClient.get(`medicaid/nadacs${buildQueryString(request)}`);
  };

  return {
    searchNadacs
  };
};
