import { RowData, Table } from '@tanstack/react-table';
import clsx from 'clsx';
import { isNil, parseInt } from 'lodash';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Pagination, PaginationGap,
  PaginationList,
  PaginationNext,
  PaginationPage,
  PaginationPrevious
} from '@/components-new/pagination';

/**
 * Calculates the pagination pages based on the current page index, page count, and neighbor count.
 */
const getPaginationPages = (
  currentPageIndex: number,
  pageCount: number,
  neighborCount = 2
) => {
  const pages: (number | null)[] = [];
  const firstPage = 1;
  const lastPage = pageCount;
  const currentPage = currentPageIndex + 1;

  // always render the first page
  pages.push(firstPage);

  // add a gap between first page and neighbors if necessary
  if (currentPage > neighborCount + 2) {
    pages.push(null);
  }

  // render the current page and its neighbors
  const startPage = Math.max(currentPage - neighborCount, 2);
  const endPage = Math.min(currentPage + neighborCount, pageCount - 1);

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  // add null between last page and neighbors if necessary
  if (currentPage < pageCount - neighborCount - 1) {
    pages.push(null);
  }

  // Always render the last page
  if (pageCount > 1) {
    pages.push(lastPage);
  }

  return pages;
};

type DataPaginatorProps<T extends RowData> = {
  table: Table<T>,
  className?: string;
}

/**
 * A component that renders a pagination control based on the given table data.
 */
export const DataPaginator = <T extends RowData>({ className, table }: DataPaginatorProps<T>) => {
  const [searchParams] = useSearchParams();

  // maintain the pagination state in the url
  useEffect(() => {
    const searchPage = parseInt(searchParams.get('page') ?? '');
    if (isNil(searchPage) || isNaN(searchPage)) return;

    const currentPage = table.getState().pagination.pageIndex;

    if (searchPage - 1 !== currentPage) {
      table.setPageIndex(searchPage - 1);
    }
  }, [searchParams, table]);

  const currentPageIndex = table.getState().pagination.pageIndex;
  const pageCount = table.getPageCount();

  const pages = getPaginationPages(currentPageIndex, pageCount);

  return (
    <Pagination className={clsx(className)}>
      <PaginationPrevious href={table.getCanPreviousPage() ? `?page=${currentPageIndex}` : null}/>
      <PaginationList>
        {pages.map((page, index) => (
          <React.Fragment key={index}>
            {page === null ?
              (<PaginationGap />) :
              (<PaginationPage href={`?page=${page}`} current={page - 1 === currentPageIndex}>{page}</PaginationPage>)
            }
          </React.Fragment>
        ))}
      </PaginationList>
      <PaginationNext
        href={table.getCanNextPage() ? `?page=${currentPageIndex + 1 + 1}` : null}
      />
    </Pagination>
  );
};
