import { ProtectedRoute } from '@shared/auth';
import { Box, Spinner } from 'grommet';
import React, { useEffect } from 'react';

import { DrugPackagingsForm } from '@/components/drug-packagings-form';
import { FormCard } from '@/components/form-card';
import { Button } from '@/components-new/button';
import { useDrugPackagingForm } from '@/hooks/use-drug-packaging-form';
import { useProductDetails } from '@/products/product-details.provider';
import { useParams } from '@/router';

const EditDrugPackagingPageContainer = () => {
  const {
    form,
    savingDrugPackaging,
    submitForm,
    fetchingDrugPackaging,
    fetchDrugPackaging
  } = useDrugPackagingForm();
  const { id: productId, ndc } = useParams('/drugs/:id/packagings/:ndc');
  const { product, loadProduct } = useProductDetails();

  useEffect(() => {
    if (product === null) {
      loadProduct(parseInt(productId));
    }

    fetchDrugPackaging(parseInt(productId), ndc);
    // TODO: revisit missing deps.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, ndc]);

  const handleSubmit = () => {
    submitForm(parseInt(productId), ndc);
  };

  return (
    <FormCard>
      {fetchingDrugPackaging && (
        <Box direction="row" justify="center" align="center">
          <Spinner size="large" />
        </Box>
      )}
      {!fetchingDrugPackaging && (
        <>
          <DrugPackagingsForm formControl={form.control} errors={form.formState.errors} />
          <Box direction="row" justify="end" gap="small">
            <Button
              outline
              href="/drugs/:id"
              params={{ id: productId }}
              disabled={savingDrugPackaging}
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              onClick={handleSubmit}
              disabled={savingDrugPackaging}
            >
              {savingDrugPackaging ? <Spinner size="xsmall" /> : 'Save'}
            </Button>
          </Box>
        </>
      )}
    </FormCard>
  );
};

const EditDrugPackagingPage = () => {
  return <ProtectedRoute component={EditDrugPackagingPageContainer} policies={['canManageDrugPackagings']} />;
};

export default EditDrugPackagingPage;
