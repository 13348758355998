import { ColumnConfig, DataTable } from 'grommet';
import React from 'react';

import { BidAnalysisState } from '@/features/bid-analysis';

export const MedicaidLives = ({ states }: { states: BidAnalysisState[] }) => {
  const formatLives = (value?: number) => {
    return value?.toLocaleString() ?? <>&mdash;</>;
  };

  const columns: ColumnConfig<BidAnalysisState>[] = [
    {
      property: 'state.name',
      header: 'State'
    },
    {
      property: 'benefit.name',
      header: 'Benefit'
    },
    {
      property: 'ffsLives',
      header: 'FFS Lives',
      align: 'end',
      render: (state) => formatLives(state.ffsLives)
    },
    {
      property: 'mcoLives',
      header: 'MMC Lives',
      align: 'end',
      render: (state) => formatLives(state.mcoLives)
    },
    {
      property: 'totalLives',
      header: 'Total Lives',
      align: 'end',
      render: (state) => formatLives(state.totalLives)
    },
    {
      property: 'stateControlledLives',
      header: 'State Controlled Lives',
      align: 'end',
      render: (state) => formatLives(state.stateControlledLives)
    }
  ];

  let rows: BidAnalysisState[];
  if (states.length === 1) {
    rows = states;
  } else {
    rows = [...states, {
      state: { name: 'Total' },
      ffsLives: states.reduce((sum, state) => sum + (state.ffsLives ?? 0), 0),
      mcoLives: states.reduce((sum, state) => sum + (state.mcoLives ?? 0), 0),
      totalLives: states.reduce((sum, state) => sum + (state.totalLives ?? 0), 0),
      stateControlledLives: states.reduce((sum, state) => sum + (state.stateControlledLives ?? 0), 0)
    }] as BidAnalysisState[];
  }

  return (
    <DataTable
      columns={columns}
      data={rows}
    />
  );
};
