import { type Comparator } from './comparator';
import { type SortDirection } from './sort';

export const sortStrings: Comparator<string> = (left: string, right: string, direction: SortDirection) => {
  if (left == null) return 1;
  if (right == null) return -1;

  if (direction === 'desc') {
    return right.localeCompare(left);
  } else {
    return left.localeCompare(right);
  }
};

export const sortNumbers: Comparator<number> = (left: number, right: number, direction: SortDirection) => {
  if (left == null) return 1;
  if (right == null) return -1;

  if (direction === 'desc') {
    if (left < right) return 1;
    else if (left > right) return -1;
    else return 0;
  } else {
    if (left < right) return -1;
    else if (left > right) return 1;
    else return 0;
  }
};
