import { CreateUpdateCoverageTagFormSchema } from '@/features/drugs/types/create-update-coverage-tag-form-schema';
import { artiaProtectedApi } from '@/lib/api-client';

export const updateCoverageTag = async (drugId: number, coverageTagId: number, formInputs: CreateUpdateCoverageTagFormSchema): Promise<{ coverageTagId: number }> => {
  const response = await artiaProtectedApi.put(`drugs/${drugId}/coverage-tags/${coverageTagId}`, {
    name: formInputs.name,
    type: formInputs.type.code
  });

  return response.data;
};
