import clsx from 'clsx';
import React from 'react';

type SectionTitleProps = {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
} & React.ComponentPropsWithoutRef<'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>

export const SectionHeading = ({ className, level = 2, ...props }: SectionTitleProps) => {
  const Element: `h${typeof level}` = `h${level}`;

  return (
    <Element
      {...props}
      className={clsx(className,  'text-lg font-semibold leading-6 text-zinc-950 dark:text-white')}
    />
  );
};

export const SectionDescription = ({ className, ...props }: React.ComponentPropsWithoutRef<'p'>) => {
  return <p {...props} className={clsx(className, 'mt-1 text-sm text-gray-700')} />;
};
