import { isEmpty, isNil } from 'lodash';
import React from 'react';

type NullishProps<T> = {
  value?: T,

  /**
   * Renderer template used when the value is null, undefined, or empty.
   */
  nullRenderer?: (value: T) => React.ReactNode,
  /**
   * Renderer template used when the value is not null, undefined, or empty.
   */
  valueRenderer?: (value: T) => React.ReactNode,
}

const DEFAULT_NULL_RENDERER = (_: any) => <>&mdash;</>;
const DEFAULT_VALUE_RENDERER = (value: any) => value;

/**
 * Renders one of two templates based on whether a value is "nullish" (null,
 * undefined, or empty).
 */
const Nullish = <T,>({ value, nullRenderer = DEFAULT_NULL_RENDERER, valueRenderer = DEFAULT_VALUE_RENDERER }: NullishProps<T>) => {
  // @ts-expect-error TS(2345): Argument of type 'T | undefined' is not assignable... Remove this comment to see the full error message
  return (isNil(value) || isEmpty(value)) ? nullRenderer(value) : valueRenderer(value);
};

export default Nullish;
