import { Layer, Text } from 'grommet';
import React from 'react';

import { Prompt } from '@/components/prompt';
import { User } from '@/features/admin/types/user';
import { Client } from '@/features/clients';
import { useResetPassword } from '@/features/clients/api/use-reset-password';

type Props = {
  user: User
  client: Client;
  onClose: () => void;
}
const ResetPasswordPrompt = ({ client, user, onClose }: Props) => {
  const {
    mutate: resetPassword,
    isPending,
  } = useResetPassword();

  const handleConfirm = () => {
    resetPassword(
      { clientId: client.id, user },
      {
        onSuccess: () => onClose()
      });
  };

  return (
    <Layer onClickOutside={onClose}>
      <Prompt
        header="Reset User Password"
        actions={[
          { name: 'Cancel', onClick: onClose, variant: 'outlined' },
          { name: 'Confirm', onClick: handleConfirm, disabled: () => isPending, variant: 'filled' },
        ]}
      >
        <Text>
          Are you sure you want to send a password reset email to <strong>{user.email}</strong>?
        </Text>
      </Prompt>
    </Layer>
  );
};

export default ResetPasswordPrompt;
