import { useHttpClient } from '@shared/http';
import { PaginationRequest, PaginationResult } from '@shared/pagination';

import { DrugUtilization } from '@/features/drug-utilization';
import { buildQueryString } from '@/utils/filtering-functions';

export type DrugUtilizationSearchRequest = {
  year?: number;
  state?: string;
  ndc?: string;
  product?: string;
} & PaginationRequest;

export const useDrugUtilizationsService = () => {
  const httpClient = useHttpClient();

  const searchDrugUtilizations = (request: DrugUtilizationSearchRequest): Promise<PaginationResult<DrugUtilization>> => {
    return httpClient.get(`medicaid/drug-utilizations${buildQueryString(request)}`);
  };

  return {
    searchDrugUtilizations
  };
};
