import React from 'react';

import { Loader } from '@/components-new/loader';
import { Overlay } from '@/components-new/overlay';

export const OverlayLoader = () => {
  return (
    <div className="relative size-full">
      <Overlay>
        <Loader />
      </Overlay>
    </div>
  );
};
