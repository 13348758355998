import React, { createContext } from 'react';

import { BreadcrumbIcon } from '@/components-new/breadcrumbs';

export const DEFAULT_HEADER_DETAILS: HeaderDetails = {
  pageTitle: '',
  pageSubtitle: '',
  breadcrumbs: [],
};

type HeaderDetailsBreadcrumb = {
  icon?: BreadcrumbIcon;
  label: string;
  url: string;
};

export type HeaderDetails = {
  documentTitle?: string;
  pageTitle?: string | React.JSX.Element;
  actions?: React.JSX.Element;
  pageSubtitle?: string | React.JSX.Element;
  breadcrumbs?: HeaderDetailsBreadcrumb[];
};

type HeaderDetailsContextProps = {
  headerDetails: HeaderDetails;
  setHeaderDetails: (headerDetails: HeaderDetails) => void;
  siteTitle?: string;
  clearHeaderDetails: () => void;
};

const DEFAULT_HEADER_DETAILS_CONTEXT: HeaderDetailsContextProps = {
  headerDetails: DEFAULT_HEADER_DETAILS,
  siteTitle: '',
  setHeaderDetails: () => null,
  clearHeaderDetails: () => null,
};

export const HeaderDetailsContext = createContext<HeaderDetailsContextProps>(DEFAULT_HEADER_DETAILS_CONTEXT);
