import { useHeaderDetails } from '@shared/header';
import { Box } from 'grommet';
import { PieChart } from 'grommet-icons';
import React, { useEffect } from 'react';

import { Wizard } from '@/components/wizard';
import { BenefitsSelectionStep } from '@/features/bid-analysis/components/new/benefits-selection-step';
import { BidAnalysisDetailsStep } from '@/features/bid-analysis/components/new/bid-analysis-details-step';
import { ReviewBidAnalysisStep } from '@/features/bid-analysis/components/new/review-bid-analysis-step';

const NewBidAnalysesPage = () => {
  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();

  useEffect(() => {
    setHeaderDetails({
      documentTitle: 'Add Bid Analysis',
      pageTitle: 'Add Bid Analysis',
      pageSubtitle: 'Start a new bid analysis.',
      breadcrumbs: [
        { icon: PieChart, label: 'Bid Analyses', url: '/bid-analyses' },
        { label: 'Add Bid Analysis', url: '/bid-analyses/new' }
      ]
    });
    return () => {
      clearHeaderDetails();
    };
  }, []);

  return (
    <Box flex align="center">
      <Wizard>
        <BidAnalysisDetailsStep stepName="Details"/>
        <BenefitsSelectionStep stepName="Benefits"/>
        <ReviewBidAnalysisStep stepName="Overview"/>
      </Wizard>
    </Box>
  );
};

export default NewBidAnalysesPage;
