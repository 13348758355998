import { useAsync } from '@shared/async';
import React from 'react';

import { DeleteConfirmationDialog } from '@/components/delete-confirmation-dialog';
import { useBidAnalysisService } from '@/features/bid-analysis';

type DeleteBidAnalysisDialogProps = {
  open: boolean;
  bidAnalysisId: number;
  onClose: () => void;
  onDeleteComplete: () => void;
};

export const DeleteBidAnalysisDialog = (props: DeleteBidAnalysisDialogProps) => {
  const {
    open,
    bidAnalysisId,
    onClose,
    onDeleteComplete
  } = props;

  const { deleteBidAnalysis } = useBidAnalysisService();
  const deleteAsync = useAsync(deleteBidAnalysis);

  return (
    <DeleteConfirmationDialog
      open={open}
      itemName="Bid Analysis"
      onCancel={onClose}
      onDeleteComplete={onDeleteComplete}
      request={deleteAsync}
      parameters={[bidAnalysisId]}
    />
  );
};
