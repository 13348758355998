import { DrugUtilizationPeriod } from '@/features/drug-utilization';
import { StateBenefit } from '@/types/benefit';
import { Lookup } from '@/types/lookup';
import { NestedLookup } from '@/types/nested-lookup';
import { Pool } from '@/types/pool';
import { State } from '@/types/state';

import { BidType } from './bid-analysis';

export type CreateBidAnalysisFormInputs = {
  name: string;
  bidType: BidType;
  marketBasket: NestedLookup;
  drug: Lookup;
  year: string;
  dueOn: string;
  reviewOn: string;
  startsOn: string;
  endsOn: string;
  pool: Pool;
  state: State;
  benefits: { [key: string]: StateBenefit };
  periods: DrugUtilizationPeriod[]
};

export const defaultCreateBidAnalysisForm: CreateBidAnalysisFormInputs = {
  name: '',
  bidType: '',
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'NestedLooku... Remove this comment to see the full error message
  marketBasket: null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Lookup<numb... Remove this comment to see the full error message
  drug: null,
  year: new Date().getFullYear().toString(),
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  dueOn: null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  reviewOn: null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  startsOn: null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  endsOn: null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Pool'.
  pool: null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'State'.
  state: null,
  benefits: {},
  periods: []
};

export type EditBidAnalysisFormInputs = {
  name: string;
  bidDueOn: string;
  reviewOn: string;
  contractTermStartsOn: string;
  contractTermEndsOn: string;
};

export const defaultEditBidAnalysisForm: EditBidAnalysisFormInputs = {
  name: '',
  bidDueOn: '',
  reviewOn: '',
  contractTermEndsOn: '',
  contractTermStartsOn: ''
};
