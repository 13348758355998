import { useAsync, UseAsyncStatus } from '@shared/async';
import { ProtectedRoute } from '@shared/auth';
import { useErrorHandler } from '@shared/errors';
import { useHeaderDetails } from '@shared/header';
import { useNotifications } from '@shared/notifications';
import { Box } from 'grommet';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormCard } from '@/components/form-card';
import { useClassificationService } from '@/features/classifications/api/use-classifications-service';
import { ClassificationForm } from '@/features/classifications/classification-form';
import { ClassificationFormInputs } from '@/features/classifications/types/classification-form-inputs';

const NewClassification = () => {
  const { createClassification } = useClassificationService();
  const { handleError } = useErrorHandler();
  const { success } = useNotifications();
  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();
  const navigate = useNavigate();

  const createClassificationAsync = useAsync(createClassification);
  const saving = createClassificationAsync.status === UseAsyncStatus.Pending;

  React.useEffect(() => {
    setHeaderDetails({
      documentTitle: 'Add Classification',
      pageTitle: 'Add Classification',
      breadcrumbs: [
        { label: 'Classifications', url: '/classifications' },
        { label: 'Add Classification', url: '/classifications/new' }
      ]
    });

    return () => {
      clearHeaderDetails();
    };
  }, []);

  useEffect(() => {
    const { status, error } = createClassificationAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(
        error,
        {
          title: 'Create Classification Failed',
          message: 'Unable to create classification.',
          autoClose: false
        }
      );

      return;
    }

    success({ title: 'Success', message: 'Successfully created classification.', autoClose: true });
    navigate('/classifications');
  }, [createClassificationAsync.status]);

  const handleFormSubmit = async (value: ClassificationFormInputs) => {
    await createClassificationAsync.execute(value);
  };

  const handleCancel = () => {
    navigate('/classifications');
  };

  return (
    <Box gap="small">
      <FormCard>
        <ClassificationForm
          saving={saving}
          onSubmit={handleFormSubmit}
          onCancel={handleCancel}
        />
      </FormCard>
    </Box>
  );
};

const NewClassificationPage = () => {
  return <ProtectedRoute component={NewClassification} policies={['canManageClassifications']} />;
};

export default NewClassificationPage;
