import { useAsync, UseAsyncStatus } from '@shared/async';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { ColumnGroupsResponse } from '@/features/state-reports/api/use-states-service';
import { useLookupsService } from '@/hooks/use-lookups-service';
import { Pool } from '@/types/pool';
import { State } from '@/types/state';

import { useStatesService } from '../api';
import { ColumnConfiguration, ColumnConfigurationGroup, CustomState } from '../types';
import { StateFilterData } from './state-data-filters';

type StatesCache = Partial<Record<ColumnConfigurationGroup, { columns: ColumnConfiguration[], states: CustomState[] }>>;

type StatesContextValue = {
  statesCache: StatesCache;
  states: State[];
  pools: Pool[];
  refresh: (tab?: ColumnConfigurationGroup, filters?: StateFilterData) => void;
  reloadLookups: () => void;
  reloadColumnGroups: () => void;
  loading: boolean;
  columnGroups: ColumnGroupsResponse;
};

const StatesContext = createContext<StatesContextValue | null>(null);

export const StatesProvider = () => {
  const { lookupStatesWithColumnGroup, getColumnGroups } = useStatesService();

  const columnGroupsRequest = useAsync(getColumnGroups);
  useEffect(() => {
    void columnGroupsRequest.execute();
  }, []);

  const [statesCache, setStatesCache] = useState<StatesCache>({});
  const { getStates, getPools } = useLookupsService();
  const statesAsync = useAsync(lookupStatesWithColumnGroup);
  const statesAndPoolsRequest = useAsync(() => Promise.all([getStates(), getPools()]));

  const value = useMemo<StatesContextValue>(() => {
    const [states, pools] = statesAndPoolsRequest.value ?? [[], []];
    return {
      statesCache,
      states,
      pools,
      refresh: (tab, filters) => {
        statesAsync.execute(tab ?? statesAsync.previousParameters[0], filters ?? statesAsync.previousParameters[1]);
      },
      reloadLookups: () => {
        statesAndPoolsRequest.execute();
      },
      reloadColumnGroups: () => {
        columnGroupsRequest.execute();
      },
      loading: statesAsync.status === UseAsyncStatus.Pending
        || statesAndPoolsRequest.status === UseAsyncStatus.Pending
        || columnGroupsRequest.status === UseAsyncStatus.Pending,
      columnGroups: columnGroupsRequest.value ?? {
        customReports: [],
        systemReports: {}
      }
    };
  }, [
    statesCache,
    statesAsync.
    previousParameters,
    statesAndPoolsRequest.status,
    statesAsync.status,
    columnGroupsRequest.status
  ]);

  useEffect(() => {
    if (statesAsync.status !== UseAsyncStatus.Success) return;

    setStatesCache((current) => ({
      ...current,
      [statesAsync.previousParameters[0]]: statesAsync.value
    }));
  }, [statesAsync.status]);

  useEffect(() => {
    statesAndPoolsRequest.execute();
  }, []);

  return (
    <StatesContext.Provider value={value}>
      <Outlet />
    </StatesContext.Provider>
  );
};

export const useStatesProvider = () => {
  const value = useContext(StatesContext);

  if (!value) {
    throw new Error('Must be called within <StatesProvider>!');
  }

  return value;
};
