import { DateTime } from 'luxon';

import { CalendarDay } from '@/features/calendar/types/calendar-day';

/**
 * Returns an array of {@link CalendarDay} objects representing the days to
 * display on the calendar for the month.
 *
 * @param value The Luxon date representing the first day of the month to build.
 * @param daysWithEvents
 */
export const buildMonth = (value: DateTime, daysWithEvents: string[]): CalendarDay[] => {
  const firstDay = value.startOf('month');
  const lastDay = firstDay.endOf('month').startOf('day');
  const firstDayWeekday = getWeekdayNumber(firstDay);
  const lastDayWeekday = getWeekdayNumber(lastDay);

  const lastMonthDaysToCover = firstDayWeekday - 1;
  const nextMonthDaysToCover = 7 - lastDayWeekday;
  const totalDaysToCover = lastMonthDaysToCover + firstDay.daysInMonth! + nextMonthDaysToCover;

  let startingDay = firstDayWeekday > 1 ?
    firstDay.minus({ days: firstDayWeekday - 1 }) :
    firstDay;

  const days: CalendarDay[] = [];
  for (let i = 0; i < totalDaysToCover; i++) {
    const isCurrentMonth = startingDay.month === firstDay.month;
    const isToday = startingDay.hasSame(DateTime.now(), 'day');

    days.push({
      date: startingDay.toISODate()!,
      isCurrentMonth: isCurrentMonth,
      isToday: isToday,
      hasEvents: daysWithEvents.includes(startingDay.toISODate()!)
    });

    startingDay = startingDay.plus({ days: 1 });
  }

  return days;
};

/**
 * Returns a number between 1 (Sunday) and 7 (Saturday) indicating the day of
 * the week for the specified date. This differs from Luxon's
 * {@link DateTime.weekday} property, which returns 1-7 ranging from Monday-Sunday.
 *
 * @param date The Luxon date.
 */
const getWeekdayNumber = (date: DateTime): number => {
  const mondayBasedWeekday = date.weekday;
  if (mondayBasedWeekday == 7) {
    return 1;
  } else {
    return mondayBasedWeekday + 1;
  }
};
