import React, { createContext, useContext } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import { CreateBidAnalysisFormInputs, defaultCreateBidAnalysisForm } from '@/features/bid-analysis/types/bid-analysis-form-inputs';
import { sortDrugUtilizationPeriods } from '@/features/drug-utilization';

import { useCreateBidAnalysis } from './create-bid-analysis.provider';

type CreateBidAnalysisFormContextValue = {
  form?: UseFormReturn<CreateBidAnalysisFormInputs>
};

const CreateBidAnalysisFormContext = createContext<CreateBidAnalysisFormContextValue>({});

export const useCreateBidAnalysisForm = () => useContext<CreateBidAnalysisFormContextValue>(CreateBidAnalysisFormContext);

export const CreateBidAnalysisFormProvider = ({ children }: CreateBidAnalysisFormProps) => {
  const { loadLookups, drugUtilizationPeriods } = useCreateBidAnalysis();

  const form = useForm<CreateBidAnalysisFormInputs>({ mode: 'onBlur', reValidateMode: 'onBlur', defaultValues: defaultCreateBidAnalysisForm });

  const { reset, setValue } = form;

  React.useEffect(() => {
    reset();
    loadLookups();

    return () => reset();
  }, []);

  // Pre-select the 4 most recent quarters.
  React.useEffect(() => {
    setValue('periods', drugUtilizationPeriods.sort(sortDrugUtilizationPeriods));
  }, [drugUtilizationPeriods]);

  const contextValue = {
    form
  };

  return (
    <CreateBidAnalysisFormContext.Provider value={contextValue}>
      {children}
    </CreateBidAnalysisFormContext.Provider>
  );
};

type CreateBidAnalysisFormProps = {
  children?: React.ReactNode
};
