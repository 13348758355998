import { Box, Text } from 'grommet';
import { StatusCritical, StatusInfo, StatusWarning } from 'grommet-icons';
import React from 'react';

export type AlertVariant = 'critical' | 'warning' | 'info';

type AlertProps = {
  variant: AlertVariant;
  message: string;
};

/**
 * Displays an inline alert with a message.
 *
 * @param variant The type of alert to display. Determines the color and icon of
 * the alert.
 * @param message The message to display.
 */
export const Alert = ({ variant, message }: AlertProps) => {
  const { backgroundColor, icon } = styleAlert(variant);

  return (
    <Box
      direction="row"
      gap="small"
      align="center"
      style={{ background: backgroundColor, borderRadius: '8px', padding: '10px' }}
    >
      {icon}
      <Text>{message}</Text>
    </Box>
  );
};

type AlertsProps = {
  variant: AlertVariant;
  messages: string[];
};

/**
 * Displays an inline alert with multiple messages.
 *
 * @param variant The type of alert to display. Determines the color and icon of
 * the alert.
 * @param message The message to display.
 */
export const Alerts = ({ variant, messages }: AlertsProps) => {
  const { backgroundColor, icon } = styleAlert(variant);

  return (
    <Box direction="row" gap="small" align="center" style={{ background: backgroundColor, borderRadius: '8px', padding: '10px' }}>
      {icon}
      {messages.length > 1 ? <ul style={{ paddingLeft: '20px' }}>
        {messages.map((msg, i) => (
          <li key={i}><Text>{msg}</Text></li>
        ))}
      </ul> : <Text>{messages[0]}</Text> }
    </Box>
  );
};

export const styleAlert = (variant: AlertVariant) => {
  let backgroundColor;
  let icon;
  switch (variant) {
    case 'critical':
      backgroundColor = '#ea868f';
      icon = <StatusCritical size="medium" color="#444444"/>;
      break;
    case 'warning':
      backgroundColor = '#ffca58';
      icon = <StatusWarning size="medium" color="#444444"/>;
      break;
    default:
      backgroundColor = '#EDEDED';
      icon = <StatusInfo size="medium" color="#444444"/>;
      break;
  }

  return { backgroundColor, icon };
};
