import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader, Heading,
  HeadingProps,
  Layer,
  LayerExtendedProps,
  Text
} from 'grommet';
import { WidthType } from 'grommet/utils';
import { Close } from 'grommet-icons';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

let dialogRootCounter = 0;

type ParentProps = {
  children?: React.ReactNode;
};

interface DialogProps extends LayerExtendedProps {
  children: React.ReactNode;
  open: boolean;
  width?: WidthType;
}

export const Dialog = (props: DialogProps) => {
  const {
    children,
    open,
    width,
    ...rest
  } = props;
  const id = useRef(null);
  const [ ready, setReady ] = React.useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    dialogRootCounter += 1;
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'null'.
    id.current = `dialog-root-${dialogRootCounter}`;

    return () => {
      destroy();
    };
  }, []);

  const makeContainer = () => {
    const container = document.createElement('div');
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
    container.id = id.current;
    document.body.appendChild(container);
    return container;
  };

  useEffect(() => {
    if (!open) return;
    // @ts-expect-error TS(2322): Type 'HTMLElement' is not assignable to type 'null... Remove this comment to see the full error message
    containerRef.current = document.getElementById(id.current) || makeContainer();
    setTimeout(() => { setReady(true); });
  }, [open]);

  useEffect(() => {
    if (!open) {
      destroy();
    }
  }, [open]);

  const destroy = () => {
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    const container = document.getElementById(id.current);
    container && container.remove();
    setReady(false);
  };

  return (open && ready && containerRef.current)
    ? createPortal(
      <Layer {...rest} background="transparent">
        <DialogContainer width={width}>
          {children}
        </DialogContainer>
      </Layer>,
      containerRef.current
    )
    : null;
};

type DialogActionsProps = {
  justify?: 'end' | 'between';
  children?: React.ReactNode;
};

export const DialogActions = ({ justify = 'end', children }: DialogActionsProps) => {
  return (
    <DialogFooter>
      <Box direction="row" align="center" justify={justify} fill gap="small">
        {children}
      </Box>
    </DialogFooter>
  );
};

export const DialogBody = ({ children }: ParentProps) => {
  return (
    <CardBody pad="medium" overflow={{ vertical: 'auto' }}>
      <Inner>{children}</Inner>
    </CardBody>
  );
};

const Inner = styled.div`
  flex-shrink: 0;
`;

type DialogContainerProps = {
  children?: React.ReactNode;
  width?: WidthType;
};

export const DialogContainer = ({ children, width = { max: 'large' } }: DialogContainerProps) => {
  return (
    <Card background="white" width={width} fill="vertical">
      {children}
    </Card>
  );
};

export const DialogFooter = ({ children }: ParentProps) => {
  return (
    <CardFooter border={{ side: 'top' }} pad={{ vertical: 'small', horizontal: 'medium' }}>
      {children}
    </CardFooter>
  );
};

type DialogHeaderProps = {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  onClose?: () => void;
};

export const DialogHeader = ({ title, subtitle, onClose }: DialogHeaderProps ) => {
  // close the dialog if the Escape key is pressed AND it can actually be closed via the X button.
  // if that button is disabled or not present, then we shouldn't close it via Escape
  const buttonRef = useRef<HTMLButtonElement & HTMLAnchorElement>(null);
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      const button = buttonRef.current;
      if (event.key === 'Escape' && button) {
        button.click();
      }
    };

    window.addEventListener('keydown', handleEscape);

    return () => window.removeEventListener('keydown', handleEscape);
  }, []);

  const subtitleContent = typeof title === 'string' ?
    (<DialogSubtitle>{subtitle}</DialogSubtitle>)
    : subtitle;

  const titleContent = typeof title === 'string' ?
    (<DialogTitle>{title}</DialogTitle>) :
    title;

  return (
    <CardHeader border={{ side: 'bottom' }} pad={{ vertical: 'small', horizontal: 'medium' }}>
      <Box direction="row" align="center" justify="between" fill>
        <Box gap="xsmall">
          {title && titleContent}
          {subtitle && subtitleContent}
        </Box>
        {onClose ? (
          <Button ref={buttonRef} icon={<Close />} onClick={onClose} hoverIndicator={true} />
        ) : null}
      </Box>
    </CardHeader>
  );
};

export const DialogSubtitle = ({ children }: ParentProps) => {
  return (
    <Text margin="none" color="dark-2">{children}</Text>
  );
};

type DialogTitleProps = {
  level?: HeadingProps['level'];
  children?: React.ReactNode;
}

export const DialogTitle = ({ level = 3, children }: DialogTitleProps) => {
  return (
    <Heading level={level} margin="none">{children}</Heading>
  );
};
