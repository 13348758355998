import { useErrorHandler } from '@shared/errors';
import { Download } from 'grommet-icons';
import React from 'react';

import { ArtiaButton } from '@/components/artia-button';
import { useNetCostComparisonService } from '@/features/bid-analysis/api/net-cost-comparison-service';

import { useBidAnalysisDetails } from '../../../bid-analysis-details.provider';

export const ExportToCsvButton = () => {
  const { bidAnalysis } = useBidAnalysisDetails();
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const { exportToCsv } = useNetCostComparisonService(bidAnalysis.id);
  const { handleError } = useErrorHandler();

  const onExportNetCostComparison = async () => {
    try {
      const csv = await exportToCsv();

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(csv.contents);
      link.download = csv.filename;
      link.click();
    } catch (ex) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      handleError(ex, {
        title: 'Export to CSV Failed',
        message: 'We encountered an unexpected error while exporting the market basket. Please try again or contact an administrator.',
        autoClose: false
      });
    }
  };

  return (
    <ArtiaButton
      icon={<Download/>}
      a11yTitle="Export to CSV"
      label="Export"
      tip="Export to CSV"
      onClick={() => onExportNetCostComparison()}
    />
  );
};
