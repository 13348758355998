import { NotificationDefinition } from '@shared/notifications/notification-definition';
import { Box, Notification, Spinner } from 'grommet';
import React from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Toast notification that is capable of closing itself after time.
 */
export const Toast = ({ notification }: ToastProps) => {
  const DEFAULT_CLOSE_DELAY = 5000;
  const { pathname } = useLocation();

  const {
    autoClose,
    id,
    onNotificationClose,
    severity,
    persistOnNavigation = false,
    ...notificationProps
  } = notification;

  const isMounted = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (!persistOnNavigation) {
      onNotificationClose(id);
    }
  }, [pathname]);

  React.useEffect(() => {
    let timeout: any = null;
    if (autoClose) {
      const closeAfter = typeof autoClose === 'number' ? autoClose : DEFAULT_CLOSE_DELAY;
      timeout = setTimeout(() => {
        onNotificationClose(id);
        clearTimeout(timeout);
      }, closeAfter);
    }
    return () => clearTimeout(timeout);
  }, []);

  const {
    message,
    ...restNotificationProps
  } = notificationProps;

  const notificationMessage = notificationProps.processing ?
    (
      <Box direction="row" align="center" gap="small">
        <Spinner />
        <Box>{message}</Box>
      </Box>
    ) : message;

  return (
    <Box background="white" round="small">
      <Notification message={notificationMessage} {...restNotificationProps} status={severity} onClose={() => onNotificationClose(id)} />
    </Box>
  );
};

type ToastProps = {
  notification: NotificationDefinition,
};
