import { isNil } from 'lodash';

export const stepTherapyDisplay = (steps?: number, hasAdditionalSteps?: boolean) => {
  if (isNil(steps)) return '';

  return hasAdditionalSteps ? `${steps}+` : `${steps}`;
};

export const automatedPaDisplay = (hasAutomatedPa?: boolean) => {
  return hasAutomatedPa ? 'Yes' : 'No';
};
