/**
 * Returns the filename from a Content-Disposition header or undefined if the
 * header does not specify a filename.
 *
 * @param contentDisposition The Content-Disposition header from a fetch
 * response.
 */
export const getDownloadFilename = (contentDisposition: string | undefined): string | undefined => {
  if (contentDisposition === undefined || contentDisposition === null) {
    return undefined;
  }

  if (!contentDisposition.startsWith('attachment; ')) {
    return undefined;
  }

  const parts = contentDisposition.split(';');
  const candidate = parts[1].split('=');

  if (candidate.length === 0) return undefined;

  let filename = candidate[1];
  if (filename.startsWith('"')) {
    // remove quotes at both ends
    filename = filename.substring(1, filename.length - 1);
  }

  return filename;
};

/**
 * Indicates whether the fetch response contains a Content-Type header that
 * starts with the specified value.
 *
 * @param response The fetch response.
 * @param contentType The value that the Content-Type header should match.
 * e.g., hasContentType('application/json') would return true for header values
 * of 'application/json' or 'application/json; charset=utf-8'.
 * @return A boolean indicating whether the response has the specified content
 * type.
 */
export const hasContentType = (response: Response, contentType: string): boolean => {
  return response.headers.has('Content-Type') &&
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    response.headers.get('Content-Type').startsWith(contentType);
};
