import { Box, Button, Spinner } from 'grommet';
import { Checkmark, Close } from 'grommet-icons';
import React from 'react';

type EditingActionsCellProps = {
  disabled: boolean;
  saving: boolean;
  onSave: () => void;
  onCancel: () => void;
};

export const EditingActionsCell = (props: EditingActionsCellProps) => {
  const { disabled, saving, onSave, onCancel } = props;

  const handleCancel = () => {
    onCancel();
  };

  const handleSave = () => {
    onSave();
  };

  return (
    <Box direction="row" align="center">
      <Button
        a11yTitle="Cancel"
        hoverIndicator="background"
        margin="none"
        icon={<Close color="accent-1" size="medium"/>}
        onClick={handleCancel}
        disabled={disabled}
      />
      {!saving && <Button
        a11yTitle="Save"
        hoverIndicator="background"
        margin="none"
        icon={<Checkmark color="accent-1" size="medium"/>}
        onClick={handleSave}
        disabled={disabled}
      />}
      {saving && <Spinner color="accent-1" size="xsmall"/>}
    </Box>
  );
};
