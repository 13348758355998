import { useErrorHandler } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createCoverageTag } from '@/features/drugs/api/create-coverage-tag';
import { makeCoverageTagsQueryKey } from '@/features/drugs/api/use-coverage-tags';
import { CreateUpdateCoverageTagFormSchema } from '@/features/drugs/types/create-update-coverage-tag-form-schema';

export const useCreateCoverageTag = () => {
  const queryClient = useQueryClient();
  const { success } = useNotifications();
  const { handleError } = useErrorHandler();

  return useMutation({
    mutationFn: ({ drugId, form }: { drugId: number, form: CreateUpdateCoverageTagFormSchema }) => createCoverageTag(drugId, form),
    onSuccess: () => {
      success({ title: 'Saved', message: 'Coverage Tag was successfully created.', autoClose: true });
    },
    onError: (error) => {
      handleError(
        error,
        {
          title: 'Save Failed',
          message: 'Unable to create coverage tag.',
          autoClose: true
        }
      );
    },
    onSettled: async (_, __, { drugId }) => {
      await queryClient.invalidateQueries({ queryKey: makeCoverageTagsQueryKey(drugId) });
    }
  });
};
