import { ProtectedRoute } from '@shared/auth';
import { Outlet } from 'react-router';

const UsersLayout = () => {
  return (
    <ProtectedRoute policies={['canManageUsers']} component={Outlet} />
  );
};

export default UsersLayout;
