export const hasValue = (value: any): string | undefined => {
  if (value) {
    return;
  }

  return 'Value is required.';
};

export const isNonEmpty = (value: any[]): string | undefined => {
  if (value.length > 0) {
    return;
  }

  return 'Value is required.';
};
