import { PaginationResult } from '@shared/pagination';
import React, { ChangeEvent, forwardRef, ReactNode, useId } from 'react';
import { Noop } from 'react-hook-form';

import { LazyLoadSelectList } from '@/components/form-controls';
import { useLookupsService } from '@/hooks/use-lookups-service';
import { Lookup } from '@/types/lookup';

type DrugLookupSelectProps = {
  required?: boolean;
  disabled?: boolean;
  name: string;
  value?: Lookup;
  error?: ReactNode;
  onBlur?: Noop;
  onChange: (nextValue: Lookup | ChangeEvent) => void;
  label?: string;
  clear?: { label: string };
};

export const DrugLookupSelect = forwardRef<HTMLInputElement, DrugLookupSelectProps>((props, ref) => {
  const {
    required,
    disabled,
    name,
    value,
    error,
    onBlur,
    onChange,
    label,
    clear
  } = props;

  const id = useId();

  const { getProductLookups } = useLookupsService();

  return (
    <LazyLoadSelectList
      id={id}
      required={required}
      name={name}
      disabled={disabled}
      error={error}
      label={label}
      placeholder="Select a product"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      lazyLoadRequest={async (searchTerm, page, rpp) => {
        const result = await getProductLookups({ query: searchTerm, page, rpp });
        return result as PaginationResult<Lookup>;
      }}
      ref={ref}
      clear={clear}
    />
  );
});
