import { queryOptions, useQuery } from '@tanstack/react-query';

import { Drug } from '@/features/drugs/types/drug';
import { artiaProtectedApi } from '@/lib/api-client';
import { QueryConfig } from '@/lib/react-query';

const makeTrackedDrugsQueryKey = () => ['trackedDrugs'];

const getTrackedDrugs = (): Promise<Drug[]> => {
  return artiaProtectedApi.get('/drugs');
};

export const getTrackedDrugsOptions = () => {
  return queryOptions({
    queryKey: makeTrackedDrugsQueryKey(),
    queryFn: () => getTrackedDrugs(),
  });
};

type UseTrackedDrugsOptions = {
  queryConfig?: QueryConfig<typeof getTrackedDrugsOptions>;
}


export const useTrackedDrugs = ({ queryConfig = {} }: UseTrackedDrugsOptions) => {
  return useQuery({
    ...getTrackedDrugsOptions(),
    ...queryConfig
  });
};
