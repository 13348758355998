import { CreateUpdateCoverageTagFormSchema } from '@/features/drugs/types/create-update-coverage-tag-form-schema';
import { artiaProtectedApi } from '@/lib/api-client';

export const createCoverageTag = async (drugId: number, formInputs: CreateUpdateCoverageTagFormSchema): Promise<{ coverageTagId: number }> => {
  const response = await artiaProtectedApi.post(`drugs/${drugId}/coverage-tags`, {
    name: formInputs.name,
    type: formInputs.type.code
  });

  return response.data;
};
