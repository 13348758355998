import { Layer } from 'grommet';
import React from 'react';

import { Pane } from './pane';
import { useModal } from './use-modal';

/**
 * Creates a drop location for all global modals. This component watches for
 * changes to the modal context and renders the correct details onto an elevated
 * layer.
 */
export const ModalRoot = () => {
  const { modalParts, options } = useModal();
  const [ modalProps, setModalProps ] = React.useState(null);

  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const ModalComponent = options.type === 'pane' ? Pane : Layer;

  React.useEffect(() => {
    if (modalParts) {
      setModalProps({
        ...options,
        // @ts-expect-error TS(2345): Argument of type '{ header: React.ReactNode; foote... Remove this comment to see the full error message
        header: modalParts.header,
        footer: modalParts.footer
      });
    }
  }, [modalParts, options]);

  return (
    <>
      {!!modalParts && (
        // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
        <ModalComponent {...modalProps}>
          {modalParts.component}
        </ModalComponent>
      )}
    </>
  );
};
