import { RequestOptions, useHttpClient } from '@shared/http';

import { User } from '@/features/admin/types/user';
import { UserFormInputs } from '@/features/admin/types/user-form-inputs';

type UserRequest = {
  assignedClients: number[]
};

export const useUserService = () => {
  const httpClient = useHttpClient();

  const getUsers = (): Promise<User[]> => {
    return httpClient.get('users');
  };

  const getUser = (id: number): Promise<User> => {
    return httpClient.get(`users/${id}`);
  };

  const updateUser = (id: number | string, { assignedClients }: UserFormInputs): Promise<any> => {
    const data: UserRequest = { assignedClients: assignedClients.map(client => client.id) };

    const options: RequestOptions<any> = {
      data
    };

    return httpClient.put(`users/${id}`, options);
  };

  return {
    getUsers,
    getUser,
    updateUser
  };
};
