export type ColumnConfigurationGroup = string;

export enum ColumnConfigurationType {
  STRING = 'String',
  INTEGER = 'Integer',
  BOOLEAN = 'Boolean',
  DATE_ONLY = 'DateOnly',
  URL = 'Url',
  MULTILINE_STRING = 'MultilineString'
}

export enum ColumnConfigurationUsage {
  UPDATE = 'Update',
  VIEW_FROM_TABLE = 'ViewFromTable'
}

export type ColumnConfiguration = {
  header: string;
  description: string;
  propertyName: string;
  type: ColumnConfigurationType;
  usages: ColumnConfigurationUsage[];
  possibleValues?: string[];
};

export type CustomState = {
  name: string;
  code: string;
  values: Record<string, string>;
};
