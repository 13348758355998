import { hasPermission } from '@shared/auth';
import { useHeaderDetails } from '@shared/header';
import { Box } from 'grommet';
import { Add } from 'grommet-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArtiaButton } from '@/components/artia-button';
import { useFilters } from '@/components/filters';
import { Tabs } from '@/components/tabs';
import { PageTitleRow } from '@/components-new/page-title-row';
import { useBidAnalyses } from '@/features/bid-analysis';
import { BidAnalysesFilterData, BidAnalysesFilters } from '@/features/bid-analysis/components/index/bid-analyses-filters';
import { BidAnalysesTable } from '@/features/bid-analysis/components/index/bid-analyses-table';
import { DeleteBidAnalysisDialog } from '@/features/bid-analysis/components/index/delete-bid-analysis-dialog';
import {
  BID_ANALYSIS_STATUS_CLOSED,
  BID_ANALYSIS_STATUS_OPEN,
  BidAnalysisStatusType
} from '@/features/bid-analysis/types/bid-analysis-status-type';
import { useUpdateBidAnalysisStatus } from '@/features/bid-analysis/use-update-bid-analysis-status';

const BidAnalysesPage = () => {
  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();
  const navigate = useNavigate();
  const { loadingBidAnalyses, bidAnalyses, loadBidAnalyses } = useBidAnalyses();
  const { updateBidAnalysisStatus } = useUpdateBidAnalysisStatus();

  const [filters, setFilters] = useFilters<BidAnalysesFilterData>('bid-analyses', {});

  const [ activeTabIndex, setActiveTabIndex ] = useState(0);

  const filteredBidAnalyses = useMemo(() => {
    const { name, client, product } = filters;

    return bidAnalyses.items.filter(bidAnalysis =>
      (!name || bidAnalysis.name.toLowerCase().includes(name.toLowerCase()))
      && (!client || bidAnalysis.clientName?.toLowerCase().includes(client.toLowerCase()))
      && (!product || bidAnalysis.productName?.toLowerCase().includes(product?.toLowerCase()))
    );
  }, [filters, bidAnalyses]);

  const canCreate = useMemo(
    () => hasPermission(bidAnalyses, 'bid-analyses.new'),
    [bidAnalyses]
  );

  /**
   * Bid Analysis status that should be filtered on based on tab index.
   */
  const STATUS_FOR_TAB_INDEX: { [key: number]: BidAnalysisStatusType } = {
    0: BID_ANALYSIS_STATUS_OPEN,
    1: BID_ANALYSIS_STATUS_CLOSED,
  };

  useEffect(() => {
    setHeaderDetails({
      documentTitle: 'Bid Analyses',
      pageTitle: (
        <PageTitleRow title="Bid Analyses">
          {canCreate && <Box>
            <ArtiaButton
              icon={<Add/>}
              a11yTitle="Add Bid Analysis"
              label="Add New"
              onClick={onCreateBidAnalysis}
            />
          </Box>}
        </PageTitleRow>
      ),
      breadcrumbs: [],
    });

    return () => {
      clearHeaderDetails();
    };
    // TODO: revisit - this is missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canCreate]);

  useEffect(() => {
    loadBidAnalyses({ status: STATUS_FOR_TAB_INDEX[activeTabIndex] });
    // TODO: revisit this and use react query instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex]);

  const onCreateBidAnalysis = () => {
    navigate('/bid-analyses/new');
  };

  const handleApplyFilters = (filters: BidAnalysesFilterData) => {
    setFilters(filters);
  };

  /**
   * Trigger request to update the status of a bid analysis.
   */
  const handleBidAnalysisStatusChange = async (bidAnalysisId: number, status: BidAnalysisStatusType) => {
    await updateBidAnalysisStatus(bidAnalysisId, status);
    loadBidAnalyses({ status: STATUS_FOR_TAB_INDEX[activeTabIndex] });
  };

  const [ deletingBidAnalysisId, setDeletingBidAnalysisId ] = useState<number>();
  const handleDeleteBidAnalysis = (bidAnalysisId: number) => {
    setDeletingBidAnalysisId(bidAnalysisId);
  };

  const handleCancelDelete = () => {
    setDeletingBidAnalysisId(undefined);
  };

  const handleDeleteComplete = () => {
    loadBidAnalyses();
    setDeletingBidAnalysisId(undefined);
  };

  return (
    <Box>
      <Box margin={{ top: 'small' }} gap="small">
        <BidAnalysesFilters defaultValue={filters} onApplyFilters={handleApplyFilters} />
        <Tabs
          activeIndex={activeTabIndex}
          onSelect={setActiveTabIndex}
          items={[
            {
              key: 'open',
              name: 'Open',
              content: (
                <BidAnalysesTable
                  data={filteredBidAnalyses}
                  loading={loadingBidAnalyses}
                  forStatus={BID_ANALYSIS_STATUS_OPEN}
                  onChangeBidAnalysisStatus={handleBidAnalysisStatusChange}
                  onDeleteBidAnalysis={handleDeleteBidAnalysis}
                />
              )
            },
            {
              key: 'closed',
              name: 'Closed',
              content: (
                <BidAnalysesTable
                  data={filteredBidAnalyses}
                  loading={loadingBidAnalyses}
                  forStatus={BID_ANALYSIS_STATUS_CLOSED}
                  onChangeBidAnalysisStatus={handleBidAnalysisStatusChange}
                  onDeleteBidAnalysis={handleDeleteBidAnalysis}
                />
              )
            }
          ]}
        />
      </Box>

      <DeleteBidAnalysisDialog
        open={!!deletingBidAnalysisId}
        // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
        bidAnalysisId={deletingBidAnalysisId}
        onClose={handleCancelDelete}
        onDeleteComplete={handleDeleteComplete}
      />
    </Box>
  );
};

export default BidAnalysesPage;
