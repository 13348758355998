import { Text, TextProps } from 'grommet';
import React from 'react';

import { formatDate, formatTimestamp, formatYearMonth } from '@/utils/date-time-functions';

export const Date = ({ value, ...props }: { value: string } & TextProps) => (
  <Text {...props}>{value ? formatDate(value) : <>&mdash;</>}</Text>
);

export const YearMonth = ({ value, ...props }: { value: string } & TextProps) => (
  <Text {...props}>{value ? formatYearMonth(value) : <>&mdash;</>}</Text>
);

export const Timestamp = ({ value, ...props }: { value: string } & TextProps) => (
  <Text {...props}>{value ? formatTimestamp(value) : <>&mdash;</>}</Text>
);
