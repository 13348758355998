import { Box } from 'grommet';
import React from 'react';

import StatusCodePage from '@/features/status-codes/status-code.page';

/**
 * Page displayed to user when they reach a route that does not exist.
 */
const _404 = () => {
  return (
    <Box fill align="center" justify="center">
      <StatusCodePage statusCode="404" statusCodeDescription="Page Not Found">
        The page you are looking for wasn't found. It may have been deleted or moved.
      </StatusCodePage>
    </Box>
  );
};

const _404Page = () => {
  return (
    <_404 />
  );
};

export default _404Page;
