import * as DateFunctions from '@/utils/date-time-functions';

export const isValidDate = (value: string): string | undefined => {
  if (!value || value.length === 0) {
    return;
  }

  // The API returns dates in YYYY-MM-DD format, and our date inputs format dates as MM/DD/YYYY.
  // Dates that come from the API shouldn't ever be invalid, so this is a quick fix to prevent
  // running validation on API dates.
  if (value.includes('-')) {
    return;
  }

  const validationMessage = 'Value is not a valid date.';

  const isValid = DateFunctions.isValidDate(value);

  if (!isValid) {
    return validationMessage;
  }
};

export const isInDateRange = ({ start, end }: { start?: string, end?: string }) => (value: string): string | undefined => {
  if (!DateFunctions.isCompleteDate(value) || !DateFunctions.isValidDate(value)) {
    return;
  }

  const startDate = new Date(start ?? '');
  const endDate = new Date(end ?? '');
  const date = new Date(value);

  if (start && date < startDate) {
    const formattedStart = `${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear()}`;
    return `Value cannot precede ${formattedStart}.`;
  }

  if (end && date > endDate) {
    const formattedEnd = `${endDate.getMonth() + 1}/${endDate.getDate()}/${endDate.getFullYear()}`;
    return `Value cannot exceed ${formattedEnd}.`;
  }
};
