import { Box, Heading, Paragraph, Text } from 'grommet';
import React from 'react';
import { LiaHomeSolid } from 'react-icons/lia';

import { Button } from '@/components-new/button';

type StatusCodePageProps = {
  statusCode: string;
  statusCodeDescription: string;
  children?: React.ReactNode;
  actions?: React.ReactNode;
}

/**
 * A universal status code page to display statuses such as 404 and 403.
 */
const StatusCodePage = ({ actions, statusCode, statusCodeDescription, children }: StatusCodePageProps) => {
  return (
    <Box fill align="center" justify="center">
      <Text size="6xl" color="brand" weight="bolder">{statusCode}</Text>
      <Heading level="2" color="dark-6" margin={{ bottom: 'none' }}>
        {statusCodeDescription}
      </Heading>
      <Paragraph textAlign="center">
        {children}
      </Paragraph>
      <div className="mt-6 flex gap-4">
        <Button href="/" color="primary">
          <LiaHomeSolid />
          Home
        </Button>
        {actions}
      </div>
    </Box>
  );
};

export default StatusCodePage;
