import { useAsync, UseAsyncStatus } from '@shared/async';
import { ProtectedRoute } from '@shared/auth';
import { useErrorHandler } from '@shared/errors';
import { useHeaderDetails } from '@shared/header';
import { useNotifications } from '@shared/notifications';
import { Box } from 'grommet';
import { Stakeholder } from 'grommet-icons';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FormCard } from '@/components/form-card';
import { useClassificationService } from '@/features/classifications/api/use-classifications-service';
import {
  ClassificationDetailsProvider,
  useClassificationDetails
} from '@/features/classifications/classification-details.provider';
import { ClassificationForm } from '@/features/classifications/classification-form';
import { ClassificationFormInputs } from '@/features/classifications/types/classification-form-inputs';

const EditClassification = () => {
  const { id } = useParams();
  const { classification, loadClassification } = useClassificationDetails();
  const { updateClassification } = useClassificationService();
  const { handleError } = useErrorHandler();
  const { success } = useNotifications();
  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();
  const navigate = useNavigate();

  const updateClassificationAsync = useAsync(updateClassification);
  const saving = updateClassificationAsync.status === UseAsyncStatus.Pending;

  React.useEffect(() => {
    if (id === null) {
      return;
    }

    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    loadClassification(parseInt(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  React.useEffect(() => {
    const breadcrumbs = classification ? [
      { icon: Stakeholder, label: 'Classifications', url: '/classifications' },
      { label: classification.name, url: `/classifications/${classification.id}/edit` },
      { label: 'Edit', url: '' }
    ] : [];

    setHeaderDetails({
      documentTitle: 'Edit Classification',
      pageTitle: 'Edit Classification',
      breadcrumbs
    });

    return () => {
      clearHeaderDetails();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classification]);

  useEffect(() => {
    const { status, error } = updateClassificationAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(
        error,
        {
          title: 'Update Classification Failed',
          message: 'Unable to update classification.',
          autoClose: false
        }
      );

      return;
    }

    success({ title: 'Success', message: 'Successfully updated classification.', autoClose: true });
    navigate('/classifications');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateClassificationAsync.status]);

  const handleFormSubmit = async (value: ClassificationFormInputs) => {
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await updateClassificationAsync.execute(id, value);
  };

  const handleCancel = () => {
    navigate('/classifications');
  };

  return (
    <Box gap="small">
      <FormCard>
        {classification ? (
          <ClassificationForm
            defaultValues={classification}
            saving={saving}
            onSubmit={handleFormSubmit}
            onCancel={handleCancel}
          />
        ) : null}
      </FormCard>
    </Box>
  );
};

const EditClassificationPage = () => {
  return (
    <ClassificationDetailsProvider>
      <ProtectedRoute component={EditClassification} policies={['canManageClassifications']} />
    </ClassificationDetailsProvider>
  );
};

export default EditClassificationPage;
