import { Box, FormField, Select, SelectExtendedProps, Tip } from 'grommet';
import { StatusInfo } from 'grommet-icons';
import React, { forwardRef, ReactNode, useId } from 'react';

// these represent props managed by the component itself, so we don't expose them
type RestrictedGrommetSelectProps = Omit<SelectExtendedProps, 'replace' | 'ref'>;

type SelectListProps = {
  /**
   * Visible label for the form field, optional but recommended.
   */
  label?: string;

  /**
   * Help text to display in a tooltip next to the label, optional.
   */
  help?: ReactNode;

  /**
   * Error message to display when the field is invalid, optional.
   */
  error?: ReactNode;
} & RestrictedGrommetSelectProps;

/**
 * A thin wrapper around Grommet's <Select> component.
 */
export const SelectList = forwardRef<HTMLInputElement, SelectListProps>(
  ({ id, label, name, help, required, error, ...rest }, ref) => {
    const generatedId = useId();
    const fieldId = id ?? generatedId;

    return (
      <FormField
        required={required}
        htmlFor={fieldId}
        name={name}
        margin="none"
        error={error}
        label={label ? (
          <Box direction="row" gap="xsmall" align="center">
            {help ? (
              <Tip content={help}>
                <StatusInfo />
              </Tip>
            ) : null}
            <span>{label}</span>
          </Box>
        ) : undefined}
      >
        <Select
          // this makes the component less performant (since it isn't virtualizing its options)
          // but it fixes the weird scrolling issue
          replace={false}
          id={fieldId}
          name={name}
          required={required}
          {...rest}
          ref={ref}
        />
      </FormField>
    );
  }
);
