import { useAsync } from '@shared/async';
import { PaginationResult } from '@shared/pagination';
import { Box, Button } from 'grommet';
import { Add } from 'grommet-icons';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';

import { LazyLoadMultiSelectList } from '@/components/form-controls';
import { useLookupsService } from '@/hooks/use-lookups-service';
import { Lookup } from '@/types/lookup';

import { useStatesService } from '../../../api';
import { CustomState, McoCarveOutMethod,StateDrugOrClass } from '../../../types';
import { useStatesProvider } from '../../states-provider';
import { CellWrapperProps, FilterProps, TableWrapperProps } from '../types';
import { McoCarveOutDialog } from './mco-carve-out-dialog';

export const DrugCarveOutProperty = 'ClinicalsProduct';
export const ClassCarveOutProperty = 'Classification';

export const DrugPdlExemptionProperty = 'PdlExemptions.ClinicalsProduct';
export const ClassPdlExemptionProperty = 'PdlExemptions.Classification';

type SelectedMcoCarveOutRow = {
  state: CustomState;
  mcoCarveOutMethod: McoCarveOutMethod;
};

export enum ProviderMode {
  PDL_EXEMPTIONS,
  MCO_CARVE_OUTS
}

type McoCarveOutContextValue = {
  mode: ProviderMode,
  carveOuts: Record<string, StateDrugOrClass[]>;
  onSelectRow: (selectedRow: SelectedMcoCarveOutRow | null) => void;
}

const DEFAULT_CONTEXT_VALUE: McoCarveOutContextValue = {
  mode: ProviderMode.MCO_CARVE_OUTS,
  carveOuts: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSelectRow: () => {}
};

const McoCarveOutContext = createContext<McoCarveOutContextValue>(DEFAULT_CONTEXT_VALUE);

export const McoCarveOutProvider = ({ children, mode = ProviderMode.MCO_CARVE_OUTS }: TableWrapperProps & { mode?: ProviderMode }) => {
  const { getMcoCarveOuts, getPdlExemptions } = useStatesService();
  const mcoCarveOutsRequest = useAsync(
    mode === ProviderMode.MCO_CARVE_OUTS ? getMcoCarveOuts : getPdlExemptions,
    true
  );
  const [selectedRow, setSelectedRow] = useState<SelectedMcoCarveOutRow | null>(null);
  const { refresh } = useStatesProvider();
  const carveOuts = mcoCarveOutsRequest.value ?? {};

  useEffect(() => {
    mcoCarveOutsRequest.execute();
  }, []);

  const contextValue = useMemo<McoCarveOutContextValue>(() => {
    return {
      mode,
      carveOuts: mcoCarveOutsRequest.value ?? {},
      onSelectRow: setSelectedRow,
    };
  }, [mode, setSelectedRow, mcoCarveOutsRequest.value, refresh]);

  return (
    <McoCarveOutContext.Provider value={contextValue}>
      {children}
      <McoCarveOutDialog
        mode={mode}
        open={selectedRow !== null}
        onClose={() => setSelectedRow(null)}
        mcoCarveOuts={selectedRow && carveOuts[selectedRow.state.code] ? carveOuts[selectedRow.state.code] : []}
        // @ts-expect-error TS(2322): Type 'McoCarveOutMethod | undefined' is not assign... Remove this comment to see the full error message
        carveOutMethod={selectedRow?.mcoCarveOutMethod}
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        stateName={selectedRow?.state?.name}
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        stateCode={selectedRow?.state?.code}
        refreshCarveOuts={() => {
          mcoCarveOutsRequest.execute();
          refresh();
        }}
      />
    </McoCarveOutContext.Provider>
  );
};

export const McoCarveOutCellWrapper = ({ row, config, children }: CellWrapperProps) => {
  const { onSelectRow } = useMcoCarveOutProvider();

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '0.25rem', alignItems: 'center', width: '100%' }}>
      {children}
      <Button
        hoverIndicator
        tip="View and Manage"
        style={{ height: 'max-content', width: 'max-content' }}
        icon={<Add />}
        onClick={() => {
          onSelectRow({
            state: row,
            mcoCarveOutMethod: config.propertyName === DrugCarveOutProperty || config.propertyName === DrugPdlExemptionProperty
              ? McoCarveOutMethod.DRUG
              : McoCarveOutMethod.CLASS
          });
        }}
      />
    </div>
  );
};

export const McoCarveOutFilters = ({ control, disabled, mode }: FilterProps & { mode: ProviderMode }) => {
  const { getClassifications, getCoverageTags } = useLookupsService();

  return (
    <>
      <Box width="16rem" height="auto">
        <Controller
          control={control}
          name={mode === ProviderMode.MCO_CARVE_OUTS ? 'carvedOutClassifications' : 'exemptClassifications'}
          render={({ field, fieldState: { error } }) => (
            <LazyLoadMultiSelectList
              {...field}
              error={error?.message}
              required
              placeholder={mode === ProviderMode.MCO_CARVE_OUTS ? 'Select a Class MCO Carve Out' : 'Select a Class PDL Exemption'}
              disabled={disabled}
              lazyLoadRequest={async (searchTerm, page, rpp) => {
                return await getClassifications({ query: searchTerm, page, rpp }) as PaginationResult<Lookup>;
              }}
            />
          )}
        />
      </Box>
      <Box width="16rem" height="auto">
        <Controller
          control={control}
          name={mode === ProviderMode.MCO_CARVE_OUTS ? 'carvedOutClinicalsProducts' : 'exemptClinicalsProducts'}
          render={({ field, fieldState: { error } }) => (
            <LazyLoadMultiSelectList
              {...field}
              error={error?.message}
              required
              placeholder={mode === ProviderMode.MCO_CARVE_OUTS ? 'Select a Drug MCO Carve Out' : 'Select a Drug PDL Exemption'}
              disabled={disabled}
              lazyLoadRequest={(searchTerm, page, rpp) => getCoverageTags({ query: searchTerm, page, rpp })}
            />
          )}
        />
      </Box>
    </>
  );
};

export const useMcoCarveOutProvider = () => useContext(McoCarveOutContext);
