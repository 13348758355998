import { setPostLoginRedirectUrl } from '@shared/auth';
import React, { useEffect } from 'react';

import { Loader } from '@/components-new/loader';
import { Overlay } from '@/components-new/overlay';
import { useAuthFlow } from '@/hooks/use-auth-flow';


/**
 * Attempts to automatically sign a use in.
 */
export const AutoSignIn = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated, isAuthenticating, isLoadingUserDetails, signInSilentAsync } = useAuthFlow();

  useEffect(() => {
    if (!isAuthenticated && !isAuthenticating) {
      setPostLoginRedirectUrl(window.location.pathname);
      void signInSilentAsync();
    }
  }, [isAuthenticated, isAuthenticating, signInSilentAsync]);

  if (isAuthenticating) return null;

  if (isLoadingUserDetails) {
    return (
      <Overlay solid>
        <Loader
          indicator={<img className="h-auto w-20" src="/artia-capsule-logo.png" alt="artia capsule logo" /> }
        />
      </Overlay>
    );
  }

  return isAuthenticated ? children : null;
};
