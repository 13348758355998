import { useAsync } from '@shared/async';
import { useHttpClient } from '@shared/http';

type MovePackagingsRequest = {
  drugId: number;
  ndcs: string[];
}

export const useMovePackagings = () => {
  const httpClient = useHttpClient();

  const movePackagings = ({ drugId, ndcs }: MovePackagingsRequest) => {
    const url = `drugs/${drugId}/move-packagings/`;

    return httpClient.post(url, { data: ndcs });
  };

  const movePackagingsAsync = useAsync(movePackagings);

  return {
    ...movePackagingsAsync
  };
};
