import { ResultSet } from '@shared/http';

/**
 * Indicates whether the current user has permission to take an action.
 * Permissions are defined on the result set from the server.
 *
 * @param rs The result set containing a permissions block.
 * @param permission The permission to check.
 */
export const hasPermission = <T>(rs: ResultSet<T>, permission: string): boolean => {
  // @ts-expect-error TS(2322): Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
  return rs.permissions && rs.permissions[permission] === true;
};
