import { Box, Text } from 'grommet';
import React from 'react';

import { NetCostComparisonRow } from '@/features/bid-analysis/types/net-cost-comparison';
import { formatCurrency } from '@/utils/formatting';

type CurrencyCellProps = {
  row: NetCostComparisonRow;
  value: number;
  minimumFractionDigits: number;
  maximumFractionDigits: number;
  isSuppressable?: boolean;
  isSuppressed?: boolean;
  singleState?: boolean;
  showTotal?: boolean;
};

export const CurrencyCell = (props: CurrencyCellProps) => {
  const {
    row,
    value,
    minimumFractionDigits,
    maximumFractionDigits,
    isSuppressable,
    isSuppressed,
    singleState,
    showTotal
  } = props;

  let renderedValue;
  if (isSuppressed && isSuppressable) {
    renderedValue = 'Suppressed';
  } else {
    renderedValue = renderOptionalCurrency(value, minimumFractionDigits, maximumFractionDigits);
  }

  const jsxMap = {
    tag: <Text>{renderedValue}</Text>,
    ndc: <Text>{renderedValue}</Text>,
    drug: <Text>{renderedValue}</Text>,
    state: <></>,
    'state-total': showTotal && (singleState ? <Text weight="bold">{renderedValue}</Text> : <Text>{renderedValue}</Text>),
    'drug-total': showTotal && <Text weight="bold">{renderedValue}</Text>,
    'grand-total': showTotal && <Text weight="bold">{renderedValue}</Text>,
  };

  return (
    <Box>
      {jsxMap[row.rowType]}
    </Box>
  );
};

const renderOptionalCurrency = (value?: number, minimumFractionDigits?: number, maximumFractionDigits?: number) => {
  if (value) {
    return formatCurrency(value, minimumFractionDigits, maximumFractionDigits);
  }

  return <>&mdash;</>;
};
