import { useHttpClient } from '@shared/http';
import { PaginationRequest, PaginationResult, SortingInfo } from '@shared/pagination';

import { MultiSelectOption } from '@/components/form-controls/multi-select-list';
import { ChangeLogReason } from '@/features/changelog/types';
import { PdlStatus } from '@/features/coverage/types/pdl-status';
import { StateCoverage } from '@/features/coverage/types/state-coverage';
import { Lookup } from '@/types/lookup';
import { buildQueryString } from '@/utils/filtering-functions';

type CoverageSearchRequest = {
  clients?: MultiSelectOption[];
  states?: MultiSelectOption<string>[];
  products?: MultiSelectOption[];
  classifications?: MultiSelectOption[];
  paTypes?: MultiSelectOption[];
  pdlStatuses?: MultiSelectOption<PdlStatus>[];
} & PaginationRequest & SortingInfo;

export type EditCoverageRequest = {
  drug?: Lookup;
  coverageTags: Lookup[];
  state?: Lookup;
  classification?: Lookup;
  pdlStatus?: PdlStatus;
  pdlStatusDate?: string;
  pdlStatusEffectiveDate?: string;
  paTypes?: Lookup[];
  stepTherapyCount?: string;
  paCriteriaDescription?: string;
  paCriteriaSourceUrl?: string;
  changeReason: ChangeLogReason;
  notes?: string;
  hasAutoPa?: boolean;
};

export enum CoverageCsvExportFormat {
  IMPORTER = 'Importer',
  ATLAS = 'Atlas'
}

export type CoverageExportCsvFormatRequest = {
  format: CoverageCsvExportFormat;
};

export type CoverageIdentifier = {
  type: 'state-profile',
  id: number;
};

export const useCoverageService = () => {
  const httpClient = useHttpClient();

  const formatSearchFilters = (data: CoverageSearchRequest) => {
    const {
      clients = [],
      states = [],
      classifications = [],
      paTypes = [],
      pdlStatuses = [],
      products = [],
      ...rest
    } = data;

    return {
      ...rest,
      clients: clients.map(option => option.id),
      states: states.map(option => option.id),
      classifications: classifications.map(option => option.id),
      paTypes: paTypes.map(option => option.id),
      pdlStatuses: pdlStatuses.map(option => option.id),
      products: products.map(option => option.id)
    };
  };

  const searchCoverages = (data: CoverageSearchRequest): Promise<PaginationResult<StateCoverage>> => {
    const request = formatSearchFilters(data);

    return httpClient.get(`state-coverage${buildQueryString(request)}`);
  };

  const searchForAllMatchingIds = (data: CoverageSearchRequest): Promise<number[]> => {
    const request = formatSearchFilters(data);

    return httpClient.get(`state-coverage/all-ids${buildQueryString(request)}`);
  };

  const exportToCsv = (formatRequest: CoverageExportCsvFormatRequest, searchFilters: CoverageSearchRequest) => {
    const formattedSearchFilters = formatSearchFilters(searchFilters);
    return httpClient.download(
      'GET',
      `state-coverage/export${buildQueryString({ ...formatRequest, ...formattedSearchFilters })}`
    );
  };

  const createNewCoverage = async (request: EditCoverageRequest) => {
    await httpClient.post('state-coverage', {
      data: formDataToRequestBody(request)
    });
  };

  const editCoverage = async (identifier: CoverageIdentifier, data: EditCoverageRequest): Promise<StateCoverage> => {
    const newData = formDataToRequestBody(data);

    switch (identifier.type) {
      case 'state-profile':
        return await httpClient.post<StateCoverage, typeof newData>(
          `state-coverage/${identifier.id}`, { data: newData }
        );
    }
  };

  const bulkEditCoverage = async (stateProfileIds: number[], data: EditCoverageRequest, fieldNames: string[]) => {
    await httpClient.post(`state-coverage/bulk-update${buildQueryString({ fieldNames })}`, {
      data: {
        stateProfileIds,
        updatedData: formDataToRequestBody(data)
      }
    });
  };

  const deleteCoverage = async (id: number, reason: ChangeLogReason) => {
    await httpClient.delete(`state-coverage/${id}`, { data: { reason } });
  };

  return {
    searchCoverages,
    exportToCsv,
    createNewCoverage,
    editCoverage,
    searchForAllMatchingIds,
    bulkEditCoverage,
    deleteCoverage
  };
};

export const parseStepTherapyCount = (value: string): { stepTherapyCount: number, hasAdditionalSteps: boolean } | string => {
  const matches = value.match(/([0-9]?[0-9])|\+?/g)?.filter((match) => !!match);
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  if (0 >= matches.length || matches.length > 2) {
    return 'Step Therapy must be a number between 0-10 and may have a plus (+) at the end.';
  }
  // @ts-expect-error TS(2548): Type 'string[] | undefined' is not an array type o... Remove this comment to see the full error message
  const [digits, trailingCharacter] = matches;
  const stepTherapyCount = parseInt(digits);
  if (isNaN(stepTherapyCount) || stepTherapyCount < 0 || stepTherapyCount > 10) {
    return 'Step Therapy must be a number between 0-10.';
  }
  if (trailingCharacter && trailingCharacter !== '+') {
    return `Step Therapy cannot end with ${trailingCharacter}, only a plus sign (+) or a digit.`;
  }

  return { stepTherapyCount, hasAdditionalSteps: !!trailingCharacter };
};

const formDataToRequestBody = ({ drug, coverageTags, state, classification, stepTherapyCount, paTypes, ...data }: EditCoverageRequest) => {
  const stepTherapyParseResult = stepTherapyCount
    ? parseStepTherapyCount(stepTherapyCount)
    : null;

  // this should never happen since the result was validated by the form
  if (typeof stepTherapyParseResult === 'string') {
    throw new Error(stepTherapyParseResult);
  }

  return {
    ...data,
    drugId: drug?.id,
    coverageTagIds: coverageTags?.map(lookup => lookup.id) || [],
    stateId: state?.id || null,
    classificationId: classification?.id,
    pdlStatusDate: data.pdlStatusDate || null,
    pdlStatusEffectiveDate: data.pdlStatusEffectiveDate || null,
    paCriteriaDescription: data.paCriteriaDescription ? data.paCriteriaDescription : null,
    paCriteriaSourceUrl: data.paCriteriaSourceUrl ? data.paCriteriaSourceUrl : null,
    paTypes: paTypes?.map(lookup => lookup.id) || [],
    stepTherapyCount: stepTherapyParseResult?.stepTherapyCount,
    hasAdditionalSteps: stepTherapyParseResult?.hasAdditionalSteps,
    notes: data.notes || null,
    hasAutoPa: data.hasAutoPa ?? false
  };
};
