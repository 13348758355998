import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { useHeaderDetails } from '@shared/header';
import { useNotifications } from '@shared/notifications';
import { PaginationResult } from '@shared/pagination';
import { Box } from 'grommet';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { ArtiaButton } from '@/components/artia-button';
import { Busy } from '@/components/busy';
import { FormCard } from '@/components/form-card';
import { LazyLoadMultiSelectList } from '@/components/form-controls';
import { useUserService } from '@/features/admin/api/use-users-service';
import { User } from '@/features/admin/types/user';
import { UserFormInputs } from '@/features/admin/types/user-form-inputs';
import { useLookupsService } from '@/hooks/use-lookups-service';
import { Lookup } from '@/types/lookup';

const EditUserPage = () => {
  const { id } = useParams();
  const { getUser, updateUser } = useUserService();
  const { handleError } = useErrorHandler();
  const { success } = useNotifications();
  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();
  const navigate = useNavigate();

  const asyncUser = useAsync(getUser);

  const updateUserAsync = useAsync(updateUser);
  const saving = updateUserAsync.status === UseAsyncStatus.Pending;

  React.useEffect(() => {
    if (id === null) {
      return;
    }

    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    asyncUser.execute(parseInt(id));
  }, [id]);

  React.useEffect(() => {
    const breadcrumbs = asyncUser.value ? [
      { label: 'Users', url: '/admin/users' },
      { label: asyncUser.value.name, url: `/admin/users/${asyncUser.value.id}/edit` }
    ] : [];

    setHeaderDetails({
      documentTitle: 'Edit User',
      pageTitle: asyncUser.value?.name,
      breadcrumbs
    });

    return () => {
      clearHeaderDetails();
    };
  }, [asyncUser.value]);

  useEffect(() => {
    const { status, error } = updateUserAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(
        error,
        {
          title: 'Update User Failed',
          message: 'Unable to update User',
          autoClose: false
        }
      );

      return;
    }

    success({ title: 'Success', message: 'Successfully updated User.', autoClose: true });
    navigate('/admin/users');
  }, [updateUserAsync.status]);

  const handleFormSubmit = async (value: UserFormInputs) => {
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    await updateUserAsync.execute(id, value);
  };

  const handleCancel = () => {
    navigate('/admin/users');
  };

  return (
    <Box gap="small">
      <FormCard>
        {/* move the actual form to an inner component to make sure a value is present when the form mounts */}
        {asyncUser.value ? (
          <Form
            defaultValues={asyncUser.value}
            onSubmit={handleFormSubmit}
            onCancel={handleCancel}
            saving={saving}
          />
        ) : null}
      </FormCard>
    </Box>
  );
};

type FormProps = {
  defaultValues: User;
  onSubmit: (value: UserFormInputs) => Promise<void>;
  onCancel: () => void;
  saving: boolean;
};

const Form = ({ defaultValues, onSubmit, onCancel, saving }: FormProps) => {
  const {
    control,
    formState: { isValid },
    handleSubmit
  } = useForm<UserFormInputs>({
    mode: 'all',
    defaultValues: {
      assignedClients: defaultValues.assignedClients.map(({ id, name }) => ({ id, label: name }))
    }
  });

  const { getClientLookups } = useLookupsService();

  const submitDisabled = !isValid || saving;

  return (
    <>
      <Box pad={{ vertical: 'medium' }}>
        <Controller
          control={control}
          name="assignedClients"
          render={({ field, fieldState: { error } }) => (
            <LazyLoadMultiSelectList
              label="Assigned Clients"
              showSelectedInline
              lazyLoadRequest={async (searchTerm, page, rpp) => {
                const result = await getClientLookups({ query: searchTerm, page, rpp });
                return result as PaginationResult<Lookup>;
              }}
              {...field}
              error={error?.message}
            />
          )}
        />
      </Box>

      <Box direction="row" justify="end" gap="small">
        <ArtiaButton label="Cancel" onClick={onCancel} disabled={saving} size="large" variant="outlined" />
        <ArtiaButton
          label={<Busy busy={saving} content="Save" />}
          onClick={handleSubmit(onSubmit)}
          disabled={submitDisabled}
          size="large"
        />
      </Box>
    </>
  );
};

export default EditUserPage;
