import { HttpError, ValidationError } from '@shared/errors';
import { FileDownload, getDownloadFilename, hasContentType } from '@shared/http';

type RequestInterceptor = (options?: RequestOptions<any>) => RequestOptions<any>;

export type HttpClient = {
  create: (instanceOptions: HttpClientOptions) => HttpClient,
  get: HttpClientGet,
  post: HttpClientPost,
  put: HttpClientPut,
  patch: HttpClientPatch,
  delete: HttpClientDelete,
  download: HttpClientDownload,
  addRequestInterceptor: (interceptor: RequestInterceptor) => void,
};

export type RequestOptions<T> = {
  data?: T,
  headers?: { [key: string]: string },
  /**
   * Optional query string params.
   */
  params?: { [key: string]: string },
};

type HttpClientGet = <T, U>(url: string, options?: RequestOptions<U>) => Promise<T>;
type HttpClientPost = <T, U>(url: string, options?: RequestOptions<U>) => Promise<T>;
type HttpClientPut = <T = void, U = unknown>(url: string, options?: RequestOptions<U>) => Promise<T>;
type HttpClientPatch = <T, U>(url: string, options?: RequestOptions<U>) => Promise<T | void>;
type HttpClientDelete = <U>(url: string, options?: RequestOptions<U>) => Promise<void>;
type HttpClientDownload = <U>(httpMethod: string, url: string, options?: RequestOptions<U>) => Promise<FileDownload>;

const extractRequestOptions = <T>(options?: RequestOptions<T>): RequestInit => {
  const headers = new Headers();
  if (options?.headers) {
    Object.entries(options.headers).forEach(([key, value]) => {
      headers.append(key, value);
    });
  }

  if (!options?.data) {
    return { headers };
  }

  let body: T | string = options.data;

  if (!(body instanceof FormData)) {
    body = JSON.stringify(body);
    headers.append('Content-Type', 'application/json');
  }

  return {
    headers,
    body
  };
};

const requestOptionsBuilder = (requestInterceptors: RequestInterceptor[]) => {
  return <T>(options?: RequestOptions<T>) => {
    return requestInterceptors ?
      requestInterceptors.reduce((accumulator, handler) => handler(accumulator), options) :
      options;
  };
};

/**
 * Generic handler for throwing an HttpError from a response.
 *
 * @param response The HTTP response, which may or may not be an error.
 * @throws HttpError If the HTTP response indicates an error we don't handle
 * more specifically.
 */
const throwIfHttpError = (response: Response) => {
  if ([401, 403, 404, 500].includes(response.status)) {
    throw new HttpError(response, false);
  }

  if (response.status === 401) {
    window.location.reload(); // reload the window this should prompt the use to reauthenticate
  }

  if (response.status >= 400) {
    throw new HttpError(response, true);
  }

  // A network error is a response whose type is "error", status is 0,
  // status message is the empty byte sequence, header list is « », body is null,
  // and body info is a new response body info.
  // https://fetch.spec.whatwg.org/#concept-network-error
  if (response.type === 'error' && response.status === 0) {
    throw new HttpError(response, false);
  }
};

type HttpClientOptions = {
  baseUrl: string;
}

const makeClient = function (options: HttpClientOptions): HttpClient {
  const requestInterceptors: RequestInterceptor[] = [];
  const {
    baseUrl,
  } = options;

  return {
    create: (instanceOptions: HttpClientOptions) => makeClient({ ...options, ...instanceOptions }),
    get: async function<T, U> (url: string, options?: RequestOptions<U>) {
      const requestOptions = extractRequestOptions(requestOptionsBuilder(requestInterceptors)(options));
      const queryParameters = new URLSearchParams({ ...options?.params });
      const requestUrl = queryParameters.toString() ?
        `${baseUrl}/${url}?${queryParameters.toString()}` :
        `${baseUrl}/${url}`;

      const response = await fetch(requestUrl, { ...requestOptions });

      throwIfHttpError(response);

      return await response.json() as T;
    },
    // @ts-expect-error TS(2322): Type '<T, U>(url: string, options?: RequestOptions... Remove this comment to see the full error message
    put: async function <T, U>(url: string, options?: RequestOptions<U>) {
      const init = extractRequestOptions(requestOptionsBuilder(requestInterceptors)(options));

      const response = await fetch(`${baseUrl}/${url}`, {
        method: 'PUT',
        ...init
      });

      if (response.status === 400) {
        const error = await response.json();
        const title = 'Validation Error';

        const errorMessages: string[] = [];
        if (error) {
          Object.keys(error.errors).forEach(key => {
            errorMessages.push(error.errors[key]);
          });
        } else {
          errorMessages.push('One or more validation errors occurred but the server did not provide any details.');
        }

        throw new ValidationError(errorMessages[0], title);
      }

      throwIfHttpError(response);

      if (hasContentType(response, 'application/json')) {
        return await response.json() as T;
      }

      return;
    },
    patch: async function <T, U>(url: string, options?: RequestOptions<U>) {
      const init = extractRequestOptions(requestOptionsBuilder(requestInterceptors)(options));

      const response = await fetch(`${baseUrl}/${url}`, {
        method: 'PATCH',
        ...init
      });

      if (response.status === 400) {
        const error = await response.json();
        const title = 'Validation Error';

        const errorMessages: string[] = [];
        if (error) {
          Object.keys(error.errors).forEach(key => {
            errorMessages.push(error.errors[key]);
          });
        } else {
          errorMessages.push('One or more validation errors occurred but the server did not provide any details.');
        }

        throw new ValidationError(errorMessages[0], title);
      }

      throwIfHttpError(response);

      if (hasContentType(response, 'application/json')) {
        return await response.json() as T;
      }

      return;
    },
    // @ts-expect-error TS(2322): Type '<T, U>(url: string, options?: RequestOptions... Remove this comment to see the full error message
    post: async function <T, U>(url: string, options?: RequestOptions<U>) {
      const init = extractRequestOptions(requestOptionsBuilder(requestInterceptors)(options));

      const response = await fetch(`${baseUrl}/${url}`, {
        method: 'POST',
        ...init
      });

      if (response.status === 400) {
        const error = await response.json();
        const title = 'Validation Error';

        const errorMessages: string[] = [];
        if (error) {
          Object.keys(error.errors).forEach(key => {
            errorMessages.push(error.errors[key]);
          });
        } else {
          errorMessages.push('One or more validation errors occurred but the server did not provide any details.');
        }

        throw new ValidationError(errorMessages[0], title);
      }

      throwIfHttpError(response);

      if (hasContentType(response, 'application/json')) {
        return await response.json() as T;
      }

      return;
    },
    delete: async function <U>(url: string, options?: RequestOptions<U>) {
      const init = extractRequestOptions(requestOptionsBuilder(requestInterceptors)(options));

      const response = await fetch(`${baseUrl}/${url}`, {
        method: 'DELETE',
        ...init
      });

      if (response.status === 400) {
        const error = await response.json();
        const title = 'Validation Error';

        const errorMessages: string[] = [];
        if (error) {
          Object.keys(error.errors).forEach(key => {
            errorMessages.push(error.errors[key]);
          });
        } else {
          errorMessages.push('One or more validation errors occurred but the server did not provide any details.');
        }

        throw new ValidationError(errorMessages[0], title);
      }

      throwIfHttpError(response);

      return;
    },
    download: async function <U>(httpMethod: string, url: string, options?: RequestOptions<U>): Promise<FileDownload> {
      const init = extractRequestOptions(requestOptionsBuilder(requestInterceptors)(options));

      // sb: we need to support absolute URLs for download links
      let fullUrl;
      if (
        url.startsWith('https://') ||
        url.startsWith('http://') ||
        url.startsWith('//')
      ) {
        fullUrl = url;
      } else {
        fullUrl = `${baseUrl}/${url}`;
      }

      const response = await fetch(fullUrl, {
        method: httpMethod,
        ...init
      });

      throwIfHttpError(response);

      return {
        contents: await response.blob(),
        // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        contentType: response.headers.get('Content-Type'),
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        filename: getDownloadFilename(response.headers.get('Content-Disposition'))
      };
    },
    addRequestInterceptor: (interceptor: RequestInterceptor) => {
      requestInterceptors.push(interceptor);
    }
  };
};

export const httpClient = makeClient({ baseUrl: '' });
