import { featureManager } from '@shared/feature-flags';
import { useHeaderDetails } from '@shared/header';
import { HeaderDetails } from '@shared/header/header-details.context';
import { transitionAll } from '@shared/styled/animated';
import { Page, PageContent } from 'grommet';
import { every, isEmpty, negate, some, values } from 'lodash';
import { User } from 'oidc-client-ts';
import React from 'react';
import {
  LiaAngleUpSolid,
  LiaBell,
  LiaBookMedicalSolid,
  LiaCalendar,
  LiaCalendarPlus,
  LiaChartPieSolid,
  LiaCloudDownloadAltSolid,
  LiaDatabaseSolid,
  LiaFileMedicalAltSolid,
  LiaFileMedicalSolid,
  LiaHomeSolid,
  LiaPillsSolid,
  LiaShoppingBasketSolid,
  LiaSignOutAltSolid,
  LiaStreamSolid,
  LiaTagSolid, LiaUser, LiaUserCogSolid,
  LiaUserTieSolid
} from 'react-icons/lia';

import { Avatar } from '@/components-new/avatar';
import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu } from '@/components-new/dropdown';
import { Link } from '@/components-new/link';
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from '@/components-new/navbar';
import { PageTitleRow } from '@/components-new/page-title-row';
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection
} from '@/components-new/sidebar';
import { SidebarLayout } from '@/components-new/sidebar-layout';
import { useAuthFlow } from '@/hooks/use-auth-flow';
import { useCurrentUser } from '@/hooks/use-current-user';
import { useIsCurrentRoute } from '@/hooks/use-is-current-route';
import { PropsWithRenderableChildren } from '@/types/react';

const AnimatedPage = transitionAll(Page);

const AuthorizedSidebarSection = (
  { sectionHeader, permissions, children }: PropsWithRenderableChildren<{ sectionHeader: React.ReactNode, permissions: Record<string, boolean> }, { permissions: Record<string, boolean> }>
) => {
  const sectionUnauthorized = !some(permissions);

  if (sectionUnauthorized) return null;

  return (
    <SidebarSection>
      {sectionHeader}
      {typeof children === 'function' ? children({ permissions }) : children}
    </SidebarSection>
  );
};

const DashboardLayout = ({ children, user }: { children: React.ReactNode, user: User }) => {
  const { signOutAsync } = useAuthFlow();
  const { hasPolicies } = useCurrentUser();
  const isCurrentRoute = useIsCurrentRoute();

  const { headerDetails } = useHeaderDetails();
  const isHeaderDetailsEmpty = (headerDetails: HeaderDetails) => every(values(headerDetails), isEmpty);
  const hasHeaderDetails = negate(isHeaderDetailsEmpty);

  const isCalendarEnabled = featureManager.getFeature('calendar');
  const isNotificationsEnabled = featureManager.getFeature('notifications');
  const isUserSettingsAvailable = featureManager.getFeature('userSettings') && hasPolicies(['isClientUser']); // TODO: should only be available to client users.

  const handleSignout = async () => {
    void signOutAsync();
  };

  return (
    <SidebarLayout
      sidebar={
        <Sidebar className="dark">
          <SidebarBody>
            <Link href="/" aria-label="Home" className="mb-4 mt-2 flex h-10 shrink-0 items-center px-2">
              <img
                className="h-5 w-auto"
                src="/artia-solutions.svg"
                alt="Artia Solutions Logo"
              />
            </Link>
            <SidebarSection>
              <SidebarItem href="/" current={isCurrentRoute('/')}>
                <LiaHomeSolid size="1.25rem" />
                <SidebarLabel>Home</SidebarLabel>
              </SidebarItem>
              {isCalendarEnabled && (
                <SidebarItem href="/calendar" current={isCurrentRoute('/calendar')}>
                  <LiaCalendar size="1.25rem" />
                  <SidebarLabel>Calendar</SidebarLabel>
                </SidebarItem>
              )}
              {isNotificationsEnabled && (
                <SidebarItem href="/notifications" current={isCurrentRoute('/notifications')}>
                  <LiaBell size="1.25rem" />
                  <SidebarLabel>Notifications</SidebarLabel>
                </SidebarItem>
              )}
            </SidebarSection>
            <AuthorizedSidebarSection
              sectionHeader={<SidebarHeading>Drug Information</SidebarHeading>}
              permissions={{
                canAccessCoverage: hasPolicies(['isAnyArtiaUser']),
                canAccessDrugs:  !!user,
                canAccessChangelog:  hasPolicies(['isAnyArtiaUser']),
              }}
            >
              {({ permissions }) => (
                <>
                  {permissions.canAccessDrugs && (
                    <SidebarItem href="/drugs" current={isCurrentRoute('/drugs')}>
                      <LiaPillsSolid size="1.25rem" />
                      <SidebarLabel>Drugs</SidebarLabel>
                    </SidebarItem>
                  )}
                  {permissions.canAccessCoverage && (
                    <SidebarItem href="/drug-coverage" current={isCurrentRoute('/drug-coverage')}>
                      <LiaBookMedicalSolid size="1.25rem" />
                      <SidebarLabel>Coverage</SidebarLabel>
                    </SidebarItem>
                  )}
                  {permissions.canAccessChangelog && (
                    <SidebarItem href="/changelog" current={isCurrentRoute('/changelog')}>
                      <LiaStreamSolid size="1.25rem" />
                      <SidebarLabel>Changelog</SidebarLabel>
                    </SidebarItem>
                  )}
                </>
              )}
            </AuthorizedSidebarSection>
            <AuthorizedSidebarSection
              sectionHeader={<SidebarHeading>Analysis</SidebarHeading>}
              permissions={{
                canAccessBidAnalyses: hasPolicies(['isAssignedToAnyClient', 'canManageBidAnalyses']),
                canAccessMarketBaskets: hasPolicies(['isAssignedToAnyClient', 'canManageMarketBaskets']),
                canAccessStateReports: hasPolicies(['isAnyArtiaUser']),
              }}
            >
              {({ permissions }) => (
                <>
                  {permissions.canAccessBidAnalyses && (
                    <SidebarItem href="/bid-analyses" current={isCurrentRoute('/bid-analyses')}>
                      <LiaChartPieSolid size="1.25rem" />
                      <SidebarLabel>Bid Analyses</SidebarLabel>
                    </SidebarItem>
                  )}
                  {permissions.canAccessMarketBaskets && (
                    <SidebarItem href="/market-baskets" current={isCurrentRoute('/market-baskets')}>
                      <LiaShoppingBasketSolid size="1.25rem" />
                      <SidebarLabel>Market Baskets</SidebarLabel>
                    </SidebarItem>
                  )}
                  {permissions.canAccessStateReports && (
                    <SidebarItem href="/state-reports" current={isCurrentRoute('/state-reports')}>
                      <LiaFileMedicalSolid size="1.25rem" />
                      <SidebarLabel>State Reports</SidebarLabel>
                    </SidebarItem>
                  )}
                </>
              )}
            </AuthorizedSidebarSection>
            <AuthorizedSidebarSection
              sectionHeader={<SidebarHeading>Management</SidebarHeading>}
              permissions={{
                canAccessClassifications: hasPolicies(['canManageClassifications']),
                canAccessClients: hasPolicies(['isAnyArtiaUser']),
                canAccessBoardMeetings: hasPolicies(['isAnyArtiaUser']),
                canManageUsers: hasPolicies(['canManageUsers'])
              }}
            >
              {({ permissions }) => (
                <>
                  {permissions.canAccessClassifications && (
                    <SidebarItem href="/classifications" current={isCurrentRoute('/classifications')}>
                      <LiaTagSolid size="1.25rem" />
                      <SidebarLabel>Classifications</SidebarLabel>
                    </SidebarItem>
                  )}
                  {permissions.canAccessClients && (
                    <SidebarItem href="/clients" current={isCurrentRoute('/clients')}>
                      <LiaUserTieSolid size="1.25rem" />
                      <SidebarLabel>Clients</SidebarLabel>
                    </SidebarItem>
                  )}
                  {permissions.canAccessBoardMeetings && (
                    <SidebarItem href="/board-meetings" current={isCurrentRoute('/board-meetings')}>
                      <LiaCalendarPlus size="1.25rem" />
                      <SidebarLabel>Board Meetings</SidebarLabel>
                    </SidebarItem>
                  )}
                  {permissions.canManageUsers && (
                    <SidebarItem href="/admin/users" current={isCurrentRoute('/admin/users')}>
                      <LiaUser size="1.25rem" />
                      <SidebarLabel>Users</SidebarLabel>
                    </SidebarItem>
                  )}
                </>
              )}
            </AuthorizedSidebarSection>
            <AuthorizedSidebarSection
              sectionHeader={<SidebarHeading>Datasets</SidebarHeading>}
              permissions={{
                canAccessMedicaidDatasets:  hasPolicies(['isAnyArtiaUser']),
                canAccessDrugUtilizations: hasPolicies(['isAnyArtiaUser']),
                canAccessNadac: hasPolicies(['isAnyArtiaUser']),
              }}
            >
              {({ permissions }) => (
                <>
                  {permissions.canAccessMedicaidDatasets && (
                    <SidebarItem href="/medicaid-datasets" current={isCurrentRoute('/medicaid-datasets')}>
                      <LiaCloudDownloadAltSolid size="1.25rem" />
                      <SidebarLabel>Medicaid Datasets</SidebarLabel>
                    </SidebarItem>
                  )}
                  {permissions.canAccessDrugUtilizations && (
                    <SidebarItem href="/drug-utilizations" current={isCurrentRoute('/drug-utilizations')}>
                      <LiaFileMedicalAltSolid size="1.25rem" />
                      <SidebarLabel>Drug Utilizations</SidebarLabel>
                    </SidebarItem>
                  )}
                  {permissions.canAccessNadac && (
                    <SidebarItem href="/nadac" current={isCurrentRoute('/nadac')}>
                      <LiaDatabaseSolid size="1.25rem" />
                      <SidebarLabel>NADAC</SidebarLabel>
                    </SidebarItem>
                  )}
                </>
              )}
            </AuthorizedSidebarSection>
          </SidebarBody>
          <SidebarFooter className="max-lg:hidden">
            <Dropdown>
              <DropdownButton as={SidebarItem}>
                <span className="flex min-w-0 items-center gap-3">
                  <Avatar src={user?.profile.picture} className="size-10" square alt="" />
                  <span className="min-w-0">
                    <span className="block truncate text-base/5 font-medium text-zinc-950 dark:text-white">{user?.profile.name}</span>
                    <span className="block truncate text-sm/5 font-normal text-zinc-500 dark:text-zinc-300">
                      {user?.profile.email}
                    </span>
                  </span>
                </span>
                <LiaAngleUpSolid />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="top start">
                {isUserSettingsAvailable && (
                  <DropdownItem href="/user-settings">
                    <LiaUserCogSolid size="1.25rem" />
                    <DropdownLabel>User Settings</DropdownLabel>
                  </DropdownItem>
                )}
                <DropdownItem onClick={handleSignout}>
                  <LiaSignOutAltSolid size="1.25rem" />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </SidebarFooter>
        </Sidebar>
      }
      navbar={
        <Navbar>
          <NavbarSpacer />
          <NavbarSection>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <Avatar src={user?.profile.picture} square />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="bottom end">
                <DropdownItem onClick={handleSignout}>
                  <LiaSignOutAltSolid size="1.25rem" />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarSection>
        </Navbar>
      }
    >
      <AnimatedPage animation="fadeIn" kind="full" flex fill>
        <PageContent pad="none" flex fill>
          {hasHeaderDetails(headerDetails) && (
            <div className="mb-4">
              {headerDetails.breadcrumbs && <Breadcrumbs breadcrumbs={headerDetails.breadcrumbs}/>}
              <PageTitleRow title={headerDetails.pageTitle}>
                {headerDetails.actions}
              </PageTitleRow>
            </div>
          )}
          {children}
        </PageContent>
      </AnimatedPage>
    </SidebarLayout>
  );
};

export { DashboardLayout };
