import { FormField } from 'grommet';
import React, { ReactNode, useId } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ArtiaButton } from '@/components/artia-button';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { DateTextInput } from '@/components/form-controls';
import { EditDialogState } from '@/features/coverage/api/use-edit-dialog';

type CoverageEditWorkflowDialogProps = {
  editDialogState: EditDialogState;
  onCancelDateSelect: () => void;
  onDateSelect: (pdlStatusDate: string) => void;
  children: ReactNode;
};

/**
 * A small wrapper around a <Dialog> component that shows a date selection screen first, then shows the `children`
 * once a value is entered.
 *
 * Primarily used for the Full PDL Review workflow.
 */
export const CoverageEditWorkflowDialog = ({ editDialogState, onCancelDateSelect, onDateSelect, children }: CoverageEditWorkflowDialogProps) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      pdlStatusDate: ''
    }
  });

  const onClose = () => {
    onCancelDateSelect();
    reset();
  };

  const onSubmit = ({ pdlStatusDate }: { pdlStatusDate: string }) => {
    onDateSelect(pdlStatusDate);
    reset();
  };

  const formId = useId();

  return (
    <Dialog width="xlarge" open={editDialogState.mode !== 'closed'}>
      {editDialogState.mode === 'open-date-select' ? (
        <>
          <DialogHeader title="Choose PDL Status Date" onClose={onClose} />
          <DialogBody>
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="pdlStatusDate"
                rules={{ required: 'PDL Status Date is required' }}
                render={({ field: { name, onChange, onBlur, value }, fieldState: { error } }) => (
                  <FormField
                    required
                    label="PDL Status Date"
                    name={name}
                    htmlFor={`${name}`}
                    margin="none"
                    error={error?.message}
                  >
                    <DateTextInput
                      name={name}
                      id={name}
                      value={value}
                      onChange={({ target }) => onChange((target as any).value)}
                      onBlur={onBlur}
                    />
                  </FormField>
                )}
              />
            </form>
          </DialogBody>
          <DialogActions>
            <ArtiaButton
              label="Cancel"
              type="button"
              onClick={onClose}
              size="large"
              variant="outlined"
            />
            <ArtiaButton
              label="Use this Date"
              type="submit"
              form={formId}
              size="large"
            />
          </DialogActions>
        </>
      ) : children}
    </Dialog>
  );
};
