import { Box, FormField, Spinner } from 'grommet';
import React, { KeyboardEventHandler, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { InlineEdit, NumericTextInput } from '@/components/form-controls';
import { BidRecommendation, BidRecommendationDrugPackaging } from '@/features/bid-analysis/types/bid-recommendation';

import { NumericReadonlyCell } from '../numeric-readonly-cell';
import * as BidRecommendationFunctions from './bid-recommendation.functions';
import { BidRecommendationPackagingFormInputs } from './bid-recommendation-packaging-form-inputs';

type NumericInlineEditCellProps = {
  fieldName: keyof BidRecommendationPackagingFormInputs,
  fieldType: 'numeric' | 'currency' | 'percent'
  bidRecommendation: BidRecommendation,
  drugPackaging: BidRecommendationDrugPackaging,
  editingFieldId: string;
  savingFieldId: string;
  onFocus: (fieldId: string) => void;
  onCancel: () => void;
  onSave: (
    bidRecommendation: BidRecommendation,
    row: BidRecommendationDrugPackaging,
    fieldId: string,
    form: BidRecommendationPackagingFormInputs) => void;
};

export const NumericInlineEditCell = (props: NumericInlineEditCellProps) => {
  const {
    fieldName,
    fieldType,
    bidRecommendation,
    drugPackaging,
    editingFieldId,
    savingFieldId,
    onFocus,
    onCancel,
    onSave,
  } = props;

  const id = BidRecommendationFunctions.buildFieldId({
    bidRecommendationId: bidRecommendation.id,
    isHistorical: bidRecommendation.isHistorical,
    packagingId: drugPackaging.id,
    fieldName
  });

  const editing = editingFieldId === id;
  const saving = savingFieldId === id;

  const getDefaultFormValue = () => {
    const fieldValue = drugPackaging[fieldName as keyof BidRecommendationDrugPackaging] as number;

    let defaultFormValue = '';
    if (fieldValue != null) {
      if (fieldType === 'numeric' || fieldType === 'currency') {
        defaultFormValue = fieldValue.toString();
      } else {
        defaultFormValue = (fieldValue * 100).toString();
      }
    }

    return defaultFormValue;
  };

  const {
    control,
    getValues,
    formState: { errors, isDirty  },
    reset
  } = useForm<BidRecommendationPackagingFormInputs>({ mode: 'onChange', reValidateMode: 'onChange', defaultValues: { [fieldName]: getDefaultFormValue() } });

  useEffect(() => {
    reset({ [fieldName]: getDefaultFormValue() });
  }, [drugPackaging[fieldName as keyof BidRecommendationDrugPackaging]]);

  const handleFocus = () => {
    onFocus(id);
  };

  const handleSave = async (form: BidRecommendationPackagingFormInputs) => {
    if (isDirty) {
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      let value = parseFloat(form[fieldName]);

      if (isNaN(value)) {
        // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'number'.
        value = null;
      } else if (fieldType === 'percent' && !isNaN(value) && value != null) {
        value = value / 100;
      }

      onSave(bidRecommendation, drugPackaging, id, { ...form, [fieldName]: value?.toString() ?? '' });
    } else {
      onCancel();
      reset();
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      handleSave(getValues());
    }

    if (event.key === 'Escape') {
      reset();
      onCancel();
    }
  };

  return (
    <Box width="12rem">
      <Controller
        control={control}
        name={fieldName}
        rules={{
          required: true
        }}
        render={({ field: { value, onChange } }) => (
          <InlineEdit
            editing={editing}
            onFocus={handleFocus}
            // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            readonlyComponent={<NumericReadonlyCell value={value} type={fieldType} />}
            inputComponent={
              <FormField
                name={fieldName}
                htmlFor={id}
                error={errors.supplementalRebate?.message}
              >
                <NumericTextInput
                  id={id}
                  value={value}
                  onChange={onChange}
                  onBlur={() => handleSave(getValues())}
                  onKeyDown={handleKeyDown}
                  name={fieldName}
                  maxWholePlaces={12}
                  maxDecimalPlaces={12}
                  icon={saving ? <Spinner size="xsmall" color="brand" /> : undefined}
                  reverse
                />
              </FormField>
            }
          />
        )}
      />
    </Box>
  );
};
