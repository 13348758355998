import { RequestOptions, useHttpClient } from '@shared/http';

import { DrugPackaging } from '@/features/drugs/types/drug-packaging';

type DrugPackagingRequest = {
  brandGenericStatus: string;
  cmsOnMarketDate?: string;
  wacBeginDate?: string;
  onMarketWacPrice?: string;
  onMarketWacUnitPrice?: string;
  baselineCpiu?: string;
  baselineCpiuDate?: string;
};

export const useDrugPackagingsService = () => {
  const httpClient = useHttpClient();

  const getDrugPackaging = (productId: number, ndc: string): Promise<DrugPackaging> => {
    return httpClient.get(`drugs/${productId}/packagings/${ndc}`);
  };

  const updateDrugPackaging = (productId: number, ndc: string, form: DrugPackagingRequest): Promise<any> => {
    const data: DrugPackagingRequest = {
      ...form,
      brandGenericStatus: form.brandGenericStatus,
      cmsOnMarketDate: form.cmsOnMarketDate?.slice(0, 10),
      wacBeginDate: form.wacBeginDate?.slice(0, 10),
      baselineCpiuDate: form.baselineCpiuDate?.slice(0, 10)
    };

    // Make sure that any empty string values are set to null.
    // @ts-expect-error TS(2345): Argument of type '(key: keyof DrugPackagingRequest... Remove this comment to see the full error message
    Object.keys(data).forEach((key: keyof DrugPackagingRequest) => {
      // @ts-expect-error TS(2322): Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
      data[key] = data[key] === '' ? null : data[key];
    });

    const options: RequestOptions<any> = {
      data
    };

    return httpClient.put(`drugs/${productId}/packagings/${ndc}`, options);
  };

  const addCoverageTagToPackaging = (productId: number, ndc: string, coverageTagId: number) => {
    return httpClient.put(`drugs/${productId}/packagings/${ndc}/coverage-tags/${coverageTagId}`);
  };

  const removeCoverageTagFromPackaging = (productId: number, ndc: string, coverageTagId: number) => {
    return httpClient.delete(`drugs/${productId}/packagings/${ndc}/coverage-tags/${coverageTagId}`);
  };

  return {
    getDrugPackaging,
    updateDrugPackaging,
    addCoverageTagToPackaging,
    removeCoverageTagFromPackaging
  };
};
