import { Box, Spinner } from 'grommet';
import React from 'react';

export const TableEmptyPlaceholder = ({ content }: { content: string | React.JSX.Element }) => {
  return (
    <Box
      width={{ min: '100%' }}
      height={{ min: 'small' }}
      background={{ color: 'light-2', opacity: 'medium' }}
      fill
      align="center"
      justify="center"
    >
      {content}
    </Box>
  );
};

export const TableLoadingOverlay = () => {
  return (
    <Box
      width={{ min: '100%' }}
      height={{ min: 'small' }}
      background={{ color: 'light-2', opacity: 'medium' }}
      fill
      align="center"
      justify="center"
    >
      <Spinner size="large" color="brand" />
    </Box>
  );
};
