import { WidthType } from 'grommet/utils';
import React, { createContext } from 'react';

export type ModalParts = {
  component: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
};

export type ModalOptions = {
  type?: 'pane' | 'layer'
  direction?: 'left' | 'right'
  showCloseIcon?: boolean;
  onClose: () => void,
  width?: WidthType,
};

export const DEFAULT_OPTIONS: ModalOptions = {
  type: 'pane',
  direction: 'right',
  showCloseIcon: true,
  width: 'medium',
  onClose: () => null,
};

export type ModalContextValue = {
  openModal: (modelParts: ModalParts, options?: ModalOptions) => void;
  closeModal: () => void;
  options?: ModalOptions
  modalParts?: ModalParts;
};

// @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
export const ModalContext = createContext<ModalContextValue>(null);
