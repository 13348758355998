import { useMarkdown } from '@shared/markdown/use-markdown';
import { Anchor, Box, NameValueList, NameValuePair, Text } from 'grommet';
import React from 'react';

import { ArtiaButton } from '@/components/artia-button';
import { Date } from '@/components/date-time';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import Nullish from '@/components/nullish';
import { TextEditor } from '@/components/text-editor';
import CoverageTagChip from '@/features/coverage/components/coverage-tag-chip';
import { StateCoverage } from '@/features/coverage/types/state-coverage';
import { stepTherapyDisplay } from '@/utils/state-coverage';

export const ViewCoverageDialog = ({ open, coverage, onClose }: ViewCoverageDialogProps) => {
  if (!open) return <></>;

  const { toHtml } = useMarkdown();

  const drugName = `${coverage.genericName ? (`${coverage.name} (${coverage.genericName})`) : coverage.name}`;

  return (
    <Dialog width="xlarge" open={open}>
      <DialogHeader
        title={coverage.state.name}
        subtitle={
          <Box direction="row" gap="small" align="center">
            <Text size="large" color="brand">{drugName}</Text>
            <Text size="large" color="brand">
              {coverage.coverageTags.map(tag =>
                <CoverageTagChip key={tag.id} coverageTag={tag}/>
              )}
            </Text>
          </Box>
        }
        onClose={onClose}
      />
      <DialogBody>
        <NameValueList nameProps={{ width: '16rem' }} valueProps={{ width: '100%' }} margin="small">
          <NameValuePair name="PDL Status Date">
            {/* @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
            <Date value={coverage.pdlStatusDate}/>
          </NameValuePair>
          <NameValuePair name="Status Change Effective Date">
            {/* @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
            <Date value={coverage.pdlStatusEffectiveDate}/>
          </NameValuePair>
          <NameValuePair name="PDL Status">
            <Text>{coverage.pdlStatus}</Text>
          </NameValuePair>
          <NameValuePair name="PA Types">
            <Text>{coverage.paTypes?.map(it => it.label)?.join('; ')}</Text>
          </NameValuePair>
          <NameValuePair name="Step Therapy">
            <Text>
              <Nullish value={stepTherapyDisplay(coverage.stepTherapyCount, coverage.hasAdditionalSteps)} />
            </Text>
          </NameValuePair>
          <NameValuePair name="PA Criteria Link">
            <Text>{coverage.paCriteriaSourceUrl ? <Anchor href={coverage.paCriteriaSourceUrl} target="_blank">{coverage.paCriteriaSourceUrl}</Anchor> : ''}</Text>
          </NameValuePair>
          <NameValuePair name="Has Auto PA?">
            <Text>{coverage.hasAutoPa ? 'Yes' : 'No'}</Text>
          </NameValuePair>
          <NameValuePair name="PA Criteria">
            <TextEditor
              // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
              value={toHtml(coverage.paCriteriaDescription)}
              editable={false}
            />
          </NameValuePair>
          <NameValuePair name="Notes">
            <TextEditor
              // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
              value={toHtml(coverage.notes)}
              editable={false}
            />
          </NameValuePair>
        </NameValueList>
      </DialogBody>
      <DialogActions>
        <ArtiaButton
          label="Close"
          type="button"
          onClick={onClose}
          size="large"
          variant="outlined"
        />
      </DialogActions>
    </Dialog>
  );
};

type ViewCoverageDialogProps = {
  open: boolean;
  coverage: StateCoverage;
  onClose: () => void;
};
