import { Box } from 'grommet';
import React from 'react';
import styled from 'styled-components';

import { DARK_2, LIGHT_3 } from '@/styles';

type InlineEditProps = {
  tabIndex?: number;
  readonlyComponent: React.JSX.Element;
  inputComponent: React.JSX.Element;
  editing: boolean;
  onFocus: () => void;
};

export const InlineEdit = (props: InlineEditProps) => {
  const {
    tabIndex = 0,
    readonlyComponent,
    inputComponent,
    editing,
    onFocus
  } = props;

  return (
    <>
      {!editing &&
        <ClickableBox tabIndex={tabIndex} onFocus={onFocus} onClick={onFocus}>
          {readonlyComponent}
        </ClickableBox>
      }

      {editing && inputComponent}
    </>
  );
};

const ClickableBox = styled(Box)`
  margin: 1px;
  &:hover {
    margin: 0;
    cursor: text;
    background-color: ${LIGHT_3};
    border: ${DARK_2} 1px solid;
  }
`;
