import { queryOptions, useQuery } from '@tanstack/react-query';
import { User } from 'oidc-client-ts';

import { artiaProtectedApi } from '@/lib/api-client';
import { QueryConfig } from '@/lib/react-query';
import { Me } from '@/types/me';

export const makeMeQueryKey = () => ['me'];

const getMe = (): Promise<Me> => {
  return artiaProtectedApi.get('/users/me');
};

export const getMeQueryOptions = (user?: User | null) => {
  return queryOptions({
    queryKey: makeMeQueryKey(),
    queryFn: () => getMe(),
    retry: 3,
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: Boolean(user),
    meta: { persist: true } // persist the data in localstorage
  });
};

type UseMeQueryOptions = {
  user?: User | null,
  queryConfig?: QueryConfig<typeof getMeQueryOptions>
}

export const useMe = ({ queryConfig = {}, user }: UseMeQueryOptions) => {
  return useQuery({
    ...getMeQueryOptions(user),
    ...queryConfig
  });
};
