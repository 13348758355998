import React from 'react';
import { LiaChartPieSolid, LiaShoppingBasketSolid } from 'react-icons/lia';

import { Heading } from '@/components-new/heading';
import { Link } from '@/components-new/link';
import { useCurrentUser } from '@/hooks/use-current-user';
import { Path } from '@/router';
import { insertIf } from '@/utils/arrays';

type QuickLink = {
  label: string;
  description: string;
  href: string | Path;
  icon: React.ReactNode;
}

const QuickLinkCard = (quickLink: QuickLink) => {
  return (
    <div
      key={quickLink.label}
      className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
    >
      <span
        className="flex size-10 shrink-0 items-center justify-center rounded bg-secondary-50 p-2 text-secondary-600 ring-4 ring-white">
        {quickLink.icon}
      </span>
      <div className="min-w-0 flex-1">
        <Link href={quickLink.href} className="focus:outline-none">
          <span aria-hidden="true" className="absolute inset-0"/>
          <p className="text-base/5 font-bold text-primary-950">{quickLink.label}</p>
          <p className="truncate text-sm text-gray-500">{quickLink.description}</p>
        </Link>
      </div>
    </div>
  );
};

const ArtiaUserHome = () => {
  const { hasPolicies } = useCurrentUser();

  const quickLinks: QuickLink[] = [
    ...insertIf(
      hasPolicies(['canManageBidAnalyses']),
      {
        label: 'Bid Analyses',
        description: 'Manage and review bid analyses.',
        href: '/bid-analyses',
        icon: <LiaChartPieSolid size="2rem"/>
      }
    ),
    ...insertIf(
      hasPolicies(['isAnyArtiaUser']),
      {
        label: 'Market Baskets',
        description: 'Maintain up-to-date market baskets for bid analyses.',
        href: '/market-baskets',
        icon: <LiaShoppingBasketSolid size="2rem"/>
      }
    )
  ];

  return (
    <div>
      <div className="mb-3 md:flex md:items-center md:justify-between">
        <Heading level={5} className="font-serif">Quick links</Heading>
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
      {quickLinks.map((link) => <QuickLinkCard {...link} key={link.label} />)}
      </div>
    </div>
  );
};

export { ArtiaUserHome };
