import { Text, Tip } from 'grommet';
import React from 'react';

/**
 * Indicates whether a drug is brand, authorized generic, or generic along with
 * a tooltip indicating the license type.
 *
 * @param brandGenericStatus The brand/generic status of the drug.
 * @param licenseType The license type of the drug.
 */
export const BrandGenericStatusIndicator = ({ brandGenericStatus, licenseType }: BrandGenericStatusIndicatorProps) => {
  if (brandGenericStatus === undefined || licenseType === undefined) {
    return <>&mdash;</>;
  }

  let status;
  if (brandGenericStatus === 'Brand') {
    status = 'Brand';
  } else if (brandGenericStatus === 'Generic' && licenseType === 'AG') {
    status = 'Authorized Generic';
  } else {
    status = 'Generic';
  }

  return (
    <Tip content={`License Type: ${licenseType}`}>
      <Text>{status}</Text>
    </Tip>
  );
};

type BrandGenericStatusIndicatorProps = {
  brandGenericStatus?: 'Brand' | 'Generic';
  licenseType?: string;
};
