import clsx from 'clsx';
import React from 'react';

export const EmptyStateHeading = ({ className, ...props }: React.ComponentPropsWithoutRef<'h3'>) => {
  return (
    <h3 className={clsx('mt-2 text-sm font-semibold text-zinc-950', className)} {...props}/>
  );
};

export const EmptyStateBody = ({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className={clsx('mt-1 text-sm text-gray-500', className)} {...props} />
  );
};

export const EmptyState = ({ className, children }: React.ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className={clsx('flex flex-col items-center px-4 py-8 text-center', className)}>
      {children}
    </div>
  );
};

