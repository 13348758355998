import { Layer, Toolbar } from 'grommet';
import React, { ReactNode } from 'react';

type ActionBarProps = {
  children?: ReactNode;
  isOpen: boolean;
}

export const ActionBar = ({ isOpen, children }: ActionBarProps) => {
  return isOpen && (
    <Layer modal={false} position="bottom" margin={{ bottom: 'medium' }}>
      <Toolbar
        gap="medium"
        pad={{ vertical: 'small', horizontal: 'medium' }}
        border={{ size: 'xsmall', color: 'light-4' }}
        elevation="small"
      >
        {children}
      </Toolbar>
    </Layer>
  );
};
