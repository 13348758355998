import { ThemeContext, ThemeType } from 'grommet';
import { useContext } from 'react';

export const useThemeContext = () => {
  // @ts-expect-error TS(2345): Argument of type 'ThemeContextI' is not assignable... Remove this comment to see the full error message
  const context = useContext<ThemeType | null>(ThemeContext);

  if (context === null) throw new Error('useThemeContext must be used within Grommet');

  return context;
};
