import { useMarkdown } from '@shared/markdown';
import { Box } from 'grommet';
import React from 'react';

import { TextEditor } from '@/components/text-editor';

/**
 * Renders markdown entered via rich text editor as HTML preview.
 */
export const MarkdownPreview = ({ maxHeight = '400px', markdown }: MarkdownPreviewProps) => {
  const { toHtml } = useMarkdown();
  return (
    <Box style={{ maxHeight }} overflow="auto">
      <TextEditor editable={false} value={toHtml(markdown!)}/>
    </Box>
  );
};

type MarkdownPreviewProps = {
  maxHeight?: string | number;
  markdown?: string;
}
