import { Box, Text } from 'grommet';
import { WidthType } from 'grommet/utils';
import React from 'react';

import { NetCostComparisonRow } from '@/features/bid-analysis/types/net-cost-comparison';
import { formatPercent } from '@/utils/formatting';

type PercentCellProps = {
  row: NetCostComparisonRow;
  value: number;
  minimumFractionDigits: number;
  maximumFractionDigits: number;
  showSuppression?: boolean;
  singleState?: boolean;
  showTotal?: boolean;
  width?: WidthType;
};

export const PercentCell = (props: PercentCellProps) => {
  const {
    row,
    value,
    minimumFractionDigits,
    maximumFractionDigits,
    showSuppression,
    singleState,
    showTotal,
    width
  } = props;

  let renderedValue;
  if (row.isSuppressionUsed && showSuppression) {
    renderedValue = 'Suppressed';
  } else {
    renderedValue = renderOptionalPercent(value, minimumFractionDigits, maximumFractionDigits);
  }

  const jsxMap = {
    tag: <Text>{renderedValue}</Text>,
    ndc: <Text>{renderedValue}</Text>,
    drug: <Text>{renderedValue}</Text>,
    state: <></>,
    'state-total': showTotal && (singleState ? <Text weight="bold">{renderedValue}</Text> : <Text>{renderedValue}</Text>),
    'drug-total': showTotal && <Text weight="bold">{renderedValue}</Text>,
    'grand-total': showTotal && <Text weight="bold">{renderedValue}</Text>,
  };

  return (
    <Box width={width}>
      {jsxMap[row.rowType]}
    </Box>
  );
};

const renderOptionalPercent = (value?: number, minimumFractionDigits?: number, maximumFractionDigits?: number) => {
  if (value != null) {
    return formatPercent(value, minimumFractionDigits, maximumFractionDigits);
  }

  return <>&mdash;</>;
};
