import { useAsync } from '@shared/async';
import React from 'react';

import { DeleteConfirmationDialog } from '@/components/delete-confirmation-dialog';
import { useClassificationService } from '@/features/classifications/api/use-classifications-service';

type DeleteClassificationDialogProps = {
  open: boolean;
  classificationId: number;
  name: string;
  onClose: () => void;
  onDeleteComplete: () => void;
};

export const DeleteClassificationDialog = (props: DeleteClassificationDialogProps) => {
  const {
    open,
    classificationId,
    name,
    onClose,
    onDeleteComplete
  } = props;

  const { deleteClassification } = useClassificationService();
  const deleteAsync = useAsync(deleteClassification);

  return (
    <DeleteConfirmationDialog
      open={open}
      itemName="classification"
      title="Delete Classification"
      message={`Are you sure you want to delete the classification "${name}"?`}
      onCancel={onClose}
      onDeleteComplete={onDeleteComplete}
      request={deleteAsync}
      parameters={[classificationId]}
    />
  );
};
