import { Box, Grid, Text } from 'grommet';
import React from 'react';

export const Setting = ({ title, description, children }: SettingProps) => {
  return (
    <Grid
      rows={[]}
      columns={['1/4', '3/4']}
      areas={[
        ['gutter', 'content'],
      ]}
      gap="small"
      pad="small"
      style={{ borderWidth: 0, borderTopWidth: 1, borderColor: '#D9D9D9', borderStyle: 'solid' }}
    >
      <Box gridArea="gutter" gap="small">
        <Text weight="bold">{title}</Text>
        <Text size="small">{description}</Text>
      </Box>
      <Box gridArea="content" gap="small">
        {children}
      </Box>
    </Grid>
  );
};

type SettingProps = {
  title: string;
  description?: string;
  children: React.ReactNode;
};

export const SettingsList = ({ children }: SettingsListProps) => {
  return (
    <Box gap="medium">
      {children}
    </Box>
  );
};

type SettingsListProps = {
  children: React.ReactNode;
};
