import { useAsync, UseAsyncStatus } from '@shared/async';
import { Box, FormField, Select, SelectMultiple, Text, TextInput } from 'grommet';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { GrommetFilterForm } from '@/components/filters';
import { DateTextInput } from '@/components/form-controls';
import { useLookupsService } from '@/hooks/use-lookups-service';
import { Lookup } from '@/types/lookup';

import { ChangeLogReason, changeLogReasonOptions } from '../../types';

type PropertySearchType = 'anySelected' | 'allSelected';

export type FilterInputs = {
  updatedAtFrom?: string;
  updatedAtUntil?: string;
  states?: string[];
  clientIds?: number[];
  product?: string;
  user?: string;
  properties?: number[];
  propertySearchType?: PropertySearchType;
  reason?: ChangeLogReason;
};

type ClinicalsChangeLogFiltersProps = {
  disabled: boolean;
  defaultFilters: FilterInputs;
  onApplyFilters: (filters: FilterInputs) => void;
  children?: ReactNode;
};

export const propertySearchTypeOptions: { label: string, value: PropertySearchType }[] = [
  // null will be treated as "only selected"
  { label: 'Any Selected Properties', value: 'anySelected' },
  { label: 'All Selected Properties', value: 'allSelected' },
];

export const ClinicalsChangeLogFilters = (props: ClinicalsChangeLogFiltersProps) => {
  const {
    defaultFilters,
    onApplyFilters,
    children
  } = props;

  const { getStates, getClinicalsChangeTypes, getClientLookups } = useLookupsService();
  const statesAsync = useAsync(getStates);
  const loadingStates = statesAsync.status === UseAsyncStatus.Pending;

  const changeTypesAsync = useAsync(getClinicalsChangeTypes);
  const loadingChangeTypes = changeTypesAsync.status === UseAsyncStatus.Pending;

  const [stateSearchTerm, setStateSearchTerm] = useState('');
  const [clientsSearchTerm, setClientsSearchTerm] = useState('');

  const clientsAsync = useAsync(getClientLookups);


  useEffect(() => {
    void statesAsync.execute();
    void changeTypesAsync.execute();
    void clientsAsync.execute({});
  }, []);

  const statesOptions = useMemo(() => {
    if (!statesAsync.value) {
      return [];
    }
    if (!stateSearchTerm) {
      return statesAsync.value;
    }
    return statesAsync.value.filter(
      ({ name }) => name.toLowerCase().includes(stateSearchTerm.toLowerCase())
    );
  }, [statesAsync.value, stateSearchTerm]);

  return (
    <GrommetFilterForm
      variant="table"
      defaultValue={defaultFilters}
      emptyValues={{
        updatedAtFrom: '',
        updatedAtUntil: '',
        states: [],
        clientIds: [],
        product: '',
        properties: [],
        // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'PropertySea... Remove this comment to see the full error message
        propertySearchType: null,
        user: ''
      }}
      onChange={(value) => onApplyFilters(value)}
      actions={children}
    >
      <Box width="16rem">
        <FormField
          label={<Text weight="bold" color="white">Updated After</Text>}
          name="updatedAtFrom"
          htmlFor="updated-at-from"
          margin="none"
        >
          <DateTextInput name="updatedAtFrom" id="updated-at-from" />
        </FormField>
      </Box>
      <Box width="16rem">
        <FormField
          label={<Text weight="bold" color="white">Updated Before</Text>}
          name="updatedAtUntil"
          htmlFor="updated-at-until"
          margin="none"
        >
          <DateTextInput name="updatedAtUntil" id="updated-at-until" />
        </FormField>
      </Box>
      <Box width="16rem">
        <FormField
          margin="none"
          name="states"
          htmlFor="states"
        >
          <SelectMultiple
            id="states"
            name="states"
            options={statesOptions}
            labelKey="name"
            valueKey={{ key: 'code', reduce: true }}
            disabled={loadingStates}
            placeholder="Search by State"
            dropHeight="medium"
            onSearch={setStateSearchTerm}
            onClose={() => setStateSearchTerm('')}
            defaultValue={defaultFilters.states}
          />
        </FormField>
      </Box>
      <Box width="16rem">
        <FormField
          margin="none"
          name="clientIds"
          htmlFor="clientIds"
        >
          <SelectMultiple
            id="clientIds"
            name="clientIds"
            options={((clientsAsync.value ?? []) as Lookup[]).filter(lookup => lookup.label.toLowerCase().includes(clientsSearchTerm.toLowerCase()))}
            labelKey="label"
            valueKey={{ key: 'id', reduce: true }}
            disabled={clientsAsync.isPending}
            placeholder="Search by Client"
            dropHeight="medium"
            onSearch={setClientsSearchTerm}
            onClose={() => setClientsSearchTerm('')}
          />
        </FormField>
      </Box>
      <Box width="16rem">
        <FormField
          margin="none"
          name="drug"
          htmlFor="drug"
        >
          <TextInput
            type="search"
            placeholder="Search By Drug"
            id="drug"
            name="drug"
          />
        </FormField>
      </Box>
      <Box width="16rem">
        <FormField
          margin="none"
          name="user"
          htmlFor="user"
        >
          <TextInput
            type="search"
            placeholder="Search By User"
            id="user"
            name="user"
          />
        </FormField>
      </Box>
      <Box width="16rem">
        <FormField
          margin="none"
          name="properties"
          htmlFor="properties"
        >
          <SelectMultiple
            id="properties"
            name="properties"
            options={changeTypesAsync.value ?? []}
            labelKey="label"
            valueKey={{ key: 'id', reduce: true }}
            disabled={loadingChangeTypes}
            placeholder="Search by Property"
            dropHeight="medium"
            defaultValue={defaultFilters.properties}
          />
        </FormField>
      </Box>
      <Box width="16rem">
        <FormField
          margin="none"
          name="propertySearchType"
          htmlFor="propertySearchType"
        >
          <Select
            id="propertySearchType"
            name="propertySearchType"
            options={propertySearchTypeOptions}
            disabled={loadingChangeTypes}
            labelKey="label"
            dropHeight="medium"
            valueKey={{ key: 'value', reduce: true }}
            defaultValue={defaultFilters.propertySearchType}
            placeholder="Only Selected Properties"
            clear={{ label: 'Only Selected Properties' }}
          />
        </FormField>
      </Box>
      <Box width="16rem">
        <FormField
          margin="none"
          name="reason"
          htmlFor="reason"
        >
          <Select
            id="reason"
            name="reason"
            options={changeLogReasonOptions}
            disabled={loadingChangeTypes}
            labelKey="label"
            dropHeight="medium"
            placeholder="Search by Reason"
            valueKey={{ key: 'id', reduce: true }}
            defaultValue={defaultFilters.reason}
            clear
          />
        </FormField>
      </Box>
    </GrommetFilterForm>
  );
};
