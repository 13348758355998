import { RequestOptions, useHttpClient } from '@shared/http';

import {
  BidRecommendationPackagingFormInputs
} from '../components/show/bid-recommendations/bid-recommendation-packaging-form-inputs';

type UpdatePackagingRequest = {
  property: keyof BidRecommendationPackagingFormInputs;
  value: string;
};

export const useBidRecommendationsService = () => {
  const httpClient = useHttpClient();

  const createBidRecommendation = (bidAnalysisId: number, { title, isHistorical }: { title: string, isHistorical: boolean }): Promise<void> => {
    const options: RequestOptions<any> = {
      data: { title }
    };

    if (isHistorical) {
      return httpClient.post(`bid-analyses/${bidAnalysisId}/historical-recommendations`, options);
    }

    return httpClient.post(`bid-analyses/${bidAnalysisId}/recommendations`, options);
  };

  const createHistoricalBidRecommendationPackaging = (
    bidAnalysisId: number,
    recommendationId: number, { ndc, description }: { ndc: string, description: string }
  ): Promise<void> => {
    const options: RequestOptions<any> = {
      data: { ndc, description }
    };

    return httpClient.post(`bid-analyses/${bidAnalysisId}/historical-recommendations/${recommendationId}/packagings`, options);
  };

  const deleteBidRecommendation = (
    bidAnalysisId: number,
    bidRecommendationId: number,
    isHistorical: boolean
  ): Promise<void> => {
    if (isHistorical) {
      return httpClient.delete(`bid-analyses/${bidAnalysisId}/historical-recommendations/${bidRecommendationId}`);
    }

    return httpClient.delete(`bid-analyses/${bidAnalysisId}/recommendations/${bidRecommendationId}`);
  };

  const updateBidRecommendation = (
    bidAnalysisId: number,
    bidRecommendationId: number,
    { title }: { title: string },
    isHistorical: boolean
  ): Promise<{ title: string }> => {
    const options: RequestOptions<any> = {
      data: { title }
    };

    if (isHistorical) {
      return httpClient.put(`bid-analyses/${bidAnalysisId}/historical-recommendations/${bidRecommendationId}`, options)
        .then(() => ({ title: title }));
    }

    return httpClient.put(`bid-analyses/${bidAnalysisId}/recommendations/${bidRecommendationId}`, options)
      .then(() => ({ title: title }));
  };

  const updatePackaging = (
    bidAnalysisId: number,
    bidRecommendationId: number,
    packagingId: number,
    propertyKey: keyof BidRecommendationPackagingFormInputs,
    propertyValue: string
  ): Promise<any> => {
    const request: UpdatePackagingRequest = {
      property: propertyKey,
      value: propertyValue
    };

    const options: RequestOptions<any> = {
      data: { ...request }
    };

    return httpClient.patch(`bid-analyses/${bidAnalysisId}/recommendations/${bidRecommendationId}/packagings/${packagingId}`, options);
  };

  const updateHistoricalPackaging = (
    bidAnalysisId: number,
    bidRecommendationId: number,
    packagingId: number,
    propertyKey: keyof BidRecommendationPackagingFormInputs,
    propertyValue: string
  ): Promise<any> => {
    const request: UpdatePackagingRequest = {
      property: propertyKey,
      value: propertyValue
    };

    const options: RequestOptions<any> = {
      data: { ...request }
    };

    return httpClient.patch(`bid-analyses/${bidAnalysisId}/historical-recommendations/${bidRecommendationId}/packagings/${packagingId}`, options);
  };

  const deleteHistoricalPackagings = (
    bidAnalysisId: number,
    bidRecommendationId: number,
    packagingIds: number[],
  ): Promise<any> => {
    const request = {
      packagingIds
    };

    const options: RequestOptions<any> = {
      data: { ...request }
    };

    return httpClient.delete(`bid-analyses/${bidAnalysisId}/historical-recommendations/${bidRecommendationId}/packagings`, options);
  };

  return {
    createBidRecommendation,
    createHistoricalBidRecommendationPackaging,
    deleteBidRecommendation,
    deleteHistoricalPackagings,
    updateBidRecommendation,
    updatePackaging,
    updateHistoricalPackaging
  };
};
