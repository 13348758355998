export const formatCurrency = (number: number, minimumFractionDigits = 2, maximumFractionDigits = 5): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
    maximumFractionDigits
  });

  return formatter.format(number);
};
