import { PaginationResult } from '@shared/pagination';
import { SelectMultiple } from 'grommet';
import { BasicSelectProps } from 'grommet/components/Select';
import React, { ComponentProps, ForwardedRef, forwardRef, ReactElement, ReactNode } from 'react';

import { SelectOption } from '@/components/form-controls/selects/select-option';
import { Lookup } from '@/types/lookup';

import { MultiSelectList } from './multi-select-list';
import { useLazyLoadOptions } from './use-lazy-load-options';

type RestrictedGrommetSelectProps = Omit<ComponentProps<typeof SelectMultiple>,
  | 'replace'
  | 'options'
  | 'value'
  | 'onChange'
  | 'labelKey'
  | 'valueKey'
  | 'onSearch'
  | 'onMore'
  | 'ref'
  | 'onClose'
>;

type LazyLoadMultiSelectListProps<TLookupId> = {
  /**
   * The options that are currently selected, required.
   */
  value: Lookup<TLookupId>[];

  /**
   * Event handler called whenever a value is changed:
   * - the user clears everything
   * - the user selects or deselects an option
   * @param value the selected options after this action
   */
  onChange: (value: Lookup<TLookupId>[]) => void;

  /**
   * The request to use to populate the options, required.
   * @param searchTerm the text in the input field
   * @param page the current page number (starts at 1)
   * @param rpp the number of results per page
   */
  lazyLoadRequest: (searchTerm: string, page: number, rpp: number) => Promise<PaginationResult<Lookup<TLookupId>>>;

  /**
   * The number of results per page, defaults to 100.
   */
  resultsPerPage?: number;

  /**
   * Visible label for the form field, optional but recommended.
   */
  label?: string;

  /**
   * Help text to display in a tooltip next to the label, optional.
   */
  help?: ReactNode;

  /**
   * Error message to display when the field is invalid, optional.
   */
  error?: ReactNode;

  /**
   * List of dependencies to refresh the options will, optional. This is used if you have
   * some external state that impacts when fetching should occur.
   */
  dependencies?: unknown[];

  icon?: BasicSelectProps['icon'];

} & RestrictedGrommetSelectProps;

const Inner = <TLookupId = number>(
  {
    value,
    onChange,
    lazyLoadRequest,
    resultsPerPage = 100,
    dependencies,
    icon,
    ...rest
  }: LazyLoadMultiSelectListProps<TLookupId>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const {
    loading,
    options,
    handleSearch,
    handleLoadMore,
    handleClose
  } = useLazyLoadOptions({ value, resultsPerPage, lazyLoadRequest, dependencies });

  return (
    <MultiSelectList
      options={options}
      value={value}
      onChange={({ value }: { value: Lookup<TLookupId>[] }) => onChange(value)}
      labelKey="label"
      valueKey="id"
      onSearch={handleSearch}
      onClose={handleClose}
      onMore={handleLoadMore}
      emptySearchMessage={loading ? 'Loading...' : 'No matches found'}
      icon={icon}
      {...rest}
      ref={ref}
    >
      {(option: Lookup<TLookupId>, _index: number, _options: unknown, state: { selected: boolean }) => (
        <SelectOption option={option} isMulti selected={state.selected} />
      )}
    </MultiSelectList>
  );
};

/**
 * A wrapper around Grommet's <SelectMultiple> component that handles lazy-loading options for you. It works something
 * like this:
 * - if the user scrolls to the bottom of the page, it fetches the next page of options
 * - if the user searches for data, it fetches the first page of options that match that search criteria
 *
 * It doesn't do any caching outside what the user LAST searched.
 */
export const LazyLoadMultiSelectList = forwardRef<HTMLInputElement, LazyLoadMultiSelectListProps<number>>(Inner) as <TLookupId = number> (props: LazyLoadMultiSelectListProps<TLookupId> & { ref?: ForwardedRef<HTMLInputElement> }) => ReactElement;
