import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { useHeaderDetails } from '@shared/header';
import { useNotifications } from '@shared/notifications';
import { required } from '@shared/validators';
import { isValidDate } from '@shared/validators/date-time-validators';
import { Anchor, Box, FormField, TextInput } from 'grommet';
import { Basket } from 'grommet-icons';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { ArtiaButton } from '@/components/artia-button';
import { Busy } from '@/components/busy';
import { FormCard } from '@/components/form-card';
import { DateTextInput, NumericTextInput } from '@/components/form-controls';
import { UpdateMarketBasketRequest, useMarketBasketService } from '@/features/market-baskets/api/use-market-baskets-service';
import { useMarketBasketDetails } from '@/features/market-baskets/market-basket-details.provider';
import { defaultEditMarketBasketForm, EditMarketBasketFormInputs } from '@/features/market-baskets/types/market-basket-form-inputs';

const EditMarketBasketPage = () => {
  const { id } = useParams();
  const { marketBasket, loadMarketBasket } = useMarketBasketDetails();
  const { updateMarketBasket } = useMarketBasketService();
  const { handleError } = useErrorHandler();
  const { success } = useNotifications();
  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();
  const navigate = useNavigate();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm<EditMarketBasketFormInputs>({ mode: 'onBlur', reValidateMode: 'onBlur', defaultValues: defaultEditMarketBasketForm });

  const updateMarketBasketAsync = useAsync(updateMarketBasket);
  const saving = updateMarketBasketAsync.status === UseAsyncStatus.Pending;

  const canSave = isValid && !saving;

  React.useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  React.useEffect(() => {
    if (id === null) {
      return;
    }

    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    loadMarketBasket(parseInt(id));
  }, [id]);

  React.useEffect(() => {
    const breadcrumbs = marketBasket ? [
      { icon: Basket, label: 'Market Baskets', url: '/market-baskets' },
      { label: marketBasket.name, url: `/market-baskets/${marketBasket.id}` },
      { label: 'Edit', url: `/market-baskets/${marketBasket.id}/edit` }
    ] : [];

    setHeaderDetails({
      documentTitle: 'Edit Market Basket',
      pageTitle: 'Edit Market Basket',
      breadcrumbs
    });

    return () => {
      clearHeaderDetails();
    };
  }, [marketBasket]);

  React.useEffect(() => {
    if (marketBasket) {
      setValue('name', marketBasket.name);
      setValue('currentCpiu', marketBasket.currentCpiu.toString());
      setValue('currentCpiuDate', marketBasket.currentCpiuDate);
    }
  }, [marketBasket]);

  useEffect(() => {
    const { status, error } = updateMarketBasketAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(
        error,
        {
          title: 'Update Market Basket Failed',
          message: 'Unable to update Market Basket',
          autoClose: false
        }
      );

      return;
    }

    success({ title: 'Success', message: 'Successfully updated Market Basket.', autoClose: true });
    navigate('..');
  }, [updateMarketBasketAsync.status]);

  const handleFormSubmit =  async (value: EditMarketBasketFormInputs) => {
    const request: UpdateMarketBasketRequest = {
      name: value.name,
      // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
      currentCpiu: value.currentCpiu != null ? parseFloat(value.currentCpiu) : undefined,
      currentCpiuDate: value.currentCpiuDate
    };

    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    updateMarketBasketAsync.execute(id, request);
  };

  const handleCancel = () => {
    navigate('..');
  };

  return (
    <Box>
      <FormCard>
        <Controller
          control={control}
          name="name"
          rules={{
            required: true,
            maxLength: 100
          }}
          render={({ field: { value, onChange, onBlur } }) => (
            <FormField
              name="name"
              htmlFor="name"
              label="Name"
              error={errors.name?.message}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                id="name"
                name="name"
                maxLength={100}
                placeholder="Market Basket Name"
              />
            </FormField>
          )}
        />
        <Box direction="row" gap="small">
            <Box basis="1/2">
              <Controller
                control={control}
                name="currentCpiu"
                rules={{
                  required: true
                }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <FormField
                    required
                    name="currentCpiu"
                    htmlFor="current-cpiu"
                    error={errors.currentCpiu?.message}
                    label={
                      <Anchor
                        label="Current CPI-U"
                        href="https://www.bls.gov/regions/mid-atlantic/data/consumerpriceindexhistorical_us_table.htm"
                        target="_blank"
                      />}
                  >
                    <NumericTextInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxWholePlaces={3}
                      maxDecimalPlaces={3}
                      name="currentCpiu"
                      id="current-cpiu"
                      placeholder="123.456"
                    />
                  </FormField>
                )}
              />
            </Box>
            <Box basis="1/2">
              <Controller
                control={control}
                name="currentCpiuDate"
                rules={{
                  validate: {
                    required: required('Current CPI-U Date is required'),
                    valid: isValidDate
                  }
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    name="currentCpiuDate"
                    htmlFor="current-cpiu-date"
                    label="Current CPI-U Date"
                    error={errors.currentCpiuDate?.message}
                  >
                    <DateTextInput
                      id="current-cpiu-date"
                      name="currentCpiuDate"
                      value={value}
                      onChange={({ target }) => onChange((target as any).value)}
                      onBlur={onBlur}
                    />
                  </FormField>
                )}
              />
            </Box>
        </Box>
        <Box flex direction="row" gap="medium" margin={{ top: 'large' }} justify="end">
          <ArtiaButton
            label="Cancel"
            onClick={handleCancel}
            size="large"
            variant="outlined"
          />

          <ArtiaButton
            label={<Busy busy={saving} content="Update" />}
            onClick={handleSubmit(handleFormSubmit)}
            disabled={!canSave}
            size="large"
          />
        </Box>
      </FormCard>
    </Box>
  );
};

export default EditMarketBasketPage;
