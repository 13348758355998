import { FormField, Heading, Spinner, TextInput } from 'grommet';
import React, { KeyboardEventHandler, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { InlineEdit } from '@/components/form-controls';
import { BidRecommendation } from '@/features/bid-analysis/types/bid-recommendation';

import * as BidRecommendationFunctions from './bid-recommendation.functions';

type BidRecommendationTitleProps = {
  bidRecommendation: BidRecommendation,
  editingFieldId: string;
  savingFieldId: string;
  onFocus: (fieldId: string) => void;
  onCancel: () => void;
  onSave: (fieldId: string, form: { title: string }, isHistorical: boolean) => void;
};

export const BidRecommendationTitle = (props: BidRecommendationTitleProps) => {
  const {
    bidRecommendation,
    editingFieldId,
    savingFieldId,
    onFocus,
    onCancel,
    onSave
  } = props;

  const id = BidRecommendationFunctions.buildFieldId({
    bidRecommendationId: bidRecommendation.id,
    isHistorical: bidRecommendation.isHistorical,
    fieldName: 'title'
  });

  const editing = editingFieldId === id;
  const saving = savingFieldId === id;

  const {
    control,
    getValues,
    formState: { errors, isDirty, isValid  },
    handleSubmit,
    reset
  } = useForm<{ title: string }>({ mode: 'onChange', reValidateMode: 'onChange', defaultValues: { title: bidRecommendation.title } });

  useEffect(() => {
    reset({ title: bidRecommendation.title });
  }, [bidRecommendation.title]);

  const handleFocus = () => {
    onFocus(id);
  };

  const handleSave = async ({ title }: { title: string }) => {
    if (isDirty) {
      onSave(id, { title }, bidRecommendation.isHistorical);
    } else {
      onCancel();
      reset();
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (!isValid) return;

    if (event.key === 'Enter') {
      handleSave(getValues());
    }

    if (event.key === 'Escape') {
      reset();
      onCancel();
    }
  };

  return (
    <Controller
      control={control}
      name="title"
      rules={{
        maxLength: 50,
        required: true
      }}
      render={({ field: { ref, value, onChange } }) => (
        <InlineEdit
          editing={editing}
          onFocus={handleFocus}
          readonlyComponent={<Heading level={4} margin="small">{value}</Heading>}
          inputComponent={
            <FormField
              name="title"
              htmlFor={id}
              error={errors.title?.message}
            >
              <TextInput
                ref={ref}
                value={value}
                onChange={onChange}
                onBlur={handleSubmit(handleSave)}
                onKeyDown={handleKeyDown}
                id={id}
                name="title"
                maxLength={50}
                icon={saving ? <Spinner size="xsmall" color="brand" /> : undefined}
                reverse
              />
            </FormField>
          }
        />
      )}
    />
  );
};
