import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import React, { useEffect, useId } from 'react';

import { ArtiaButton } from '@/components/artia-button';
import { Busy } from '@/components/busy';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { BoardMeeting } from '@/types/board-meetings';

import { useBoardMeetingService } from '../api';
import { BoardMeetingForm } from './board-meeting-form';

type UpdateBoardMeetingDialogProps = {
  /**
   * The Board Meeting to update, required. If null, this dialog won't open
   */
  boardMeeting?: BoardMeeting | null;

  /**
   * Cancel event handler, required. Usually used to close the dialog w/o saving changes.
   */
  onCancel: () => void;

  /**
   * Submit-completion event handler, required. Usually used to close the dialog and refresh any data needed.
   */
  onSubmitComplete: () => void;
};

/**
 * Dialog that lets you update an existing Board Meeting
 */
export const UpdateBoardMeetingDialog = ({ boardMeeting, onCancel, onSubmitComplete }: UpdateBoardMeetingDialogProps) => {
  const formId = useId();
  const open = !!boardMeeting;

  const { updateBoardMeeting } = useBoardMeetingService();
  const updateAsync = useAsync(updateBoardMeeting);
  const saving = updateAsync.status === UseAsyncStatus.Pending;
  const { handleError } = useErrorHandler();
  const { success } = useNotifications();

  useEffect(() => {
    const { status, error } = updateAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(error, {
        title: 'Failed to update Board Meeting',
        autoClose: false
      });

      return;
    }

    success({ title: 'Successfully updated Board Meeting', autoClose: true });
    onSubmitComplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // TODO: revisit this, missing deps
  }, [updateAsync.status]);

  return (
    <Dialog open={open} width="xlarge">
      <DialogHeader
        title={`Update ${boardMeeting?.state?.name} ${boardMeeting?.meetingType?.label} Meeting`}
        // @ts-expect-error TS(2322): Type 'false | (() => void)' is not assignable to t... Remove this comment to see the full error message
        onClose={!saving && onCancel}
      />
      <DialogBody>
        <BoardMeetingForm
          id={formId}
          // @ts-expect-error TS(2322): Type '{ state: string; meetingTypeId: number; star... Remove this comment to see the full error message
          defaultValues={boardMeeting  ? {
            state: boardMeeting.state.code,
            meetingTypeId: boardMeeting.meetingType.id,
            startDate: boardMeeting.startTime.setZone(boardMeeting.ianaTimeZone).toISODate(),
            startTime: boardMeeting.startTime.setZone(boardMeeting.ianaTimeZone).toFormat('HH:mm'),
            endTime: boardMeeting.endTime.setZone(boardMeeting.ianaTimeZone).toFormat('HH:mm'),
            ianaTimeZone: boardMeeting.ianaTimeZone,
            format: boardMeeting.format,
            venueName: boardMeeting.venueName ?? '',
            meetingRoom: boardMeeting.meetingRoom ?? '',
            locationStreetLine1: boardMeeting.locationStreetLine1 ?? '',
            locationStreetLine2: boardMeeting.locationStreetLine2 ?? '',
            locationCity: boardMeeting.locationCity ?? '',
            locationState: boardMeeting.locationState?.code ?? null,
            locationZipCode: boardMeeting.locationZipCode ?? '',
            locationNotes: boardMeeting.locationNotes ?? '',
            webinarLink: boardMeeting.webinarLink ?? '',
            webinarPhone: boardMeeting.webinarPhone ?? '',
            webinarMeetingId: boardMeeting.webinarMeetingId ?? '',
            webinarPassword: boardMeeting.webinarPassword ?? '',
            agendaLink: boardMeeting.agendaLink ?? '',
            meetingMinutesLink: boardMeeting.meetingMinutesLink ?? '',
            members: boardMeeting.members ?? '',
            recordingLink: boardMeeting.recordingLink ?? '',
            speakerPoliciesLink: boardMeeting.speakerPoliciesLink ?? '',
            discussedClassifications: boardMeeting.discussedClassifications,
            discussedDrugs: boardMeeting.discussedDrugs
          } : undefined}
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          onSubmit={(formData) => updateAsync.execute(boardMeeting.id, formData)}
        />
      </DialogBody>
      <DialogActions>
        <ArtiaButton
          type="button"
          label="Cancel"
          onClick={onCancel}
          disabled={saving}
          size="large"
          variant="outlined"
        />
        <ArtiaButton
          type="submit"
          form={formId}
          label={<Busy busy={saving} content="Save" />}
          disabled={saving}
          size="large"
        />
      </DialogActions>
    </Dialog>
  );
};
