import { Box, Layer, Text } from 'grommet';
import React from 'react';

import { Alert } from '@/components/alert';
import { Prompt } from '@/components/prompt';
import { User } from '@/features/admin/types/user';
import { Client } from '@/features/clients';
import { useDeleteClientUser } from '@/features/clients/api/use-delete-client-user';
import { isUserPrimaryAdmin } from '@/features/clients/clients-utils';

type Props = {
  user: User,
  client: Client,
  onClose: () => void;
}
const DeleteClientUserPrompt = ({ client, user, onClose }: Props) => {
  const {
    mutate: deleteUser,
    isPending,
  } = useDeleteClientUser();

  const handleConfirm = () => {
    deleteUser(
      { clientId: client.id, userId: user.id },
      {
        onSuccess: () => {
          onClose();
        }
      }
    );
  };

  return (
    <Layer onClickOutside={onClose}>
      <Prompt
        header="Delete User"
        actions={[
          { name: 'Cancel', onClick: onClose, variant: 'outlined' },
          { name: 'Delete', onClick: handleConfirm, disabled: () => isPending, variant: 'danger' },
        ]}
      >
        {isUserPrimaryAdmin(user, client) && (
          <Box margin={{ bottom: 'small' }}>
            <Alert variant="info" message={`This user is the primary admin for ${client.name}.`} />
          </Box>
        )}
        <Text>
          Are you sure you want to delete <strong>{user.name}</strong>?
        </Text>
      </Prompt>
    </Layer>
  );
};

export default DeleteClientUserPrompt;
