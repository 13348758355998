import { Button } from 'grommet';
import { Down, Up } from 'grommet-icons';
import React from 'react';

type ExpandCollapseCellProps = {
  expanded: boolean
  onExpand: () => void;
  onCollapse: () => void;
};

export const ExpandCollapseCell = ({ expanded, onExpand, onCollapse }: ExpandCollapseCellProps) => (
  <Button onClick={expanded ? onCollapse : onExpand}>
    {expanded ? <Up /> : <Down />}
  </Button>
);
