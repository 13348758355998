import { useSuspenseQuery } from '@tanstack/react-query';

import { useClientService } from '@/features/clients/api/use-clients-service';

export const makeClientQueryKey = (id: string | number) => ['client', id];

export const useClient = (clientId: number) => {
  const { getClient } = useClientService();
  const { data, error, isFetching, dataUpdatedAt } = useSuspenseQuery({
    queryKey: makeClientQueryKey(clientId),
    queryFn: async () => getClient(clientId)
  });

  if (error && !isFetching) throw error;

  return { data, dataUpdatedAt };
};
