import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import {
  Box,
  Card,
  CardBody,
  List,
  NameValueList,
  NameValuePair,
  Text
} from 'grommet';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArtiaButton } from '@/components/artia-button';
import { Busy } from '@/components/busy';
import { type WizardControlState } from '@/components/wizard';
import { CreateBidAnalysisRequest } from '@/features/bid-analysis/api/use-bid-analysis-service';
import { CreateBidAnalysisFormInputs } from '@/features/bid-analysis/types/bid-analysis-form-inputs';

import { useCreateBidAnalysis } from '../../create-bid-analysis.provider';
import { useCreateBidAnalysisForm } from '../../create-bid-analysis-form.provider';
import { useBidAnalysisService } from '../../index';

export const ReviewBidAnalysisStep = ({ previousStep }: ReviewBidAnalysisStepProps) => {
  const { states } = useCreateBidAnalysis();
  const { createBidAnalysis } = useBidAnalysisService();
  const { handleError } = useErrorHandler();
  const navigate = useNavigate();
  const { success } = useNotifications();

  const { form: {
    // @ts-expect-error TS(2339): Property 'getValues' does not exist on type 'UseFo... Remove this comment to see the full error message
    getValues,
    // @ts-expect-error TS(2339): Property 'formState' does not exist on type 'UseFo... Remove this comment to see the full error message
    formState: { isValid },
    // @ts-expect-error TS(2339): Property 'handleSubmit' does not exist on type 'Us... Remove this comment to see the full error message
    handleSubmit
  } } = useCreateBidAnalysisForm();

  const createBidAnalysisAsync = useAsync(createBidAnalysis);
  const saving = createBidAnalysisAsync.status === UseAsyncStatus.Pending;

  const canSave = isValid && !saving;

  useEffect(() => {
    const { status, error, value } = createBidAnalysisAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(
        error,
        {
          title: 'Create Bid Analysis Failed',
          message: 'Unable to create Bid Analysis',
          autoClose: false
        }
      );

      return;
    }


    const { id, slug } = value;
    success({ title: 'Created Successfully', message: 'Successfully created Bid Analysis.', autoClose: true });
    navigate(`/bid-analyses/${id}-${slug}`);
  }, [createBidAnalysisAsync.status]);

  const stateNames: { [key: string]: string } = useMemo(
    () => states.reduce((previous, current) => ({ ...previous, [current.code]: current.name }), {}),
    [states]
  );

  const getStateBenefits = () => {
    const benefits = getValues('benefits');

    return Object.keys(benefits).map(key => ({
      state: stateNames[key],
      benefit: benefits[key]?.name
    })).filter(({ benefit }) => benefit);
  };

  const handleFormSubmit =  async (value: CreateBidAnalysisFormInputs) => {
    const states = Object.keys(value.benefits)
      .map(key => ({ state: key, benefit: value.benefits[key]?.code }))
      .filter(entry => entry.benefit);

    const request: CreateBidAnalysisRequest = {
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      name: value.name?.length > 0 ? value.name : null,
      states,
      pool: value.pool?.code,
      marketBasketId: value.marketBasket?.id,
      drugId: value.drug?.id,
      year: value.year,
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      bidDueOn: value.dueOn?.trim() ? value.dueOn : null,
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      reviewOn: value.reviewOn?.trim() ? value.reviewOn : null,
      contractTermStartsOn: value.startsOn,
      contractTermEndsOn: value.endsOn,
      periods: value.periods.map(period => period.label)
    };

    createBidAnalysisAsync.execute(request);
  };

  return (
    <Card pad="medium" width="large">
      <CardBody>
        <NameValueList>
          <NameValuePair name="Market Basket">
            <Text wordBreak="break-word" color="text-strong">{getValues('marketBasket')?.label}</Text>
          </NameValuePair>
          <NameValuePair name="Product">
            <Text wordBreak="break-word" color="text-strong">{getValues('drug')?.label}</Text>
          </NameValuePair>
          <NameValuePair name="Periods">
            <List
              color="text-strong"
              primaryKey="label"
              data={getValues('periods')}
            />
          </NameValuePair>
          <NameValuePair name="Benefits">
            <List
              color="text-strong"
              primaryKey="state"
              secondaryKey="benefit"
              data={getStateBenefits()}
            />
          </NameValuePair>
        </NameValueList>
        <Box flex direction="row" gap="medium" margin={{ top: 'large' }} justify="end">
          <ArtiaButton label="Back" onClick={previousStep} size="large" variant="outlined" />

          <ArtiaButton
            label={<Busy busy={saving} content="Create" />}
            onClick={handleSubmit(handleFormSubmit)}
            disabled={!canSave}
            size="large"
          />
        </Box>
      </CardBody>
    </Card>
  );
};

type ReviewBidAnalysisStepProps = {
  stepName: string,
} & WizardControlState;
