import { useSuspenseQuery } from '@tanstack/react-query';

import { UserSettings } from '@/features/user-settings/types/user-settings';
import { artiaProtectedApi } from '@/lib/api-client';

const getUserSettings = (): Promise<UserSettings> => {
  return artiaProtectedApi.get('users/me/user-settings');
};

export const useUserSettings = () => {
  const { data, error, isFetching, dataUpdatedAt } = useSuspenseQuery({
    queryKey: ['userSettings'],
    queryFn: async () => getUserSettings()
  });

  if (error && !isFetching) throw error;

  return { data, dataUpdatedAt, isFetching };
};
