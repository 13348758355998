import { Box } from 'grommet';
import { KeyboardEvent,ReactNode } from 'react';

type TextEditorFormContainerProps = {
  children?: ReactNode;
  /**
   * Max height before the text editor content will scroll.
   */
  maxHeight?: string | number;
}

/**
 * Represents a container component for a text editor used inside a form.
 */
export const TextEditorFormContainer = ({ children, maxHeight = '40rem' }: TextEditorFormContainerProps) => {
  const handleOnKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;

    // the enter key from within a text editor should never submit the form
    if (key === 'Enter') {
      event.stopPropagation();
    }
  };
  return (
    <Box
      onKeyDown={handleOnKeyDown}
      style={{ maxHeight }}
    >
      {children}
    </Box>
  );
};
