import React, { useEffect } from 'react';
import {
  LiaCalendar,
  LiaExternalLinkAltSolid,
  LiaPillsSolid, LiaTagsSolid,
  LiaTimesSolid,
  LiaVideoSolid
} from 'react-icons/lia';

import { Badge } from '@/components-new/badge';
import { Button } from '@/components-new/button';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '@/components-new/description-list';
import { Drawer, DrawerBody, DrawerHeader, DrawerTitle } from '@/components-new/drawer';
import { SectionHeading } from '@/components-new/section';
import { TextLink } from '@/components-new/text';
import { formatMeetingFormat, Meeting } from '@/features/calendar/types/meeting';
import { useDelayedClose } from '@/hooks/use-delayed-close';

type MeetingCardProps = {
  meeting: Meeting;
  showMeetingDetails: (meeting: Meeting) => void;
};

export const MeetingCard = ({ meeting, showMeetingDetails }: MeetingCardProps) => {
  return (
    <li key={meeting.id} className="flex items-center justify-between gap-x-6 py-5">
      <div className="min-w-0">
        <div className="flex items-start gap-x-3">
          <p dangerouslySetInnerHTML={{ __html: meeting.name }} className="text-base font-semibold leading-6 text-gray-900"></p>
          <Badge color="secondary">{formatMeetingFormat(meeting.format)}</Badge>
        </div>
        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
          <dl className="flex flex-row gap-2">
            <dt className="mt-0.5">
              <span className="sr-only">When</span>
              <LiaCalendar className="size-5 text-gray-400" aria-hidden="true"/>
            </dt>
            <dd>
              {meeting.date}, {meeting.time}
            </dd>

            <dt className="mt-0.5">
              <span className="sr-only">Drugs</span>
              <LiaPillsSolid className="size-5 text-gray-400" aria-hidden="true"/>
            </dt>
            <dd>
              {meeting.drugs?.join(', ')}
            </dd>

            <dt className="mt-0.5">
              <span className="sr-only">Drug Classes</span>
              <LiaTagsSolid className="size-5 text-gray-400" aria-hidden="true"/>
            </dt>
            <dd>
              {meeting.classifications?.join(', ')}
            </dd>
          </dl>
        </div>
      </div>
      <div className="flex flex-none items-center gap-x-4">
        <Button outline onClick={() => showMeetingDetails(meeting)}>
          View Details<span className="sr-only">, {meeting.name}</span>
        </Button>
      </div>
    </li>
  );
};

type MeetingDetailsDrawerProps = {
  meeting?: Meeting;
  onClose: () => void;
}

export const MeetingDetailsDrawer = ({ meeting, onClose }: MeetingDetailsDrawerProps) => {
  const { isOpen, handleOpen, handleClose } = useDelayedClose(!!meeting, onClose);

  useEffect(() => {
    if (meeting) {
      handleOpen();
    }
  }, [meeting, handleOpen]);

  return (
    <Drawer open={isOpen} onClose={handleClose} size="xl">
      {!!meeting && (
        <>
          <DrawerHeader>
            <DrawerTitle>{meeting?.name}</DrawerTitle>
            <Button plain onClick={handleClose}>
              <LiaTimesSolid/>
            </Button>
          </DrawerHeader>
          <DrawerBody>
            <div className="space-y-6">
              <Button color="secondary">Add to Calendar</Button>

              <div>
                <SectionHeading level={3}>Information</SectionHeading>
                <DescriptionList>
                  <DescriptionTerm>Date</DescriptionTerm>
                  <DescriptionDetails>{meeting?.date}</DescriptionDetails>

                  <DescriptionTerm>Time</DescriptionTerm>
                  <DescriptionDetails>{meeting?.time}&ndash;8:45 AM EDT</DescriptionDetails>

                  <DescriptionTerm>Format</DescriptionTerm>
                  <DescriptionDetails>{formatMeetingFormat(meeting?.format)}</DescriptionDetails>
                </DescriptionList>
              </div>

              {meeting?.location && (
                <div>
                  <SectionHeading>Location</SectionHeading>
                  <DescriptionList>
                    <DescriptionTerm>Venue</DescriptionTerm>
                    <DescriptionDetails>
                      {meeting.location.venue}
                    </DescriptionDetails>

                    {meeting.location.room && (
                      <>
                        <DescriptionTerm>Room</DescriptionTerm>
                        <DescriptionDetails>{meeting.location.room}</DescriptionDetails>
                      </>
                    )}

                    {meeting.location.address && (
                      <>
                        <DescriptionTerm>Address</DescriptionTerm>
                        <DescriptionDetails>
                          {meeting.location.address.line1}<br/>
                          {meeting.location.address.line2 && (
                            <>
                              {meeting.location.address.line2}<br/>
                            </>
                          )}
                          {meeting.location.address.city}, {meeting.location.address.state} {meeting.location.address.zipcode}
                        </DescriptionDetails>
                      </>
                    )}
                  </DescriptionList>
                </div>
              )}

              {meeting?.webinar && (
                <div>
                  <SectionHeading>Webinar</SectionHeading>
                  <DescriptionList>
                    <DescriptionTerm>
                      <LiaVideoSolid
                        aria-hidden="true"
                        className="mr-2 inline h-6 w-5 text-gray-400"
                      /> {new URL(meeting.webinar.url).hostname}
                    </DescriptionTerm>
                    <DescriptionDetails>
                      <Button color="secondary" href={meeting.webinar.url}>Join</Button>
                    </DescriptionDetails>

                    <DescriptionTerm>Webinar Phone</DescriptionTerm>
                    <DescriptionDetails>{meeting.webinar.phone}</DescriptionDetails>

                    <DescriptionTerm>Webinar Meeting ID</DescriptionTerm>
                    <DescriptionDetails>{meeting.webinar.meetingId}</DescriptionDetails>

                    <DescriptionTerm>Webinar Password</DescriptionTerm>
                    <DescriptionDetails>{meeting.webinar.password}</DescriptionDetails>
                  </DescriptionList>
                </div>
              )}

              <div>
                <SectionHeading>Details</SectionHeading>
                <DescriptionList>
                  <DescriptionTerm>Type</DescriptionTerm>
                  <DescriptionDetails>{meeting?.type}</DescriptionDetails>

                  <DescriptionTerm>Drugs</DescriptionTerm>
                  <DescriptionDetails>{meeting?.drugs?.join(', ')}</DescriptionDetails>

                  <DescriptionTerm>Drug Classes</DescriptionTerm>
                  <DescriptionDetails>{meeting?.classifications?.join(', ')}</DescriptionDetails>

                  <DescriptionTerm>Board Members</DescriptionTerm>
                  <DescriptionDetails>{meeting?.boardMembers}</DescriptionDetails>
                </DescriptionList>
              </div>

              <div>
                <SectionHeading className="mb-3">Resources</SectionHeading>
                {meeting?.resources?.map(resource => (
                  <div className="mt-2 flex w-full flex-none gap-x-2">
                    <dt className="flex-none">
                      <LiaExternalLinkAltSolid aria-hidden="true" className="h-6 w-5 text-gray-400"/>
                    </dt>
                    <dd className="text-sm leading-6 text-gray-500">
                      <TextLink href={resource.url}>{resource.name}</TextLink>
                    </dd>
                  </div>
                ))}
              </div>
            </div>
          </DrawerBody>
        </>
      )}
    </Drawer>
  );
};
