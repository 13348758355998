import React from 'react';
import { Helmet } from 'react-helmet';

type SeoProps = {
  children?: React.ReactNode;
  description?: string;
  title?: string;
}

/**
 * A component for managing the SEO metadata of a web page.
 */
const Seo = ({ children, description, title }: SeoProps) => {
  return (
    <Helmet>
      {description && <meta name="description" content={description} />}
      {title && <title>{title}</title>}
      {children}
    </Helmet>
  );
};

export { Seo };
