import { SelectExtendedProps } from 'grommet';
import React, { ForwardedRef, forwardRef, ReactElement, ReactNode, useMemo, useState } from 'react';

import { SelectOption } from '@/components/form-controls/selects/select-option';
import { Lookup } from '@/types/lookup';

import { SelectList } from './select-list';

// these represent props managed by the component itself, so we don't expose them
type RestrictedGrommetSelectProps = Omit<SelectExtendedProps,
  | 'replace'
  | 'options'
  | 'value'
  | 'onChange'
  | 'labelKey'
  | 'valueKey'
  | 'onSearch'
  | 'ref'
>;

type InMemorySelectListProps<TLookupId> = {
  /**
   * The list of options to choose from, required.
   */
  options: Lookup<TLookupId>[];

  /**
   * The options that are currently selected, required.
   */
  value: Lookup<TLookupId> | undefined;

  /**
   * Event handler called whenever a value is changed:
   * - the user clears everything
   * - the user selects or deselects an option
   * @param value the selected options after this action
   */
  onChange: (value: Lookup<TLookupId>) => void;

  /**
   * Visible label for the form field, optional but recommended.
   */
  label?: string;

  /**
   * Help text to display in a tooltip next to the label, optional.
   */
  help?: ReactNode;

  /**
   * Error message to display when the field is invalid, optional.
   */
  error?: ReactNode;
} & RestrictedGrommetSelectProps;

const Inner = <TLookupId = number>(
  {
    value,
    onChange,
    options,
    ...rest
  }: InMemorySelectListProps<TLookupId>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOptions = useMemo(() => {
    if (!options) {
      return [];
    }
    if (!searchTerm) {
      return options;
    }
    return options.filter(
      (lookup) => lookup.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [options, searchTerm]);

  return (
    <SelectList
      value={value}
      onChange={({ value }) => onChange(value)}
      labelKey="label"
      valueKey="id"
      options={filteredOptions}
      onSearch={setSearchTerm}
      onClose={() => setSearchTerm('')}
      {...rest}
      ref={ref}
    >
      {(option: Lookup<TLookupId>, _index, _options, state) => (
        <SelectOption option={option} {...state} />
      )}
    </SelectList>
  );
};

/**
 * A wrapper around Grommet's <Select> component that handles lazy-loading options for you. It works something
 * like this:
 * - if the user scrolls to the bottom of the page, it fetches the next page of options
 * - if the user searches for data, it fetches the first page of options that match that search criteria
 *
 * It doesn't do any caching outside what the user LAST searched.
 */
export const InMemorySelectList = forwardRef<HTMLInputElement, InMemorySelectListProps<number>>(Inner) as <TLookupId = number> (props: InMemorySelectListProps<TLookupId> & { ref?: ForwardedRef<HTMLInputElement> }) => ReactElement;
