import { useModal } from '@shared/modal';
import { ColumnConfig, DataTable } from 'grommet';
import React, { useMemo, useState } from 'react';
import { HiArrowPath, HiTrash } from 'react-icons/hi2';
import { LiaPlusSolid } from 'react-icons/lia';
import { useNavigate } from 'react-router-dom';

import { Timestamp } from '@/components/date-time';
import { useFilters } from '@/components/filters';
import { TableEmptyPlaceholder, TableLoadingOverlay } from '@/components/loading';
import { Prompt } from '@/components/prompt';
import { Visible } from '@/components/visible';
import { Button } from '@/components-new/button';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Seo } from '@/components-new/seo';
import { TextLink } from '@/components-new/text';
import { MedicaidDatasetsProvider, useMedicaidDatasets } from '@/features/medicaid-datasets';
import { MedicaidDataset } from '@/features/medicaid-datasets';
import { MedicaidDatasetsFilterData, MedicaidDatasetsFilters } from '@/features/medicaid-datasets/components/medicaid-datasets-filters';
import { MedicaidSyncDialog } from '@/features/medicaid-datasets/components/medicaid-sync-dialog';
import { useCurrentUser } from '@/hooks/use-current-user';
import { insertIf } from '@/utils/arrays';

const MedicaidDatasets = () => {
  const { hasPolicies } = useCurrentUser();
  const {
    medicaidDatasets,
    loadMedicaidDatasets,
    deleteMedicaidDataset,
    loadingMedicaidDatasets
  } = useMedicaidDatasets();
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();

  const canManageMedicaidDatasets = hasPolicies(['canManageMedicaidDatasets']);

  const [filters, setFilters] = useFilters<MedicaidDatasetsFilterData>('medicaid-datasets', {});

  const filteredDatasets = useMemo(() => {
    const { title, year } = filters;

    return medicaidDatasets.filter(medicaidDataset =>
      (!title || medicaidDataset.title.toLowerCase().includes(title.toLowerCase()))
      && (!year || medicaidDataset.year === parseInt(year))
    );
  }, [filters, medicaidDatasets]);

  React.useEffect(() => {
    loadMedicaidDatasets();
    // TODO: revisit, missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreateMedicaidDataset = () => {
    navigate('/medicaid-datasets/new');
  };

  const openDeleteModal = (id: string) => {
    openModal(
      {
        component: (
          <Prompt
            header="Delete Medicaid Dataset"
            actions={[
              { name: 'Close', onClick: () => closeModal(), variant: 'outlined' },
              { name: 'Delete', onClick: () => onDeleteMedicaidDataset(id), variant: 'danger' }
            ]}
            message="Are you sure you want to delete this Medicaid Dataset?"
          />
        )
      },
      { type: 'layer', showCloseIcon: true, onClose: () => closeModal() }
    );
  };

  const onDeleteMedicaidDataset = (id: string) => {
    deleteMedicaidDataset(id);
    closeModal();
  };

  const [refreshingDataset, setRefreshingDataset] = useState<MedicaidDataset>();

  const handleRefreshDataset = (dataset: MedicaidDataset) => {
    setRefreshingDataset(dataset);
  };

  const handleCloseRefreshDatasetDialog = () => {
    setRefreshingDataset(undefined);
  };

  const columns: ColumnConfig<MedicaidDataset>[] = [
    {
      property: 'id',
      header: 'ID',
      render: dataset => (
        <TextLink href={`https://data.medicaid.gov/dataset/${dataset.id}`} target="_blank" referrerPolicy="no-referrer">
          {dataset.id}
        </TextLink>
      )
    },
    { property: 'title', header: 'Title' },
    { property: 'year', header: 'Year' },
    {
      property: 'lastImportedAt',
      header: 'Last Imported At',
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      render: (dataset) => <Timestamp value={dataset.lastImportedAt} />
    },
    ...insertIf(
      canManageMedicaidDatasets,
      {
        property: 'actions',
        size: 'xsmall',
        sortable: false,
        render: (dataset: MedicaidDataset) => (
          <div className="flex flex-row">
            <Button
              plain
              title={`Refresh ${dataset.title}`}
              aria-label={`Refresh ${dataset.title}`}
              onClick={() => handleRefreshDataset(dataset)}
            >
              <HiArrowPath className="size-6"/>
            </Button>

            <Button
              plain
              title={`Delete ${dataset.title}`}
              aria-label={`Delete ${dataset.title}`}
              onClick={() => openDeleteModal(dataset.id)}
            >
              <HiTrash className="size-6"/>
            </Button>
          </div>
        )
      }
    )
  ];

  return (
    <>
      <Seo title="Medicaid Datasets" />
      <PageTitleRow title="Medicaid Datasets">
        <Visible when={canManageMedicaidDatasets}>
          <div className="flex">
            <Button color="secondary" aria-label="Add New Medicaid Dataset" onClick={onCreateMedicaidDataset}>
              <LiaPlusSolid />
              Add New
            </Button>
          </div>
        </Visible>
      </PageTitleRow>
      <div className="flex max-w-full flex-col pb-6">
        <div className="mt-3 flex max-w-full flex-col">
          <MedicaidDatasetsFilters defaultValue={filters} onSearch={setFilters} />
          <DataTable
            columns={columns}
            data={filteredDatasets}
            step={10}
            paginate
            placeholder={
              (loadingMedicaidDatasets || filteredDatasets.length === 0) &&
              <div className="flex size-full flex-col">
                {loadingMedicaidDatasets && <TableLoadingOverlay />}
                {!loadingMedicaidDatasets && filteredDatasets.length === 0 && <TableEmptyPlaceholder content="No Medicaid Dataset data is available." />}
              </div>
            }
          />
        </div>

        <MedicaidSyncDialog
          open={!!refreshingDataset}
          // @ts-expect-error TS(2322): Type 'MedicaidDataset | undefined' is not assignab... Remove this comment to see the full error message
          dataset={refreshingDataset}
          onCancel={handleCloseRefreshDatasetDialog}
          onRequestComplete={handleCloseRefreshDatasetDialog}
        />
      </div>
    </>
  );
};

const MedicaidDatasetsPage = () => {
  return (
    <MedicaidDatasetsProvider>
      <MedicaidDatasets />
    </MedicaidDatasetsProvider>
  );
};

export default MedicaidDatasetsPage;
