import { Box, CheckBox } from 'grommet';
import React from 'react';

import { Lookup } from '@/types/lookup';

type SelectOptionProps<TLookupId> = {
  option: Lookup<TLookupId>;
  isMulti?: boolean;
  selected?: boolean;
  active?: boolean;
}

export const SelectOption = <TLookupId,>({ option, isMulti, selected, active }: SelectOptionProps<TLookupId>) => {
  let backgroundColor = !isMulti && selected ? 'brand' : null;
  backgroundColor = !isMulti && active ? 'light-2' : backgroundColor;

  return (
    <Box
      pad="small"
      margin={option.nested ? { left: 'medium' } : undefined}
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'Ba... Remove this comment to see the full error message
      background={backgroundColor}
    >
      {isMulti ? (
        <CheckBox
          checked={selected}
          label={option.label}
        />
      ) : (option.label)}
    </Box>
  );
};
