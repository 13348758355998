import { useErrorHandler } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { User } from '@/features/admin/types/user';
import { Client } from '@/features/clients';
import { makeClientQueryKey } from '@/features/clients/api/use-client';
import { useClientService } from '@/features/clients/api/use-clients-service';

export const useUpdateClientPrimaryAdmin = () => {
  const { updateClientPrimaryAdmin } = useClientService();
  const queryClient = useQueryClient();
  const { success } = useNotifications();
  const { handleError } = useErrorHandler();

  return useMutation({
    mutationFn: ({ clientId, user }: { clientId: number, user: User }) => updateClientPrimaryAdmin(clientId, user.id),
    onSuccess: () => {
      success({ title: 'Saved', message: 'User updated successfully.', autoClose: true });
    },
    onMutate: ({ clientId, user }) => {
      const queryKey = makeClientQueryKey(clientId);
      const previousClient = queryClient.getQueryData<Client>(queryKey);

      if (previousClient) {
        // @ts-expect-error TS(2345): Argument of type '(old: Client | undefined) => { p... Remove this comment to see the full error message
        queryClient.setQueryData<Client>(queryKey, (old) => ({
          ...old,
          primaryUser: {
            name: user.name,
            id: user.id,
          }
        }));
      }

      return { previousClient };
    },
    onError: (error, { clientId, user }, context) => {
      handleError(
        error,
        {
          title: 'Save Failed',
          message: `Unable to make ${user.name} primary admin.`,
          autoClose: true
        }
      );

      if (context?.previousClient) {
        queryClient.setQueryData<Client>(makeClientQueryKey(clientId), context.previousClient);
      }
    },
    onSettled: async (_, __, { clientId }) => {
      await queryClient.invalidateQueries({ queryKey: makeClientQueryKey(clientId) });
    }
  });
};
