import { Box, BoxProps, Text } from 'grommet';
import React, { JSX } from 'react';

type MenuItemProps = {
  icon?: JSX.Element
  label?: JSX.Element | string
}

export const MenuItem = ({ icon, label }: MenuItemProps) => {
  return (
    <Box align="center" direction="row" gap="small">
      {icon && icon}
      {label && <Text>{label}</Text>}
    </Box>
  );
};

type MenuItemsProps = {
  children?: React.ReactNode;
} & BoxProps;

export const MenuItems = ({ children, ...rest }: MenuItemsProps) => {
  return (
    <Box direction="row" justify="end" align="center" {...rest} >
      {children}
    </Box>
  );
};
