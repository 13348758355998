import { matchPath, useLocation } from 'react-router-dom';

import { Path } from '@/router';

export const useIsCurrentRoute = () => {
  const { pathname } = useLocation();

  return (route: Path) => {
    return Boolean(matchPath(`${route}/*`, pathname));
  };
};
