import * as Headless from '@headlessui/react';
import React from 'react';
import { toast, useToaster } from 'react-hot-toast/headless';

import { Notification } from '@/components-new/notification';

export const NotificationsRoot = () => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause } = handlers;

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        {toasts.map((toastInstance) => {
          const { message, visible, id, ariaProps } = toastInstance;
          return (
            <Headless.Transition key={id} appear show={visible}>
              {typeof message === 'string' && (
                <Notification onClose={() => toast.dismiss(id)} ariaProps={ariaProps}>
                  {message}
                </Notification>
              )}
              {typeof message === 'function' && message(toastInstance)}
            </Headless.Transition>
          );
        })
        }
      </div>
    </div>
  );
};
