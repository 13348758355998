/**
 * Get the URL to redirect to after login.
 */
export const getPostLoginRedirectUrl = (): string => {
  const redirectUrl = window.sessionStorage.getItem('postLoginRedirectUrl');
  window.sessionStorage.removeItem('postLoginRedirectUrl');

  return redirectUrl || '/';
};

/**
 * Set the URL to redirect to after login.
 *
 * @param url The URL to redirect to after login.
 */
export const setPostLoginRedirectUrl = (url: string) => {
  window.sessionStorage.setItem('postLoginRedirectUrl', url);
};
