import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import {
  DefaultOptions, OmitKeyof, Query,
  QueryClient, QueryKey,
  UseMutationOptions,
} from '@tanstack/react-query';
import { PersistQueryClientOptions } from '@tanstack/react-query-persist-client';

export const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 1000 * 60,
    gcTime: 1000 * 60 * 60 * 24, // 24 hours
  },
} satisfies DefaultOptions;

export const queryClient = new QueryClient({
  defaultOptions: queryConfig,
});

export const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
  throttleTime: 0 // this normally defaults to 1000ms, certain tasks will fail due to navigation away from the app
});

/**
 * Determines whether the query should be persisted in the cache.
 */
const shouldDehydrateQuery = (query: Query<unknown, Error, unknown, QueryKey>) => {
  const queryIsReadyForPersistance = query.state.status === 'success';

  if (queryIsReadyForPersistance) {
    const { meta } = query.options;
    return !!meta?.persist;
  }

  return queryIsReadyForPersistance;
};

export const persistOptions:  OmitKeyof<PersistQueryClientOptions, 'queryClient'> = {
  persister: localStoragePersister,
    dehydrateOptions: {
    shouldDehydrateQuery: shouldDehydrateQuery
  }
};

export type ApiFnReturnType<FnType extends (...args: any) => Promise<any>> =
  Awaited<ReturnType<FnType>>;

export type QueryConfig<T extends (...args: any[]) => any> = Omit<
  ReturnType<T>,
  'queryKey' | 'queryFn'
>;

export type MutationConfig<
  MutationFnType extends (...args: any) => Promise<any>,
> = UseMutationOptions<
  ApiFnReturnType<MutationFnType>,
  Error,
  Parameters<MutationFnType>[0]
>;
