import { FormField, Text, TextInput } from 'grommet';
import { KeyboardEvent,ReactNode, useRef, useState } from 'react';

import { ArtiaButton } from '@/components/artia-button';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import {
  PromptContext,
  PromptContextValue,
  PromptFn,
  PromptOptions
} from '@/components/interactions/prompt-context';

type InteractionsContextProviderProps = {
  children: ReactNode;
};

export const PromptContextProvider = ({ children }: InteractionsContextProviderProps) => {
  const [promptOptions, setPromptOptions] = useState<PromptOptions | null>();
  const awaitingPromiseRef = useRef<{ resolve: (value?: string) => void } >();
  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [promptValue, setPromptValue] = useState<string>(null);

  const prompt: PromptFn = ({ cancelText = 'Cancel', confirmText = 'Submit', defaultValue, ...rest }: PromptOptions) => {
    setPromptOptions({ confirmText, cancelText, defaultValue, ...rest });
    setPromptValue(defaultValue ?? '');

    return new Promise((resolve) => {
      // @ts-expect-error TS(2322): Type '(value: string | PromiseLike<string>) => voi... Remove this comment to see the full error message
      awaitingPromiseRef.current = { resolve };
    });
  };

  const closePrompt = () => {
    setPromptOptions(null);
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setPromptValue(null);
  };

  const handlePromptCancel = () => {
    closePrompt();
  };

  const handlePromptConfirm = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(promptValue);
    }

    closePrompt();
  };

  const contextValue: PromptContextValue = {
    prompt
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;

    if (key === 'Enter') {
      handlePromptConfirm();
      event.stopPropagation();
    }

    if (key === 'Escape') {
      handlePromptCancel();
      event.stopPropagation();
    }
  };

  return (
    <PromptContext.Provider value={contextValue}>
      {children}
      {!!promptOptions && (
        <Dialog
          open={!!promptOptions}
          width="medium"
          onKeyDown={handleOnKeyDown}
        >
          <DialogHeader title={promptOptions.title} />
          <DialogBody>
            <Text>{promptOptions.text}</Text>
            <FormField name="promptValue" margin={{ top: 'small' }}>
              <TextInput
                name="promptValue"
                value={promptValue}
                onChange={({ target }) => setPromptValue(target.value)} />
            </FormField>
          </DialogBody>
          <DialogActions>
            <ArtiaButton
              label={promptOptions.cancelText}
              type="button"
              onClick={handlePromptCancel}
              size="large"
              variant="outlined"
            />
            <ArtiaButton
              onClick={handlePromptConfirm}
              label={promptOptions.confirmText}
              type="submit"
              size="large"
            />
          </DialogActions>
        </Dialog>
      )}
    </PromptContext.Provider>
  );
};
