import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { Box, Card, CardBody, CardHeader, CheckBox, DataTable, RadioButtonGroup } from 'grommet';
import { Add, Trash } from 'grommet-icons';
import React, { useEffect, useMemo, useState } from 'react';

import { ArtiaButton } from '@/components/artia-button';
import { TableEmptyPlaceholder } from '@/components/loading';
import { useBidRecommendationsService } from '@/features/bid-analysis/api/bid-recommendations-service';
import { BidRecommendation, BidRecommendationDrugPackaging } from '@/features/bid-analysis/types/bid-recommendation';

import { useBidAnalysisDetails } from '../../../bid-analysis-details.provider';
import { NumericReadonlyCell } from '../numeric-readonly-cell';
import * as BidRecommendationFunctions from './bid-recommendation.functions';
import { BidRecommendationPackagingFormInputs } from './bid-recommendation-packaging-form-inputs';
import { BidRecommendationTitle } from './bid-recommendation-title';
import { DescriptionCell } from './description-cell';
import { NdcCell } from './ndc-cell';
import { NumericInlineEditCell } from './numeric-inline-edit-cell';
import { TextInlineEditCell } from './text-inline-edit-cell';

type BidRecommendationDetailsProps = {
  bidRecommendation: BidRecommendation;
  onDelete: (bidRecommendation: BidRecommendation) => void;
  onAddPackaging: (bidRecommendation: number) => void;
  onDeletePackagings: (recommendation: BidRecommendation, packagingIds: number[]) => void;
};

export const BidRecommendationDetails = (props: BidRecommendationDetailsProps) => {
  const {
    bidRecommendation,
    onDelete,
    onAddPackaging,
    onDeletePackagings
  } = props;

  const { bidAnalysis } = useBidAnalysisDetails();
  const { updateBidRecommendation, updatePackaging, updateHistoricalPackaging } = useBidRecommendationsService();
  const { handleError } = useErrorHandler();

  const [ defaultBidRecommendation, setDefaultBidRecommendation ] = useState(bidRecommendation);

  useEffect(() => {
    setDefaultBidRecommendation(bidRecommendation);
  }, [bidRecommendation]);

  const [ focusedFieldId, setFocusedFieldId ] = useState<string | undefined>();
  const [ savingFieldId, setSavingFieldId ] = useState<string | undefined>();

  const editingFieldId = useMemo(() => savingFieldId ?? focusedFieldId, [focusedFieldId, savingFieldId]);

  useEffect(() => {
    if (focusedFieldId && !savingFieldId) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      setTimeout(() => document.getElementById(focusedFieldId).focus());
    }
  }, [focusedFieldId, savingFieldId]);

  const updateBidRecommendationAsync = useAsync(updateBidRecommendation);

  useEffect(() => {
    const { status, error, value } = updateBidRecommendationAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleSaveError();
      handleError(
        error,
        {
          title: 'Bid Recommendation Update Failed',
          message: 'Unable to update Bid Recommendation.',
          autoClose: false
        }
      );

      return;
    }

    setDefaultBidRecommendation({
      ...defaultBidRecommendation,
      title: value.title
    });
    handleSaveComplete();
  }, [updateBidRecommendationAsync.status]);

  const updatePackagingAsync = useAsync(updatePackaging);
  const updateHistoricalPackagingAsync = useAsync(updateHistoricalPackaging);

  useEffect(() => {
    const { status, error, value } = updatePackagingAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleSaveError();
      handleError(
        error,
        {
          title: 'Drug Packaging Update Failed',
          message: 'Unable to update Bid Recommendation Drug Packaging.',
          autoClose: false
        }
      );

      return;
    }

    setDefaultBidRecommendation({
      ...defaultBidRecommendation,
      packagings: defaultBidRecommendation.packagings.map(packaging =>
        packaging.id === value.id ? value : packaging
      )
    });

    handleSaveComplete();
  }, [updatePackagingAsync.status]);

  useEffect(() => {
    const { status, error, value } = updateHistoricalPackagingAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleSaveError();
      handleError(
        error,
        {
          title: 'Drug Packaging Update Failed',
          message: 'Unable to update Bid Recommendation Drug Packaging.',
          autoClose: false
        }
      );

      return;
    }

    setDefaultBidRecommendation({
      ...defaultBidRecommendation,
      packagings: defaultBidRecommendation.packagings.map(packaging =>
        packaging.id === value.id ? value : packaging
      )
    });

    handleSaveComplete();
  }, [updateHistoricalPackagingAsync.status]);

  const handleFocus = (id: string) => {
    setFocusedFieldId(id);
  };

  const handleCancel = () => {
    setFocusedFieldId(undefined);
  };

  const handleSaveTitle = (
    fieldId: string,
    form: { title: string },
    isHistorical: boolean
  ) => {
    if (savingFieldId) {
      return;
    }

    setSavingFieldId(fieldId);
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    updateBidRecommendationAsync.execute(bidAnalysis.id, defaultBidRecommendation.id, { ...form }, isHistorical);
  };

  const handleSavePackaging = (
    bidRecommendation: BidRecommendation,
    row: BidRecommendationDrugPackaging,
    fieldId: string,
    form: BidRecommendationPackagingFormInputs
  ) => {
    if (savingFieldId) {
      return;
    }

    setSavingFieldId(fieldId);

    const { fieldName } = BidRecommendationFunctions.parseFieldId(fieldId);
    const value = form[fieldName];

    if (bidRecommendation.isHistorical) {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      updateHistoricalPackagingAsync.execute(bidAnalysis.id, bidRecommendation.id, row.id, fieldName, value);
      return;
    }

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    updatePackagingAsync.execute(bidAnalysis.id, defaultBidRecommendation.id, row.id, fieldName, value);
  };

  const handleSaveComplete = () => {
    if (focusedFieldId === savingFieldId) {
      setFocusedFieldId(undefined);
    }

    setSavingFieldId(undefined);
  };

  const handleSaveError = () => {
    setFocusedFieldId(undefined);
    setSavingFieldId(undefined);
  };

  const [selectedPackagings, setSelectedPackagings] = useState<number[]>([]);

  useEffect(() => {
    setSelectedPackagings(selectedPackagings.filter(id => bidRecommendation.packagings.some(packaging => packaging.id === id)));
  }, [bidRecommendation.packagings]);

  const handleSelectPackaging = (packagingId: number, checked: boolean) => {
    if (checked) {
      setSelectedPackagings([...selectedPackagings, packagingId]);
    } else {
      setSelectedPackagings(selectedPackagings.filter(id => id !== packagingId));
    }
  };

  const isCA = useMemo(() =>
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      bidAnalysis.stateBenefits.length === 1 && bidAnalysis.stateBenefits[0].state.code === 'CA',
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    [bidAnalysis.stateBenefits]
  );

  const isTX = useMemo(() =>
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      bidAnalysis.stateBenefits.length === 1 && bidAnalysis.stateBenefits[0].state.code === 'TX',
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    [bidAnalysis.stateBenefits]
  );

  const [ percentType, setPercentType ] = useState('wac');
  const handlePercentTypeChange = (event: any) => {
    setPercentType(event.value);
  };

  const showPercentWac = useMemo(() => percentType === 'wac', [percentType]);

  return (
    <Card key={defaultBidRecommendation.id} margin="small" style={{ background: '#fff' }} pad={{ bottom: 'small' }}>
      <CardHeader pad="small">
        <Box fill="horizontal" direction="row" justify="between">
          <Box width="medium">
            <BidRecommendationTitle
              bidRecommendation={defaultBidRecommendation}
              // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
              editingFieldId={editingFieldId}
              // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
              savingFieldId={savingFieldId}
              onFocus={handleFocus}
              onCancel={handleCancel}
              onSave={handleSaveTitle}
            />
          </Box>
          <Box direction="row" gap="medium">
            {isCA && <RadioButtonGroup
              name="percentType"
              value={percentType}
              onChange={handlePercentTypeChange}
              options={[
                { label: '%WAC', value: 'wac' },
                { label: '%AMP', value: 'amp' }
              ]}
              direction="row"
            />}
            <ArtiaButton
              icon={<Trash/>}
              label="Delete"
              a11yTitle="Delete Bid Recommendation"
              tip="Delete Bid Recommendation"
              onClick={() => onDelete(bidRecommendation)}
            />
          </Box>
        </Box>
      </CardHeader>
      <CardBody pad={{ horizontal: 'medium' }}>
        <Box fill overflow={{ horizontal: 'scroll' }}>
          <DataTable
            columns={[
              {
                property: 'select',
                pin: true,
                size: !bidRecommendation.isHistorical ? '0rem' : 'xxsmall',
                render: (row) => (
                  bidRecommendation.isHistorical && <Box width="xxsmall" align="center" justify="center">
                    <CheckBox key={row.id} checked={selectedPackagings.includes(row.id)} onChange={(event) => handleSelectPackaging(row.id, event.target.checked)} />
                  </Box>
                )
              },
              {
                property: 'ndc',
                header: 'NDC',
                size: '8rem',
                primary: true,
                pin: true,
                render: (row) => <NdcCell
                  bidRecommendation={bidRecommendation}
                  packaging={row}
                  editingFieldId={editingFieldId}
                  savingFieldId={savingFieldId}
                  onFocus={handleFocus}
                  onCancel={handleCancel}
                  onSave={handleSavePackaging}
                  cellWidth="8rem"
                />
              },
              {
                property: 'description',
                header: 'Description',
                size: '18rem',
                pin: true,
                render: (row) => <DescriptionCell
                  bidRecommendation={bidRecommendation}
                  packaging={row}
                  editingFieldId={editingFieldId}
                  savingFieldId={savingFieldId}
                  onFocus={handleFocus}
                  onCancel={handleCancel}
                  onSave={handleSavePackaging}
                  cellWidth="18rem"
                />
              },
              {
                property: 'wacUnitPrice',
                header: 'WAC',
                align: 'end',
                size: '12rem',
                render: (row) => <Box width="12rem">
                  {bidRecommendation.isHistorical ?
                    <NumericInlineEditCell
                      fieldName="wacUnitPrice"
                      fieldType="currency"
                      bidRecommendation={defaultBidRecommendation}
                      drugPackaging={row}
                      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                      editingFieldId={editingFieldId}
                      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                      savingFieldId={savingFieldId}
                      onFocus={handleFocus}
                      onCancel={handleCancel}
                      onSave={handleSavePackaging}
                    /> :
                    <NumericReadonlyCell
                      value={row.wacUnitPrice?.toString() ?? ''}
                      type="currency"
                    />
                  }
                </Box>
              },
              {
                property: 'amp',
                header: isCA || isTX ? 'AMP' : '',
                align: 'end',
                size: isCA || isTX ? '12rem' : '0rem',
                render: (row) =>
                  isCA || isTX ? <NumericInlineEditCell
                    fieldName="amp"
                    fieldType="currency"
                    bidRecommendation={defaultBidRecommendation}
                    drugPackaging={row}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    editingFieldId={editingFieldId}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    savingFieldId={savingFieldId}
                    onFocus={handleFocus}
                    onCancel={handleCancel}
                    onSave={handleSavePackaging}
                  /> : <></>
              },
              {
                property: 'ura',
                header: 'URA',
                align: 'end',
                size: '12rem',
                render: (row) => <Box width="12rem">
                  {bidRecommendation.isHistorical ?
                    <NumericInlineEditCell
                      fieldName="ura"
                      fieldType="currency"
                      bidRecommendation={defaultBidRecommendation}
                      drugPackaging={row}
                      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                      editingFieldId={editingFieldId}
                      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                      savingFieldId={savingFieldId}
                      onFocus={handleFocus}
                      onCancel={handleCancel}
                      onSave={handleSavePackaging}
                    /> :
                    <NumericReadonlyCell
                      value={row.ura?.toString() ?? ''}
                      type="currency"
                    />
                  }
                </Box>
              },
              {
                property: showPercentWac ? 'uraPercentWac' : 'uraPercentAmp',
                header: showPercentWac ? 'URA % WAC' : 'URA % AMP',
                align: 'end',
                size:  '12rem',
                render: (row) => <Box width="12rem">
                  {bidRecommendation.isHistorical ?
                    <NumericInlineEditCell
                      fieldName={showPercentWac ? 'uraPercentWac' : 'uraPercentAmp'}
                      fieldType="percent"
                      bidRecommendation={defaultBidRecommendation}
                      drugPackaging={row}
                      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                      editingFieldId={editingFieldId}
                      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                      savingFieldId={savingFieldId}
                      onFocus={handleFocus}
                      onCancel={handleCancel}
                      onSave={handleSavePackaging}
                    /> :
                    <NumericReadonlyCell
                      value={showPercentWac ? (row.uraPercentWac * 100)?.toString() ?? '' : (row.uraPercentAmp * 100)?.toString() ?? ''}
                      type="percent"
                    />
                  }
                </Box>
              },
              {
                property: 'supplementalRebate',
                header: 'Supplemental Rebate',
                align: 'end',
                size: '12rem',
                render: (row) =>
                  <NumericInlineEditCell
                    fieldName="supplementalRebate"
                    fieldType="currency"
                    bidRecommendation={defaultBidRecommendation}
                    drugPackaging={row}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    editingFieldId={editingFieldId}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    savingFieldId={savingFieldId}
                    onFocus={handleFocus}
                    onCancel={handleCancel}
                    onSave={handleSavePackaging}
                  />
              },
              {
                property: showPercentWac ? 'supplementalRebatePercentWac' : 'supplementalRebatePercentAmp',
                header: showPercentWac ? 'Supplemental Rebate % WAC' : 'Supplemental Rebate % AMP',
                align: 'end',
                size: '12rem',
                render: (row) =>
                  <NumericInlineEditCell
                    fieldName={showPercentWac ? 'supplementalRebatePercentWac' : 'supplementalRebatePercentAmp'}
                    fieldType="percent"
                    bidRecommendation={defaultBidRecommendation}
                    drugPackaging={row}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    editingFieldId={editingFieldId}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    savingFieldId={savingFieldId}
                    onFocus={handleFocus}
                    onCancel={handleCancel}
                    onSave={handleSavePackaging}
                  />
              },
              {
                property: 'gnup',
                header: 'GNUP',
                align: 'end',
                size: '12rem',
                render: (row) =>
                  <NumericInlineEditCell
                    fieldName="gnup"
                    fieldType="currency"
                    bidRecommendation={defaultBidRecommendation}
                    drugPackaging={row}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    editingFieldId={editingFieldId}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    savingFieldId={savingFieldId}
                    onFocus={handleFocus}
                    onCancel={handleCancel}
                    onSave={handleSavePackaging}
                  />
              },
              {
                property: 'totalRebate',
                header: 'Total Rebate',
                align: 'end',
                size: '12rem',
                render: (row) =>
                  <NumericInlineEditCell
                    fieldName="totalRebate"
                    fieldType="currency"
                    bidRecommendation={defaultBidRecommendation}
                    drugPackaging={row}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    editingFieldId={editingFieldId}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    savingFieldId={savingFieldId}
                    onFocus={handleFocus}
                    onCancel={handleCancel}
                    onSave={handleSavePackaging}
                  />
              },
              {
                property: showPercentWac ? 'totalRebatePercentWac' : 'totalRebatePercentAmp',
                header: showPercentWac ? 'Total Rebate % WAC' : 'Total Rebate % AMP',
                align: 'end',
                size: '12rem',
                render: (row) =>
                  <NumericInlineEditCell
                    fieldName={showPercentWac ? 'totalRebatePercentWac' : 'totalRebatePercentAmp'}
                    fieldType="percent"
                    bidRecommendation={defaultBidRecommendation}
                    drugPackaging={row}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    editingFieldId={editingFieldId}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    savingFieldId={savingFieldId}
                    onFocus={handleFocus}
                    onCancel={handleCancel}
                    onSave={handleSavePackaging}
                  />
              },
              {
                property: 'position',
                header: 'Position',
                size: '18rem',
                render: (row) =>
                  <TextInlineEditCell
                    fieldName="position"
                    bidRecommendation={defaultBidRecommendation}
                    drugPackaging={row}
                    editingFieldId={editingFieldId}
                    savingFieldId={savingFieldId}
                    onFocus={handleFocus}
                    onCancel={handleCancel}
                    onSave={handleSavePackaging}
                  />
              },
              {
                property: 'unitsPerRx',
                header: 'Units / Rx',
                align: 'end',
                size: '12rem',
                render: (row) =>
                  <NumericInlineEditCell
                    fieldName="unitsPerRx"
                    fieldType="numeric"
                    bidRecommendation={defaultBidRecommendation}
                    drugPackaging={row}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    editingFieldId={editingFieldId}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    savingFieldId={savingFieldId}
                    onFocus={handleFocus}
                    onCancel={handleCancel}
                    onSave={handleSavePackaging}
                  />
              },
              {
                property: 'uroa',
                header: 'UROA',
                align: 'end',
                size: '12rem',
                render: (row) =>
                  <NumericInlineEditCell
                    fieldName="uroa"
                    fieldType="currency"
                    bidRecommendation={defaultBidRecommendation}
                    drugPackaging={row}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    editingFieldId={editingFieldId}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    savingFieldId={savingFieldId}
                    onFocus={handleFocus}
                    onCancel={handleCancel}
                    onSave={handleSavePackaging}
                  />
              },
              {
                property: 'pharmacyReimbursement',
                header: 'Pharmacy Reimbursement (NADAC)',
                align: 'end',
                size: '12rem',
                render: (row) =>
                  <NumericInlineEditCell
                    fieldName="pharmacyReimbursement"
                    fieldType="currency"
                    bidRecommendation={defaultBidRecommendation}
                    drugPackaging={row}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    editingFieldId={editingFieldId}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    savingFieldId={savingFieldId}
                    onFocus={handleFocus}
                    onCancel={handleCancel}
                    onSave={handleSavePackaging}
                  />
              },
              {
                property: 'netNetCostPerRx',
                header: 'Net Net Cost / Rx',
                align: 'end',
                size: '12rem',
                render: (row) =>
                  <NumericInlineEditCell
                    fieldName="netNetCostPerRx"
                    fieldType="currency"
                    bidRecommendation={defaultBidRecommendation}
                    drugPackaging={row}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    editingFieldId={editingFieldId}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    savingFieldId={savingFieldId}
                    onFocus={handleFocus}
                    onCancel={handleCancel}
                    onSave={handleSavePackaging}
                  />
              },
              {
                property: 'netNetCostPerRxWac',
                header: 'Net Net Cost / Rx (WAC-Based)',
                align: 'end',
                size: '12rem',
                render: (row) =>
                  <NumericInlineEditCell
                    fieldName="netNetCostPerRxWac"
                    fieldType="currency"
                    bidRecommendation={defaultBidRecommendation}
                    drugPackaging={row}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    editingFieldId={editingFieldId}
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    savingFieldId={savingFieldId}
                    onFocus={handleFocus}
                    onCancel={handleCancel}
                    onSave={handleSavePackaging}
                  />
              },
            ]}
            data={defaultBidRecommendation.packagings}
            background={{ body: { opacity: 100, color: 'light-1' } }}
            placeholder={
              (defaultBidRecommendation.packagings.length === 0) &&
              <Box fill>
                {defaultBidRecommendation.packagings.length === 0 && <TableEmptyPlaceholder content="This Bid Recommendation has no packagings." />}
              </Box>
            }
          />
        </Box>
        {bidRecommendation.isHistorical && <Box margin={{ vertical: 'small' }}>
          <Box direction="row" gap="small" justify="end">
            <ArtiaButton
              disabled={selectedPackagings.length === 0}
              icon={<Trash/>}
              label="Delete Packaging(s)"
              a11yTitle="Delete Packaging(s)"
              tip="Delete Packaging(s)"
              onClick={() => onDeletePackagings(bidRecommendation, selectedPackagings)}
            />
            <ArtiaButton
              icon={<Add/>}
              label="Add Packaging"
              a11yTitle="Add Packaging"
              tip="Add Packaging"
              onClick={() => onAddPackaging(bidRecommendation.id)}
            />
          </Box>
        </Box>}
      </CardBody>
    </Card>
  );
};
