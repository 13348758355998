import { useHeaderDetails } from '@shared/header';
import { Box, Spinner } from 'grommet';
import { Add } from 'grommet-icons';
import React, { useEffect } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { useStatesProvider } from '@/features/state-reports/components';

const ManageReportsLayout = () => {
  const {
    columnGroups: { customReports },
    loading
  } = useStatesProvider();

  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();
  useEffect(() => {
    setHeaderDetails({
      documentTitle: 'Custom State Reports',
      pageTitle: 'Custom State Reports',
      pageSubtitle: 'Create, edit, and delete custom state reports.',
      breadcrumbs: [
        { label: 'States', url: '/state-reports' },
        { label: 'Manage Reports', url: '/state-reports/manage-reports' }
      ]
    });

    return () => {
      clearHeaderDetails();
    };
  }, []);

  return (
    <Container>
      <List>
        <ListItem to="new">
          <Add />
          <span>New Report</span>
        </ListItem>
        {loading ? (
          <Box align="center" justify="start" fill pad="medium">
            <Spinner size="medium" />
          </Box>
        ) : (
          customReports.map((report) => (
            <ListItem key={report.id} to={report.id.toString()}>{report.name}</ListItem>
          ))
        )}
      </List>
      <Outlet />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: max-content;
  background: white;
  border-radius: 5px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.global.colors['light-3']};
`;

const ListItem = styled(NavLink)`
  all: unset;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.global.colors['brand']};
    color: ${({ theme }) => theme.global.colors['light-1']};
  }

  // it's not unused, it is used by NavLink to apply the active styles
  /*noinspection CssUnusedSymbol*/
  &.active {
    background: ${({ theme }) => theme.global.colors['accent-1']};
    color: ${({ theme }) => theme.global.colors['light-1']};
  }
`;

export default ManageReportsLayout;
