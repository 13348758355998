import React, { useState } from 'react';

import { useGetStates } from '@/api/use-states';
import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { Button } from '@/components-new/button';
import { ButtonBar } from '@/components-new/button-bar';
import { Checkbox, CheckboxField, CheckboxGroup } from '@/components-new/checkbox';
import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/components-new/dialog';
import { Description, Field, Fieldset, Label } from '@/components-new/fieldset';
import { Input } from '@/components-new/input';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Radio, RadioField, RadioGroup } from '@/components-new/radio';
import { Select } from '@/components-new/select';
import { Seo } from '@/components-new/seo';
import { Textarea } from '@/components-new/textarea';

const NewNotificationPage = () => {
  const { data: stateOptions } = useGetStates();
  const [ selectPriorityStates, setSelectPriorityStates ] = useState(false);
  const [ showConfirmation, setShowConfirmation ] = useState(false);

  const handleRadioButton = (value: boolean) => {
    setSelectPriorityStates(value);
  };

  const handleSend = () => {
    alert('Yippeee');
  };

  return(
    <>
      <Breadcrumbs
        breadcrumbs={[
          { label: 'Notifications', url: '/notifications' }
        ]}
      />
      <Seo title="New Notification"/>
      <PageTitleRow title="New Notification" />
      <div className="flex space-x-6">
        <div className="w-1/2">
            <Field>
              <Label htmlFor="title">Title</Label>
              <Description>This will be used as the subject for Emails.</Description>
              <Input
                id="title"
                name="title"
                type="text"
                placeholder="Title"
              />
            </Field>
            <Field>
              <Label htmlFor="body">Body</Label>
              {/*TODO: wysiwyg-ify this bad boi*/}
              <Textarea
                className=""
                id="body"
                name="body"
                placeholder="This will be a WYSIWYG!"
              />
            </Field>
            <ButtonBar className="">
              <Button href="/notifications" color="plain">Cancel</Button>
              <Button color="secondary" onClick={() => setShowConfirmation(true)}>Next</Button>
            </ButtonBar>
        </div>
        <div className="w-1/2">
        <Fieldset className="mt-6">
          <Label htmlFor="body">Type</Label>
          <Select id="body" name="body">
            <option value="General">General</option>
            <option value="FFS">Fee-for-Service (FFS)</option>
            <option value="MMC">Medicaid Managed Care (MMC)</option>
            <option value="InTheNews">Medicaid in the News</option>
            <option value="AdvantagePlus">Advantage +</option>
          </Select>
        </Fieldset>
        <Fieldset className="mt-6">
          <Field>
            <Label htmlFor="body">Target Audience</Label>
            <Description>Send this to all subscribers or only those who have specific priority states.</Description>
            <RadioGroup name="" className="flex-1" defaultValue={selectPriorityStates ? 'Priority' : 'All'}>
              <RadioField>
                <Radio value="All" onClick={() => handleRadioButton(false)} />
                <Label>All subscribers</Label>
              </RadioField>
              <RadioField>
                <Radio value="Priority" onClick={() => handleRadioButton(true)}/>
                <Label>Subscribers with priority states</Label>
              </RadioField>
            </RadioGroup>
          </Field>

          {selectPriorityStates && <CheckboxGroup className="mt-6 sm:columns-2 md:columns-3">
            {stateOptions.map((stateOption) => {
              return(
                <CheckboxField key={stateOption.code}>
                  <Checkbox
                    value={stateOption.code}
                  />
                  <Label>{stateOption.name}</Label>
                </CheckboxField>
              );
            })}
          </CheckboxGroup>}
        </Fieldset>
        </div>
      </div>

      <Dialog open={showConfirmation} onClose={() => setShowConfirmation(false)} size="xl">
        <DialogTitle>Send This Message?</DialogTitle>
        <DialogBody className="text-sm/6 text-zinc-900 dark:text-white">
          Are you sure you want to send this message to all <strong>General</strong> subscribers

          with priority states: <strong>WA</strong>, <strong>NY</strong>.
        </DialogBody>
        <DialogActions>
          <Button color="plain" onClick={() => setShowConfirmation(false)}>Cancel</Button>
          <Button color="secondary" onClick={handleSend}>Send Message</Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default NewNotificationPage;
