import { useSuspenseQuery } from '@tanstack/react-query';

import { Notification } from '@/features/notifications/types/notification';
import { artiaProtectedApi } from '@/lib/api-client';

const getNotifications = (): Promise<Notification[]> => {
  return artiaProtectedApi.get('notifications');
};

export const useNotifications = () => {
  const { data, error, isFetching, dataUpdatedAt } = useSuspenseQuery({
    queryKey: ['user-notifications'],
    queryFn: async () => getNotifications()
  });

  if (error && !isFetching) throw error;

  return { data, dataUpdatedAt, isFetching };
};
