import { Box } from 'grommet';
import React, { ReactNode } from 'react';

import { ArtiaButton } from '@/components/artia-button';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';

type ConfirmationDialogProps = {
  open: boolean;
  title: ReactNode;
  children: ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ConfirmationDialog = ({ open, title, children, onCancel, onConfirm }: ConfirmationDialogProps) => {
  return (
    <Dialog open={open} width="large">
      <DialogHeader title={title} onClose={onCancel} />
      <DialogBody>
        <Box>
          {children}
        </Box>
      </DialogBody>
      <DialogActions>
        <ArtiaButton label="Cancel" onClick={onCancel} size="large" variant="outlined" />
        <ArtiaButton
          color="accent-1"
          label="Confirm"
          onClick={onConfirm}
          size="large"
        />
      </DialogActions>
    </Dialog>
  );
};
