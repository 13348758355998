import { ProtectedRoute } from '@shared/auth';
import { Outlet } from 'react-router';

const ProductsLayout = () => {
  return (
    <ProtectedRoute>
      <Outlet />
    </ProtectedRoute>
  );
};

export default ProductsLayout;
