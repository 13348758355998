import { Box, FormField, TextInput } from 'grommet';
import { Search } from 'grommet-icons';
import React, { ReactNode } from 'react';

import { GrommetFilterForm } from '@/components/filters';

type DrugMarketDetailsFiltersProps = {
  defaultValue: DrugMarketDetailsFilterData;
  onApplyFilters: (data: DrugMarketDetailsFilterData) => void;
  children?: ReactNode;
};

export const DrugMarketDetailsFilters = ({ defaultValue, onApplyFilters, children }: DrugMarketDetailsFiltersProps) => {
  return (
    <GrommetFilterForm
      variant="table"
      defaultValue={defaultValue}
      emptyValues={{ ndc: '', name: '', product: '' }}
      onChange={(value) => onApplyFilters(value)}
      actions={children}
    >
      <Box width="22rem">
        <FormField
          name="ndc"
          htmlFor="ndc"
          margin="none"
        >
          <TextInput
            type="search"
            icon={<Search color="accent-1" />}
            id="ndc"
            name="ndc"
            placeholder="Search By NDC"
            maxLength={11}
          />
        </FormField>
      </Box>
      <Box width="22rem">
        <FormField
          name="name"
          htmlFor="name"
          margin="none"
        >
          <TextInput
            type="search"
            icon={<Search color="accent-1" />}
            id="name"
            name="name"
            placeholder="Search By Description"
          />
        </FormField>
      </Box>
      <Box width="22rem">
        <FormField
          name="product"
          htmlFor="product"
          margin="none"
        >
          <TextInput
            type="search"
            icon={<Search color="accent-1" />}
            id="product"
            name="product"
            placeholder="Search By Product"
          />
        </FormField>
      </Box>
    </GrommetFilterForm>
  );
};

type DrugMarketDetailsFilterData = { ndc?: string, name?: string, product?: string };
