import { ProtectedRoute } from '@shared/auth';
import { featureManager } from '@shared/feature-flags';
import { Box } from 'grommet';
import { Edit } from 'grommet-icons';
import { findIndex, maxBy, toString } from 'lodash';
import React, { Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { ArtiaButton } from '@/components/artia-button';
import { OverlayLoader } from '@/components/loading';
import { Tabs } from '@/components/tabs';
import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { PageTitleRow } from '@/components-new/page-title-row';
import { useClient } from '@/features/clients/api/use-client';
import { useCurrentUser } from '@/hooks/use-current-user';
import { Path, useNavigate, useParams } from '@/router';
import { insertIf } from '@/utils/arrays';

type Props = {
  clientId: number;
}

type Tab = {
  key: string;
  name: string;
  path: Path
};

const ClientLayoutScreen = ({ clientId }: Props) => {
  const { data: client } = useClient(clientId);
  const location = useLocation();
  const { hasPolicies } = useCurrentUser();
  const canManageClients = hasPolicies(['canManageClients']);

  const tabs: Tab[] = [
    {
      key: 'subscription',
      name: 'Subscriptions',
      path: '/clients/:id',
    },
    {
      key: 'users',
      name: 'Users',
      path: '/clients/:id/users' as Path,
    }
  ];

  const getActiveTabFromRoute = () => {
    const matchingTabs = tabs.filter(
      tab => location.pathname.includes(tab.path.replace(':id', toString(clientId))),
    );

    const bestMatch = maxBy(matchingTabs, 'path.length');

    return bestMatch ? findIndex(matchingTabs, bestMatch) : 0;
  };

  const navigate = useNavigate();

  const handleActiveTabChange = (tabIndex: number) => {
    const selectedTab = tabs[tabIndex];
    navigate(selectedTab.path as any, { params: { id: clientId } });
  };

  return (
    <>
      <Breadcrumbs breadcrumbs={[
        { label: 'Clients', url: '/clients' },
        { label: client.name, url: `/clients/${client.id}` }
      ]} />
      <PageTitleRow title={client.name}>
        <Box direction="row" gap="small">
          {canManageClients && (
            <ArtiaButton
              icon={<Edit/>}
              a11yTitle="Edit Client"
              label="Edit"
              onClick={() => navigate(
                '/clients/:id/edit',
                {
                  params: { id: toString(clientId) },
                  state: { referrer: `/clients/${client.id}` }
                }
              )}
            />
          )}
        </Box>
      </PageTitleRow>
      <div className="mt-6">
        <Tabs
          activeIndex={getActiveTabFromRoute()}
          onSelect={handleActiveTabChange}
          items={tabs.map(tab => ({
            key: tab.key,
            name: tab.name,
            content: (<Outlet />)
          }))}
        />
      </div>
    </>
  );
};

const ClientLayout = () => {
  const { id } = useParams('/clients/:id');

  return (
    <ProtectedRoute policies={['canManageClients']}>
      <Suspense fallback={<OverlayLoader />}>
        <ClientLayoutScreen clientId={parseInt(id)} />
      </Suspense>
    </ProtectedRoute>
  );
};

export default ClientLayout;
