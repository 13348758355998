import { NumericValidators, required } from '@shared/validators';
import { isValidDate } from '@shared/validators/date-time-validators';
import { Box, FormField, Select, TextInput } from 'grommet';
import React from 'react';
import { Control, Controller, DeepRequired, FieldErrorsImpl, GlobalError } from 'react-hook-form';

import { CurrencyTextInput, DateTextInput, NumericTextInput } from '@/components/form-controls';
import { brandGenericStatusOptions } from '@/features/drugs/types/brand-generic-status';

type DrugPackagingsFormProps = {
  formControl: Control<DrugPackagingFormFields>
  errors?:  Partial<FieldErrorsImpl<DeepRequired<DrugPackagingFormFields>>> & { root?: Record<string, GlobalError> & GlobalError };
};

export type DrugPackagingFormFields = {
  brandGenericStatus: string;
  licenseType: string;
  onMarketWacPrice: string;
  onMarketWacUnitPrice: string;
  cmsOnMarketDate: string;
  wacBeginDate: string;
  baselineCpiu: string;
  baselineCpiuDate: string;
};

export const DrugPackagingsForm = (props: DrugPackagingsFormProps) => {
  const {
    formControl,
    errors
  } = props;

  return (
    <Box direction="row" gap="medium">
      <Box basis="1/2" justify="end">
        <Controller
          control={formControl}
          rules={{
            validate: {
              required: required('Brand/Generic Status is required')
            }
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormField
              required
              name="brandGenericStatus"
              htmlFor="brand-generic-status"
              label="Brand/Generic Status"
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              error={errors.brandGenericStatus?.message}
            >
              <Select
                value={value}
                valueKey="value"
                labelKey="label"
                onChange={({ value: nextValue }) => onChange(nextValue.value)}
                onBlur={onBlur}
                options={brandGenericStatusOptions}
                id="brand-generic-status"
                name="brandGenericStatus"
              />
            </FormField>
          )}
          name="brandGenericStatus"
        />
        <Controller
          control={formControl}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormField
              name="onMarketWacPrice"
              htmlFor="on-market-wac-price"
              label="On Market WAC Price"
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              error={errors.onMarketWacPrice?.message}
            >
              <CurrencyTextInput
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                id="on-market-wac-price"
                name="onMarketWacPrice"
              />
            </FormField>
          )}
          name="onMarketWacPrice"
        />
        <Controller
          control={formControl}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormField
              name="onMarketWacUnitPrice"
              htmlFor="on-market-wac-unit-price"
              label="On Market WAC Unit Price"
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              error={errors.onMarketWacUnitPrice?.message}
            >
              <CurrencyTextInput
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                id="on-market-wac-unit-price"
                name="onMarketWacUnitPrice"
              />
            </FormField>
          )}
          name="onMarketWacUnitPrice"
        />
        <Controller
          control={formControl}
          rules={{
            validate: {
              isGreaterThan: NumericValidators.isGreaterThan(0)
            }
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormField
              name="baselineCpiu"
              htmlFor="baseline-cpiu"
              label="Baseline CPI-U"
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              error={errors.baselineCpiu?.message}
            >
              <NumericTextInput
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                id="baseline-cpiu"
                name="baselineCpiu"
                maxWholePlaces={3}
                maxDecimalPlaces={3}
              />
            </FormField>
          )}
          name="baselineCpiu"
        />
      </Box>
      <Box basis="1/2" justify="end">
        <Controller
          control={formControl}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormField
              name="licenseType"
              htmlFor="license-type"
              label="License Type"
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              error={errors.licenseType?.message}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                id="license-type"
                name="licenseType"
              />
            </FormField>
          )}
          name="licenseType"
        />
        <Controller
          control={formControl}
          rules={{
            validate: isValidDate
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormField
              name="cmsOnMarketDate"
              htmlFor="cms-on-market-date"
              label="CMS On Market Date"
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              error={errors.cmsOnMarketDate?.message}
            >
              <DateTextInput
                id="cms-on-market-date"
                name="cmsOnMarketDate"
                value={value}
                onChange={({ target }) => onChange((target as any).value)}
                onBlur={onBlur}
              />
            </FormField>
          )}
          name="cmsOnMarketDate"
        />
        <Controller
          control={formControl}
          rules={{
            validate: isValidDate
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormField
              name="wacBeginDate"
              htmlFor="wac-begin-date"
              label="WAC Begin Date"
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              error={errors.wacBeginDate?.message}
            >
              <DateTextInput
                id="wac-begin-date"
                name="wacBeginDate"
                value={value}
                onChange={({ target }) => onChange((target as any).value)}
                onBlur={onBlur}
              />
            </FormField>
          )}
          name="wacBeginDate"
        />
        <Controller
          control={formControl}
          rules={{
            validate: isValidDate
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormField
              name="baselineCpiuDate"
              htmlFor="baseline-cpiu-date"
              label="Baseline CPI-U Date"
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              error={errors.baselineCpiuDate?.message}
            >
              <DateTextInput
                id="baseline-cpiu-date"
                name="baselineCpiuDate"
                value={value}
                onChange={({ target }) => onChange((target as any).value)}
                onBlur={onBlur}
              />
            </FormField>
          )}
          name="baselineCpiuDate"
        />
      </Box>
    </Box>
  );
};
