import React, { useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

type Tab = (
  { label: React.ReactNode; } &
  ( | { href: string } )
)

export const useTabs = (tabs: Tab[]) => {
  const navigate = useNavigate();
  const location = useLocation();
  const tabByHref = useMemo(() => {
    return tabs.findIndex(tab => matchPath(location.pathname, tab.href));
  }, [location.pathname, tabs]);

  const [ activeTabIndex, setActiveTabIndex ] = useState(tabByHref);

  useEffect(() => {
    if (tabByHref !== -1) {
      setActiveTabIndex(tabByHref);
    }
  }, [tabByHref]);

  const handleOnChange = (index: number) => {
    const tab = tabs[index];
    setActiveTabIndex(index);

    if ('href' in tab) {
      navigate(tab.href);
    }

  };

  return {
    tabs,
    activeTabIndex,
    onChange: handleOnChange
  };
};
