import { Box } from 'grommet';
import React from 'react';

import { EllipsisAnimation } from '@/components/busy/ellipsis-animation';

type BusyProps = {
  content?: string | React.ReactNode;
  busy?: boolean;
  color?: string;
};

export const Busy = ({ content, busy, color = 'white' }: BusyProps) => {
  return (
    <Box style={{ position: 'relative' }}>
      <Box style={{ opacity: busy ? 0 : 1 }}>{content}</Box>
      {busy && <EllipsisAnimation color={color} />}
    </Box>
  );
};
