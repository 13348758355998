import React from 'react';
import { ReactNode } from 'react';

type VisibleProps = {
  fallback?: ReactNode;
  children?: ReactNode;
  when: boolean;
}

export const Visible = ({ children, fallback, when }: VisibleProps) => {
  return when ? <>{children}</> : <>{fallback}</>;
};
