import { Text } from 'grommet';
import React from 'react';
import styled from 'styled-components';

import { MonospaceText } from '@/components/monospace-text';
import {
  NetCostComparisonRow,
  NetCostComparisonRowExpansionArgs
} from '@/features/bid-analysis/types/net-cost-comparison';

import { ExpandCollapseCell } from './expand-collapse-cell';

type ProductCellProps = {
  row: NetCostComparisonRow;
  isRowExpanded: (args: NetCostComparisonRowExpansionArgs) => boolean;
  onRowExpand: (args: NetCostComparisonRowExpansionArgs) => void;
  onRowCollapse: (args: NetCostComparisonRowExpansionArgs) => void;
};

export const ProductCell = (props: ProductCellProps) => {
  const { row, isRowExpanded, onRowExpand, onRowCollapse } = props;

  const jsxMap = {
    tag: <Text>{row.drug?.label + ' ' + row.tag?.label ?? ''}</Text>,
    ndc: <MonospaceText>{row.ndc}</MonospaceText>,
    drug: <Text>{row.drug?.label ?? ''}</Text>,
    state: <Text weight="bold">{row.state?.name ?? ''}</Text>,
    'state-total': <Text weight="bold">Total</Text>,
    'drug-total': <Text weight="bold">{row.drug?.label ?? ''}</Text>,
    'grand-total': <Text weight="bold">Grand Total</Text>
  };

  let margin = '0';

  if (row.rowType === 'tag' || row.rowType === 'ndc' && !row.tag) {
    margin = '2rem';
  } else if (row.rowType === 'ndc') {
    margin = '3rem';
  }

  return (
    <ProductCellContainer margin={margin}>
      {row.rowType === 'drug' || row.rowType === 'tag' ? (
        <ExpandCollapseCell
          expanded={isRowExpanded({
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            stateCode: row.state.code,
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            productId: row.drug.id,
            tagId: row.tag?.id
          })}
          onExpand={() => onRowExpand({
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            stateCode: row.state.code,
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            productId: row.drug.id,
            tagId: row.tag?.id
          })}
          onCollapse={() => onRowCollapse({
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            stateCode: row.state.code,
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            productId: row.drug.id,
            tagId: row.tag?.id
          })}
        />
      ) : null}
      {jsxMap[row.rowType]}
    </ProductCellContainer>
  );
};

const ProductCellContainer = styled.div<{ margin?: string }>`
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-left: ${({ margin }) => margin};
`;
