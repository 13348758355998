import { User } from 'oidc-client-ts';
import React from 'react';

import { Heading } from '@/components-new/heading';
import { Text } from '@/components-new/text';

const WelcomeMessage = ({ user }: { user: User }) => {
  const name = user.profile.name;

  return (
    <div className="overflow-hidden rounded-lg border border-gray-300 bg-white">
      <div className="flex flex-col items-center gap-4 px-6 py-5 sm:flex-row">
        <img
          className="h-14 w-auto"
          src="/artia-capsule-logo.png"
          alt="artia capsule logo"
        />
        <div>
          <Heading level={3} className="font-serif">Welcome back, {name}.</Heading>
          <Text>Quickly access your most important tasks.</Text>
        </div>
      </div>
    </div>
  );
};

export { WelcomeMessage };
