import React from 'react';

import { formatNumber } from '@/utils/formatting';

export const IntegerCell = ({ number }: { number?: number | null }) => {
  if (!number && number !== 0) {
    return <>&mdash;</>;
  }

  return <>{formatNumber(number, 0, 0)}</>;
};
