import { policyChecker } from '@shared/auth/authorize';
import { useAuth } from 'react-oidc-context';

export const useAuthorization = () => {
  const { user } = useAuth();
  // @ts-expect-error TS(2345): Argument of type 'User | null | undefined' is not ... Remove this comment to see the full error message
  const checkPolicies = policyChecker(user);

  return { checkPolicies };
};
