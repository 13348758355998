import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { PaginationResult } from '@shared/pagination';
import { useEffect, useRef, useState } from 'react';

import { Lookup } from '@/types/lookup';

type Props<TLookupId> = {
  value?: Lookup<TLookupId>[];
  resultsPerPage: number;
  lazyLoadRequest: (searchTerm: string, page: number, rpp: number) => Promise<PaginationResult<Lookup<TLookupId>>>;
  dependencies?: unknown[];
};

type SearchState = {
  searchTerm: string;
  page: number;
};

/**
 * Helper hook for managing lazy-loaded select lists
 * @param value the values currently selected, will be merged in with the options
 * @param resultsPerPage the number of results per page to use when fetching
 * @param lazyLoadRequest the function to fetch the next set of data
 * @param dependencies any dependencies you want to trigger a refetch of the options, optional
 */
export const useLazyLoadOptions = <TLookupId>({ value = [], resultsPerPage, lazyLoadRequest, dependencies = [] }: Props<TLookupId>) => {
  const [{ searchTerm, page }, setState] = useState<SearchState>({
    searchTerm: '',
    page: 1
  });
  const request = useAsync(lazyLoadRequest);
  const [options, setOptions] = useState<Lookup<TLookupId>[]>(value);
  const { handleError } = useErrorHandler();

  const makeRequest = async (searchTerm: string, page: number, rpp: number) => {
    try {
      return await request.execute(searchTerm, page, rpp);
    } catch (e) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      handleError(e, {
        title: 'Couldn\'t load options',
        message: 'Data for the page could not be loaded. If this issue persists, try again later or contact an administrator.',
        autoClose: false,
      });

      throw e;
    }
  };

  // load initial data or when the dependencies change
  useEffect(() => {
    // also reset the page
    setState((current) => ({ ...current, page: 1 }));
    makeRequest(searchTerm, 1, resultsPerPage)
      .then(({ items }) => setOptions(mergeAndDedupe(value.sort((a, b) => a.label.localeCompare(b.label)), items)));
  }, [...dependencies]);

  const timeoutRef = useRef(null);
  const handleSearch = (searchTerm: string) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    // @ts-expect-error TS(2322): Type 'Timeout' is not assignable to type 'null'.
    timeoutRef.current = setTimeout(async () => {
      setState({ searchTerm, page: 1 });
      const { items } = await makeRequest(searchTerm, 1, resultsPerPage);
      setOptions(searchTerm ? items : mergeAndDedupe(value.sort((a, b) => a.label.localeCompare(b.label)), items));
    }, 500);
  };

  const handleLoadMore = async () => {
    const nextPage = page + 1;
    setState((current) => ({ ...current, page: nextPage }));
    const { items } = await makeRequest(searchTerm, nextPage, resultsPerPage);

    // when retrieving the next page, append it to the end of the current list instead of overriding it
    setOptions((current) => mergeAndDedupe(current, items));
  };

  const handleClose = () => handleSearch('');

  return {
    options,
    loading: request.status === UseAsyncStatus.Pending,
    handleSearch,
    handleLoadMore,
    handleClose
  };
};

export const mergeAndDedupe = <TLookupId>(...listsToMerge: Lookup<TLookupId>[][]) => {
  const result: Lookup<TLookupId>[] = [];
  const alreadySeenIds = new Set<TLookupId>();

  for (const list of listsToMerge) {
    for (const lookup of list) {
      if (alreadySeenIds.has(lookup.id)) continue;

      alreadySeenIds.add(lookup.id);
      result.push(lookup);
    }
  }

  return result;
};
