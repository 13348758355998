import { useErrorHandler } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Client } from '@/features/clients';
import { makeClientQueryKey } from '@/features/clients/api/use-client';
import { useClientService } from '@/features/clients/api/use-clients-service';
import { SubscriptionFormInputs } from '@/features/clients/models/subscription-form-inputs';

export const useUpdateSubscription = () => {
  const { updateSubscription } = useClientService();
  const queryClient = useQueryClient();
  const { success } = useNotifications();
  const { handleError } = useErrorHandler();

  return useMutation({
    mutationFn: ({ id, form }: { id: number, form: SubscriptionFormInputs }) => updateSubscription(id, form),
    onSuccess: () => {
      success({ title: 'Saved', message: 'Subscription settings were updated.', autoClose: true });
    },
    onMutate: ({ id, form }) => {
      const previousClient = queryClient.getQueryData<Client>(makeClientQueryKey(id));

      if (previousClient) {
        // @ts-expect-error TS(2345): Argument of type '(old: Client | undefined) => { s... Remove this comment to see the full error message
        queryClient.setQueryData<Client>(makeClientQueryKey(id), (old) => ({
          ...old,
          subscription: {
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            ...old.subscription,
            isActive: form.isActive,
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            premiumFeatures: old.subscription.premiumFeatures
              .map(feature => ({
                ...feature,
                isEnabled: form.premiumFeatures.includes(feature.key)
              })),
            // @ts-expect-error TS(2345): Argument of type 'string | null | undefined' is no... Remove this comment to see the full error message
            numberOfUsers: parseInt(form.numberOfUsers),
          },
          trackedClassifications: form.trackedClassifications,
          trackedClinicalsProducts: form.trackedClassifications,
        }));
      }

      return { previousClient };
    },
    onError: (error, { id }, context) => {
      handleError(
        error,
        {
          title: 'Save Failed',
          message: 'Unable to update subscription settings.',
          autoClose: true
        }
      );

      if (context?.previousClient) {
        queryClient.setQueryData<Client>(makeClientQueryKey(id), context.previousClient);
      }
    },
    onSettled: async (_, __, { id }) => {
      await queryClient.invalidateQueries({ queryKey: makeClientQueryKey(id) });
    }
  });
};
