import { Box, FormField, TextInput } from 'grommet';
import { Search } from 'grommet-icons';
import React from 'react';

import { GrommetFilterForm } from '@/components/filters';

type NadacFiltersProps = {
  defaultValue: NadacFilterData;
  onSearch: (data: NadacFilterData) => void;
};

export const NadacFilters = ({ defaultValue, onSearch }: NadacFiltersProps) => {
  return (
    <GrommetFilterForm
      defaultValue={defaultValue}
      emptyValues={{ ndc: '', description: '' }}
      onChange={(value) => onSearch(value)}
    >
      <Box width="medium">
        <FormField
          name="ndc"
          htmlFor="ndc"
          margin="none"
        >
          <TextInput
            type="search"
            icon={<Search color="accent-1"/>}
            id="ndc"
            name="ndc"
            placeholder="Enter an NDC"
          />
        </FormField>
      </Box>
      <Box width="medium">
        <FormField
          name="description"
          htmlFor="description"
          margin="none"
        >
          <TextInput
            type="search"
            icon={<Search color="accent-1"/>}
            id="description"
            name="description"
            placeholder="Search by Description"
          />
        </FormField>
      </Box>
    </GrommetFilterForm>
  );
};

export type NadacFilterData = {
  ndc?: string;
  description?: string;
};
