import { ProtectedRoute } from '@shared/auth';
import { useErrorHandler } from '@shared/errors';
import { useNotifications } from '@shared/notifications';
import { Cubes } from 'grommet-icons';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Seo } from '@/components-new/seo';
import { useProductsService } from '@/products/api/use-products-service';
import { ProductForm } from '@/products/product-form';
import { ProductFormProvider, useProductForm } from '@/products/product-form.provider';

const NewProductPageContainer = () => {
  const { handleError } = useErrorHandler();
  const navigate = useNavigate();
  const { form, resetForm } = useProductForm();
  const { createProduct, uploadDrugPackagingsFile } = useProductsService();
  const { success } = useNotifications();

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);

    try {
      const product = await createProduct(form);

      const triggerSuccessMessage = () => success({ title: 'Drug Created', message: 'Successfully created drug.', autoClose: true });
      const triggerNavigation = () => navigate(`/drugs/${product.id}`);

      const drugPackagingsFile = form.drugPackagingsFile[0];
      if (!drugPackagingsFile) {
        triggerSuccessMessage();
        triggerNavigation();
        return Promise.resolve();
      }

      try {
        await uploadDrugPackagingsFile(drugPackagingsFile, product.id);
        triggerSuccessMessage();
      } catch (fileUploadError) {
        // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        handleError(fileUploadError, {
          title: 'Gold Standard Upload Failed',
          message: 'Drug creation was successful, but the Gold Standard file could not be parsed. Please try importing the data again.',
          autoClose: false
        }, 'warn');
      } finally {
        triggerNavigation();
      }

      resetForm();
    } catch (ex) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      handleError(ex, {
        title: 'Drug Creation Failed',
        message: 'We encountered an unexpected error while creating the Drug.',
        autoClose: false
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    navigate('..');
  };

  return (
    <>
      <Seo title="Add Drug" />
      <Breadcrumbs breadcrumbs={[
        { icon: Cubes, label: 'Drugs', url: '/drugs' },
        { label: 'Add Drug', url: '/drugs/new' }
      ]} />
      <PageTitleRow title="Add Drug" />
      <ProductForm onSubmit={handleSubmit} onCancel={handleCancel} submitting={submitting} />
    </>
  );
};

const NewProductPage = () => {
  return (
    <ProtectedRoute policies={['canManageDrugs']}>
      <ProductFormProvider>
        <NewProductPageContainer />
      </ProductFormProvider>
    </ProtectedRoute>
  );
};

export default NewProductPage;
