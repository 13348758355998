import { Box, Button } from 'grommet';
import { DocumentTime, Edit } from 'grommet-icons';
import React from 'react';

type ActionsCellProps = {
  productId?: number;
  ndc: string;
  needsAttention: boolean;
  onEditClick: (productId: number, ndc: string) => void;
  onOverrideClick: (ndc: string) => void;
};

export const ActionsCell = (props: ActionsCellProps) => {
  const {
    productId,
    ndc,
    needsAttention,
    onEditClick,
    onOverrideClick
  } = props;

  const hasProduct = productId !== undefined && productId !== null;

  const handleEditClick = () => {
    if (productId) {
      onEditClick(productId, ndc);
    }
  };

  const handleOverrideClick = () => {
    onOverrideClick(ndc);
  };

  return (
    <Box direction="row" align="center">
      <Button
        tip={needsAttention ? 'Please resolve missing NDC information before overriding pricing.' : 'Override NDC Pricing'}
        a11yTitle="Override NDC Pricing"
        hoverIndicator="background"
        margin="none"
        disabled={needsAttention}
        icon={<Edit color="accent-1" size="medium"/>}
        onClick={handleOverrideClick}
      />

      <Button
        tip={hasProduct ? 'Edit Historical NDC details' : 'NDC must be associated to a product before you can edit these details'}
        a11yTitle="Edit Historical Details"
        hoverIndicator="background"
        margin="none"
        disabled={!hasProduct}
        icon={<DocumentTime color="accent-1" size="medium" />}
        onClick={handleEditClick}
      />
    </Box>
  );
};
